/**
 * Authentication Service
 */
import axios from 'axios';

let baseUrl = process.env.REACT_APP_API_URL;

async function getBehalfOfAllList(behalfOfUserId) {
	let response = await axios.get(`${baseUrl}/api/leave-request/getLeaveBehalfRequest/${behalfOfUserId}`);
	return response.data;
}

async function getBehalfOf(behalfOfUserId, filter) {
	let response = await axios.get(`${baseUrl}/api/leave-request/getLeaveBehalfRequest/${behalfOfUserId}?from=${filter?.from}&to=${filter?.to}&status=${filter?.status}&user=${filter?.user}`);
	return response.data;
}

async function getLeaveInfo(leaveId) {
	let response = await axios.get(`${baseUrl}/api/leave-request/${leaveId}`);
	return response.data;
}

async function getReporterDetails(currentUserId) {
	let response = await axios.get(`${baseUrl}/api/user/getReporterDetails/${currentUserId}`);
	return response.data;
}

async function behalfOfCancel(data) {
	let response = await axios.patch(`${baseUrl}/api/leave-request/leaveCancel/${data.id}`, data);
	return response.data;
}

async function getAllLeaveRequestByUser(currentUserId) {
	let response = await axios.get(`${baseUrl}/api/leave-request/getLeaveRequest/${currentUserId}`);
	return response.data;
}

async function editMyLeaveDetails(leaveId) {
	let response = await axios.get(`${baseUrl}/api/leave-request/${leaveId}`);
	return response.data;
}

async function getAssociatesAllLeaveList(reportingUserId) {
	let response = await axios.get(`${baseUrl}/api/leave-request/getReportersLeaveRequest/${reportingUserId}`);
	return response.data;
}

async function getAssociatesLeaveList(reportingUserId, filter) {
	let response = await axios.get(`${baseUrl}/api/leave-request/getReportersLeaveRequest/${reportingUserId}?from=${filter.from}&to=${filter.to}&status=${filter.status}&user=${filter.user}`);
	return response.data;
}

async function assLeaveApproveRejectCancel(data) {
	let response = await axios.post(`${baseUrl}/api/leave-balance/bulk`, data);
	return response.data;
}

async function getWfhAllList(wfhUserId) {
	let response = await axios.get(`${baseUrl}/api/leave-request/getLeaveWfhRequest/${wfhUserId}`);
	return response.data;
}

async function getWfh(wfhUserId, filter) {
	let response = await axios.get(`${baseUrl}/api/leave-request/getLeaveWfhRequest/${wfhUserId}?from=${filter?.from}&to=${filter?.to}&status=${filter?.status}&user=${filter?.user}`);
	return response.data;
}

async function getAssociatesAllWfhLeaveList(reportingUserId) {
	let response = await axios.get(`${baseUrl}/api/leave-request/getReportersWfhRequest/${reportingUserId}`);
	return response.data;
}

async function getAssociatesWfhLeaveList(reportingUserId, filter) {
	let response = await axios.get(`${baseUrl}/api/leave-request/getReportersWfhRequest/${reportingUserId}?from=${filter.from}&to=${filter.to}&status=${filter.status}&user=${filter?.user}`);
	return response.data;
}

export default { getBehalfOfAllList, getAssociatesAllLeaveList, assLeaveApproveRejectCancel, getAssociatesLeaveList, editMyLeaveDetails, getAllLeaveRequestByUser, getBehalfOf, getLeaveInfo, getReporterDetails, behalfOfCancel, getWfhAllList, getWfh, getAssociatesAllWfhLeaveList, getAssociatesWfhLeaveList };