/* Import Section - Start */
/* React Imports - Start */

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';

/* React Imports - End */

/* Plugin Imports - Start */

import _ from "lodash";
import Cookies from 'universal-cookie';

/* Plugin Imports - End */

/* Project Components Imports - Start */

import DataTableElement from "../uiComponents/DataTableElement";
import ClientsService from "../../services/clients/ClientsService";
import ClientCreation from "./ClientCreation";
import EmployeesService from "../../services/employees/EmployeesService";
import ToastElement from "../uiComponents/ToastElement";
import Loader from "../uiComponents/Loader";
import ToggleElement from "../uiComponents/ToggleElement";
import { Button } from "primereact/button";

/* Project Components Imports - End */

/* Import Section - End */

/* Function - Start */

const Clients = () => {
    /* State Declaration - Start */
    /* useState - Start */

    const [clientsList, setClientsList] = useState([]);
    const [totalRecords, setTotalRecords] = useState();
    const [totalPages, setTotalPages] = useState();
    const [filter, setFilter] = useState({
        page: 0,
        per_page: 10,
        orderby: 'asc',
        search: ''
    });
    const [button, setButton] = useState(false);
    const [datatable, setDatatable] = useState(true);
    const [create, setCreate] = useState(false);
    const [edit, setEdit] = useState(false);
    const [currentBu, setCurrentBU] = useState();
    const [clientSuffixCode, setClientSuffixCode] = useState();
    const [loading, setLoading] = useState(false);
    const [visible, setvisible] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState();
    const [clientId, setClientId] = useState();

    /* useState - End */

    /* navigate Declaration */

    let navigate = useNavigate();
    const cookies = new Cookies();

    /* useRef - Start */

    const toastRef = useRef();

    /* useRef - End */

    /* useEffect - Start */

    const currentBuId = cookies.get("buId");

    useEffect(() => {
        getClientList(filter, true);
        createButton();
        getActiveBu();
        getClientSuffixCode(currentBuId);
    }, [])

    /* useEffect - End */

    /* State Declaration - End */

    /* Methods - Start */

    //Function for API hit- Client list
    const getClientList = async (filter, isTotalPages) => {
        setLoading(true);
        let clientData = [{}];
        ClientsService.getClients().then(data => {
            setLoading(false);
            // setClientsList(data.data);
            clientData = data.data.data;
            setTotalRecords(data.data.total_items);
            setTotalPages(data.data.total_pages);
            clientData?.sort(function (a, b) {
                var a1 = a.clientName?.toLowerCase();
                var b1 = b.clientName?.toLowerCase();
                return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
            });
            // let clientList = _.orderBy(clientData, ['updatedAt'], ['desc']);
            setClientsList(clientData)
        })
    }

    const getActiveBu = () => {
        EmployeesService.getActiveBu().then(data => {
            var bu = cookies.get('buId')
            if (bu) {
                setCurrentBU(_.find(data.data, { id: bu }))
            }
        })
    }

    const getClientSuffixCode = (currentBuId) => {
        ClientsService.getClientSuffixCode(currentBuId).then(data => {
            setClientSuffixCode(data.data);
        })
    }

    //Function for client Status
    const toggleChanged = (statusId, checked) => {
        var statusData = {
            id: statusId,
            clientStatus: checked
        }
        ClientsService.statusChange(statusData).then(data => {
            !data.posted.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: data.posted.message
            })
            data.posted.success && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated successsfully',
                message: data.posted.message
            })
            getClientList(filter, false);
        })
    }

    // const selectedId = (selectedId) => {
    // }

    const createButton = () => {
        setButton(true);
    }

    const onSuccess = (create, edit, data, createProject, updateProject) => {
        setEdit(edit);
        setCreate(create);
        setDatatable(true);
        if (createProject) {
            !data.posted.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Failed',
                message: data.posted.message
            })
            data.posted.success && toastRef.current.showToast({
                type: 'success',
                summary: 'Successsfully',
                message: data.posted.message
            })
        } else if (updateProject) {
            !data.patched.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Failed',
                message: data.patched.message
            })
            data.patched.success && toastRef.current.showToast({
                type: 'success',
                summary: 'Successsfully',
                message: data.patched.message
            })
        }
        getClientList(filter, true);
        getClientSuffixCode(currentBuId);
    }

    const createView = () => {
        setDatatable(true);
        setCreate(true);
        setEdit(false);
        setvisible(true);
    }

    //On Click Edit User Action Icon
    const editClientDetail = (id) => {
        setClientId(id);
        setEdit(true);
        setvisible(true);
    }

    /* Methods - End */

    /* Render Templates - Start */

    //function for getting yes when Global Client is true
    const globalClientTemplate = (clientData) => {
        if (clientData.isGlobalClient == true) {
            return "Yes"
        } else {
            return "No"
        }
    }

    const onSelectRow = () => {
        return ' '
    }

    const onSelectPage = (e) => {
        if (e.target.classList.contains('pi-angle-left')) {
            filter['page'] = filter.page - 1;
        } else if (e.target.classList.contains('pi-angle-right')) {
            filter['page'] = filter.page + 1;
        }

        getClientList(filter, false);

        // if (filter.page >= 1) {
        //     getClientList(filter, false);
        // } else {
        //     getClientList(filter, true);
        // }

    }

    //Template for Client Status(Approval, reject)
    const statusTemplate = (clientData) => {
        if (clientData.clientStatus === false) {
            return (
                <div className="d-flex toggle-button">
                    <ToggleElement
                        statusId={clientData.id}
                        value={clientData.clientStatus}
                        onChange={(e, v) => toggleChanged(e, v)}
                        onIcon='pi pi-check'
                        offIcon='pi pi-times'
                        className="toggle-button-yes"
                    />
                </div>
            );
        } else if (clientData.clientStatus === true) {
            return (
                <div className="d-flex toggle-button">
                    <ToggleElement
                        statusId={clientData.id}
                        value={clientData.clientStatus}
                        onChange={(e, v) => toggleChanged(e, v)}
                        onIcon='pi pi-check'
                        offIcon='pi pi-times'
                        className="toggle-button-yes"
                    />
                </div>
            );
        }
    };

    //Edit Icon for Action Template
    const editTemplate = (rowData) => {
        return (
            <span onClick={() => editClientDetail(rowData.id)} className="action">
                <i className="pi pi-pencil leave-edit"></i>
            </span>
        )

    }

    /* Render Templates - End */

    //Datatable columns header and templates
    const columns = [
        { field: "clientName", header: "Client Name", sortable: true },
        { field: "clientCode", header: "Client Code", sortable: true },
        { field: "isGlobalClient", header: "Global Client", body: globalClientTemplate, sortable:true },
        { field: "clientStatus", header: "Status", body: statusTemplate, sortable: false },
        { field: "icon", header: "Actions", body: editTemplate, sortable: false }
    ];

    const navi = () => {
        navigate(`/clients`);
    }

    const getSelectedId = (id) => {
        setSelectedClientId(id);
    }

    /* Render View Return - Start */
    const onHide = () => {
        setvisible(false);
        navi();
    }

    return (
        <div className="mt-20" id="client">
            <ToastElement ref={toastRef} />
            <Loader loading={loading} />
            {
                datatable &&
                <DataTableElement
                    value={clientsList}
                    columns={columns}
                    selectedId={getSelectedId}
                    placeholder="Search"
                    totalRecords={totalRecords}
                    totalPages={totalPages}
                    onClickRow={() => onSelectRow()}
                    onPageChange={(e) => onSelectPage(e)}
                    button={button}
                    createButton={
                        <div className="d-flex employee-buttons">
                            <Button label="Create Client" onClick={createView} icon="pi pi-plus" />
                        </div>
                    }
                    className={filter.page === 0 || filter.page === totalPages ? true : false}
                    startIndex={1}
                    paginationView={false}
                    header={true}
                    search={true}
                />
            }
            {
                create &&
                <ClientCreation
                    type={'create'}
                    visible={visible}
                    onHide={onHide}
                    header="Client Creation"
                    onSuccess={(create, edit, data, createProject, updateProject) => onSuccess(create, edit, data, createProject, updateProject)}
                    currentBu={currentBu}
                    clientSuffixCode={clientSuffixCode}
                />
            }
            {
                edit &&
                <ClientCreation
                    type={'edit'}
                    visible={visible}
                    header="Client Update"
                    onHide={onHide}
                    // editData={editData}
                    clientId = {clientId}
                    onSuccess={(create, edit, data, createProject, updateProject) => onSuccess(create, edit, data, createProject, updateProject)}
                    clientSuffixCode={clientSuffixCode}
                />
            }
        </div>
    )

    /* Render View Return - End */
}

/* Function - End */

/* Export default functionName; */

export default Clients;