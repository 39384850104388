/* Import Section - Start */

/* React Imports - Start */

import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'universal-cookie';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { classNames } from 'primereact/utils';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import Moment from 'moment';
/* React Imports - End */

/* Project Components Imports - Start */
import ToastElement from "../uiComponents/ToastElement";
import SingleSelectElement from "../uiComponents/SingleSelectElement";
import DataTableElement from "../uiComponents/DataTableElement";
import Loader from '../uiComponents/Loader';
import PerformanceService from '../../services/controlPanel/performanceService';
import TextboxElement from "../uiComponents/TextboxElement";

/* Project Components Imports - End */

/* Plugin Imports - Start */

import _ from 'lodash';
import CalendarElement from '../uiComponents/CalendarElement';
import { Table } from 'reactstrap';
import moment from 'moment';

/* Plugin Imports - End */

/* Import Section - End */

/* Function - Start */

const PerformanceSettings = () => {

    /* State Declaration - Start */

    /* useState - Start */

    const [loading, setLoading] = useState(false);
    const [buUnits, setBuUnits] = useState([]);
    const [buId, setBuId] = useState("");
    const [currentBuName, setCurrentBuName] = useState("");
    const [ratingData, setRatingData] = useState({});
    const [performanceSchedule, setPerformanceSchedule] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const [expand, setexpand] = useState(true);
    const [extendDate, setExtendDate] = useState('');
    const [formatSchedule, setFormatSchedule] = useState([]);
    const performance = {
        defaultStartDate: '',
        defaultEndDate: null,
        quarter: "",
        QuadStartMonth: "",
        QuadEndMonth: "",
        startYear: '',
        endYear: '',
        scheduling: [
            { by: '', formStatus: '', toStatus: '', label: 'Form Validity', isEnabledOnView: false, startedOn: '', expiredOn: '', extendedTo: '' },
            { by: '', formStatus: '', toStatus: '', label: 'Publish Date', isEnabledOnView: false, startedOn: '', expiredOn: '', extendedTo: '' },
            { by: 'user', formStatus: 'ACCEPT', toStatus: 'USER_SUBMIT', label: 'Appraisee Submit', isEnabledOnView: false, startedOn: '', expiredOn: '', extendedTo: '' },
            { by: 'manager', formStatus: 'USER_SUBMIT', toStatus: 'MANAGER_SUBMIT', label: 'Appraiser Submit', isEnabledOnView: false, startedOn: '', expiredOn: '', extendedTo: '' },
            { by: 'Management Reviewer', formStatus: 'MANAGER_SUBMIT', toStatus: 'MANAGEMENT_REVIEW', label: 'Management Review', isEnabledOnView: false, startedOn: '', expiredOn: '', extendedTo: '' },
            { by: 'reviewer', formStatus: 'MANAGEMENT_REVIEW', toStatus: 'REVIEWER_SUBMIT', label: 'Reviewer Submit', isEnabledOnView: false, startedOn: '', expiredOn: '', extendedTo: '' },
            { by: 'hr', formStatus: 'REVIEWER_SUBMIT', toStatus: 'HR_SUBMIT', label: 'HR Submit', isEnabledOnView: false, startedOn: '', expiredOn: '', extendedTo: '' }
        ]
    };


    /* useState - End */

    /* useRef - Start */

    const toastRef = useRef();

    const cookies = new Cookies();

    /* useRef - Start */

    /* useEffect - Start */

    useEffect(() => {
        getActiveBu();
    }, [])

    /* useEffect - End */

    /* Static JSON  - Start */

    const handleAddClick = (index, ratingDetails) => {
        var ratingDt = [...ratingData];
        const item = { minRange: '', maxRange: '', description: '' };
        ratingDt.push(item);
        setRatingData(ratingDt);
    };

    const handleRemoveClick = (i, type) => {
        var index = i.rowIndex;
        const list = [...ratingData];
        list.splice(index, 1);
        setRatingData(list);
    };

    const editTemplate = (data, index) => {
        return (
            <span>
                <i className="pi pi-plus bu-plus-icon" onClick={() => handleAddClick(index, data)}></i>
                {
                    index.rowIndex != 0 && <i className="pi pi-minus bu-minus-icon" onClick={() => handleRemoveClick(index, data)}></i>
                }
            </span>
        )
    }


    const handleAddSchedulerClick = () => {
        var schedulerDt = [...performanceSchedule];
        schedulerDt.push(performance);
        setPerformanceSchedule(schedulerDt);
    };

    const handleRemoveSchedulerClick = (i) => {
        const list = [...performanceSchedule];
        list.splice(i, 1);
        setPerformanceSchedule(list);
    };

    const onChangeDescription = (e, index, type) => {
        let rating_data = [...ratingData];
        rating_data[index.rowIndex].description = e.target.value;
        setRatingData(rating_data);
    }
    const onChangeMinRange = (e, index, type) => {
        let rating_data = [...ratingData];
        rating_data[index.rowIndex].minRange = e.target.value;
        setRatingData(rating_data);
    }
    const onChangeMaxRange = (e, index, type) => {
        let rating_data = [...ratingData];
        rating_data[index.rowIndex].maxRange = e.target.value;
        setRatingData(rating_data);
    }

    const descriptionTemplate = (data, index) => {
        return (
            <TextboxElement
                value={data.description}
                className='timesheet-comment-box1'
                onChangeText={(e) => onChangeDescription(e, index, data.type)}
            />
        )
    }


    const minRangeTemplate = (data, index) => {
        return (
            <TextboxElement
                value={data.minRange}
                className='timesheet-comment-box'
                onChangeText={(e) => onChangeMinRange(e, index, data.type)}

            />
        )
    }

    const maxRangeTemplate = (data, index) => {
        return (
            <TextboxElement
                value={data.maxRange}
                className='timesheet-comment-box'
                onChangeText={(e) => onChangeMaxRange(e, index, data.type)}
            />
        )
    }


    const ratingCol = [
        { field: "minRange", header: "Performance Score(KRA + Competency)", body: minRangeTemplate, style: { width: '170px' } },
        { field: "maxRange", header: "", body: maxRangeTemplate, style: { width: '170px' } },
        { field: "description", header: "Description", body: descriptionTemplate, style: { width: '200px' } },
        { field: "icon", header: "Action", body: editTemplate, sortable: false, style: { width: '75px' } }
    ];

    const getSelectedId = (id) => {
        return ''
    }

    const footerTemplate = () => {
        return (
            <div className="d-flex justify-end">
                <Button type="submit" icon='pi pi-arrow-right' iconPos="right" label="SUBMIT" className="create-button" onClick={() => submitRatingConfig()} />
            </div>
        )
    }

    const ratingFooter = [
        { field: 'button', footer: footerTemplate, colSpan: '5' },
    ];



    const rowExpansionTemplate = () => {
        return (
            <div className="allocation-expansion">
                {
                    <DataTableElement
                        value={ratingData}
                        columns={ratingCol}
                        selectedId={getSelectedId}
                        colGroup={true}
                        footer={ratingFooter}
                        search={true}
                    />
                }
            </div>
        );
    }

    const submitRatingConfig = () => {
        // var buId = cookies.get('buId');
        let performancedata = {};
        performancedata.buId = buId ? buId : cookies.get('buId');
        performancedata.performanceAppdata = ratingData;
        // setexpand(null)
        PerformanceService.addNewRating(performancedata).then(data => {
            setLoading(false);
            !data.posted.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: data.posted.message
            })
            data.posted.success && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated successsfully',
                message: data.posted.message
            })
        })
    }

    const submitScheduleConfig = () => {
        let formatScheduleDt = performanceSchedule;
        formatScheduleDt.map(fs => {
            if (moment(fs.QuadStartMonth).format("MMM") != 'Invalid date') {
                fs.QuadStartMonth = moment(fs.QuadStartMonth).format("MMM");
            }
            if (moment(fs.QuadEndMonth).format("MMM") != 'Invalid date') {
                fs.QuadEndMonth = moment(fs.QuadEndMonth).format("MMM");
            }
            if (moment(fs.startYear).format("YYYY") != 'Invalid date') {
                fs.startYear = moment(fs.startYear).format("YYYY");
            }
            if (moment(fs.endYear).format("YYYY") != 'Invalid date') {
                fs.endYear = moment(fs.endYear).format("YYYY");
            }
            if (moment(fs.startedOn).format("YYYY-MM-DD") != 'Invalid date') {
                fs.startedOn = moment(fs.startedOn).format("YYYY-MM-DD");
            }
            if (moment(fs.expiredOn).format("YYYY-MM-DD") != 'Invalid date') {
                fs.expiredOn = moment(fs.expiredOn).format("YYYY-MM-DD");
            }
        })
        let data = {
            performanceSchedule: formatScheduleDt
        }
        data.buId = buId ? buId : cookies.get('buId');
        PerformanceService.addNewSchedule(data).then(dataDt => {
            setLoading(false);
            !dataDt.posted.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: dataDt.posted.message
            })
            dataDt.posted.success && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated successsfully',
                message: dataDt.posted.message
            });
            let schedule = formatScheduleDt;
            schedule.map(s => {
                let year = new Date().getFullYear();
                if (s.startYear != null && Date.parse(s.startYear)) {
                    s.startYear = new Date(s.startYear.toString());
                    // s.startYear = s.startYear.toString();
                }
                return s;
            })
            setPerformanceSchedule(schedule);
        })
    }

    const { control, formState: { errors }, reset, setValue, resetField } = useForm({});
    const { handleSubmit } = useForm({});


    /* Static JSON  - End */

    /* Methods - Start */

    const getActiveBu = () => {
        let ratingData = [{ minRange: "", maxRange: "", description: "" }];
        setRatingData(ratingData);
        PerformanceService.getActiveBu().then(response => {
            setBuUnits(response.data)
            var currentBu = cookies.get('buId')
            if (currentBu) {
                setCurrentBuName(_.find(response.data, { id: currentBu }))
                getPerfomanceConfig(currentBu);
            }
        })
    }

    const getPerfomanceConfig = (buId) => {
        setLoading(true);
        PerformanceService.getRatingConfig(buId).then(data => {
            setLoading(false);
            if (data.data.length > 0) {
                let ratingDt = data.data[0].performanceAppdata;
                let schedule = data.data[0].performanceSchedule;
                if (ratingDt) {
                    setRatingData(ratingDt);
                } else {
                    ratingDt = [];
                    const item = { minRange: '', maxRange: '', description: '' };
                    ratingDt.push(item);
                    setRatingData(ratingDt);
                }

                if (schedule && schedule.length > 0) {
                    schedule.map(s => {
                        let year = new Date().getFullYear();
                        if (s.startYear != null) {
                            s.startYear = s.startYear.toString();
                        } else {
                            s.startYear = s.startYear.toString();
                        }
                        if (s.endYear != null) {
                            s.endYear = s.endYear.toString();
                        } else {
                            s.endYear = s.endYear.toString();
                        }
                        if (s.QuadStartMonth != null) {
                            s.QuadStartMonth = new Date(Date.parse(s.QuadStartMonth + " " + year))
                        }
                        if (s.QuadEndMonth != null) {
                            s.QuadEndMonth = new Date(Date.parse(s.QuadEndMonth + " " + year))
                        }
                        s.scheduling.map(sc => {
                            if (sc.startedOn != null) {
                                sc.startedOn = new Date(sc.startedOn);
                            }
                            if (sc.expiredOn != null) {
                                sc.expiredOn = new Date(sc.expiredOn);
                            }
                            if (sc.extendedTo != null) {
                                sc.extendedTo = new Date(sc.extendedTo);
                            }
                            return sc;
                        })
                        return s;
                    })
                    setPerformanceSchedule(schedule);
                } else {
                    schedule = [];
                    schedule.push(performance);
                    setPerformanceSchedule(schedule);
                }
            }
        })
    }

    const getSchedulePerfomanceConfig = (buId) => {
        setLoading(true);
        PerformanceService.getScheduleConfig(buId).then(data => {
            setLoading(false);
            if (data.data.length > 0) {
                let schedulerDt = data.data[0].performanceSchedule;
                if (schedulerDt) {
                    setPerformanceSchedule(schedulerDt);
                } else {
                    schedulerDt = [...performanceSchedule];
                    schedulerDt.push(performance);
                    setPerformanceSchedule(schedulerDt);
                }
            }
        })
    }

    const buChange = (e) => {
        getPerfomanceConfig(e.value.id);
        setCurrentBuName(e.value);
        setBuId(e.value.id)
    }

    const setExtendDateTo = (pIndex, index, date) => {
        let performance = [...performanceSchedule]
        performance[pIndex].scheduling[index].expiredOn = Moment(date).format('YYYY-MM-DD');
        performance[pIndex].scheduling[index].extendedTo = date;
        setPerformanceSchedule(performance);
    }

    const setExtendedDate = (pIndex, index, date) => {
        let performance = [...performanceSchedule]
        performance[pIndex].scheduling[index].extendedTo = Moment(date).format('YYYY-MM-DD');
        setPerformanceSchedule(performance);
    }

    const setStartDateTo = (pIndex, index, date) => {
        let performance = [...performanceSchedule]
        performance[pIndex].scheduling[index].startedOn = Moment(date).format('YYYY-MM-DD');
        setPerformanceSchedule(performance);
    }

    const setQuarter = (pIndex, label) => {
        let performance = [...performanceSchedule]
        performance[pIndex].quarter = label;
        setPerformanceSchedule(performance);
    }


    const setStartMonth = (pIndex, date) => {
        let performance = [...performanceSchedule]
        performance[pIndex].QuadStartMonth = Moment(date).format('MMM');
        setPerformanceSchedule(performance);
    }

    const setEndMonth = (pIndex, date) => {
        let performance = [...performanceSchedule]
        performance[pIndex].QuadEndMonth = Moment(date).format('MMM');
        setPerformanceSchedule(performance);
    }

    const setStartYear = (pIndex, label) => {
        let performance = [...performanceSchedule];
        performance[pIndex].startYear = label;
        setPerformanceSchedule(performance);
    }

    const setEndYear = (pIndex, label) => {
        let performance = [...performanceSchedule]
        performance[pIndex].endYear = label;
        setPerformanceSchedule(performance);
    }

    const optionSelectItems = [
        { label: 'H1', value: 'H1' },
        { label: 'H2', value: 'H2' },
        { label: 'H3', value: 'H3' }
    ];

    const optionSelectYear = [
        { label: '2020', name: '2020', value: '2020' },
        { label: '2021', name: '2021', value: '2021' },
        { label: '2022', name: '2022', value: '2022' },
        { label: '2023', name: '2023', value: '2023' },
        { label: '2024', name: '2024', value: '2024' },
        { label: '2025', name: '2025', value: '2025' },
        { label: '2026', name: '2026', value: '2026' },
        { label: '2027', name: '2027', value: '2027' },
        { label: '2028', name: '2028', value: '2028' },
        { label: '2029', name: '2029', value: '2029' },
        { label: '2030', name: '2030', value: '2030' }
    ];

    /* Methods - End */

    /* Render View Return - Start */


    return (
        <div className="mt-10 perform-set">
            <ToastElement ref={toastRef} />
            <Loader loading={loading} />
            <div className="p-grid m-0">
                <SingleSelectElement
                    placeholder="Select BU"
                    value={currentBuName}
                    options={buUnits}
                    optionLabel="name"
                    onChangeValue={(e) => buChange(e)}
                    defaultValue="code"
                    filterBy="name"
                />
            </div>
            <div className="p-col-12 p-0">
                <form onSubmit={handleSubmit(submitRatingConfig)}>
                    <div className="mt-20 business-unit service-desk-edit request-accordian perform-config">
                        <Accordion className='accordian' activeIndex={[0]}>
                            <AccordionTab header="Rating">
                                <div>
                                    <div className="kraConfig-dt">
                                        {
                                            <DataTableElement
                                                expand={expand}
                                                columns={ratingCol}
                                                expandedRows={expandedRows}
                                                onRowToggle={(e) => setExpandedRows(e.data)}
                                                rowExpansionTemplate={rowExpansionTemplate}
                                                colGroup={true}
                                                value={ratingData}
                                                performancedata={ratingCol}
                                                search={true}
                                            />
                                        }
                                    </div>
                                    <div className="footer-kra-config">
                                        <Button type="submit" label="UPDATE" className="update-button" />
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </form>
                <form className='schedule-form' onSubmit={handleSubmit(submitScheduleConfig)}>
                    <div className="p-grid m-0">
                        <div className='p-col-12 p-0'>
                            <div className="schedule-kra">
                                <p className="fs-13">Schedule</p>
                                <span className='schedule-icon'>
                                    <i className="pi pi-plus bu-plus-icon" onClick={() => handleAddSchedulerClick()}></i>
                                    <i className="pi pi-minus bu-minus-icon" onClick={() => handleRemoveSchedulerClick()}></i>
                                </span>
                            </div>
                            <div className='schedule-config'>
                                {
                                    performanceSchedule.map((performance, pIndex) => {
                                        return (
                                            <Accordion className='accordian' activeIndex={[0]}>
                                                <AccordionTab header={
                                                    <React.Fragment>
                                                        <div className='p-grid m-0'>
                                                            <div className='p-col-12 p-lg-4 p-md-6 p-sm-6'>
                                                                <p className='fs-13'>Half-Yearly</p>
                                                                <SingleSelectElement
                                                                    className="schedule-dropdown"
                                                                    placeholder="Choose your option"
                                                                    options={optionSelectItems}
                                                                    value={performance.quarter}
                                                                    onChangeValue={(e) => setQuarter(pIndex, e.target.value)}
                                                                    filterBy="name"
                                                                />
                                                            </div>
                                                            <div className='p-col-12 p-lg-4 p-md-6 p-sm-6'>
                                                                <p className='fs-13'>Start Month</p>
                                                                <CalendarElement view="month" dateFormat="M" className="schedule-dropdown" placeholder="Select" value={performance.QuadStartMonth} onChange={(e) => setStartMonth(pIndex, e.target.value)} />
                                                            </div>
                                                            <div className='p-col-12 p-lg-4 p-md-6 p-sm-6'>
                                                                <p className='fs-13'>End Month</p>
                                                                <CalendarElement view="month" dateFormat="M" className="schedule-dropdown" placeholder="Select" value={performance.QuadEndMonth} onChange={(e) => setEndMonth(pIndex, e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </React.Fragment>}
                                                    className='config-accord'>
                                                    <div>
                                                        <div className='align-center justify-between px-0 py-9'>
                                                            <h4 className='mx-10 my-0'>Form Validity</h4>
                                                            <div>
                                                                <Button type="submit" label="SUBMIT" />
                                                            </div>
                                                        </div>
                                                        <div className='p-grid m-0'>
                                                            <div className='p-col-12 p-lg-3 p-md-6 p-sm-6'>
                                                                <p className='fs-13 text-lightgray'>Start Year</p>
                                                                <SingleSelectElement
                                                                    className="schedule-dropdown"
                                                                    placeholder="Choose your option"
                                                                    options={optionSelectYear}
                                                                    value={performance.startYear}
                                                                    onChangeValue={(e) => setStartYear(pIndex, e.target.value)}
                                                                    filterBy="label"
                                                                />
                                                            </div>
                                                            <div className='p-col-12 p-lg-3 p-md-6 p-sm-6'>
                                                                <p className='fs-13 text-lightgray'>End Year</p>
                                                                <SingleSelectElement
                                                                    className="schedule-dropdown"
                                                                    placeholder="Choose your option"
                                                                    options={optionSelectYear}
                                                                    value={performance.endYear}
                                                                    onChangeValue={(e) => setEndYear(pIndex, e.target.value)}
                                                                    filterBy="name"
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            performance.scheduling.map((scheduling, index) => {
                                                                return (
                                                                    <div className="p-grid m-0 align-items-center border-config">
                                                                        <div className="p-col-12 p-lg-3 p-md-6 p-sm-6 text-center">
                                                                            <p className="font-normal fs-13 text-lightgray mt-0">{scheduling.label}</p>
                                                                        </div>
                                                                        <div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
                                                                            <p className="font-normal fs-13 text-lightgray mt-0">Valid From</p>
                                                                            <div className="p-field">
                                                                                <CalendarElement
                                                                                    className="schedule-dropdown"
                                                                                    value={scheduling.startedOn}
                                                                                    onChange={(e) => setStartDateTo(pIndex, index, e.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
                                                                            <p className="font-normal fs-13 text-lightgray mt-0">Valid Till</p>
                                                                            <div className="p-field">
                                                                                <CalendarElement
                                                                                    className="schedule-dropdown"
                                                                                    name='valid_till'
                                                                                    value={scheduling.expiredOn}
                                                                                    view='date'
                                                                                    onChange={(e) => setExtendDateTo(pIndex, index, e.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
                                                                            <p className="font-normal fs-13 text-lightgray mt-0">Extends Till</p>
                                                                            <div className="p-field">
                                                                                <CalendarElement
                                                                                    className="schedule-dropdown"
                                                                                    value={scheduling.extendedTo ? scheduling.extendedTo : scheduling.expiredOn}
                                                                                    view='date'
                                                                                    // dateFormat='dd/mm/yy'
                                                                                    onChange={(e) => setExtendedDate(pIndex, index, e.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </AccordionTab>
                                            </Accordion>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {/* ) )}  */}
                    </div>
                </form>
            </div>
        </div >
    )

    /* Render View Return - End */
}

/* Function - End */

/* Export default functionName; */

export default PerformanceSettings;