/* Import Section - Start */
/* React Imports - Start */
import React from "react";
/* React Imports - End */

/* Plugin Imports - Start */
import { useRef,useState } from "react";
import { FileUpload } from 'primereact/fileupload'
import AWS from 'aws-sdk'
import { Toast } from 'primereact/toast';

/* Plugin Imports - End */
/* Import Section - End */

/* Function - Start */
const FileUploadElement = (props) => {

    const [fileData, setFileData] = useState([])
    /* Methods - Start */
    const myUploader = (e) => {
        setFileData(e.checked);
        props.OnUpload(e);
    };
    // const myUploader = (e) => {
    //     setFileData(e);
    //     const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
    //     const REGION = process.env.REACT_APP_AWS_REGION;

    //     AWS.config.update({
    //         accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    //         region:process.env.REACT_APP_AWS_REGION,
    //         secretAccessKey: process.env.AWS_SECRET_KEY
    //     })

    //     const myBucket = new AWS.S3()

    //     const params = {
    //         ACL: 'public-read',
    //         Body: e.files[0],
    //         Bucket: S3_BUCKET,
    //         Key: e.files[0].name
    //     };

    //     myBucket.putObject(params)
    //         // .on('httpUploadProgress', (evt) => {
    //         //     setProgress(Math.round((evt.loaded / evt.total) * 100))
    //         // })
    //         .send((err) => {
    //             if (err) console.log(err, "______________________________________")
    //         })

    //     toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    // }
    const toast  = useRef(null);

    /* Methods - End */

    /* Render View Return - Start */
    return(
        <div>
        <Toast ref={toast}></Toast>
        {/* //<FileUpload name="demo[]" url="./upload" customUpload uploadHandler={myUploader}/> */}
        <FileUpload
            customUpload
            name={props.name}
            url={props.url}
            label={props.label}
            uploadHandler={(e) => myUploader(e)}
            chooseOptions={props.chooseOptions}
            uploadOptions={props.uploadOptions}
            cancelOptions={props.cancelOptions}
            multiple={props.multiple}
            // uploadHandler={myUploader}
            // selectData={props.selectData(fileData)}
            disabled={props.disabled}
            accept={props.accept}
        />
        </div>
    );
    /* Render View Return - End */
}
/* Function - End */
/* Export default functionName; */
export default FileUploadElement;