/* Import Section - Start */
/* React Imports - Start */

import React, { useState, useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

/* React Imports - End */

/* Plugin Imports - Start */

import { BreadCrumb } from 'primereact/breadcrumb';
import { UserContext } from '../../App';
import { Button } from 'primereact/button';

/* Plugin Imports - End */
/* Import Section - End */

/* Function - Start */

const Header = ({ onSelectHamburger, isOpen, menuLabel, menuTitle }) => {

    /* State Declaration - Start */

    /* useState - Start */

    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
    /* useState - End */

    /* Methods - Start */

    const menuOpenClick = () => {
        setMenuOpen(true);
        onSelectHamburger(menuOpen);
    }
    const backToMenuTitle = (e) => {
        if(e.item.label == "Business Unit"){
            navigate(`/businessUnit`)
        }else if(e.item.label == "Employees"){

            navigate(`/employees`)
        }
    }

    /* Methods - End */

    const { setPageTitle, pageTitle, title } = useContext(UserContext);

    const [items,setItems] = useState([]);

    useEffect(() => {
        if(menuTitle || title) {
            getBreadcrumbsList();
        }
    },[pageTitle])

    const getBreadcrumbsList = () => {
        // if(title != undefined){
            var data = [
                { label: menuTitle },
                { label: menuLabel , command: (event) => {
                        backToMenuTitle(event)
                    } 
                }
            ];
            if(setPageTitle) {
                data.push({label: setPageTitle ? title : ''})
            }
            setItems(data)
        // }
    }

    /* Render View Return - Start */

    return (
        <div className='w-100'>
            <div className='justify-between align-center'>
                <div className={`menu-icon ${isOpen ? 'hideMenuIcon' : 'show'}`}>
                    <i className="pi pi-bars navIcon" onClick={() => menuOpenClick()}></i>
                </div>
                <div>
                    {
                        menuTitle &&
                        <BreadCrumb model={items} className="breadcrumb hide" />
                    }
                    {
                        setPageTitle ? <h1 className='m-0'>{pageTitle === 'Login' ? 'Dashboard' : pageTitle === 'ATS' ? '' : pageTitle === 'WSR' ? 'Delivery - Weekly Status Report' : pageTitle}</h1> : <h1 className='m-0'>{menuLabel}</h1>
                    }
                </div>
                { 
                    location.pathname !== '/trackingSystem' && !location.pathname.includes('/jobs/') &&
                    <Button type="submit" label="Back" icon='pi pi-arrow-left' className="request-button" iconPos="left" onClick={() => navigate("/trackingSystem")} />
                }
                {/* <div className='align-center'>
                    <i className="bi bi-bell header-notification"></i>
                </div> */}
            </div>
        </div>
    )
    /* Render View Return - End */
}
/* Function - End */

/* Export default functionName; */
export default Header;
