/**
 * Authentication Service
 */
import axios from 'axios';

let baseUrl = process.env.REACT_APP_API_URL;

async function getClients() {
	let response = await axios.get(`${baseUrl}/api/clients/`);
	return response.data;
}

async function statusChange(statusData) {
	let response = await axios.post(`${baseUrl}/api/clients/clientStatus/${statusData.id}`, statusData);
	return response.data;
}

async function getHubspotClients() {
	let response = await axios.get(`${baseUrl}/api/clients/getHubspotRecords`);
	return response.data;
}

async function getClientSuffixCode(buId) {
	let response = await axios.get(`${baseUrl}/api/clients/getClientSuffixCode/${buId}`);
	return response.data;
}

async function createClient(data) {
	let response = await axios.post(`${baseUrl}/api/clients/`, data);
	return response.data;
}

async function editClient(id) {
	let response = await axios.get(`${baseUrl}/api/clients/${id}`);
	return response.data;
}

async function updateClient(id, data) {
	let response = await axios.patch(`${baseUrl}/api/clients/${id}`, data);
	return response.data;
}

export default { getClients, statusChange, getHubspotClients, getClientSuffixCode, createClient, editClient, updateClient };