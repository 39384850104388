/* Import Section - Start */

/* React Imports - Start */

import React, { useState, useEffect } from "react";

/* React Imports - End */

/* Plugin Imports - Start */

import { Checkbox } from "primereact/checkbox";

/* Plugin Imports - End */

/* Import Section - End */

/* Function - Start */

const CheckboxElement = (props) => {

	/* State Declaration - Start */

	/* useState - Start */

	const [checked, setCheckedValue] = useState(props.checked);

	/* useState - End */

	/* State Declaration - End */

	/* useEffect - Start */

	useEffect(() => {
		setCheckedValue(props.checked);
	});

	/* useEffect - End */

	/* Methods - Start */

	const checkValue = (e) => {
		setCheckedValue(e.checked);
		props.onChangeCheck(e, props.inputId);
	};

	/* Methods - End */

	/* Render View Return - Start */

	return (
		<div className="p-field-checkbox">
			<Checkbox
				// key={props.key}
				name={props.name}
				inputId={props.inputId}
				value={props.value}
				onChange={(e) => checkValue(e)}
				checked={checked}
				disabled={props.disabled}
				readOnly={props.readOnly}
			/>
			<label htmlFor={props.inputId} className="checkbox-label">
				{props?.value}
			</label>
		</div>
	);

	/* Render View Return - End */

};
/* Function - End */

/* Export default functionName; */

export default CheckboxElement;
