/* Import Section - Start */

/* React Imports - Start */

import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

/* React Imports - End */

/* Project Components Imports - Start */

import { PanelMenu } from 'primereact/panelmenu';
// import logo from '../../assets/images/jin_new.png';
import ig from '../../assets/images/ig.png'
import ToastElement from "../uiComponents/ToastElement";
import LeaveService from "../../services/leave/LeaveService";
import avatar from '../../assets/images/Avatar.png';
import avatarFemale from '../../assets/images/AvatarFemale.png';
import Cookies from 'universal-cookie';
import EmployeesService from '../../services/employees/EmployeesService';
import Moment from 'moment';
import AdminService from '../../services/admin/AdminService';
import _ from 'lodash';

import { UserContext } from '../../App';
import { color } from 'highcharts';

/* Project Components Imports - End */

/* Import Section - End */

/* Function - Start */

const arePropsEqual = (prevProps, nextProps) => {
	if ((!prevProps.isOpen && nextProps.isOpen) || (prevProps.isOpen && !nextProps.isOpen)) {
		return false;
	} else if (prevProps.isOpen && nextProps.isOpen) {
		return true;
	} else {
		return true;
	}
}

/* Methods - Start */

const SideBar = ({ isOpen, onSelectHamburger, onMenuSelect }) => {
	const cookies = new Cookies();

	const [showSidebar, setShowSidebar] = useState(false);
	const [userName, setUserName] = useState(cookies.get('userName'));
	const [reporterGender, setReporterGender] = useState('');
	const [experienceYears, setExperienceYears] = useState('');
	const [experienceMonths, setExperienceMonths] = useState('');
	const [items, setItems] = useState([]);
	const [userDesignation, setUserDesignation] = useState('');
	const [wfhVisibility, setWfhVisibility] = useState(true);
	const { selectedId } = useContext(UserContext);

	let roleNames = cookies.get("roles");
	let showMenu = false;
	// roleNames.map(role => {
	// 	if (role == "BuAdmin") {
	// 		showMenu = true
	// 	}
	// })
	if (roleNames) {
		roleNames.forEach(role => {
			if (role == "Reporting Manager") {
				showMenu = true
			}
		})
	}

	const toastRef = useRef();

	var roles = cookies.get('roles');
	const navigate = useNavigate();

	useEffect(() => {
		getWorkExperience();
		defaultMenuSelect();
		getReportingManager();
		getEmployeeList();

	}, [])

	const getWorkExperience = () => {
		var userId = cookies.get('userId')
		LeaveService.getReporteesDetails(userId, true).then(data => {
			for (var i = 0; i < data?.data?.length; i++) {
				if (data.data[i].id == userId) {
					var designation = data.data[i].Designation.designation
					var status = data.data[i].UserStatus.status
				}
			}

			if (designation == 'Software Engineer' && status == 'In Probation') {
				setWfhVisibility(false);
				getMenus(false);
			} else {
				getMenus(true);
			}
		})
	}

	//Get Menus
	const getMenus = (wfhVisible) => {
		var userId = cookies.get('userId');
		AdminService.getMenusByUserId(userId).then(data => {
			const ids = data.data.map(o => o.id);
			const filtered = data.data.filter(({ id }, index) => !ids.includes(id, index + 1));
			var filterData = _.filter(filtered, (item) => item.isActive);
			if (filterData) {
				if (roles !== undefined && roles.includes('Super Admin')) {
					var filteredData = _.filter(filterData, (item) => item.isAdmin);
					menusList(filterData, wfhVisible);
				} else {
					var filteredData = _.filter(filterData, (item) => !item.isAdmin);
					menusList(filteredData, wfhVisible);
				}
			}
		});
	}

	// Get Reporting Manager Details API call
	const getReportingManager = () => {
		let data = {
			buId: cookies.get('buId'),
			userId: cookies.get('userId')
		}
		LeaveService.getUsersListByBuId(data.buId).then(data => {
			if (data.data) {
				data.data.forEach(user => {
					if (user.id == data.userId) {
						setReporterGender(user.gender);
					}
				});
			}
		});
	}

	const getEmployeeList = () => {
		let userData = {
			buId: cookies.get('buId'),
			userId: cookies.get('userId')
		}
		EmployeesService.getEmployees(userData.buId).then(data => {
			var startDate;
			data.data.rows.forEach(user => {
				if (user.accountStatus == 'ACTIVATED' && user.id == userData.userId) {
					setReporterGender(user.gender);
					startDate = Moment(user.dateOfJoin, 'DD-MM-YYYY').format();
					setUserDesignation(user.Designation && user.Designation.designation);
				}
			});
			experienceOfEmployee(startDate);

		})
	}

	const experienceOfEmployee = (startDate) => {
		let experienceYears = Moment().diff(startDate, 'years');
		let months = Moment().diff(startDate, 'months');
		let experienceMonths;
		if (months < 12) {
			experienceMonths = months;
		} else {
			experienceMonths = Math.floor(months / 12);
		}
		setExperienceYears(experienceYears);
		setExperienceMonths(experienceMonths);
	}

	const menuCloseClick = () => {
		setShowSidebar(false)
		onSelectHamburger(showSidebar)
	}

	const defaultMenuSelect = () => {
		var header = document.getElementById("sidebar");
		var btns = header.getElementsByClassName("p-panelmenu-header");
		// btns[2].classList.add("active");
	}

	const navigateToPage = (event, title, menuTitle) => {
		if (title === 'Login') {
			onMenuSelect(title, menuTitle);
		} else if (title === 'Settings' || title === 'Approvals' || title === 'Control Panel') {
			return false;
		} else {
			onMenuSelect(title, menuTitle);

			var element = event.originalEvent.target.closest(".p-panelmenu-header");

			var current = document.getElementsByClassName("active");
			if (current.length > 0) {
				current[0].className = current[0].className.replace(" active", "");
			}
			element.classList.add("active");
		}
	}

	const activeSubMenu = (event, title, menuTitle) => {
		onMenuSelect(title, menuTitle);

		var element = event.originalEvent.target.closest(".p-menuitem");

		var current = document.getElementsByClassName("active");
		if (current.length > 0) {
			current[0].className = current[0].className.replace(" active", "");
		}
		element.classList.add("active");
	}

	/* Methods - End */

	const menusList = (data, wfhVisible) => {
		var items = [];
		var subgroup = _.groupBy(data, function (item) {
			if (item.isSubMenu) {
				return item.menuData?.menuName
			} else {
				return item.menuName
			}
		});
		var menus = {};
		Object.entries(subgroup).forEach(([key, data]) => {
			var filteredData = _.filter(data, (item) => item.isSubMenu);
			const ids = filteredData.map(o => o.id);
			const filtered = filteredData.filter(({ id }, index) => !ids.includes(id, index + 1));
			var orderedData = _.orderBy(filtered, function (o) { return parseInt(o.menuIndex); }, ['asc']);

			var filteredMenuData = _.filter(data, (item) => !item.isSubMenu);

			if (filteredMenuData?.length > 0) {
				filteredMenuData.forEach(menulist => {
					if (key === menulist.menuName) {
						menus = {
							label: menulist.menuName,
							command: (event) => {
								navigateToPage(event, menulist.menuName)
							},
							index: parseInt(menulist.menuIndex)
						}
					}
				})
			}

			if (orderedData?.length > 0) {
				orderedData.forEach(menu => {
					var subMenus = {
						label: menu.menuName,
						command: (event) => {
							activeSubMenu(event, menu.menuName, menu.menuData.menuName)
						}
					}
					menus.label = menu.menuData.menuName;

					if (menus && menus.items && menus.items.length > 0) {
						menus.items.push(subMenus);
					} else {
						menus.items = [];
						menus.items.push(subMenus);
					}
				})
			} else {
				menus.className = 'mainMenu';
			}
			items.push(menus);
			return " "
		})
		var filteredData
		if (wfhVisible) {
			filteredData = items
		}
		else {
			filteredData = _.filter(items, (item) => item.label !== 'Work From Home');
		}
		var allItems = _.orderBy(filteredData, ['index'], ['asc']);
		// Role based URL access
		let path = data.filter(f => { return ('/' + f.menuName.replace(/ +/g, '').toLowerCase()) == location.pathname.toLowerCase() })
		var locationData = ((!location.pathname.startsWith('/competency/view/')) && (!location.pathname.startsWith('/wsr')))
		if (path.length === 0 && locationData) {
			navigate('/trackingSystem')
		}

		setItems(allItems);
	}

	const onClickLogOut = (event) => {
		cookies.remove('token', { path: '/' });
		cookies.remove('userId', { path: '/' });
		cookies.remove('buId', { path: '/' });
		cookies.remove('userName', { path: '/' });
		cookies.remove('roles', { path: '/' });
		cookies.remove('email', { path: '/' });
		cookies.remove('idToken', { path: '/' });
		cookies.remove('accessToken', { path: '/' });
		cookies.remove('isAuth', { path: '/' });
		cookies.remove('buName', { path: '/' });
		window.location.reload(true);
		navigateToPage(event, 'Login')
	}

	const profileDetails = () => {
		navigate('/profile');
	}

	/* Render View Return - Start */

	return (
		<div id="sidebar" className={`sidebar ${isOpen ? 'show' : 'hide'}`}>
			<ToastElement ref={toastRef} />
			<div>
				<div className="ml-20 sidebarMenu mt-20">
					<div className='sidebar-ig-logo align-center'>
						<img src={ig} className='logo' label/> <span className='sidebar-ig-title'>Imitor Graphica</span>
					</div>					
					<PanelMenu model={items} className='mt-12' />
					<div className={`close-sidebar ${isOpen ? 'show' : 'hide'}`}>
						<i className="pi pi-times navIcon" onClick={menuCloseClick}></i>
					</div>
				</div>
				<div className="ml-20">
					<div className='p-grid sidebar-footer'>
						{
							!(roles !== undefined && roles.includes('Super Admin')) ?
								<div className='p-col-12 d-flex justify-between align-center pl-0 px-15 py-10'>
									<div className='p-col-8 pl-8'>
										<p className="font-normal fs-10 m-0 footer-user">{userName}</p>
										{/* <p className="font-normal fs-11 m-0 mt-6 footer-designation">
											{experienceYears > 1 ? `${experienceYears}years ` : `${experienceYears}year `}
											{experienceMonths > 1 ? `${experienceMonths}months` : `${experienceMonths}month`}
										</p> */}
										{/* <p className="font-normal fs-10 m-0 mt-6 footer-designation">
											{userDesignation}
										</p> */}
									</div>
									{/* <div className="p-col-2">
										{
											reporterGender == "Male" ?
												<img src={avatar} className='associates-avatar' onClick={profileDetails} style={{ cursor: 'pointer' }} /> :
												<img src={avatarFemale} className='associates-avatar' onClick={profileDetails} style={{ cursor: 'pointer' }} />
										}
									</div> */}
									<div className='p-col-2 align-center justify-end pl-0'>
										<i className="pi pi-sign-out footer-logout" onClick={onClickLogOut}></i>
									</div>
								</div>
								:
								<div className='p-col-12 d-flex justify-between align-center pl-0 px-15 py-10'>
									<div className='p-col-8 pl-8'>
										<p className="font-normal fs-10 m-0 footer-user">{userName}</p>
									</div>
									{/* <div className="p-col-2">
									{
										reporterGender == "Male" ?
											<img src={avatar} className='associates-avatar' onClick={profileDetails} style={{ cursor: 'pointer' }} /> :
											<img src={avatarFemale} className='associates-avatar' onClick={profileDetails} style={{ cursor: 'pointer' }} />
									}
									</div> */}
									<div className='p-col-2 align-center justify-end pl-0'>
										<i className="pi pi-sign-out footer-logout" onClick={onClickLogOut}></i>
									</div>
								</div>
						}
					</div>
				</div>
			</div>
		</div>
	);

	/* Render View Return - Start */

}

/* Function - End */

/* Export default functionName; */

export default React.memo(SideBar, arePropsEqual)
