/* Import Section - Start */
/* React Imports - Start */
import React, { forwardRef, useRef, useImperativeHandle} from 'react';
/* React Imports - End */

/* Plugin Imports - Start */
import { Toast } from 'primereact/toast';
/* Plugin Imports - End */
/* Import Section - End */

/* Function - Start */
const ToastElement = forwardRef((props,ref) => {
    /* State Declaration - Start */
    /* useRef - Start */
    const toast = useRef(null);
    /* useRef - End */
    /* State Declaration - End */

    /* Methods - Start */
    useImperativeHandle(ref, () => ({

        showToast(data){
            toast.current.show(
                {severity: data.type, 
                 summary: data.summary, 
                 detail: data.message, 
                 life: 3000}
            );
        }
    
    }));
    /* Methods - End */
    
    /* Render View Return - Start */
    return (
        <div>
            <Toast ref={toast}/>
        </div>
    );
    /* Render View Return - End */
});
/* Function - End */
/* Export default functionName; */
export default ToastElement;
