/* Import Section - Start */
/* React Imports - Start */
import { Button } from 'primereact/button';
import React from "react";
import JINlogo from '../../assets/images/Login-logo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import JMANfooter from '../../assets/images/JMANfooter1.png'
import JMANLogoBlue from '../../assets/images/JMANLogoBlue.png';

/* React Imports - End */

/* Plugin Imports - Start */
/* Plugin Imports - End */
/* Import Section - End */

/* Function - Start */
const JinLoginError = () => {
    /* Methods - Start */
    /* Methods - End */
    let navigate = useNavigate();

    const onClickGoBack = () => {
        navigate(`/`);
    }
    /* Render View Return - Start */
    return(
        <div>
            <div className="p-grid login-grid h-100 m-0">
                <div className="p-col-12 p-md-6 p-lg-6 login-bg p-0">
                    <img src={JINlogo} alt="logo" className='jin-logo' />
                </div>
                <div className="p-col-12 p-md-6 p-lg-6 flex-center ">
                    <div className="login-error-content">
                        <h3>You don't have access to JIN App</h3>
                        <h1 className="m-0">Contact admin</h1>
                        <Button type="submit" label="BACK TO LOGIN" onClick={onClickGoBack} className="goback-button" />
                    </div>
                </div>
            </div>
            <div className='p-grid copyright-footer m-0'>
                <div className='p-col-4'>
                    <p className="font-normal fs-10 m-0 login-footer-text">Powered by</p>
                    <img src={JMANfooter} alt="footer" className='footer-image d-des-block' />
                    <img src={JMANLogoBlue} alt="footer" className='footer-image d-mob-block' />
                </div>
                <div className='p-col-4 pl-30 footer-border-1'>
                    <p className="font-normal fs-10 m-0 copyright-text">2.0</p>
                    <p className="font-normal fs-10 m-0 mt-5 login-footer-text no-wrap">IG JIN APPS Version</p>
                </div>
                <div className='p-col-4 pl-30 footer-border-2'>
                    <p className="font-normal fs-10 m-0 copyright-text">2022</p>
                    <p className="font-normal fs-10 m-0 mt-5 login-footer-text">&copy; Copyright</p>
                </div>
            </div>
        </div>
    );
    /* Render View Return - End */
}
/* Function - End */
/* Export default functionName; */
export default JinLoginError;