/* Import Section - Start */

/* React Imports - Start */

import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';


/* React Imports - End */

/* Plugin Imports - Start */

import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import Cookies from 'universal-cookie';

/* Plugin Imports - End */

/* Project Components Imports - Start */

import TextboxElement from "../uiComponents/TextboxElement";
import SingleSelectElement from "../uiComponents/SingleSelectElement";
import ToastElement from "../uiComponents/ToastElement";
import EmployeesService from "../../services/employees/EmployeesService";
import ClientsService from "../../services/clients/ClientsService";
import Loader from "../uiComponents/Loader";
import RadioElement from "../uiComponents/RadioElement";
// import FileUploadElement from '../uiComponents/FileUpload';

/* Project Components Imports - End */

/* Import Section - End */

/* Function - Start */

const ClientCreation = (props) => {
    const [prefixCode, setPrefixCode] = useState("");
    const [clientCode, setClientCode] = useState();
    const [clientName, setClientName] = useState();
    const [buId, setBuId] = useState(props.clientId ? "" : props.currentBu);
    const [isGlobalClient, setIsGlobalClient] = useState();
    const [buUnit, setBuUnit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editData, setEditData] = useState();

    const toastRef = useRef();
    const cookies = new Cookies();

    //Function call on load
    useEffect(() => {
        getActiveBu();
    }, []);

    useEffect(() => {
        if (props.clientId) {
            editClient(props.clientId);
        }
    }, [props.clientId]);

    const globalClient = [
        { key: "1", name: "Yes" },
        { key: "2", name: "No" }
    ];

    let navigate = useNavigate();

    const defaultValues = {
        prefixCode: prefixCode,
        clientCode: clientCode,
        clientName: clientName,
        buId: buId,
        isGlobalClient: isGlobalClient
    }

    const { control, formState: { errors }, handleSubmit, watch, setValue, reset } = useForm({
        defaultValues
    });

    const editClient = (id) => {
        setLoading(true);
        ClientsService.editClient(id).then(data => {
            setLoading(false);
            setEditData(data.data);
            let editedData = data.data;
            setPrefixCode(editedData.prefixCode);
            setValue('prefixCode', editedData.prefixCode);
            setValue('clientCode', editedData.clientCode);
            setValue('clientName', editedData.clientName);
            setValue('buId', editedData.BusinessUnit);
            setValue('isGlobalClient', editedData.isGlobalClient ? "Yes" : "No");
        })
        reset();
    }
    const prefix_code = watch("prefixCode");

    useEffect(() => {
        // const re = /^[A-Za-z]+$/;
        if (prefix_code !== '') {
            // if ((prefix_code && prefix_code.length > 3) || !re.test(prefix_code)) {
            if (prefix_code && prefix_code.length > 3) {
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Error',
                    message: 'Prefix can have maximum 3 characters and Only characters are allowed'
                })
                setValue('prefixCode', `${prefixCode.substr(0, 3)}`);
            }
        }
        setValue('clientCode', `${prefix_code}${props.clientSuffixCode.sequenceNumber}`);
    }, [prefix_code])

    const getActiveBu = () => {
        setLoading(true);
        EmployeesService.getActiveBu().then(data => {
            setLoading(false);
            setBuUnit(data.data);
        })
    }

    // Function for API hit - Client Save
    const savebutton = (FormData) => {
        setLoading(true);
        var save = {
            prefixCode: FormData.prefixCode,
            clientCode: FormData.clientCode,
            clientName: FormData.clientName,
            type: FormData.type,
            buId: FormData.buId.id,
            isGlobalClient: FormData.isGlobalClient,
            createdBy: cookies.get("userId")
        }
        save.hubspotId = "";

        ClientsService.createClient(save).then(data => {
            setLoading(false);
            let create = false;
            let edit = false;
            let createProject = true;
            let updateProject = false;
            if (data.posted.success !== undefined) {
                props.onSuccess(create, edit, data, createProject, updateProject);
            } else {
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Failed',
                    message: data.posted
                })
                reset({
                    prefixCode: '',
                    clientName: FormData.clientName,
                    type: FormData.type,
                    buId: FormData.buId,
                    isGlobalClient: FormData.isGlobalClient,
                })
            }
            reset();
            return data;
        })
        reset();
        navigate(`/clients`);
    }

    // Function for API hit - Client Update
    const editButton = (FormData) => {
        setLoading(true);
        let edit = {
            id: editData.id,
            prefixCode: FormData.prefixCode,
            clientCode: FormData.clientCode,
            clientName: FormData.clientName,
            type: FormData.type,
            buId: FormData.buId.id,
            isGlobalClient: FormData.isGlobalClient,
            createdBy: cookies.get("userId")
        }
        ClientsService.updateClient(editData.id, edit).then(data => {
            setLoading(false);
            let create = false;
            let edit = false;
            let createProject = false;
            let updateProject = true;
            props.onSuccess(create, edit, data, createProject, updateProject);
            return data;
        })
        navigate(`/clients`);
    }

    const toast = useRef(null);

    const renderSubmit = () => {
        return (
            <div className="cancel-footer">
                <div className='align-center justify-end pb-0'>
                    {
                        props.type === 'create' &&
                        <div className="p-grid">
                            <div className="p-col-12">
                                <Button type="submit" label="CREATE" className="create-button" onClick={handleSubmit(savebutton)} />
                            </div>
                        </div>
                    }
                    {
                        props.type === 'edit' &&
                        <div className="p-grid">
                            <div className="p-col-12">
                                <Button type="submit" label="UPDATE" className="create-button" onClick={handleSubmit(editButton)} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }


    return (
        <div>
            <Toast ref={toast}></Toast>
            <ToastElement ref={toastRef} />
            <Loader loading={loading} />

            <Dialog header={props.header} visible={props.visible} style={{ width: '50vw' }} onHide={props.onHide} footer={renderSubmit()}>
                <div className="client-create">
                    <p className="font-normal fs-13 text-lightgray" >Business Unit < span className="text-red" >* </span></p >
                    <Controller name="buId" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                        <SingleSelectElement id={field.name} placeholder="Select" optionLabel="name" options={buUnit} value={field.value} onChangeValue={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })}
                            disabled={props.clientId} />
                    )} />
                    <p className="font-normal fs-13 text-lightgray" >Is Global Client < span className="text-red" >* </span></p >
                    <div className="p-field employee-creation font-normal fs-13 text-lightgray" >
                        <Controller name="isGlobalClient" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                            <RadioElement id={field.name} options={globalClient} value={field.value} onChangeRadio={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid }, 'group-radio')} />
                        )} />
                    </div>
                    <p className="font-normal fs-13 text-lightgray space">Prefix Code <span className="text-red">*</span></p>
                    <Controller name="prefixCode" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                        <TextboxElement id={field.name} value={field.value} placeholder="Max 3 letters" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} disabled={props.clientId} />
                    )} />
                    <p className="font-normal fs-13 text-lightgray space">Client Code <span className="text-red">*</span></p>
                    <Controller name="clientCode" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                        <TextboxElement id={field.name} value={field.value} placeholder="Client Code" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} disabled />
                    )} />
                    <p className="font-normal fs-13 text-lightgray space">Client Name <span className="text-red">*</span></p>
                    <Controller name="clientName" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                        <TextboxElement id={field.name} value={field.value} placeholder="Client Name" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                </div>
            </Dialog>
        </div>
    )

}


export default ClientCreation;
