/* Import Section - Start */

/* React Imports - Start */

import React, { useState, useEffect, useRef } from "react";
import { ToggleButton } from 'primereact/togglebutton';
import { Calendar } from 'primereact/calendar';
import { TreeSelect } from 'primereact/treeselect';
import { Button } from "primereact/button";
import { RadioButton } from 'primereact/radiobutton';
import * as XLSX from 'xlsx';
/* React Imports - End */

/* Plugin Imports - Start */

import Moment from 'moment';

/* Plugin Imports - End */

/* Project Components Imports - Start */
import Cookies from 'universal-cookie';
import ButtonElement from "./ButtonElement";
import MultiSelectElement from "./MultiSelectElement";
import SingleSelectElement from '../uiComponents/SingleSelectElement';
import CalendarElement from "./CalendarElement";
import { async } from "q";
import { MultiSelect } from 'primereact/multiselect';
import DateTimeFunctions from "../../util/DateTimeFunctions";

const cookies = new Cookies();

import FilterViews from "../uiComponents/FilterViews";

/* Project Components Imports - End */

/* Import Section - End */

/* Function - Start */

const AdminFilterElement = (props) => {

    /* State Declaration - Start */

    /* useState - Start */
    const [selectedFilterUserData, setSelectedFilterUserData] = useState([]);
    const [selectedFilterClientData, setSelectedFilterClientData] = useState([]);
    const [selectedPreposition,setSelectedPreposition] = useState('');
    const [selectedTechnical,setSelectedCoe] = useState('');
    const [selectedFilterStatusData, setSelectedFilterStatusData] = useState([]);
    const [selectedFilterStatusSingle, setSelectedFilterStatusSingle] = useState('');
    const [selectedFilterProjectData, setSelectedFilterProjectData] = useState([]);
    const [selectedFilterDesignationData, setselectedfilterDesignationData] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [projectFromDate, setProjectFromDate] = useState(0)
    const [projectToDate, setProjectToDate] = useState(0)

    const [toDate, setToDate] = useState(null);
    const [month, setMonth] = useState(null);
    const [isAppraisee, setIsAppraisee] = useState(false);
    const [buUnit, setCurrentBU] = useState([]);
    const [status,setStatus] = useState([]);
    const [wsrStatus, setWsrStatus] = useState([])
    const [prevStatus, setPrevStatus] = useState([]);
    const [currentBu, setBU] = useState("");
    const [selectedGroupedStatus, setSelectedGroupedStatus] = useState([]);
    const [nodeKeys, setNodeKeys] = useState(null);
    const [wsrNodeKeys, setWsrNodeKeys] = useState(null);
    const [wsrPreviousNodeKeys, setPreviousWsrNodeKeys] = useState(null);
    const [loadRadioButton, setLoadRadioButton] = useState(false)
    const [filterProjectType, setProjectFilterType] = useState('')
    const [pe, setPe]= useState('')
    const isFilterOtherFilters = props.isFilterOtherFilters ?? false;
    const [reportType, setReportType] = useState([])

    /* useState - End */

    /* State Declaration - End */

    /* Render Templates - Start */
    var curBuId = currentBu?.id;

    const groupedItemTemplate = (option) => {
        return (
            <div className="mt-20 status-dropdown">
                <div>{option.label}</div>
            </div>
        );
    };

    const onClickLoadMoreHandler = () => {
        setFromDate(null);
        setToDate(null);
        setSelectedFilterUserData('');
        setSelectedFilterClientData('');
        setSelectedFilterStatusData('');
        setReportType('');
        setSelectedFilterProjectData('');
        setCurrentBU('');
        setSelectedPreposition('')
        setPe('')
        setSelectedCoe('')
        setStatus('')
        setPrevStatus([])
        setWsrStatus([])
        setselectedfilterDesignationData('')
        setNodeKeys('')
        setWsrNodeKeys('')
        setPreviousWsrNodeKeys('')
        setSelectedGroupedStatus('');
        setProjectFromDate('')
        setProjectToDate('')
        setProjectFilterType('')
        setLoadRadioButton(false)
        props.getList(true);
    }

    useEffect(()=>{
        if(props.reset){
        onClickLoadMoreHandler()
        }
    },[props.reset])


    const onClickFilterHandler = () => {
        props.filter({
            from: fromDate ? fromDate : '',
            to: toDate ? toDate : '',
            user: selectedFilterUserData,
            project: selectedFilterProjectData,
            designationId: selectedFilterDesignationData,
            isAppraisee: isAppraisee,
            month: month ? month : '',
            status: selectedFilterStatusData.length > 0 ? selectedFilterStatusData : selectedFilterStatusSingle,
            isAppraisee: isAppraisee,
            bu_units: buUnit,
            wsrStatus:wsrStatus,
            prevWsrStatus:prevStatus,
            overallStatus: status,
            selectedGroupedStatus: selectedGroupedStatus,
            client: selectedFilterClientData,
            projectStartDate: projectFromDate ? Moment(projectFromDate).format('YYYY-MM-DD') : '',
            projectEndDate: projectToDate ? Moment(projectToDate).format('YYYY-MM-DD') : '',
            filterType: filterProjectType,
            rangeOfDate:props.rangeOfDate,
            prepositionCoe:selectedPreposition,
            pe:pe,
            technicalCoe:selectedTechnical,
            reportType:reportType
        })
    }

    //Survey download button
    const onClickSurveyDownloader = () => {
        props.downloader({
            from: fromDate ? Moment(fromDate).format('MM-DD-YYYY') : '',
            to: toDate ? Moment(toDate).format('MM-DD-YYYY') : '',
            user: selectedFilterUserData,
            project: selectedFilterProjectData,
            designationId: selectedFilterDesignationData,
            status: selectedFilterStatusData,
            selectedGroupedStatus: selectedFilterStatusData,
            client: selectedFilterClientData,
            
        });
    }

    // const onClickTrigger = () => {
    //     props.emailTrigger()
    // }
    
    const onClickDownload = () => {

        let summaryData = [];
        let projectAllocations = [];
        let leave = [];
        let nextWeekDate = `W/C ${DateTimeFunctions.getWeeksFirstDateText()['1']}`
        let nextTwoWeekDate = `W/C ${DateTimeFunctions.getWeeksFirstDateText()['2']}`
        let nextThreeWeekDate = `W/C ${DateTimeFunctions.getWeeksFirstDateText()['3']}`
        let nextFourWeekDate = `W/C ${DateTimeFunctions.getWeeksFirstDateText()['4']}`
        let nextFiveWeekDate = `W/C ${DateTimeFunctions.getWeeksFirstDateText()['5']}`
        let nextSixWeekDate = `W/C ${DateTimeFunctions.getWeeksFirstDateText()['6']}`
        let nextSevenWeekDate = `W/C ${DateTimeFunctions.getWeeksFirstDateText()['7']}`
        props.value.forEach(data => {
            let objData = {
                ResourceName: data.firstName + ' ' + data.lastName,
                ResourceBUName: data.name,
                ResourceDesignation: data.designation,
                Proposed: parseFloat(data.totalEffort.proposed ? data.totalEffort.proposed : 0),
                Billed: parseFloat(data.totalEffort.billable ? data.totalEffort.billable : 0),
                Shadow: parseFloat(data.totalEffort.shadow ? data.totalEffort.shadow : 0),
                CurrentStatus: data.current_status,
            }
            objData[nextWeekDate] = data.next_status
            objData[nextTwoWeekDate] = data.next_two_week_status
            objData[nextThreeWeekDate] = data.future_status
            objData[nextFourWeekDate] = data.fifth_week
            objData[nextFiveWeekDate] = data.sixth_week
            objData[nextSixWeekDate] = data.seventh_week
            objData[nextSevenWeekDate] = data.eigth_week
            summaryData.push(objData)
        })
        props.value.forEach(data => {
            data.values.forEach(data => {
                if (data.Project) {
                    const hubspotProjectIdValue = Array.isArray(data.Project.hubspotProjectId)
                    ? data.Project.hubspotProjectId.join(', ') 
                    : data.Project.hubspotProjectId;
                    projectAllocations.push({
                        ResourceName: data.firstName + ' ' + data.lastName,
                        ResourceBUName: data.name,
                        ResourceDesignation: data.designation,
                        Client: data.clientName,
                        Project: data.Project.name,
                        ProjectCode: data.Project.code,
                        HubspotProjectId:hubspotProjectIdValue,
                        ChennaiLead: data.approverData.firstName + ' ' + data.approverData.lastName,
                        UKLead: data.Project.reporter.firstName + ' ' + data.Project.reporter.lastName,
                        Status: data.billing_status,
                        "Utilization(%)": parseFloat(data.allocationByPercentage),
                        ProjectStartDate: moment(data.Project.startDate, 'DD-MM-YYYY').format('DD/MM/YYYY'),
                        ProjectEndDate: moment(data.Project.endDate, 'DD-MM-YYYY').format('DD/MM/YYYY'),
                        AllocationStartDate: moment(data.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                        AllocationEndDate: moment(data.endDate, 'YYYY_MM_DD').format('DD/MM/YYYY')
                    })
                }
            })
        })
        props.value.forEach(data => {
            data.values.forEach(data => {
                if (data.hasOwnProperty('Project')) {
                    if (data.Project == '') {
                        leave.push({
                            ResourceName: data.firstName + ' ' + data.lastName,
                            ResourceBUName: data.name,
                            ResourceDesignation: data.designation,
                            LeaveType: data.leaveTypeName,
                            From: moment(data.from_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                            To: moment(data.to_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                            NoOfDays: data.total,
                            RequestStatus: data.leaveStatus
                        })
                    }
                }
            })
        })
        const summaryWorksheet = XLSX.utils.json_to_sheet(summaryData, { autoWidth: true });
        const projectAllocationWorksheet = XLSX.utils.json_to_sheet(projectAllocations, { autoWidth: true });
        const leaveSheet = XLSX.utils.json_to_sheet(leave, { autoWidth: true })
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, summaryWorksheet, 'Summary');
        XLSX.utils.book_append_sheet(workbook, projectAllocationWorksheet, 'Project Allocations');
        XLSX.utils.book_append_sheet(workbook, leaveSheet, 'Leave');
        XLSX.writeFile(workbook, `${moment().format('YYMMDD')}resource_data.xlsx`);
    }

    const statusList = [
        {
            label: 'Available', key: '0',
            children: [
                { key: '0-0', label: 'Current Status', value: 'Current_Available' },
                { key: '0-1', label: 'Next Week', value: 'Next_Available' },
                { key: '0-2', label: 'After 2 Weeks', value: 'After2Weeks_Available' },
                { key: '0-3', label: 'After 4 Weeks', value: 'Future_Available' },
            ],
        },
        {
            label: 'Partially Billed', key: '1',
            children: [
                { key: '1-0', label: 'Current Status', value: 'Current_Partially Billed' },
                { key: '1-1', label: 'Next Week', value: 'Next_Partially Billed' },
                { key: '1-2', label: 'After 2 Weeks', value: 'After2Weeks_Partially Billed' },
                { key: '1-3', label: 'After 4 Weeks', value: 'Future_Partially Billed' },
            ],
        },
        {
            label: 'Proposed', key: '2',
            children: [
                { key: '2-0', label: 'Current Status', value: 'Current_Proposed' },
                { key: '2-1', label: 'Next Week', value: 'Next_Proposed' },
                { key: '2-2', label: 'After 2 Weeks', value: 'After2Weeks_Proposed' },
                { key: '2-3', label: 'After 4 Weeks', value: 'Future_Proposed' },
            ],
        },
        {
            label: 'Partially Proposed', key: '3',
            children: [
                { key: '3-0', label: 'Current Status', value: 'Current_Partially Proposed' },
                { key: '3-1', label: 'Next Week', value: 'Next_Partially Proposed' },
                { key: '3-2', label: 'After 2 Weeks', value: 'After2Weeks_Partially Proposed' },
                { key: '3-3', label: 'After 4 Weeks', value: 'Future_Partially Proposed' },
            ],
        },
        {
            label: 'Billable', key: '4',
            children: [
                { key: '4-0', label: 'Current Status', value: 'Current_Billable' },
                { key: '4-1', label: 'Next Week', value: 'Next_Billable' },
                { key: '4-2', label: 'After 2 Weeks', value: 'After2Weeks_Billable' },
                { key: '4-3', label: 'After 4 Weeks', value: 'Future_Billable' },
            ],
        },
        {
            label: 'Shadow', key: '5',
            children: [
                { key: '5-0', label: 'Current Status', value: 'Current_Shadow' },
                { key: '5-1', label: 'Next Week', value: 'Next_Shadow' },
                { key: '5-2', label: 'After 2 Weeks', value: 'After2Weeks_Shadow' },
                { key: '5-3', label: 'After 4 Weeks', value: 'Future_Shadow' },
            ],
        },
        {
            label: 'Pending', key: '6',
            children: [
                { key: '6-0', label : 'Current Status',value: 'Current_Pending' },
                // { key: '6-1', label: 'Next Week', value: 'Next_Pending' },
                // { key: '6-2', label: 'After 2 Weeks', value: 'After2Weeks_Pending' },
                // { key: '6-3', label: 'After 4 Weeks', value: 'Future_Pending' },
            ],
        },
    ];

    const wsrStatusFilterList = [
        {
            label: 'Red', key: '0',
            children: [
                { key: '0-0', label: 'Scope', value: 'Scope_Red' },
                { key: '0-1', label: 'Schedule', value: 'Schedule_Red' },
                { key: '0-2', label: 'Quality', value: 'Quality_Red' },
                { key: '0-3', label: 'CSAT', value: 'CSAT_Red' }
            ],
        },
        {
            label: 'Amber', key: '1',
            children: [
                { key: '1-0', label: 'Scope', value: 'Scope_Amber' },
                { key: '1-1', label: 'Schedule', value: 'Schedule_Amber' },
                { key: '1-2', label: 'Quality', value: 'Quality_Amber' },
                { key: '1-3', label: 'CSAT', value: 'CSAT_Amber' }
            ],
        },
        {
            label: 'Green', key: '2',
            children: [
                { key: '2-0', label: 'Scope', value: 'Scope_Green' },
                { key: '2-1', label: 'Schedule', value: 'Schedule_Green' },
                { key: '2-2', label: 'Quality', value: 'Quality_Green' },
                { key: '2-3', label: 'CSAT', value: 'CSAT_Green' }
            ],
        }
    ];


    const onChangeGroupedStatus = (e) => {
        let keys = Object.keys(e.value)
        var arr = []
        for (var i = 1; i < keys.length; i++) {
            if (keys[i] == '0-0') {
                arr.push('Current_Available')
            }
            if (keys[i] == '0-1') {
                arr.push('Next_Available')
            }
            if (keys[i] == '0-2') {
                arr.push('After2Weeks_Available')
            }
            if (keys[i] == '0-3') {
                arr.push('Future_Available')
            }
            if (keys[i] == '1-0') {
                arr.push('Current_Partially Billed')
            }
            if (keys[i] == '1-1') {
                arr.push('Next_Partially Billed')
            }
            if (keys[i] == '1-2') {
                arr.push('After2Weeks_Partially Billed')
            }
            if (keys[i] == '1-3') {
                arr.push('Future_Partially Billed')
            }
            if (keys[i] == '2-0') {
                arr.push('Current_Proposed')
            }
            if (keys[i] == '2-1') {
                arr.push('Next_Proposed')
            }
            if (keys[i] == '2-2') {
                arr.push('After2Weeks_Proposed')
            }
            if (keys[i] == '2-3') {
                arr.push('Future_Proposed')
            }
            if (keys[i] == '3-0') {
                arr.push('Current_Partially Proposed')
            }
            if (keys[i] == '3-1') {
                arr.push('Next_Partially Proposed')
            }
            if (keys[i] == '3-2') {
                arr.push('After2Weeks_Partially Proposed')
            }
            if (keys[i] == '3-3') {
                arr.push('Future_Partially Proposed')
            }
            if (keys[i] == '4-0') {
                arr.push('Current_Billable')
            }
            if (keys[i] == '4-1') {
                arr.push('Next_Billable')
            }
            if (keys[i] == '4-2') {
                arr.push('After2Weeks_Billable')
            }
            if (keys[i] == '4-3') {
                arr.push('Future_Billable')
            }
            if (keys[i] == '5-0') {
                arr.push('Current_Shadow')
            }
            if (keys[i] == '5-1') {
                arr.push('Next_Shadow')
            }
            if (keys[i] == '5-2') {
                arr.push('After2Weeks_Shadow')
            }
            if (keys[i] == '5-3') {
                arr.push('Future_Shadow')
            }
            if (keys[i] == '6-0'){
                arr.push('Current_Pending')
            }
        }
        setNodeKeys(e.value)
        setSelectedGroupedStatus(arr)
    }

    const onChangeWsrStatus = (e) => {
        let keys = Object.keys(e.value)
        var arr = []
        for (var i = 1; i < keys.length; i++) {
            if (keys[i] == '0-0') {
                arr.push('Scope_Red')
            }
            if (keys[i] == '0-1') {
                arr.push('Schedule_Red')
            }
            if (keys[i] == '0-2') {
                arr.push('Quality_Red')
            }
            if (keys[i] == '0-3') {
                arr.push('CSAT_Red')
            }

            if (keys[i] == '1-0') {
                arr.push('Scope_Amber')
            }
            if (keys[i] == '1-1') {
                arr.push('Schedule_Amber')
            }
            if (keys[i] == '1-2') {
                arr.push('Quality_Amber')
            }
            if (keys[i] == '1-3') {
                arr.push('CSAT_Amber')
            }

            if (keys[i] == '2-0') {
                arr.push('Scope_Green')
            }
            if (keys[i] == '2-1') {
                arr.push('Schedule_Green')
            }
            if (keys[i] == '2-2') {
                arr.push('Quality_Green')
            }
            if (keys[i] == '2-3') {
                arr.push('CSAT_Green')
            }
        }
        setWsrNodeKeys(e.value)
        setWsrStatus(arr)
    }

    const onChangeWsrPrevStatus = (e) => {
        let keys = Object.keys(e.value)
        var arr = []
        for (var i = 1; i < keys.length; i++) {
            if (keys[i] == '0-0') {
                arr.push('Scope_Red')
            }
            if (keys[i] == '0-1') {
                arr.push('Schedule_Red')
            }
            if (keys[i] == '0-2') {
                arr.push('Quality_Red')
            }
            if (keys[i] == '0-3') {
                arr.push('CSAT_Red')
            }

            if (keys[i] == '1-0') {
                arr.push('Scope_Amber')
            }
            if (keys[i] == '1-1') {
                arr.push('Schedule_Amber')
            }
            if (keys[i] == '1-2') {
                arr.push('Quality_Amber')
            }
            if (keys[i] == '1-3') {
                arr.push('CSAT_Amber')
            }

            if (keys[i] == '2-0') {
                arr.push('Scope_Green')
            }
            if (keys[i] == '2-1') {
                arr.push('Schedule_Green')
            }
            if (keys[i] == '2-2') {
                arr.push('Quality_Green')
            }
            if (keys[i] == '2-3') {
                arr.push('CSAT_Green')
            }
        }
        setPreviousWsrNodeKeys(e.value)
        setPrevStatus(arr)
    }

    const buChange = (e) => {
        setCurrentBU(e.value)
    }
    const statusChange = (e) => {
        setStatus(e.value)
    }

    const prevStatusChange = (e) => {
        setPrevStatus(e.value)
    }
    const ChangeStatus = (e) => {
        setSelectedGroupedStatus(e.value)
    }
    const onChangeFromDate = (e) => {
        setFromDate(e.value)
    }
    const onChangeProjectStartDate = (e) => {
        setProjectFromDate(e.value)
        setLoadRadioButton(true)
    }
    const onChangeProjectEndDate = (e) => {
        setProjectToDate(e.value)
        setLoadRadioButton(true)
    }
    const onProjectFilterTypeClick = (e) => {
        setLoadRadioButton(true)
        setProjectFilterType(e.value)
    }

    const onChangeToDate = (e) => {
        setToDate(e.value)
    }

    const onChangeUserInfo = (e) => {
        setSelectedFilterUserData(e.value)
    }
    const onChangeClientInfo = (e) => {
        setSelectedFilterClientData(e.value)
    }

    const onChangePe = (e) => {
        setPe(e.value)
    }
    const onChangePrepositionCoe = (e) => {
        setSelectedPreposition(e.value)
    }

    const onChangeTechnicalCoe = (e) => {
        setSelectedCoe(e.value)
    }
    const onChangeStatusInfo = (e) => {
        setSelectedFilterStatusData(e.value)
    }
    const onChangeRportType = (e) => {
        setReportType(e.value)
    }

    const onChangeStatus = (e) => {
        setSelectedFilterStatusSingle(e.value)
    }

    const onChangeProjectInfo = (e) => {
        setSelectedFilterProjectData(e.value)
    }
    const onChangeDesignationInfo = (e) => {
        setselectedfilterDesignationData(e.value)
    }

    const getUsersList = () => {
        if (isFilterOtherFilters && props.userList && buUnit.length > 0) {
            return props.userList.filter(item => buUnit.includes(item.buId))
        }
        return props.userList
    }

    const getDesignationList = () => {
        if (isFilterOtherFilters && props.designationList && buUnit.length > 0) {
            return props.designationList.filter(item => buUnit.includes(item.buId))
        }
        return props.designationList
    }

    const getProjectsList = () => {
        if (isFilterOtherFilters && props.clientsList && props.projectList && selectedFilterClientData.length > 0) {
            return props.projectList.filter(item => selectedFilterClientData.includes(item.clientId))
        }
        return props.projectList
    }

    /* Render View Return - Start */

    return (
        <div className="p-grid buCreation">
            {props.label !== 'projectStatus' &&
                <div className="d-flex">
                    <div className="p-col-2  p-md-2 p-sm-12 pr-6 pl-0 filter suv-calender timesheet-cal travel-cal">
                        <CalendarElement
                            id="multiple"
                            placeholder="From"
                            value={fromDate}
                            onChange={(e) => onChangeFromDate(e)}
                            readOnlyInput
                        />
                    </div>
                    {
                        props.toDate !== 'toDate' &&
                        <div className="p-col-2  p-md-2 p-sm-12 pr-6 pl-0 filter">
                            <CalendarElement
                                id="multiple"
                                placeholder="To"
                                value={toDate}
                                onChange={(e) => onChangeToDate(e)}
                                minDate={fromDate}
                                readOnlyInput
                            />
                        </div>
                    }

                </div>
            }

            {props.isProjectList &&
                <div className="d-flex">
                    <div className="p-col-12 p-md-2 p-sm-12 pr-6 pl-0 filter suv-calender timesheet-cal travel-cal">
                        <CalendarElement
                            id="multiple"
                            placeholder="From"
                            value={fromDate}
                            onChange={(e) => onChangeFromDate(e)}
                            readOnlyInput
                        />
                    </div>
                    {
                        props.toDate !== 'toDate' &&
                        <div className="p-col-12 p-md-2 p-sm-12 pr-6 pl-0 filter">
                            <CalendarElement
                                id="multiple"
                                placeholder="To"
                                value={toDate}
                                onChange={(e) => onChangeToDate(e)}
                                minDate={fromDate}
                                readOnlyInput
                            />
                        </div>
                    }

                </div>
            }
            {props.isMonth &&
                // <div className="d-flex">
                <div className="p-col-2  p-md-2 p-sm-12 pr-6 pl-0 filter suv-calender">
                    <Calendar
                        value={month}
                        placeholder="Month"
                        onChange={(e) => setMonth(e.value)}
                        view="month"
                        dateFormat="mm/yy"
                        yearNavigator yearRange="2010:2030"
                    />
                </div>
                // </div>
            }

            {
                props.typeList &&
                <div className="p-col-2  p-md-2 p-sm-12 pr-6 pl-0 filter">
                    <MultiSelectElement
                        isMulti={true}
                        placeholder="Status"
                        className="p-inputtext-multiselect"
                        options={props.typeList}
                        onChangeValue={(e) => onChangeStatusInfo(e)}
                        value={selectedFilterStatusData}
                        optionLabel='name'
                        maxSelectLabel={1}
                    />
                </div>
            }
            {
                props.statusList &&
                <div className="p-col-2  p-md-2 p-sm-12 pr-6 pl-0 filter">
                    <MultiSelect
                        isMulti={true}
                        placeholder="Status"
                        className="p-inputtext-multiselect"
                        options={props.statusList}
                        onChange={(e) => onChangeStatusInfo(e)}
                        value={selectedFilterStatusData}
                        optionLabel='name'
                        maxSelectLabel={1}
                    />
                </div>
            }
            {
                props.type&&
                <div className="p-col-12 p-md-2 p-sm-12 pr-6 pl-0 pt-2 filter">

                <SingleSelectElement
                    placeholder="Report"
                    options={props.type.map(type => ({ label: type.reportType, value: type.reportType }))}
                    value={reportType}
                    onChangeValue={(e) => onChangeRportType(e)}
                    optionLabel="value"
                    filterBy='name'
                />
            </div>


            }
            {
                props.singleSelect &&
                <div className="p-col-2  p-md-2 p-sm-12 pr-6 pl-0 filter">

                    <SingleSelectElement
                        placeholder="Status"
                        options={props.singleSelect}
                        value={selectedFilterStatusData}
                        onChangeValue={(e) => onChangeStatus(e)}
                        optionLabel="name"
                        filterBy='name'
                    />
                </div>
            }
            {
                props.currentBu == 'currentBu' &&
                <div className="p-lg-2 p-md-2 p-sm-12">
                    <MultiSelectElement
                        isMulti={true}
                        placeholder="BU"
                        className="p-inputtext-multiselect"
                        options={props.buList}
                        onChangeValue={(e) => buChange(e)}
                        value={buUnit}
                        optionLabel='name'
                        maxSelectLabel={1}
                    />
                </div>
            }
            {
                props.userList &&
                <div className="p-lg-2 p-md-2 p-sm-12">
                    <MultiSelect
                        isMulti={true}
                        placeholder="Employees"
                        className="p-inputtext-multiselect"
                        options={getUsersList()}
                        onChange={(e) => onChangeUserInfo(e)}
                        value={selectedFilterUserData}
                        optionLabel='name'
                        maxSelectLabel={1}
                    />
                </div>
            }
            {
                props.clientsList &&
                <div className="p-col-12 p-md-2 p-sm-12">
                    <MultiSelectElement
                        isMulti={true}
                        placeholder="Client"
                        className="p-inputtext-multiselect wsrstatus"
                        options={props.clientsList}
                        onChangeValue={(e) => onChangeClientInfo(e)}
                        value={selectedFilterClientData}
                        optionLabel='name'
                        maxSelectLabel={1}
                    />
                </div>
            }
            {
                props.peList &&
                <div className="p-col-12 p-md-3 p-sm-12">
                    <MultiSelectElement
                        isMulti={true}
                        placeholder="PE"
                        className="p-inputtext-multiselect wsrstatus"
                        options={props.peList }
                        onChangeValue={(e) => onChangePe(e)}
                        value={pe}
                        optionLabel='name'
                        maxSelectLabel={1}
                    />
                </div>
            }
            {
                props.wsrClientsList &&
                <div className="p-col-12 p-md-3 p-sm-12">
                    <MultiSelectElement
                        isMulti={true}
                        placeholder="Port Co"
                        className="p-inputtext-multiselect wsrstatus"
                        options={props.wsrClientsList}
                        onChangeValue={(e) => onChangeClientInfo(e)}
                        value={selectedFilterClientData}
                        optionLabel='name'
                        maxSelectLabel={1}
                    />
                </div>
            }
            {
                props.projectList && props.wsr &&
                <div className="p-col-12 p-md-3 p-sm-12">
                    <MultiSelectElement
                        isMulti={true}
                        placeholder="Projects"
                        className="p-inputtext-multiselect wsrstatus"
                        options={getProjectsList()}
                        onChangeValue={(e) => onChangeProjectInfo(e)}
                        value={selectedFilterProjectData}
                        optionLabel='name'
                        maxSelectLabel={1}
                    />
                </div>
            }
            {
            props.projectList && !props.wsr &&
                <div className="p-col-12 p-md-2 p-sm-12">
                    <MultiSelectElement
                        isMulti={true}
                        placeholder="Projects"
                        className="p-inputtext-multiselect wsrstatus"
                        options={getProjectsList()}
                        onChangeValue={(e) => onChangeProjectInfo(e)}
                        value={selectedFilterProjectData}
                        optionLabel='name'
                        maxSelectLabel={1}
                    />
                </div>
            }
            {
                props.wsr &&
                <div className="p-lg-3 p-md-3 p-sm-12">
                    <TreeSelect
                        value={wsrNodeKeys}
                        onChange={(e) => onChangeWsrStatus(e)}
                        options={wsrStatusFilterList}
                        metaKeySelection={false}
                        className="treeSelect"
                        selectionMode="checkbox"
                        display="chip"
                        placeholder="Status"
                    >
                    </TreeSelect>
                </div>
            }
            {
                props.technicalCoeOption &&
                <div className="p-col-12 p-md-3 p-sm-12">
                    <MultiSelectElement
                        isMulti={true}
                        placeholder="Technical CoE"
                        className="p-inputtext-multiselect wsrstatus"
                        options={props.technicalCoeOption }
                        onChangeValue={(e) => onChangeTechnicalCoe(e)}
                        value={selectedTechnical}
                        optionLabel='name'
                        maxSelectLabel={1}
                    />
                </div>
            }
            {
                props.prepositionCoeOption &&
                <div className="p-col-12 p-md-3 p-sm-12">
                    <MultiSelectElement
                        isMulti={true}
                        placeholder="Proposition CoE"
                        className="p-inputtext-multiselect wsrstatus"
                        options={props.prepositionCoeOption }
                        onChangeValue={(e) => onChangePrepositionCoe(e)}
                        value={selectedPreposition}
                        optionLabel='name'
                        maxSelectLabel={1}
                    />
                </div>
            }
            
            {
                props.designationList &&
                <div className="p-lg-2 p-md-2 p-sm-12">
                    <MultiSelectElement
                        isMulti={true}
                        placeholder="Designation"
                        className="p-inputtext-multiselect"
                        options={getDesignationList()}
                        onChangeValue={(e) => onChangeDesignationInfo(e)}
                        value={selectedFilterDesignationData}
                        optionLabel='shortName'
                        maxSelectLabel={1}
                    />
                </div>
            }
            {
                props.groupedSelect == 'groupedSelect' &&
                <div className="p-lg-2 p-md-2 p-sm-12">
                    <TreeSelect
                        value={nodeKeys}
                        onChange={(e) => onChangeGroupedStatus(e)}
                        options={statusList}
                        metaKeySelection={false}
                        className="treeSelect"
                        selectionMode="checkbox"
                        display="chip"
                        placeholder="Status"
                    >
                    </TreeSelect>
                </div>
            }
            {/* {
                props.wsrStatus  &&
                <div className="p-lg-2 p-md-2 p-sm-12">
                    <MultiSelectElement
                        isMulti={true}
                        placeholder="Overall Status"
                        className="p-inputtext-multiselect wsrstatus"
                        options={props.wsrStatus}
                        onChangeValue={(e) => statusChange(e)}
                        value={status}
                        optionLabel='name'
                        maxSelectLabel={1}
                    />
                </div>
            } */}
            
            {
                props.currentBu == 'wsrBu' &&
                <div className="p-lg-3 p-md-3 p-sm-12">
                    <SingleSelectElement
                                placeholder="BU"
                                value={buUnit}
                                options={props.buList}
                                className="p-inputtext-multiselect wsrstatus"
                                optionLabel="name"
                                onChangeValue={(e) => buChange(e)}
                                filterBy='name'
                            />
                </div>
            }
            {/* {
                props.wsr &&
                <div className="p-lg-2 p-md-2 p-sm-12">
                    <TreeSelect
                        value={wsrPreviousNodeKeys}
                        onChange={(e) => onChangeWsrPrevStatus(e)}
                        options={wsrStatusFilterList}
                        metaKeySelection={false}
                        className="treeSelect"
                        selectionMode="checkbox"
                        display="chip"
                        placeholder="Status(Prev Week)"
                    >
                    </TreeSelect>
                </div>
            } */}
            {/* {
                <div className="p-col-2  p-md-2 p-sm-12 pl-0 filter">
                    <Button icon="pi pi-plus" tooltip="Expand All" onClick={props.doExpand} />
                    <Button icon="pi pi-minus" tooltip="Collapse All" onClick={props.doCollapse} />
                </div>} */}
            {
                props.projectFilter &&
                <div className="p-lg-2 p-md-1 p-sm-12" style={{ display: "flex" }}>
                    {/* <div className="flex "> */}

                    <div className="flex align-items-center" >
                        <RadioButton value="Project" onChange={(e) => onProjectFilterTypeClick(e)} checked={filterProjectType === 'Project'} />
                        <label style={{ fontSize: '12px', fontFamily: 'Arial', color: 'rgb(106, 108, 113)' }} className="ml-2">Project</label>
                    </div>
                    <div className="flex align-items-center" style={{ marginLeft: "8px" }}>
                        <RadioButton value="Allocation" onChange={(e) => onProjectFilterTypeClick(e)} checked={filterProjectType === 'Allocation'} />
                        <label style={{ fontSize: '12px', fontFamily: 'Arial', color: 'rgb(106, 108, 113)' }} className="ml-2">Allocation</label>
                    </div>
                </div>
                // </div>
            }



            {
                props.projectFilter &&
                <div className="p-lg-2 p-md-2 p-sm-12">
                    <CalendarElement
                        id="multiple"
                        placeholder="Start Date"
                        value={projectFromDate}
                        onChange={(e) => onChangeProjectStartDate(e)}
                        tooltip='start date'
                        readOnlyInput
                    />
                </div>
            }
            {
                props.projectFilter &&
                <div className="p-lg-2 p-md-2 p-sm-12">
                    <CalendarElement
                        id="multiple"
                        placeholder="End Date"
                        value={projectToDate}
                        onChange={(e) => onChangeProjectEndDate(e)}
                        minDate={projectFromDate}
                        tooltip='end date'
                        readOnlyInput
                    />
                </div>
            }

            {

                props.projectFilter &&
                <div className="p-col p-md-2 p-lg-3 pl-0 pr-6 filter-times">
                    <ButtonElement
                        className="fs-13 font-normal"
                        icon="pi pi-filter-fill"
                        iconPos="right"
                        onClickButton={() => onClickFilterHandler()}
                        tooltip="Filter"
                    />
                </div>
            }
            {!props.projectFilter && !props.filterSecond &&
                <div className="p-col-2 p-md-2 pl-0 pr-6 filter-times">
                    <ButtonElement
                        className="fs-13 font-normal"
                        icon="pi pi-filter-fill"
                        iconPos="right"
                        onClickButton={() => onClickFilterHandler()}
                        tooltip="Filter"
                    />
                </div>}
            {props.isKra &&
                <div className="p-md-2  pl-0 pr-6 filter-times">
                    <ToggleButton
                        checked={isAppraisee}
                        onLabel="Appraisee"
                        offLabel="Appraiser"
                        // style={{width: '8em'}}                     
                        onChange={(e) => setIsAppraisee(e.value)}
                        onIcon='pi pi-check'
                        offIcon='pi pi-times'

                    />
                </div>
            }
            {

                props.projectFilter &&
                <div className="p-md-2 p-lg-3 pl-0 pr-6 filter-times">
                    <ButtonElement
                        className="fs-13 font-normal"
                        icon="pi pi-filter-slash"
                        iconPos="right"
                        onClickButton={() => onClickLoadMoreHandler()}
                        tooltip="Reset"
                    />
                </div>}
            {/* {props.projectFilter &&
                <div className="p-md-2 p-lg-3 pl-0 pr-6 filter-times"><ButtonElement
                    icon="pi pi-plus"
                    onClickButton={props.doExpand}
                /></div>}
            {props.projectFilter &&
                <div className="p-md-2 p-lg-3 pl-0 pr-6 filter-times"><ButtonElement
                    icon="pi pi-minus"
                    onClickButton={props.doCollapse}
                /></div>} */}
            {!props.projectFilter && !props.filterSecond &&
                <div className="p-col-2 p-md-2 pl-0 pr-6 filter-times">
                    <ButtonElement
                        className="fs-13 font-normal"
                        icon="pi pi-filter-slash"
                        iconPos="right"
                        onClickButton={() => onClickLoadMoreHandler()}
                        tooltip="Reset"
                    />
                </div>}

            {props.label == 'reportStatus' && (props.reportsData && props.reportsData?.length>0) &&
                <div className="p-col-2 p-md-2 pl-0 pr-6 filter-times">
                    {console.log("props",props.reportsData.length)}
                    <ButtonElement
                        className="fs-13 font-normal"
                        icon="pi pi-download"
                        iconPos="right"
                        onClickButton={() => onClickSurveyDownloader()}
                        tooltip="Download"
                    />
                </div>
            }
            {
                props.filterSecond &&
                <div style={{ display: "flex", width: '100%' }}>
                    <div className="p-lg-2 p-md-2 p-sm-12 mt-6" style={{ display: "flex", whiteSpace: 'nowrap' }}>
                        <div className="flex align-items-center" >
                            <RadioButton value="Project_start" onChange={(e) => onProjectFilterTypeClick(e)} checked={filterProjectType === 'Project_start'} />
                            <label style={{ fontSize: '11px', fontFamily: 'Arial', color: 'rgb(106, 108, 113)' }} className="ml-2">Proj S.Date</label>
                        </div>
                        <div className="flex align-items-center" style={{ marginLeft: "8px" }}>
                            <RadioButton value="Project_end" onChange={(e) => onProjectFilterTypeClick(e)} checked={filterProjectType === 'Project_end'} />
                            <label style={{ fontSize: '11px', fontFamily: 'Arial', color: 'rgb(106, 108, 113)' }} className="ml-2">Proj E.Date</label>
                        </div>
                    </div>
                    <div className="p-lg-2 p-md-2 p-sm-12 mt-6" style={{ display: "flex", whiteSpace: 'nowrap' }}>
                        <div className="flex align-items-center">
                            <RadioButton value="Allocation_start" onChange={(e) => onProjectFilterTypeClick(e)} checked={filterProjectType === 'Allocation_start'} />
                            <label style={{ fontSize: '11px', fontFamily: 'Arial', color: 'rgb(106, 108, 113)' }} className="ml-2">Alloc S.Date</label>
                        </div>
                        <div className="flex align-items-center" style={{ marginLeft: "8px" }}>
                            <RadioButton value="Allocation_end" onChange={(e) => onProjectFilterTypeClick(e)} checked={filterProjectType === 'Allocation_end'} />
                            <label style={{ fontSize: '11px', fontFamily: 'Arial', color: 'rgb(106, 108, 113)' }} className="ml-2">Alloc E.Date</label>
                        </div>
                    </div>
                    <div className="p-lg-2 p-md-2 p-sm-12">
                        <CalendarElement
                            id="multiple"
                            placeholder="From"
                            value={projectFromDate}
                            onChange={(e) => onChangeProjectStartDate(e)}
                            tooltip='start date'
                            readOnlyInput
                            showButtonBar={true}
                        />
                    </div>
                    <div className="p-lg-2 p-md-2 p-sm-12">
                        <CalendarElement
                            id="multiple"
                            placeholder="To"
                            value={projectToDate}
                            onChange={(e) => onChangeProjectEndDate(e)}
                            minDate={projectFromDate}
                            tooltip='end date'
                            readOnlyInput
                            showButtonBar={true}
                        />
                    </div>
                    <div className="d-flex p-lg-2 p-md-2 p-sm-12">
                        <div className="p-md-2 p-lg-2 pl-0 pr-6 filter-times-resource">
                            <ButtonElement
                                className="fs-13 font-normal"
                                icon="pi pi-filter-fill"
                                iconPos="right"
                                onClickButton={() => onClickFilterHandler()}
                                tooltip="Filter"
                            />
                        </div>
                        <div className="p-col-2 p-md-2 pl-0 pr-6 filter-times-resource">
                            <ButtonElement
                                className="fs-13 font-normal"
                                icon="pi pi-filter-slash"
                                iconPos="right"
                                onClickButton={() => onClickLoadMoreHandler()}
                                tooltip="Reset"
                            />
                        </div>
                        {/* <div className="p-md-2 p-lg-3 pl-0 pr-6 filter-times-resource"><ButtonElement
                            icon="pi pi-plus"
                            onClickButton={props.doExpand}
                        /></div>
                        <div className="p-md-2 p-lg-3 pl-0 pr-6 filter-times-resource"><ButtonElement
                            icon="pi pi-minus"
                            onClickButton={props.doCollapse}
                        /></div> */}
                    </div>
                    {/* <div className="p-lg-2 p-md-2 p-sm-12 d-flex" id="email-trigger">
                        {
                            props.emailTrigger &&
                            <div className="trigger-button mt-3 mr-5">
                                <ButtonElement
                                    className=" fs-13 font-normal"
                                    label="Trigger Email"
                                    onClickButton={() => onClickTrigger()}
                                />
                            </div>
                        }
                    </div> */}
                    <div className="p-lg-2 p-md-2 p-sm-12 d-flex">
                        {
                            props.download &&
                            <div className="mt-3 mr-5 userFilter-resource">
                                <ButtonElement
                                    className=" fs-13 font-normal"
                                    label=""
                                    icon="pi pi-download"
                                    onClickButton={() => onClickDownload()}
                                />
                            </div>
                        }
                        <FilterViews pageName="Resourcing" filter={props.filterViewData} filterFunction={props.filter} />
                    </div>
                </div>
            }
        </div>
    );

    /* Render View Return - End */

};

/* Function - End */

/* Export default functionName; */

export default AdminFilterElement;