/**
 * Authentication Service
 */
 import axios from 'axios';

 let baseUrl = process.env.REACT_APP_API_URL;
 
 /* Leave Page Service - Start */
 
 async function getallHolidaysList(currentBuId) {
     let response = await axios.get(`${baseUrl}/api/holiday/holidayByBuId/${currentBuId}`);
     return response.data;
 }

 async function getHolidaysListById(id) {
    let response = await axios.get(`${baseUrl}/api/holiday/${id}`);
    return response.data;
}

 async function addNewHolidayList(data) {
    let response = await axios.post(`${baseUrl}/api/holiday`, data);
    return response.data;
}

async function editHolidayList(id,data) {
    let response = await axios.patch(`${baseUrl}/api/holiday/${id}`, data);
    return response.data;
}

async function getHolidaysListByDateRange(data) {
    let response = await axios.get(`${baseUrl}/api/holiday/holidayByBuId/dateRange/${data.buId}/${data.userId}?startDate=${data.startDate}&endDate=${data.endDate}&flag=${data.flag}`);
    return response.data;
}
 
 /* Export default functionNames; */
 
 export default { getHolidaysListById, editHolidayList, getallHolidaysList, addNewHolidayList, getHolidaysListByDateRange };