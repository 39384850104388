/* Import Section - Start */

/* React Imports - Start */

import React, { useEffect, useState, useRef } from "react";

/* React Imports - End */

/* Plugin Imports - Start */

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import Moment from 'moment';
import { OrderList } from 'primereact/orderlist';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import _ from 'lodash';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import LeaveService from "../../services/leave/LeaveService";

/* Plugin Imports - End */

/* Project Components Imports - Start */

import RequestModalElement from './RequestModal';
import ButtonElement from "../uiComponents/ButtonElement";
import SingleSelectElement from '../uiComponents/SingleSelectElement';
import ToastElement from "../uiComponents/ToastElement";
import EmptyImage from '../../assets/images/EmptyImage.png';
import avatar from '../../assets/images/Avatar.png';
import avatarFemale from '../../assets/images/AvatarFemale.png';
import leaveService from "../../services/leave/LeaveService";
import CheckboxElement from "../uiComponents/CheckboxElement";
import EmployeesService from "../../services/employees/EmployeesService";
import Cookies from "universal-cookie";
import Loader from "../uiComponents/Loader";
import CalendarElement from "../uiComponents/CalendarElement";
import MultiSelectElement from '../uiComponents/MultiSelectElement';

/* Project Components Imports - End */

/* Import Section - End */

/* Function - Start */

function Leave() {

	/* State Declaration - Start */

	/* useState - Start */

	//Common States
	const [workExperience, setWorkExperience] = useState(0);
	const [holidays, setHolidays] = useState([]);
	const [date, setDate] = useState('');
	const [assLeaveRejectInfo, setAssLeaveRejectInfo] = useState('');
	const [inputAssLeaveText, setInputAssLeaveText] = useState("");
	const [inputAssCompOffText, setInputAssCompOffText] = useState("");
	const [inputAssPermissionText, setInputAssPermissionText] = useState("");
	const [ptoLeaveCount, setPTOLeaveCount] = useState({
		totalLeave: '',
		balanceLeave: ''
	});
	const [compLeaveCount, setCompLeaveCount] = useState({
		totalLeave: '',
		balanceLeave: ''
	});
	const [permissionCount, setPermissionCount] = useState({
		totalPermission: '',
		balancePermission: ''
	});
	const [earnedCount, setEarnedCount] = useState({
		totalEarned: '',
		balanceEarned: ''
	});
	const [annualCount, setAnnualCount] = useState({
		totalLeave: '',
		balanceLeave: ''
	});
	const [wfhCount, setWfhCount] = useState({
		totalLeave: '',
		balanceLeave: ''
	});
	const [reportingManager, setReportingManager] = useState('');
	const [reporterGender, setReporterGender] = useState('');
	const [reporterId, setReporterId] = useState('');
	const [reportingManagerData, setReportingManagerData] = useState('');
	const [listData, setListData] = useState([]);
	const [editMyLeaveData, setEditMyLeaveData] = useState();
	const [permissionHoursLimit, setPermissionHoursLimit] = useState('');
	const [editMyLeaveType, setEditMyLeaveType] = useState();
	const [permissionBalance, setPermissionBalance] = useState();
	const [success, setSuccess] = useState('');
	const [compOffId, setCompOffId] = useState('');
	const [checkedBulkLeave, setCheckedBulkLeave] = useState(false);
	const [checkedBulkComp, setCheckedBulkComp] = useState(false);
	const [checkedBulkPermission, setCheckedBulkPermission] = useState(false);
	const [loading, setLoading] = useState(false);

	//My Leave, Comp Off, Permission List States
	const [myLeave, setMyLeave] = useState([]);
	const [earnedLeave, setEarnedLeave] = useState([]);
	const [myLeaveCalendar, setLeaveCalendar] = useState([]);
	const [myCompOff, setMyCompOff] = useState([]);
	const [myPermission, setMyPermission] = useState([]);

	//Associates Leave, Comp Off, Permission List States
	const [associateLeaveList, setAssociateLeaveList] = useState([]);
	const [associatesCompOffList, setAssociatesCompOffList] = useState([]);
	const [associatesPermissionList, setAssociatesPermissionList] = useState([]);

	//My Leave, Comp Off, Permission Cancel Data States
	const [myLeaveCancelData, setMyLeaveCancelData] = useState();
	const [myCompOffCancelData, setMyCompOffCancelData] = useState();
	const [myPermissionCancelData, setMyPermissionCancelData] = useState();

	//Associates Leave, Comp Off, Permission Approve, Reject, Cancel Data States
	const [assLeaveApproveData, setAssLeaveApproveData] = useState();
	const [assLeaveRejectData, setAssLeaveRejectData] = useState();
	const [assLeaveCancelData, setAssLeaveCancelData] = useState();
	const [assCompApproveData, setAssCompApproveData] = useState();
	const [assCompRejectData, setAssCompRejectData] = useState();
	const [assPermApproveData, setAssPermApproveData] = useState([]);
	const [assPermRejectData, setAssPermRejectData] = useState([]);
	const [assPermCancelData, setAssPermCancelData] = useState();

	//New and Edit Request Modal Popup States
	const [displayRequestModal, setDisplayRequestModal] = useState(false);
	const [displayEditLeaveRequestModal, setDisplayEditLeaveRequestModal] = useState(false);

	//My Leave, Comp Off, Permission Cancel Modal Popup States
	const [displayMyLeaveCancel, setDisplayMyLeaveCancel] = useState(false);
	const [displayMyCompOffCancel, setDisplayMyCompOffCancel] = useState(false);
	const [displayMyPermissionCancel, setDisplayMyPermissionCancel] = useState(false);

	//Associates Leave, Comp Off, Permission Approve, Reject, Cancel Modal Popup States
	const [displayAssociateLeaveApprove, setDisplayAssociateLeaveApprove] = useState(false);
	const [displayAssociateLeaveReject, setDisplayAssociateLeaveReject] = useState(false);
	const [displayAssociateLeaveCancel, setDisplayAssociateLeaveCancel] = useState(false);
	const [displayAssociateCompOffApprove, setDisplayAssociateCompOffApprove] = useState(false);
	const [displayAssociateCompOffReject, setDisplayAssociateCompOffReject] = useState(false);
	const [displayAssociatePermissionApprove, setDisplayAssociatePermissionApprove] = useState(false);
	const [displayAssociatePermissionReject, setDisplayAssociatePermissionReject] = useState(false);
	const [displayAssociatePermissionCancel, setDisplayAssociatePermissionCancel] = useState(false);

	//Bulk Leave Approve, Reject
	const [leaveCheckedCount, setLeaveCheckedCount] = useState(0);
	const [leaveId, setLeaveId] = useState([]);

	//Bulk Comp Off Approve, Reject
	const [compCheckedCount, setCompCheckedCount] = useState(0);
	const [compId, setCompId] = useState([]);

	//Bulk Permission Approve, Reject
	const [permissionCheckedCount, setPermissionCheckedCount] = useState(0);
	const [permissionId, setPermissionId] = useState([]);

	//Show more Leave, Comp Off, Permission
	const [leaveLimitCount, setLeaveLimitCount] = useState(10);
	const [leaveShowMore, setLeaveShowMore] = useState(false);

	const [leaveAssociateLimitCount, setLeaveAssociateLimitCount] = useState(10);
	const [leaveAssociateShowMore, setLeaveAssociateShowMore] = useState(false);

	const [compoffLimitCount, setCompoffLimitCount] = useState(10);
	const [compoffShowMore, setCompoffShowMore] = useState(false);

	const [compoffAssociateLimitCount, setCompOffAssociateLimitCount] = useState(10);
	const [compoffAssociateShowMore, setCompOffAssociateShowMore] = useState(false);

	const [permissionLimitCount, setPermissionLimitCount] = useState(10);
	const [permissionShowMore, setPermissionShowMore] = useState(false);

	const [permissionAssociateLimitCount, setPermissionAssociateLimitCount] = useState(10);
	const [permissionAssociateShowMore, setPermissionAssociateShowMore] = useState(false);

	const [employeesList, setEmployeesList] = useState([]);
	const [employeeDetails, setEmployeeDetails] = useState([]);
	const [associateLeave, setAssociateLeave] = useState([]);
	const [displayFilter, setDisplayFilter] = useState(false);
	const [allAssociateLeaveList, setAllAssociateLeaveList] = useState([]);
	const [balanceLeaves, setBalanceLeaves] = useState({});

	/* useState - End */

	/* useRef - Start */

	const toastRef = useRef();
	const cookies = new Cookies();

	/* useRef - End */

	/* useEffect - Start */

	//Function call on load
	useEffect(() => {
		getCurrentDate();
		getAllLeaveCount();
		gettingWorkExperience();
		// getPermissionBalance();
		getAllLeaveRequestByUser();
		getAllCompRequestByUser();
		// getAllPermissionRequestByUser();
		// getAssociatesPermissionList();
		getAssociatesCompOffList();
		getReportingManager();
		getEmployeeList();
	}, [])

	useEffect(() => {
		getAllLeaveRequestByUser();
	}, [success])

	/* useEffect - End */

	/* State Declaration - End */

	/* Static JSON - Start */

	const cancelReasons = [
		{ name: 'Transportation issue', value: 'Transportation issue' },
		{ name: 'Need to Reschedule', value: 'Need to Reschedule' },
		{ name: 'Permission', value: 'Permission' },
		{ name: 'Discuss with Manager', value: 'Discuss with Manager' }
	];

	let buId = cookies.get('buId');
	let buName = cookies.get('buName');

	let roleNames = cookies.get("roles");

	let showAssociateTab = false;
	if (roleNames) {
		roleNames.map(role => {
			if (role == "Reporting Manager") {
				showAssociateTab = true
			}
		})
	}

	let showAllAssociates = false;
	if (roleNames) {
		roleNames.map(role => {
			if (role == "Admin") {
				showAllAssociates = true
			}
		})
	}

	/* Static JSON - End */

	/* Service Method Call - Start */

	const getEmployeeList = () => {
		EmployeesService.getEmployees(buId).then(data => {
			let employeeList = [];
			data.data.rows.forEach(emp => {
				// Exculded loggedIn User an DeActivated
				// if (emp.accountStatus == 'ACTIVATED' && emp.id != cookies.get('userId')) {
				if (emp.accountStatus == 'ACTIVATED') {
					employeeList.push({
						name: emp.firstName + ' ' + emp.lastName,
						value: emp.id
					});
				}
			});
			var filteredData = _.filter(employeeList, (item) => item.name.toLowerCase() !== 'admin user');
			setEmployeesList(filteredData);
		})
	}

	//Get Reporting Manager Details API call
	const getReportingManager = () => {
		var userId = cookies.get('userId');
		leaveService.getReporterDetails(userId).then(data => {
			if (data.data !== null) {
				setReportingManager(data.data.firstName + ' ' + data.data.lastName);
				setReporterGender(data.data.gender);
				setReporterId(data.data.id);
			}
		})
	}

	//Get All Leave Count API call
	const getAllLeaveCount = () => {
		let currentId = {
			currentBuId: cookies.get('buId'),
			currentUserId: cookies.get('userId')
		}
		leaveService.getLeaveTypes(currentId.currentUserId).then(data => {
			data.data.map(element => {
				if (element.ConfigLeaveType?.leaveTypeName == 'Casual / Sick Leave') {
					setPTOLeaveCount({
						totalLeave: element.eligibleLeaves,
						balanceLeave: element.balanceLeaves,
						taken: element.taken
					})
				} else if (element.ConfigLeaveType?.leaveTypeName == 'Compensated Time Off') {
					setCompLeaveCount({
						totalLeave: element.eligibleLeaves,
						balanceLeave: element.balanceLeaves
					})
				} else if (element.ConfigLeaveType?.leaveTypeName == 'Annual Leave') {
					setAnnualCount({
						totalLeave: element.eligibleLeaves,
						balanceLeave: element.balanceLeaves,
						taken: element.taken
					})
				} else if (element.ConfigLeaveType?.leaveTypeName == 'WFH') {
					setWfhCount({
						totalLeave: element.eligibleLeaves,
						balanceLeave: element.balanceLeaves
					})
				} else if (element.ConfigLeaveType?.leaveTypeName == 'Permission') {
					setPermissionCount({
						totalLeave: element.eligibleLeaves,
						balanceLeave: element.balanceLeaves
					})
					setPermissionHoursLimit(element.ConfigLeaveType?.hoursPerDayLimit)
				} else if (element.ConfigLeaveType?.leaveTypeName == 'Earned Leave') {
					setEarnedCount({
						totalLeave: element.eligibleLeaves,
						balanceLeave: element.balanceLeaves
					})
				}
			})
		})
	}

	//Get Permission Balance API call
	/* const getPermissionBalance = () => {
		var currentUserId = cookies.get('userId');
		leaveService.getPermissionBalance(currentUserId).then(data => {
			if (data.data != null) {
				setPermissionBalance(data.data.balancePermissions);
				setPermissionCount({
					totalPermission: data.data.eligiblePermissions,
					balancePermission: data.data.balancePermissions
				})
			}
		})
	} */

	//Get User Leave Request API call
	const getAllLeaveRequestByUser = () => {
		setLoading(true);
		var currentUserId = cookies.get('userId');

		// Use the below code when showmoe has been done in backend with limit
		/* leaveService.getAllLeaveRequestByUser(leaveLimitCount, currentUserId).then(data => {   
			if (data.rows.length <= leaveLimitCount) {
				setLeaveShowMore(data.isShowMore);
			}
			setMyLeave(data.rows);
		})

		let count = leaveLimitCount + 10;
		setLeaveLimitCount(count); 
	}*/
		leaveService.getAllLeaveRequestByUser(currentUserId).then(data => {
			setLoading(false);
			var leaveData = _.orderBy(data.data, [(item) => {
				return item.from_date
			}], ['desc']);
			var excludingWfhLeaveData = _.filter(leaveData,(item)=>item.ConfigLeaveType.leaveTypeName !='WFH' && item.ConfigLeaveType.leaveTypeName !='Earned Leave')
			var earnedLeaveData = _.filter(leaveData,(item)=>item.ConfigLeaveType.leaveTypeName =='Earned Leave')
			setMyLeave(excludingWfhLeaveData);
			setEarnedLeave(earnedLeaveData);
			setLeaveCalendar(leaveData)
		})
	}

	//Get User Comp Off Request API call
	const getAllCompRequestByUser = () => {
		setLoading(true);
		var currentUserId = cookies.get('userId');
		// Use the below code when showmoe has been done in backend with limit
		/* 
			leaveService.getAllCompRequestByUser(compoffLimitCount, currentUserId).then(data => {   
				if (data.rows.length <= compoffLimitCount) {
					setCompoffShowMore(data.isShowMore);
				}
				setMyCompOff(data.rows); 			

				let count = compoffLimitCount + 10;
				setCompoffLimitCount(count);
		*/

		leaveService.getAllCompRequestByUser(currentUserId).then(data => {
			setLoading(false);
			var leaveData = _.orderBy(data.data, [(item) => {
				return item.from_date
			}], ['desc']);
			setMyCompOff(leaveData);
			// setCompOffId(data.data[0].leaveTypeId);
		})
	}

	//Get User Permission Request API call
	const getAllPermissionRequestByUser = () => {
		setLoading(true);
		var currentUserId = cookies.get('userId');

		// Use the below code when showmoe has been done in backend with limit		
		/* 
			leaveService.getAllPermissionRequestByUser(permissionLimitCount, currentUserId).then(data => { 
				if (data.rows.length <= permissionLimitCount) {
					setPermissionShowMore(data.isShowMore);
				}
				setMyPermission(data.rows);
			});

			let count = permissionLimitCount + 10;
			setPermissionLimitCount(count);
		*/

		leaveService.getAllPermissionRequestByUser(currentUserId).then(data => {
			setLoading(false);
			var leaveData = _.orderBy(data.data, [(item) => {
				return item.on_date
			}], ['desc']);
			setMyPermission(leaveData);
		})
	}

	//Get Associates Leave List API call
	const getAllAssociatesLeaveList = () => {
		setLoading(true);
		var buId = cookies.get('buId');
		{
			(showAllAssociates || buName === 'IG-LONDON') &&
				leaveService.getAllLeaveRequests(buId).then(data => {
					setLoading(false);
					setAllAssociateLeaveList(data.data);
				})
		}
	}

	//Get Associates Leave List API call
	const getAssociatesLeaveList = () => {
		setLoading(true);
		let currentId = {
			buId: cookies.get('buId'),
			reportingUserId: cookies.get('userId'),
		}
		// Use the below code when showmoe has been done in backend with limit		
		/* 
				leaveService.getAssociatesLeaveList(leaveAssociateLimitCount, currentId).then(data => {
				if (data.filteredRequests.rows.length <= leaveAssociateLimitCount) {
					setLeaveAssociateShowMore(data.isShowMore);
				}
				setAssociateLeaveList(data.filteredRequests.rows);
			})

			let count = leaveAssociateLimitCount + 10;
			setLeaveAssociateLimitCount(count);
		*/
		{
			showAssociateTab &&
				leaveService.getAssociatesLeaveList(currentId.reportingUserId).then(data => {
					setLoading(false);
					var leaveData = _.orderBy(data.data, [(item) => {
						return item.from_date
					}], ['desc']);
					setAssociateLeaveList(leaveData);
				})
		}
		leaveService.allHolidays(currentId.buId).then(data => {
			setLoading(false);
			setHolidays(data.data);
		});
	}

	//Get Associates Comp Off List API call
	const getAssociatesCompOffList = () => {
		setLoading(true);
		let currentId = {
			reportingUserId: cookies.get('userId')
		}

		// Use the below code when showmoe has been done in backend with limit		
		/* 
			leaveService.getAssociatesCompOffList(compoffAssociateLimitCount, currentId.reportingUserId).then(data => {			
			if (data.filteredRequests.rows.length <= compoffAssociateLimitCount) {
				setCompOffAssociateShowMore(data.filteredRequests.isShowMore);
			}
			setAssociatesCompOffList(data.filteredRequests.rows);
		*/
		leaveService.getAssociatesCompOffList(currentId.reportingUserId).then(data => {
			setLoading(false);
			var leaveData = _.orderBy(data.data, [(item) => {
				return item.from_date
			}], ['desc']);
			setAssociatesCompOffList(leaveData);
		})

		let count = compoffAssociateLimitCount + 10;
		setCompOffAssociateLimitCount(count);
	}

	//Get Associates Permission List API call
	// const getAssociatesPermissionList = () => {
	// 	setLoading(true);
	// 	let currentId = {
	// 		reportingUserId: cookies.get('userId')
	// 	}

	// 	// Use the below code when showmoe has been done in backend with limit		
	// 	/* 
	// 		leaveService.getAssociatesPermissionList(currentId.reportingUserId).then(data => {	
	// 		if (data.allPermRequests.length <= permissionAssociateLimitCount) {
	// 			setCompOffAssociateShowMore(data.isShowMore);
	// 		}
	// 		setAssociatesPermissionList(data.allPermRequests);
		
	// 		let count = permissionAssociateLimitCount + 10;
	// 		setPermissionAssociateLimitCount(count);
	// 	*/

	// 	leaveService.getAssociatesPermissionList(currentId.reportingUserId).then(data => {
	// 		setLoading(false);
	// 		var leaveData = _.orderBy(data.data, [(item) => {
	// 			return item.on_date
	// 		}], ['desc']);
	// 		setAssociatesPermissionList(leaveData);
	// 	})

	// 	let count = permissionAssociateLimitCount + 10;
	// 	setPermissionAssociateLimitCount(count);
	// }


	//My Leave Cancel API call
	const myLeaveCancelHandler = (e) => {
		setLoading(true);
		let data = {
			id: myLeaveCancelData ? [myLeaveCancelData.id] : null,
			leaveStatus: myLeaveCancelData ? myLeaveCancelData.leaveStatus : null,
			comments: assLeaveRejectInfo
		}
		if (assLeaveRejectInfo != '') {
			leaveService.myLeaveCancel(data).then(data => {
				setLoading(false);
				let message = {
					type: data.patched.success ? 'success' : 'error',
					summary: data.patched.success ? 'Success' : 'Something went wrong',
					message: data.patched.message
				}
				let success = data.patched.success ? true : false
				onHide('displayMyLeaveCancel', message, success);
			})
		}
		else {
			setLoading(false);
			toastRef.current.showToast({
				type: 'error',
				summary: 'Error',
				message: 'Fill the mandatory fields'
			})
		}
	}

	//Associate Leave Approve API call
	const assLeaveApproveHandler = () => {
		setLoading(true);
		let data = {
			leaveRequests: assLeaveApproveData ? [assLeaveApproveData.id] : null,
			status: "APPROVED"
		}
		leaveService.assLeaveApproveRejectCancel(data).then(data => {
			setLoading(false);
			let message = {
				type: data.patched.success ? 'success' : 'error',
				summary: data.patched.success ? 'Success' : 'Something went wrong',
				message: data.patched.message
			}
			let success = data.patched.success ? true : false
			onHide('displayAssociateLeaveApprove', message, success);
		})
	}

	//Associate Leave Reject API call
	const assLeaveRejectHandler = () => {
		setLoading(true);

		let data = {
			leaveRequests: assLeaveRejectData ? [assLeaveRejectData.id] : null,
			status: "REJECTED",
			comments: assLeaveRejectInfo
		}
		if (assLeaveRejectInfo != '') {
			leaveService.assLeaveApproveRejectCancel(data).then(data => {
				setLoading(false);
				let message = {
					type: data.patched.success ? 'success' : 'error',
					summary: data.patched.success ? 'Success' : 'Something went wrong',
					message: data.patched.message
				}
				let success = data.patched.success ? true : false
				onHide('displayAssociateLeaveReject', message, success);
				setAssLeaveRejectInfo('');
			})
		}
		else {
			setLoading(false);
			toastRef.current.showToast({
				type: 'error',
				summary: 'Error',
				message: 'Fill the mandatory fields'
			})
		}
		setAssLeaveRejectInfo('');
	}

	//Associate Leave Cancel API call
	const assLeaveCancelHandler = () => {
		setLoading(true);
		let data = {
			leaveRequests: assLeaveCancelData ? [assLeaveCancelData.id] : null,
			status: "CANCELLED",
			comments: assLeaveRejectInfo
		}
		if (assLeaveRejectInfo != '') {
			leaveService.assLeaveApproveRejectCancel(data).then(data => {
				setLoading(false);
				let message = {
					type: data.patched.success ? 'success' : 'error',
					summary: data.patched.success ? 'Success' : 'Something went wrong',
					message: data.patched.message
				}
				let success = data.patched.success ? true : false
				onHide('displayAssociateLeaveCancel', message, success);
			})
		}
		else {
			setLoading(false);
			toastRef.current.showToast({
				type: 'error',
				summary: 'Error',
				message: 'Fill the mandatory fields'
			})
		}
		setAssLeaveRejectInfo('');
	}

	//Bulk Leave Approve
	const bulkLeaveApprove = () => {
		setLoading(true);
		let data = {
			leaveRequests: leaveId,
			status: "APPROVED"
		}
		leaveService.assLeaveApproveRejectCancel(data).then(data => {
			setLoading(false);
			let message = {
				type: data.patched.success ? 'success' : 'error',
				summary: data.patched.success ? 'Success' : 'Something went wrong',
				message: data.patched.message
			}
			let success = data.patched.success ? true : false
			onHide('', message, success);
			setCheckedBulkLeave(false);
		})
	}

	//Bulk Leave Reject
	const bulkLeaveReject = () => {
		setLoading(true);
		let data = {
			leaveRequests: leaveId,
			status: "REJECTED",
			comments: "Bulk Rejection"
		}
		leaveService.assLeaveApproveRejectCancel(data).then(data => {
			setLoading(false);
			let message = {
				type: data.patched.success ? 'success' : 'error',
				summary: data.patched.success ? 'Success' : 'Something went wrong',
				message: data.patched.message
			}
			let success = data.patched.success ? true : false
			onHide('', message, success);
			setCheckedBulkLeave(false);
		})
	}

	//My Comp Off Cancel API call
	const myCompOffCancelHandler = () => {
		setLoading(true);
		let data = {
			compIds: myCompOffCancelData ? [myCompOffCancelData.id] : null,
			status: "CANCELLED",
			comment: assLeaveRejectInfo
		}
		if (assLeaveRejectInfo != '') {
			leaveService.assCompOffApproveReject(data).then(data => {
				setLoading(false);
				let message = {
					type: data.patched.success ? 'success' : 'error',
					summary: data.patched.success ? 'Success' : 'Something went wrong',
					message: data.patched.message
				}
				let success = data.patched.success ? true : false
				onHide('displayMyCompOffCancel', message, success);
			})
		}
		else {
			setLoading(false);
			toastRef.current.showToast({
				type: 'error',
				summary: 'Error',
				message: 'Fill the mandatory fields'
			})
		}
		setAssLeaveRejectInfo('');
	}

	//Associate Comp Off Approve API call
	const assCompOffApproveHandler = () => {
		setLoading(true);
		let data = {
			compIds: assCompApproveData ? [assCompApproveData.id] : null,
			status: "APPROVED"
		}
		leaveService.assCompOffApproveReject(data).then(data => {
			setLoading(false);
			let message = {
				type: data.patched.success ? 'success' : 'error',
				summary: data.patched.success ? 'Success' : 'Something went wrong',
				message: data.patched.message
			}
			let success = data.patched.success ? true : false
			onHide('displayAssociateCompOffApprove', message, success);
		})
	}

	//Associate Comp Off Reject API call
	const assCompOffRejectHandler = () => {
		setLoading(true);
		let data = {
			compIds: assCompRejectData ? [assCompRejectData.id] : null,
			status: "REJECTED",
			// comment: assLeaveRejectInfo
		}
		// if (assLeaveRejectInfo != '') {
		leaveService.assCompOffApproveReject(data).then(data => {
			setLoading(false);
			let message = {
				type: data.patched.success ? 'success' : 'error',
				summary: data.patched.success ? 'Success' : 'Something went wrong',
				message: data.patched.message
			}
			let success = data.patched.success ? true : false
			onHide('displayAssociateCompOffReject', message, success);
		})
		// }
		// else {
		// 	setLoading(false);
		// 	toastRef.current.showToast({
		// 		type: 'error',
		// 		summary: 'Error',
		// 		message: 'Fill the mandatory fields'
		// 	})
		// }
	}

	//Bulk Comp Off Approve
	const bulkCompApprove = () => {
		setLoading(true);
		let data = {
			compIds: compId,
			status: "APPROVED"
		}
		leaveService.assCompOffApproveReject(data).then(data => {
			setLoading(false);
			let message = {
				type: data.patched.success ? 'success' : 'error',
				summary: data.patched.success ? 'Success' : 'Something went wrong',
				message: data.patched.message
			}
			let success = data.patched.success ? true : false
			onHide('', message, success);
			setCheckedBulkComp(false);
		})
	}

	//Bulk Comp Off Reject
	const bulkCompReject = () => {
		setLoading(true);
		let data = {
			compIds: compId,
			status: "REJECTED",
			comment: "Bulk Rejection"
		}
		leaveService.assCompOffApproveReject(data).then(data => {
			setLoading(false);
			let message = {
				type: data.patched.success ? 'success' : 'error',
				summary: data.patched.success ? 'Success' : 'Something went wrong',
				message: data.patched.message
			}
			let success = data.patched.success ? true : false
			onHide('', message, success);
			setCheckedBulkComp(false);
		})
	}

	//My Permission Cancel API call
	const myPermissionCancelHandler = () => {
		setLoading(true);
		let data = {
			permissionIds: myPermissionCancelData ? [myPermissionCancelData.id] : null,
			status: "CANCELLED",
			comments: assLeaveRejectInfo
		}
		leaveService.assPermissionApproveRejectCancel(data).then(data => {
			setLoading(false);
			let message = {
				type: data.patched.success ? 'success' : 'error',
				summary: data.patched.success ? 'Success' : 'Something went wrong',
				message: data.patched.message
			}
			let success = data.patched.success ? true : false
			onHide('displayMyPermissionCancel', message, success);
		})
	}

	//Associate Permission Approve API call
	const assPermissionApproveHandler = () => {
		setLoading(true);
		let data = {
			permissionIds: assPermApproveData ? [assPermApproveData.id] : null,
			status: "APPROVED"
		}
		leaveService.assPermissionApproveRejectCancel(data).then(data => {
			setLoading(false);
			let message = {
				type: data.patched.success ? 'success' : 'error',
				summary: data.patched.success ? 'Success' : 'Something went wrong',
				message: data.patched.message
			}
			let success = data.patched.success ? true : false
			onHide('displayAssociatePermissionApprove', message, success);
		})
	}

	//Associate Permission Reject API call
	const assPermissionRejectHandler = () => {
		setLoading(true);
		let data = {
			permissionIds: assPermRejectData ? [assPermRejectData.id] : null,
			status: "REJECTED",
			// comment: assLeaveRejectInfo
		}
		// if (assLeaveRejectInfo != '') {
		leaveService.assPermissionApproveRejectCancel(data).then(data => {
			setLoading(false);
			let message = {
				type: data.patched.success ? 'success' : 'error',
				summary: data.patched.success ? 'Success' : 'Something went wrong',
				message: data.patched.message
			}
			let success = data.patched.success ? true : false
			onHide('displayAssociatePermissionReject', message, success);
		})
		// }
		// else {
		// 	setLoading(false);
		// 	toastRef.current.showToast({
		// 		type: 'error',
		// 		summary: 'Error',
		// 		message: 'Fill the mandatory fields'
		// 	})
		// }
	}

	//Associate Permission Cancel API call
	const assPermissionCancelHandler = () => {
		setLoading(true);
		let data = {
			permissionIds: assPermCancelData ? [assPermCancelData.id] : null,
			status: "CANCELLED",
			comment: assLeaveRejectInfo
		}
		if (assLeaveRejectInfo != '') {
			leaveService.assPermissionApproveRejectCancel(data).then(data => {
				setLoading(false);
				let message = {
					type: data.patched.success ? 'success' : 'error',
					summary: data.patched.success ? 'Success' : 'Something went wrong',
					message: data.patched.message
				}
				let success = data.patched.success ? true : false
				onHide('displayAssociatePermissionCancel', message, success);
			})
		}
		else {
			setLoading(false);
			toastRef.current.showToast({
				type: 'error',
				summary: 'Error',
				message: 'Fill the mandatory fields'
			})
		}
	}

	//Bulk Permission Approve
	const bulkPermissionApprove = () => {
		setLoading(true);
		let data = {
			permissionIds: permissionId,
			status: "APPROVED"
		}
		leaveService.assPermissionApproveRejectCancel(data).then(data => {
			setLoading(false);
			let message = {
				type: data.patched.success ? 'success' : 'error',
				summary: data.patched.success ? 'Success' : 'Something went wrong',
				message: data.patched.message
			}
			let success = data.patched.success ? true : false
			onHide('', message, success);
			setCheckedBulkPermission(false);
		})
	}

	//Bulk Permission Reject
	const bulkPermissionReject = () => {
		setLoading(true);
		let data = {
			permissionIds: permissionId,
			status: "REJECTED",
			comment: "Bulk Rejection"
		}
		leaveService.assPermissionApproveRejectCancel(data).then(data => {
			setLoading(false);
			let message = {
				type: data.patched.success ? 'success' : 'error',
				summary: data.patched.success ? 'Success' : 'Something went wrong',
				message: data.patched.message
			}
			let success = data.patched.success ? true : false
			onHide('', message, success);
			setCheckedBulkPermission(false);
		})
	}

	//Edit My Leave API call
	const editMyLeave = (id, leaveEditType, leaveTypeId, userId) => {
		setLoading(true);
		setEditMyLeaveType(leaveEditType);
		leaveService.getLeaveBalanceByTypeAndUser(leaveTypeId, userId).then(data => {
			var advanceBal = '';
			if (data.data.userStatus.UserStatus.leaveLimit === 0 || data.data.userStatus.UserStatus.leaveLimit > 1) {
				advanceBal = data.data.advanceBalance;
			}
			setBalanceLeaves({
				balanceLeaves: data.data.balanceLeaves,
				advanceLeaves: advanceBal,
				leaveTypeName: data.data.ConfigLeaveType?.leaveTypeName,
				userStatus: data.data.userStatus.UserStatus.status
			});
		})
		leaveService.editMyLeaveDetails(id).then((data) => {
			setLoading(false);
			setEditMyLeaveData(data.data);
			dialogFuncMap['displayEditLeaveRequestModal'](true);
		})
	}

	//Edit My Comp Off API call
	const editMyCompOff = (id, leaveEditType, userId) => {
		setLoading(true);
		setEditMyLeaveType(leaveEditType);
		leaveService.editMyCompOffDetails(id, userId).then((data) => {
			setLoading(false);
			setEditMyLeaveData(data.data);
			dialogFuncMap['displayEditLeaveRequestModal'](true);
		})
	}

	//Edit My Permission API call
	const editMyPermission = (id, leaveEditType, userId) => {
		setLoading(true);
		setEditMyLeaveType(leaveEditType);
		var currentBuId = cookies.get('buId');
		// leaveService.leaveConfiguration(currentBuId).then(data => {
		// 	setPermissionHoursLimit(data.data[0].leavedata.permissionHoursLimit);
		// });
		leaveService.editMyPermissionDetails(id, userId).then((data) => {
			setLoading(false);
			setEditMyLeaveData(data.data);
			dialogFuncMap['displayEditLeaveRequestModal'](true);
		})
	}

	/* Service Method Call - End */

	/* Methods - Start */

	//Get current Date
	const getCurrentDate = () => {
		let todayDate = new Date();
		let date = Moment(todayDate).format("DD MMMM YYYY");
		setDate(date);
	}

	//On Click Leave Show More Button
	const onLeaveLoadMoreHandler = () => {
		setLeaveLimitCount(leaveLimitCount + 10);
		getAllLeaveRequestByUser();
	}

	//On Click Leave Associates Show More Button
	const onLeaveAssociateLoadMoreHandler = () => {
		setLeaveAssociateLimitCount(leaveAssociateLimitCount + 10);
		getAssociatesLeaveList();
	}

	//On Click Comp Off Show More Button
	const onCompOffLoadMoreHandler = () => {
		setCompoffLimitCount(leaveLimitCount + 10);
		getAllCompRequestByUser();
	}

	//On Click Comp Off Associates Show More Button
	const onCompOffAssociateLoadMoreHandler = () => {
		setCompOffAssociateLimitCount(leaveAssociateLimitCount + 10);
		getAssociatesCompOffList();
	}

	//On Click Permission Show More Button
	const onPermissionLoadMoreHandler = () => {
		setPermissionLimitCount(permissionLimitCount + 10);
		getAllPermissionRequestByUser();
	}

	//On Click Permission Associate Show More Button
	const onPermissionAssociateLoadMoreHandler = () => {
		setPermissionLimitCount(permissionAssociateLimitCount + 10);
		// getAssociatesPermissionList();
	}

	//Get and set the cancel info for Reject Leave
	const onChangeRejectInfo = (e) => {
		setAssLeaveRejectInfo(e.target.value)
	}

	//On Click My Leave Cancel Icon
	const onClickMyLeaveCancel = (leave, data) => {
		setMyLeaveCancelData(data);
		setDisplayMyLeaveCancel(true);
		// setAssLeaveRejectInfo([]);
	}

	//On Click My Comp Off Cancel Icon
	const onClickMyCompOffCancel = (comOff, data) => {
		setMyCompOffCancelData(data);
		setDisplayMyCompOffCancel(true);
	}

	//On Click My Permission Cancel Icon
	const onClickMyPermissionCancel = (permission, data) => {
		setMyPermissionCancelData(data);
		setDisplayMyPermissionCancel(true);
	}

	//On Click Associate Leave Approve Icon
	const onClickAssLeaveApprove = (leave, data) => {
		setAssLeaveApproveData(data);
		setDisplayAssociateLeaveApprove(true);
	}

	// On Click view certificate icon
	const sickleavecertificate = (rowData) => {
		const chooseOptions = {iconOnly: true,  icon: 'pi pi-fw pi-plus'};
		const uploadOptions = {iconOnly: true,  icon: 'pi pi-upload', className: 'p-button-success'};
		const cancelOptions = {iconOnly: true,  icon: 'pi pi-times', className: 'file-upload-cancel'};
		return (
			<div>
					<a href={rowData.url} target="_blank"> {rowData.fileName}</a>
			</div>
		)
	}

	//On Click Associate Leave Reject Icon
	const onClickAssLeaveReject = (leave, data) => {
		setAssLeaveRejectData(data);
		setDisplayAssociateLeaveReject(true);
	}

	//On Click Associate Leave Cancel Icon
	const onClickAssLeaveCancel = (leave, data) => {
		setAssLeaveCancelData(data);
		setDisplayAssociateLeaveCancel(true);
	}

	//On Click Associate Comp Off Approve Icon
	const onClickAssCompApprove = (compOff, data) => {
		setAssCompApproveData(data);
		setDisplayAssociateCompOffApprove(true);
	}

	//On Click Associate Comp Off Reject Icon
	const onClickAssCompReject = (compOff, data) => {
		setAssCompRejectData(data);
		setDisplayAssociateCompOffReject(true);
	}

	//On Click Associate Permission Approve Icon
	const onClickAssPermApprove = (permission, data) => {
		setAssPermApproveData(data);
		setDisplayAssociatePermissionApprove(true);
	}

	//On Click Associate Permission Reject Icon
	const onClickAssPermReject = (permission, data) => {
		setAssPermRejectData(data);
		setDisplayAssociatePermissionReject(true);
	}

	//On Click Associate Permission Cancel Icon
	const onClickAssPermCancel = (permission, data) => {
		setAssPermCancelData(data);
		setDisplayAssociatePermissionCancel(true);
	}

	//On Change Date on Responsive calendar
	const onChangeDate = (e) => {
		setDate(e.value);
	}

	const onChangeEmployeeValue = (e) => {
		setEmployeeDetails(e.value);
		setLoading(true);
		if (e.value === null || e.value.length === 0) {
			setLoading(false);
			setAssociateLeave([]);
			setAllAssociateLeaveList(allAssociateLeaveList);
			setMyLeave(myLeave);
			setLeaveCalendar(myLeaveCalendar)
		} else {
			var userId = cookies.get('userId');

			var id = _.filter(e.value, (item) => item != userId);
			leaveService.getAllLeaveRequestByUser(id).then(data => {
				setLoading(false);
				setAllAssociateLeaveList([]);
				setMyLeave(myLeave);
				setLeaveCalendar(myLeaveCalendar)
				let leaveData = _.orderBy(data.data, ['createdAt'], ['desc']);
				setAssociateLeave(leaveData);
				setDisplayFilter(false);
			})
		}
	}

	/* Methods - End */

	/* Render Templates - Start */

	//All Modal Pop-up Function Map
	const dialogFuncMap = {
		'displayRequestModal': setDisplayRequestModal,
		'displayEditLeaveRequestModal': setDisplayEditLeaveRequestModal,
		'displayMyLeaveCancel': setDisplayMyLeaveCancel,
		'displayAssociateLeaveApprove': setDisplayAssociateLeaveApprove,
		'displayAssociateLeaveReject': setDisplayAssociateLeaveReject,
		'displayAssociateLeaveCancel': setDisplayAssociateLeaveCancel,
		'displayMyCompOffCancel': setDisplayMyCompOffCancel,
		'displayAssociateCompOffApprove': setDisplayAssociateCompOffApprove,
		'displayAssociateCompOffReject': setDisplayAssociateCompOffReject,
		'displayMyPermissionCancel': setDisplayMyPermissionCancel,
		'displayAssociatePermissionApprove': setDisplayAssociatePermissionApprove,
		'displayAssociatePermissionReject': setDisplayAssociatePermissionReject,
		'displayAssociatePermissionCancel': setDisplayAssociatePermissionCancel
	}

	//On Click New Request Button
	const onCLickNewRequestModal = () => {
		setDisplayRequestModal(true);
	}

	//On Click Filter
	const onCLickFilter = (event) => {
		confirmPopup({
			target: event.currentTarget,
			message: <div>
				{/* <SingleSelectElement
					placeholder="Select"
					className="p-inputtext text-left align-center"
					options={employeesList}
					onChangeValue={(e) => onChangeEmployeeValue(e)}
					value={employeeDetails}
					optionLabel='name'
					filterBy='name'
					showClear={true}
				/> */}
				<MultiSelectElement
					placeholder="Select"
					className="p-inputtext-multiselect"
					options={employeesList}
					onChangeValue={(e) => onChangeEmployeeValue(e)}
					value={employeeDetails}
					optionLabel='name'
					maxSelectLabel={1}
					showClear={true}
				/>
			</div>,
			className: 'filter-button'
		});
	}

	// Hide modal when click cancel (top right)
	const onHide = (name, message, success) => {
		if (name) {
			dialogFuncMap[`${name}`](false);
		}
		setAssLeaveRejectInfo([]);
		message && toastRef.current.showToast(message)
		if (success) {
			setSuccess(success)
			getAllLeaveRequestByUser();
			getAllCompRequestByUser();
			getAllPermissionRequestByUser();
			getAssociatesLeaveList();
			getAssociatesCompOffList();
			// getAssociatesPermissionList();
		}
	}

	const addRequestHandler = (requestData) => {
		setListData([...listData, { requestData }]);
	}

	//Search Associate List in Associate Leave tab
	const inputAssLeaveHandler = (e) => {
		setInputAssLeaveText(e.target.value);
	};

	//Search Associate List in Associate Comp Off tab
	const inputAssCompOffHandler = (e) => {
		setInputAssCompOffText(e.target.value);
	};

	//Search Associate List in Associate Permission tab
	const inputAssPermissionHandler = (e) => {
		setInputAssPermissionText(e.target.value);
	};

	//Filter Associate Leave Data
	const assLeaveData = associateLeaveList.filter((el) => {
		if (inputAssLeaveText === '') {
			return el;
		}
		else {
			var name = el.userDetails.firstName + ' ' + el.userDetails.lastName;
			return el.userDetails.firstName.toLowerCase().includes(inputAssLeaveText.toLowerCase()) || el.userDetails.lastName.toLowerCase().includes(inputAssLeaveText.toLowerCase()) || name.toLowerCase().includes(inputAssLeaveText.toLowerCase());
		}
	})

	//Filter Associate Comp Off Data
	const assCompOffData = associatesCompOffList.filter((el) => {
		if (inputAssCompOffText === '') {
			return el;
		}
		else {
			var name = el.userDetails.firstName + ' ' + el.userDetails.lastName;
			return el.userDetails.firstName.toLowerCase().includes(inputAssCompOffText.toLowerCase()) || el.userDetails.lastName.toLowerCase().includes(inputAssCompOffText.toLowerCase()) || name.toLowerCase().includes(inputAssCompOffText.toLowerCase());
		}
	})

	//Filter Associate Permission Data
	const assPermissionData = associatesPermissionList.filter((el) => {
		if (inputAssPermissionText === '') {
			return el;
		}
		else {
			var name = el.userDetails.firstName + ' ' + el.userDetails.lastName;
			return el.userDetails.firstName.toLowerCase().includes(inputAssPermissionText.toLowerCase()) || el.userDetails.lastName.toLowerCase().includes(inputAssPermissionText.toLowerCase()) || name.toLowerCase().includes(inputAssPermissionText.toLowerCase());
		}
	})

	// Select Bulk Leave
	const checkedMultiLeave = (e) => {
		var leaveCheckedCountData = 0;
		var leaveIds = [];
		let temp = associateLeaveList.map((leave) => {
			if (leave.leaveStatus == "REQUESTED") {
				leaveCheckedCountData = leaveCheckedCountData + 1;
				leaveIds.push(leave.id);
				return {
					...leave,
					checked: e.checked
				}
			} else {
				return {
					...leave,
					checked: false
				}
			}
		});
		setAssociateLeaveList(temp);
		setCheckedBulkLeave(e.checked);
		setLeaveCheckedCount(leaveCheckedCountData);
		setLeaveId(leaveIds);
	}

	// Select Single Leave
	const checkedSingleLeave = (e, checkedId) => {
		var leaveCheckedCountData = 0;
		var leaveIds = [];
		let temp = associateLeaveList.map((list) => {
			if (list.id === checkedId) {
				return {
					...list,
					checked: e.checked
				}
			} else {
				return {
					...list
				}
			}
		});
		temp.map((list) => {
			if (list.checked) {
				leaveCheckedCountData = leaveCheckedCountData + 1;
				leaveIds.push(list.id);
			}
		});
		setAssociateLeaveList(temp);
		if (leaveCheckedCountData > 0) {
			setCheckedBulkLeave(true);
		} else {
			setCheckedBulkLeave(false);
		}
		setLeaveCheckedCount(leaveCheckedCountData);
		setLeaveId(leaveIds);
	}

	// Select Bulk Comp Off
	const checkedMultiCompOff = (e) => {
		var compCheckedCountData = 0;
		var compIds = [];
		let temp = associatesCompOffList.map((comp) => {
			if (comp.compStatus == "REQUESTED") {
				compCheckedCountData = compCheckedCountData + 1;
				compIds.push(comp.id);
				return {
					...comp,
					checked: e.checked
				}
			} else {
				return {
					...comp,
					checked: false
				}
			}
		});
		setAssociatesCompOffList(temp);
		setCheckedBulkComp(e.checked);
		setCompCheckedCount(compCheckedCountData);
		setCompId(compIds);
	}

	// Select Single Comp Off
	const checkedSingleCompOff = (e, checkedId) => {
		var compCheckedCountData = 0;
		var compIds = [];
		let temp = associatesCompOffList.map((list) => {
			if (list.id === checkedId) {
				return {
					...list,
					checked: e.checked
				}
			} else {
				return {
					...list
				}
			}
		});
		temp.map((list) => {
			if (list.checked) {
				compCheckedCountData = compCheckedCountData + 1;
				compIds.push(list.id);
			}
		});
		setAssociatesCompOffList(temp);
		if (compCheckedCountData > 0) {
			setCheckedBulkComp(true);
		} else {
			setCheckedBulkComp(false);
		}
		setCompCheckedCount(compCheckedCountData);
		setCompId(compIds);
	}

	// Select Bulk Permission
	const checkedMultiPermission = (e) => {
		var permissionCheckedCountData = 0;
		var permissionIds = [];
		let temp = associatesPermissionList.map((permission) => {
			if (permission.permissionStatus == "REQUESTED") {
				permissionCheckedCountData = permissionCheckedCountData + 1;
				permissionIds.push(permission.id);
				return {
					...permission,
					checked: e.checked
				}
			} else {
				return {
					...permission,
					checked: false
				}
			}
		});
		setAssociatesPermissionList(temp);
		setCheckedBulkPermission(e.checked);
		setPermissionCheckedCount(permissionCheckedCountData);
		setPermissionId(permissionIds);
	}

	// Select Single Permission
	const checkedSinglePermission = (e, checkedId) => {
		var permissionCheckedCountData = 0;
		var permissionIds = [];
		let temp = associatesPermissionList.map((list) => {
			if (list.id === checkedId) {
				return {
					...list,
					checked: e.checked
				}
			} else {
				return {
					...list
				}
			}
		});
		temp.map((list) => {
			if (list.checked) {
				permissionCheckedCountData = permissionCheckedCountData + 1;
				permissionIds.push(list.id);
			}
		});
		setAssociatesPermissionList(temp);
		if (permissionCheckedCountData > 0) {
			setCheckedBulkPermission(true);
		} else {
			setCheckedBulkPermission(false);
		}
		setPermissionCheckedCount(permissionCheckedCountData);
		setPermissionId(permissionIds);
	}

	//Leave Tab Template

	const tabLeaveTemplate = (options) => {
		return (
			<div>
				<Tooltip target=".tabButton" />
				{
					buName == 'IG-CHENNAI' ?
						<div className="d-flex justify-center">
							<button type="button" data-pr-tooltip={"Available balance / Taken"} data-pr-position="left" onClick={options.onClick} className="d-flex direction-column tabButton font-normal">
								<span className="fs-10">{options.titleElement.props.children}</span>
								<span className='pt-5 fs-10'>{ptoLeaveCount.balanceLeave} / {ptoLeaveCount.taken}</span>
							</button>
						</div> :
						<div className="d-flex justify-center">
							<button type="button" onClick={options.onClick} className="d-flex direction-column tabButton font-normal">
								<span className="fs-10">{options.titleElement.props.children}</span>
								<span className='pt-5 fs-10'>{annualCount.totalLeave}</span>
							</button>
						</div>
				}
			</div>
		)
	}

	//Comp Off Tab Template
	const tabCompOffTemplate = (options) => {
		return (
			<div>
				<Tooltip target=".tabButton" />
				{
					buName == 'IG-CHENNAI' ?
						<div className="d-flex justify-center">
							<button type="button" data-pr-tooltip={"Available balance / Total"} data-pr-position="left" onClick={options.onClick} className="d-flex direction-column tabButton font-normal">
								<span className="fs-10">{options.titleElement.props.children}</span>
								<span className='pt-5 fs-10'>  {(compLeaveCount.balanceLeave ?? 0) + '/' + (compLeaveCount.totalLeave ?? 0)}
</span>
							</button>
						</div> :
						<div className="d-flex justify-center">
							<button type="button" className="d-flex direction-column tabButton font-normal">
								<span className="fs-10">{options.titleElement.props.children}</span>
								<span className='pt-5 fs-10'>{annualCount.balanceLeave}</span>
							</button>
						</div>
				}
			</div>
		)
	}

	//Permission Tab Template
	// const tabPermissionTemplate = (options) => {
	// 	return (
	// 		<div>
	// 			<Tooltip target=".tabButton" />
	// 			{
	// 				buName == 'JMAN-CHENNAI' ?
	// 					<div className="d-flex justify-center">
	// 						<button type="button" data-pr-tooltip={"Available balance / Total"} data-pr-position="left" onClick={options.onClick} className="d-flex direction-column tabButton font-normal">
	// 							<span className="fs-10">{options.titleElement.props.children}</span>
	// 							<span className='pt-5 fs-10'>{permissionCount.balanceLeave + '/' + permissionCount.totalLeave}</span>
	// 						</button>
	// 					</div> :
	// 					<div className="d-flex justify-center">
	// 						<button type="button" className="d-flex direction-column tabButton font-normal">
	// 							<span className="fs-10">{options.titleElement.props.children}</span>
	// 							<span className='pt-5 fs-10'>{annualCount.taken}</span>
	// 						</button>
	// 					</div>
	// 			}
	// 		</div>
	// 	)
	// }

	//Earned Tab Template
	const tabEarnedTemplate = (options) => {
		return (
			<div>
				<Tooltip target=".tabButton" />
				{
					buName == 'IG-CHENNAI' ?
						<div className="d-flex justify-center">
							<button type="button" data-pr-tooltip={"Available balance / Total"} data-pr-position="left" onClick={options.onClick} className="d-flex direction-column tabButton font-normal">
								<span className="fs-10">{options.titleElement.props.children}</span>
								<span className='pt-5 fs-10'>{earnedCount.balanceLeave + '/' + earnedCount.totalLeave}</span>
							</button>
						</div> :
						<div className="d-flex justify-center">
							<button type="button" className="d-flex direction-column tabButton font-normal">
								<span className="fs-10">{options.titleElement.props.children}</span>
								<span className='pt-5 fs-10'>{annualCount.taken}</span>
							</button>
						</div>
				}
			</div>
		)
	}

	//My Leave Tab Template
	const myLeaveTemplate = (item) => {
		let leaveData = {
			id: item.id,
			leaveStatus: item.leaveStatus
		}
		let fromDate = Moment(item.from_date).format('DD MMM YYYY');
		let toDate = Moment(item.to_date).format('DD MMM YYYY');
		setReportingManagerData(item.reportingUserId)

		var current_date = Moment().format("YYYY-MM-DD");
		return (
			<div>
				<div className="leave-item">
					<div className="leave-list-detail">
						<div className="d-flex">
							<div className={((item.leaveStatus === "APPROVED") ? "p-tag-approved" : (item.leaveStatus === "REJECTED") ? "p-tag-rejected" : (item.leaveStatus === "REQUESTED") ? "p-tag-requested" : (item.leaveStatus === "CANCELLED") ? "p-tag-cancelled" : "")}>
								<span>{item.total}</span>
							</div>
							<div className='pl-8'>
								<h5 className='m-0 userName'>{item.ConfigLeaveType?.leaveTypeName}</h5>
								<div className='leave-date'>
									<div className="d-flex justify-center">
										<Tooltip target=".custom-target-icon" />
										{/* {
											(item.leaveStatus === "REQUESTED" || item.leaveStatus === "APPROVED") &&
											<i className="bi bi-chat-square-text-fill custom-target-icon" data-pr-tooltip={item.description ? item.description : "No Description"} data-pr-position="left"></i>
										} */}
										{
											item.ConfigLeaveType?.leaveTypeName !== 'WFH' && item.ConfigLeaveType?.leaveTypeName !== 'Earned Leave' && 
											<>
												{
													(item.leaveStatus === "REQUESTED" || (item.leaveStatus === "APPROVED" && current_date < item.from_date)) &&
													<i className="bi bi-pencil-fill custom-target-icon" data-pr-tooltip='Edit' data-pr-position="left" onClick={() => editMyLeave(item.id, 'leave', item.leaveTypeId, item.userId)}></i>
												}
												{
													(item.leaveStatus === "REQUESTED" || (item.leaveStatus === "APPROVED" && current_date < item.from_date)) &&
													<i className="bi bi-x custom-target-icon" data-pr-tooltip='Cancel' data-pr-position="left" onClick={() => onClickMyLeaveCancel('displayMyLeaveCancel', leaveData)} ></i>
												}
											</>
										}

										{
											item.leaveStatus === "CANCELLED" &&
											<i className=" bi bi-chat-square-text-fill custom-target-icon color" data-pr-tooltip={
												(item.leaveLogDetails != undefined && item.leaveLogDetails != null) ?	
													item.leaveLogDetails.filter(data=>{ return data.comments != '' && data.comments != null;}).length > 0 ?	
														item.leaveLogDetails.map((data) => {
															
															if (data.comments != '' && data.comments != null) {
																return data.comments
															}
														}).join('')	
														: 'No comments'
													: 'No comments'
												}
											data-pr-position="left"></i>
										}
									</div>
								</div>
								<div>
									<span className="leave-status">{fromDate} - {toDate}</span>
								</div>
								<div className='leave-status-box leave-status-approvals'>
									{item.leaveStatus.toLowerCase()}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	//My Comp Off Tab Template
	const myCompOffTemplate = (item) => {
		let compCancel = {
			id: item.id,
			compStatus: item.compStatus
		}
		let fromDate = Moment(item.from_date).format('DD MMM YYYY');
		let toDate = Moment(item.to_date).format('DD MMM YYYY');
		return (
			<div className="leave-item">
				<div className="leave-list-detail">
					<div className="d-flex">
						<div className={((item.compStatus === "APPROVED") ? "p-tag-approved" : (item.compStatus === "REJECTED") ? "p-tag-rejected" : (item.compStatus === "REQUESTED") ? "p-tag-requested" : (item.compStatus === "CANCELLED") ? "p-tag-cancelled" : "")}>
							<span>{item.total}</span>
						</div>
						<div className='pl-8'>
							<h5 className='m-0 userName'>Comp Off</h5>
							<div className='leave-date'>
								<div className="d-flex justify-center">
									<Tooltip target=".custom-target-icon" />
									{/* {(item.compStatus === "REQUESTED" || item.compStatus === "APPROVED") &&
										<i className="bi bi-chat-square-text-fill custom-target-icon" data-pr-tooltip={item.description ? item.description : "No Description"} data-pr-position="left"></i>
									} */}
									{item.compStatus === "REQUESTED" &&
										<i className="bi bi-pencil-fill custom-target-icon" data-pr-tooltip='Edit' data-pr-position="left" onClick={() => editMyCompOff(item.id, 'Compensatory', item.userId)}></i>
									}
									{item.compStatus === "REQUESTED" &&
										<i className="bi bi-x custom-target-icon" data-pr-tooltip='Cancel' data-pr-position="left" onClick={() => onClickMyCompOffCancel('displayMyCompOffCancel', compCancel)}></i>
									}
									{item.compStatus === "CANCELLED" &&
										<i className="bi bi-chat-square-text-fill custom-target-icon color" data-pr-tooltip={item.comment ? item.comment : "No Comment"} data-pr-position="left"></i>
									}
								</div>
							</div>
							<div>
								<span className="leave-status">{fromDate} - {toDate}</span>
							</div>
							<div className='leave-status-box leave-status-approvals'>
								{item.compStatus.toLowerCase()}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	//My Permission Tab Template
	// const myPermissionTemplate = (item) => {
	// 	let fromTime = Moment(item.from_time).format("hh:mm A");
	// 	let toTime = Moment(item.to_time).format("hh:mm A");
	// 	let total = item.total;

	// 	var splittedString = total.split(":");

	// 	let totalTime = splittedString.slice(0, -1).join(':');

	// 	let permissionCancel = {
	// 		id: item.id,
	// 		permissionStatus: item.permissionStatus
	// 	}
	// 	return (
	// 		<div className="leave-item">
	// 			<div className="leave-list-detail">
	// 				<div className="d-flex">
	// 					<div className={((item.permissionStatus === "APPROVED") ? "p-tag-approved" : (item.permissionStatus === "REJECTED") ? "p-tag-rejected" : (item.permissionStatus === "REQUESTED") ? "p-tag-requested" : (item.permissionStatus === "CANCELLED") ? "p-tag-cancelled" : "")}>
	// 						<span>{totalTime}</span>
	// 					</div>
	// 					<div className='pl-8'>

	// 						<h5 className='m-0 userName'>{Moment(item.on_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}</h5>
	// 						<div className='leave-date'>
	// 							<div className="d-flex justify-center">
	// 								<Tooltip target=".custom-target-icon" />
	// 								{/* {(item.permissionStatus === "REQUESTED" || item.permissionStatus === "APPROVED") &&
	// 									<i className="bi bi-chat-square-text-fill custom-target-icon" data-pr-tooltip={item.description ? item.description : "No Description"} data-pr-position="left"></i>
	// 								} */}
	// 								{item.permissionStatus === "REQUESTED" &&
	// 									<i className="bi bi-pencil-fill custom-target-icon" data-pr-tooltip='Edit' data-pr-position="left" onClick={() => editMyPermission(item.id, 'leave', item.userId)}></i>
	// 								}
	// 								{item.permissionStatus === "REQUESTED" &&
	// 									<i className="bi bi-x custom-target-icon" data-pr-tooltip='Cancel' data-pr-position="left" onClick={() => onClickMyPermissionCancel('displayMyPermissionCancel', permissionCancel)}></i>
	// 								}
	// 								{item.permissionStatus === "CANCELLED" &&
	// 									<i className=" bi bi-chat-square-text-fill custom-target-icon color" data-pr-tooltip={assLeaveRejectInfo ? assLeaveRejectInfo : 'No Comments'} data-pr-position="left"></i>
	// 								}
	// 							</div>
	// 						</div>
	// 						<div>
	// 							<span className="leave-status">{fromTime} - {toTime}</span>
	// 						</div>
	// 						<div className='leave-status-box leave-status-approvals'>
	// 							{item.permissionStatus.toLowerCase()}
	// 						</div>
	// 					</div>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	);
	// }

	//My Earned Tab Template
	const myEarnedTemplate = (item) => {
		let leaveData = {
			id: item.id,
			leaveStatus: item.leaveStatus
		}
		let fromDate = Moment(item.from_date).format('DD MMM YYYY');
		let toDate = Moment(item.to_date).format('DD MMM YYYY');
		setReportingManagerData(item.reportingUserId)

		var current_date = Moment().format("YYYY-MM-DD");
		return (
			<div>
				<div className="leave-item">
					<div className="leave-list-detail">
						<div className="d-flex">
							<div className={((item.leaveStatus === "APPROVED") ? "p-tag-approved" : (item.leaveStatus === "REJECTED") ? "p-tag-rejected" : (item.leaveStatus === "REQUESTED") ? "p-tag-requested" : (item.leaveStatus === "CANCELLED") ? "p-tag-cancelled" : "")}>
								<span>{item.total}</span>
							</div>
							<div className='pl-8'>
								<h5 className='m-0 userName'>{item.ConfigLeaveType?.leaveTypeName}</h5>
								<div className='leave-date'>
									<div className="d-flex justify-center">
										<Tooltip target=".custom-target-icon" />
										{/* {
											(item.leaveStatus === "REQUESTED" || item.leaveStatus === "APPROVED") &&
											<i className="bi bi-chat-square-text-fill custom-target-icon" data-pr-tooltip={item.description ? item.description : "No Description"} data-pr-position="left"></i>
										} */}
										{
											item.ConfigLeaveType?.leaveTypeName == 'Earned Leave' &&
											<>
												{
													(item.leaveStatus === "REQUESTED" || (item.leaveStatus === "APPROVED" && current_date < item.from_date)) &&
													<i className="bi bi-pencil-fill custom-target-icon" data-pr-tooltip='Edit' data-pr-position="left" onClick={() => editMyLeave(item.id, 'leave', item.leaveTypeId, item.userId)}></i>
												}
												{
													(item.leaveStatus === "REQUESTED" || (item.leaveStatus === "APPROVED" && current_date < item.from_date)) &&
													<i className="bi bi-x custom-target-icon" data-pr-tooltip='Cancel' data-pr-position="left" onClick={() => onClickMyLeaveCancel('displayMyLeaveCancel', leaveData)} ></i>
												}
											</>
										}

										{
											item.leaveStatus === "CANCELLED" &&
											<i className=" bi bi-chat-square-text-fill custom-target-icon color" data-pr-tooltip={
												(item.leaveLogDetails != undefined && item.leaveLogDetails != null) ?	
													item.leaveLogDetails.filter(data=>{ return data.comments != '' && data.comments != null;}).length > 0 ?	
														item.leaveLogDetails.map((data) => {
															
															if (data.comments != '' && data.comments != null) {
																return data.comments
															}
														}).join('')	
														: 'No comments'
													: 'No comments'
												}
											data-pr-position="left"></i>
										}
									</div>
								</div>
								<div>
									<span className="leave-status">{fromDate} - {toDate}</span>
								</div>
								<div className='leave-status-box leave-status-approvals'>
									{item.leaveStatus.toLowerCase()}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	const gettingWorkExperience = () => {
		var userId = cookies.get('userId');
		LeaveService.getReporteesDetails(userId, true).then(data => {
			//Calculating work exp on the basis of date of join +previous work exp 
			for (var i = 0; i < data.data.length; i++) {
                if (data.data[i].id == userId) {
                    var dateOfJoin = data.data[i].dateOfJoin
                    var previousWorkExp= data.data[i].previousWorkExp
                }
            }
            var date = dateOfJoin?.substring(0,2)
            var month = dateOfJoin?.substring(3,5)
            var year = dateOfJoin?.substring(6,10)
            var JoiningDate =Moment(new Date(year, month-1, date, 0, 0));
            var currentDate = Moment(new Date())
            var diffInDays = currentDate.diff(JoiningDate,'days')
            let jmanWorkExp = diffInDays/365
            const totalWorkExp = jmanWorkExp + previousWorkExp
				setWorkExperience(totalWorkExp)
		});
	}
	const myWFHTemplate = (options) => {
		return (
			<button type="button" className={options.className}>
				<div className="p-col-12 p-1 pr-0 cursor-default justify-end">
				{/* <span>WFH Balance: {wfhCount.balanceLeave}</span> */}

					{/* {workExperience > 1 ? <span>WFH Balance: {wfhCount.balanceLeave}</span> : <></>} */}
				</div>
			</button>
		)
	}

	//Associate Leave Tab Template
	const tabAssLeaveTemplate = (options) => {
		return (
			<button type="button" onClick={options.onClick} className={options.className}>
				<div className="p-col-4 p-1">
					<span>{options.titleElement.props.children}</span>
				</div>
			</button>
		);
	}

	//Associate Comp Off Tab Template
	const tabAssCompOffTemplate = (options) => {
		return (
			<button type="button" onClick={options.onClick} className={options.className}>
				<div className='align-center'>
					<div className="p-col-4 p-1">
						<span>{options.titleElement.props.children}</span>
					</div>
				</div>
			</button>
		);
	}

	//Associate Permission Tab Template
	const tabAssPermissionTemplate = (options) => {
		return (
			<button type="button" onClick={options.onClick} className={options.className}>
				<div className='align-center'>
					<div className="p-col-4 p-1">
						<span>{options.titleElement.props.children}</span>
					</div>
				</div>
			</button>
		);
	}

	//Associate Leave Template
	const assLeaveTemplate = (item) => {
		let data = {
			id: item.id,
			leaveStatus: item.leaveStatus
		}
		let startDate = Moment(item.from_date).format('DD MMM YYYY');
		let endDate = Moment(item.to_date).format('DD MMM YYYY');
		return (
			<div className="leave-item">
				<div className="leave-list-detail">
					<div className="d-flex">
						{/* <div className="select-bulk-item">
							<CheckboxElement
								checked={item.checked}
								inputId={item.id}
								onChangeCheck={(e, checkedId) => checkedSingleLeave(e, checkedId)}
								disabled={(item.leaveStatus != 'REQUESTED') ? true : false}
							/>
						</div> */}
						<div className={((item.leaveStatus === "APPROVED") ? "p-tag-approved" : (item.leaveStatus === "REJECTED") ? "p-tag-rejected" : (item.leaveStatus === "REQUESTED") ? "p-tag-requested" : (item.leaveStatus === "CANCELLED") ? "p-tag-cancelled" : "")}>
							<span>{item.total}</span>
						</div>
						{/* {item.userDetails.gender === "Male" ?
							<img src={avatar} className='associates-avatar' /> :
							<img src={avatarFemale} className='associates-avatar' />
						} */}

						<div className='pl-8'>
							<h5 className='m-0 userName'>{item.userDetails.firstName + ' ' + item.userDetails.lastName}</h5>
							<div className='leave-date'>
								<div className="d-flex justify-center">
									<Tooltip target=".custom-target-icon" />
									{/* {(item.leaveStatus === "REQUESTED" || item.leaveStatus === "APPROVED") &&
										<i className="bi bi-chat-square-text-fill custom-target-icon" data-pr-tooltip={item.description ? item.description : "No Description"} data-pr-position="left"></i>
									} */}
									{
										item.leaveStatus === "APPROVED" ?
										<>
										{item.fileName!=null &&
											<i className="pi pi-eye viewcertificateicon travelApproval-eye travel-edit custom-target-icon" data-pr-tooltip="View Certificate" data-pr-position="left" onClick={() => window.open(item.url, '_blank')}></i> 
											}
											<i className="pi pi-ban custom-target-icon" data-pr-tooltip='Cancel' data-pr-position="left" onClick={() => onClickAssLeaveCancel('displayAssociateLeaveCancel', data)}></i></> :
											(item.leaveStatus === "REQUESTED") ?
												<> <i className="bi bi-check-lg custom-target-icon" data-pr-tooltip='Approve' data-pr-position="left" onClick={() => onClickAssLeaveApprove('displayAssociateLeaveApprove', data)}></i>
													{item.fileName!=null &&
													<i className="pi pi-eye viewcertificateicon travelApproval-eye travel-edit custom-target-icon" data-pr-tooltip="View Certificate" data-pr-position="left" onClick={() => window.open(item.url, '_blank')}></i> 
													}
													<i className="bi bi-x custom-target-icon" data-pr-tooltip="Reject" data-pr-position="left" onClick={() => onClickAssLeaveReject('displayAssociateLeaveReject', data)}></i> </> : <div></div>
									}
									{(item.leaveStatus === "CANCELLED" || item.leaveStatus === "REJECTED") &&
										<>
										{item.fileName!=null &&
											<i className="pi pi-eye viewcertificateicon travelApproval-eye travel-edit custom-target-icon" data-pr-tooltip="View Certificate" data-pr-position="left" onClick={() => window.open(item.url, '_blank')}></i> 
										}
										<i className=" bi bi-chat-square-text-fill custom-target-icon color" data-pr-tooltip={
											(item.leaveLogDetails != undefined && item.leaveLogDetails != null) ? item.leaveLogDetails.map((data) => {
												if (data.comments != '' && data.comments != null) {
													return data.comments
												}
											}).join('') : 'No comments'
										}
											data-pr-position="left"></i>
										</>
									}
								</div>
							</div>
							<div className="d-flex">
								{/* <div>
									<span className={((item.leaveStatus === "APPROVED") ? "p-tag-associates-approved" : (item.leaveStatus === "REJECTED") ? "p-tag-associates-rejected" : (item.leaveStatus === "REQUESTED") ? "p-tag-associates-requested" : (item.leaveStatus === "CANCELLED") ? "p-tag-associates-cancelled" : "")}>{item.total} Day<span>{item.total > 1 ? 's' : ""}</span></span>
								</div> */}
								<span className="leave-status">{startDate} - {endDate}</span>
							</div>
							<div className='leave-status-box leave-status-approvals'>
								{item.leaveStatus.toLowerCase()}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	//Associate Comp Off Template
	const assCompOffTemplate = (item) => {
		let data = {
			id: item.id,
			compStatus: item.compStatus
		}
		let startDate = Moment(item.from_date).format('DD MMM YYYY');
		let endDate = Moment(item.to_date).format('DD MMM YYYY');
		return (
			<div className="leave-item">
				<div className="leave-list-detail">
					<div className="d-flex">
						{/* <div className="select-bulk-item">
							<CheckboxElement
								checked={item.checked}
								inputId={item.id}
								onChangeCheck={(e, checkedId) => checkedSingleCompOff(e, checkedId)}
								disabled={(item.compStatus != 'REQUESTED') ? true : false}
							/>
						</div> */}
						<div className={((item.compStatus === "APPROVED") ? "p-tag-approved" : (item.compStatus === "REJECTED") ? "p-tag-rejected" : (item.compStatus === "REQUESTED") ? "p-tag-requested" : (item.compStatus === "CANCELLED") ? "p-tag-cancelled" : "")}>
							<span>{item.total}</span>
						</div>
						{/* {item.userDetails.gender === "Male" ?
							<img src={avatar} className='associates-avatar' /> :
							<img src={avatarFemale} className='associates-avatar' />
						} */}
						<div className='pl-8'>
							<h5 className='m-0 userName'>{item.userDetails.firstName + ' ' + item.userDetails.lastName}</h5>
							<div className='leave-date'>
								<div className="d-flex justify-center">
									<Tooltip target=".custom-target-icon" />
									{/* {(item.compStatus === "REQUESTED" || item.compStatus === "APPROVED") &&
										<i className="bi bi-chat-square-text-fill custom-target-icon" data-pr-tooltip={item.description ? item.description : "No Description"} data-pr-position="left"></i>
									} */}
									{
										(item.compStatus === "REQUESTED") ?
											<>
												<i className="bi bi-check-lg custom-target-icon" data-pr-tooltip='Approve' data-pr-position="left" onClick={() => onClickAssCompApprove('displayAssociateCompOffApprove', data)}></i>
												<i className="bi bi-x custom-target-icon" data-pr-tooltip='Reject' data-pr-position="left" onClick={() => onClickAssCompReject('displayAssociateCompOffReject', data)}></i>
											</> : <div></div>
									}
									{(item.compStatus === "CANCELLED" || item.compStatus === "REJECTED") &&
										<i className="bi bi-chat-square-text-fill custom-target-icon color" data-pr-tooltip={item.comment ? item.comment : "No Comment"} data-pr-position="left"></i>
									}
								</div>
							</div>
							<div className="d-flex">
								{/* <div>
									<span className={((item.compStatus === "APPROVED") ? "p-tag-associates-approved" : (item.compStatus === "REJECTED") ? "p-tag-associates-rejected" : (item.compStatus === "REQUESTED") ? "p-tag-associates-requested" : (item.compStatus === "CANCELLED") ? "p-tag-associates-cancelled" : "")}>{item.total} Day<span>{item.total > 1 ? 's' : ""}</span></span>
								</div> */}
								<span className="leave-status">{startDate} - {endDate}</span>
							</div>
							<div className='leave-status-box leave-status-approvals'>
								{item.compStatus.toLowerCase()}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	//Associate Permission Template
	const assPermissionTemplate = (item) => {
		let data = {
			id: item.id,
			permissionStatus: item.permissionStatus,
		}
		let fromTime = Moment(item.from_time).format("hh:mm A");
		let toTime = Moment(item.to_time).format("hh:mm A");
		let total = item.total;

		var splittedString = total.split(":");

		let totalTime = splittedString.slice(0, -1).join(':');
		return (
			<div className="leave-item">
				<div className="leave-list-detail">
					<div className="d-flex">
						{/* <div className="select-bulk-item">
							<CheckboxElement
								checked={item.checked}
								inputId={item.id}
								onChangeCheck={(e, checkedId) => checkedSinglePermission(e, checkedId)}
								disabled={(item.permissionStatus != 'REQUESTED') ? true : false}
							/>
						</div> */}
						<div className={((item.permissionStatus === "APPROVED") ? "p-tag-approved" : (item.permissionStatus === "REJECTED") ? "p-tag-rejected" : (item.permissionStatus === "REQUESTED") ? "p-tag-requested" : (item.permissionStatus === "CANCELLED") ? "p-tag-cancelled" : "")}>
							<span>{totalTime}</span>
						</div>
						{/* {item.userDetails.gender === "Male" ?
							<img src={avatar} className='associates-avatar' /> :
							<img src={avatarFemale} className='associates-avatar' />
						} */}
						<div className='pl-8'>
							<h5 className='m-0 userName'>{item.userDetails.firstName + ' ' + item.userDetails.lastName}</h5>
							<div className='leave-date'>
								<div className="d-flex justify-center">
									<Tooltip target=".custom-target-icon" />
									{/* {(item.permissionStatus === "REQUESTED" || item.permissionStatus === "APPROVED") &&
										<i className="bi bi-chat-square-text-fill custom-target-icon" data-pr-tooltip={item.description ? item.description : "No Description"} data-pr-position="left"></i>
									} */}
									{
										// item.permissionStatus === "APPROVED" ?
										// 	<i className="pi pi-ban" onClick={() => onClickAssPermCancel('displayAssociatePermissionCancel', data)}></i>
										// 	: 
										(item.permissionStatus === "REQUESTED") ?
											<>
												<i className="bi bi-check-lg custom-target-icon" data-pr-tooltip='Approve' data-pr-position="left" onClick={() => onClickAssPermApprove('displayAssociatePermissionApprove', data)}></i>
												<i className="bi bi-x custom-target-icon" data-pr-tooltip='Reject' data-pr-position="left" onClick={() => onClickAssPermReject('displayAssociatePermissionReject', data)}></i>
											</> : <div></div>
									}
									{(item.permissionStatus === "CANCELLED" || item.permissionStatus === "REJECTED") &&
										<i className=" bi bi-chat-square-text-fill custom-target-icon color" data-pr-tooltip={item.comment ? item.comment : "No Comment"} data-pr-position="left"></i>
									}
								</div>
							</div>
							<div className="d-flex">
								{/* <div>
									<span className={((item.permissionStatus === "APPROVED") ? "p-tag-associates-approved" : (item.permissionStatus === "REJECTED") ? "p-tag-associates-rejected" : (item.permissionStatus === "REQUESTED") ? "p-tag-associates-requested" : (item.permissionStatus === "CANCELLED") ? "p-tag-associates-cancelled" : "")}>{item.total} Hour<span>{item.total > 1 ? 's' : ""}</span></span>
								</div> */}
								<span className="leave-status">{fromTime} - {toTime}</span>
								<span className="leave-status pl-10">{Moment(item.on_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}</span>
							</div>
							<div className='leave-status-box leave-status-approvals'>
								{item.permissionStatus.toLowerCase()}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	//My Leave Cancel Footer Button
	const renderMyLeaveCancel = () => {
		return (
			<div className="cancel-footer">
				<div className='align-center justify-end pb-0'>
					<Button type="submit" label="Yes" className="create-button request-button" onClick={() => myLeaveCancelHandler()} />
				</div>
			</div>
		);
	}

	//Associate Leave Approve Footer Button
	const renderAssLeaveApprove = () => {
		return (
			<div className="cancel-footer">
				<div className='align-center justify-end pb-0'>
					<Button type="submit" label="Yes" className="create-button request-button" onClick={() => assLeaveApproveHandler()} />
				</div>
			</div>
		);
	}

	//Associate Leave Reject Footer Button
	const renderAssLeaveReject = () => {
		return (
			<div className="cancel-footer">
				<div className='align-center justify-end pb-0'>
					<Button type="submit" label="Yes" className="create-button request-button" onClick={() => assLeaveRejectHandler()} />
				</div>
			</div>
		);
	}

	//Associate Leave Cancel Footer Button
	const renderAssLeaveCancel = () => {
		return (
			<div className="cancel-footer">
				<div className='align-center justify-end pb-0'>
					<Button type="submit" label="Yes" className="create-button request-button" onClick={() => assLeaveCancelHandler()} />
				</div>
			</div>
		);
	}

	//My Comp Off Cancel Footer Button
	const renderMyCompCancel = () => {
		return (
			<div className="cancel-footer">
				<div className='align-center justify-end pb-0'>
					<Button type="submit" label="Yes" className="create-button request-button" onClick={() => myCompOffCancelHandler()} />
				</div>
			</div>
		);
	}

	//Associate Comp Off Approve Footer Button
	const renderAssCompApprove = () => {
		return (
			<div className="cancel-footer">
				<div className='align-center justify-end pb-0'>
					<Button type="submit" label="Yes" className="create-button request-button" onClick={() => assCompOffApproveHandler()} />
				</div>
			</div>
		);
	}

	//Associate Comp Off Reject Footer Button
	const renderAssCompReject = () => {
		return (
			<div className="cancel-footer">
				<div className='align-center justify-end pb-0'>
					<Button type="submit" label="Yes" className="create-button request-button" onClick={() => assCompOffRejectHandler()} />
				</div>
			</div>
		);
	}

	//My Permission Cancel Footer Button
	const renderMyPermissionCancel = () => {
		return (
			<div className="cancel-footer">
				<div className='align-center justify-end pb-0'>
					<Button type="submit" label="Yes" className="create-button request-button" onClick={() => myPermissionCancelHandler()} />
				</div>
			</div>
		);
	}

	//Associate Permission Approve Footer Button
	const renderAssPermissionApprove = () => {
		return (
			<div className="cancel-footer">
				<div className='align-center justify-end pb-0'>
					<Button type="submit" label="Yes" className="create-button request-button" onClick={() => assPermissionApproveHandler()} />
				</div>
			</div>
		);
	}

	//Associate Permission Reject Footer Button
	const renderAssPermissionReject = () => {
		return (
			<div className="cancel-footer">
				<div className='align-center justify-end pb-0'>
					<Button type="submit" label="Yes" className="create-button request-button" onClick={() => assPermissionRejectHandler()} />
				</div>
			</div>
		);
	}

	//Associate Permission Cancel Footer Button
	const renderAssPermissionCancel = () => {
		return (
			<div className="cancel-footer">
				<div className='align-center justify-end pb-0'>
					<Button type="submit" label="Yes" className="create-button request-button" onClick={() => assPermissionCancelHandler()} />
				</div>
			</div>
		);
	}

	/* const injectCellContent = (info) => {
		return (
			<div>
				<p style={{margin: 0}}>{info.dayNumberText}</p>
				<Avatar icon="pi pi-user" className="p-mr-2" size="xlarge" shape="circle" />

				<img src={avatar} className='associates-avatar' />
				<img src={avatar} className='associates-avatar' />
				<img src={avatar} className='associates-avatar' />

			</div>
		)
	} */

	let Todate = new Date().getDate()
	let day = new Date().getDay()
	if (day == 0) {
		day = "SUN"
	} else if (day == 1) {
		day = "MON"
	} else if (day == 2) {
		day = "TUE"
	} else if (day == 3) {
		day = "WED"
	} else if (day == 4) {
		day = "THU"
	} else if (day == 5) {
		day = "FRI"
	} else {
		day = "SAT"
	}

	const nameTemplate = () => {
		return (
			<div className="d-flex justify-center">
				<button type="button" className="d-flex direction-column tabLastButton">
					<span className="fs-10">{Todate < 10 ? "0" + Todate : Todate}</span>
					<span className='pt-5 fs-10'>{day}</span>
				</button>
			</div>
		)
	}

	/* Render Templates - End */

	/* Render View Return - Start */

	return (
		<div>
			<ToastElement ref={toastRef} />
			<Loader loading={loading} />
			<div className="p-grid mt-20">
				<ConfirmPopup />
				<div className="p-col-12 p-lg-8 p-xl-9 d-des-block pb-0 mt-20">
					<div>
						<FullCalendar
							plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
							initialView="dayGridMonth"
							datesSet={
								function () {
									getAllAssociatesLeaveList();
									getAssociatesLeaveList();
								}
							}
							customButtons={{
								newRequestButton: {
									text: <i className="pi pi-plus fs-11 font-normal leave-request-button">
										<span className="pl-8 button-label">New Request</span>
									</i>,
									click: function () {
										onCLickNewRequestModal();
									}
								},
								filterButton: {
									text: <i className="pi pi-filter-fill fs-11 font-normal leave-request-button">
									</i>,
									click: function (e) {
										onCLickFilter(e);
									}
								}
							}}
							headerToolbar={{
								left: '',
								center: '',
								right: 'title prev,next newRequestButton filterButton'
							}}
							titleFormat={{
								month: 'short',
								year: 'numeric',
							}}
							// dayCellContent={(injectCellContent)} // Please dont remove
							fixedWeekCount={false}
							firstDay={1}
							nowIndicator={false}
							selectable={false}
							dayHeaders={false}
							eventSources={
								[
									holidays.map((event) => {
										return {
											title: event.description,
											start: event.holiday_date,
											className: 'holiday'
										}
									}),
									allAssociateLeaveList ?
										associateLeave.map((event) => {
											if (event.leaveStatus == "APPROVED" || event.leaveStatus == "REQUESTED") {
												var color = ''; var name = ''; var barClassname = ''; var total = ''; var title = '';

												if (event.userDetails !== undefined) {
													name = event.userDetails.firstName;
												}
												total = event.total;
												title = name + ' - ' + total;

												if (event.from_date === event.to_date) {
													var endDate = event.to_date;
												} else {
													var endDate = Moment.utc(event.to_date).utcOffset("+240").format('YYYY-MM-DD');
												}

												if (event.leaveStatus == "APPROVED" && event.ConfigLeaveType?.leaveTypeName == "WFH") {
													color = '#1a237e';
												} else if (event.leaveStatus == "APPROVED") {
													color = '#16978E';
													// barClassname = 'leaveProgressbar'
												} else if (event.leaveStatus == 'REQUESTED' && event.ConfigLeaveType?.leaveTypeName == "WFH") {
													color = '#b7aef1';
													// barClassname = 'leaveProgressbar'
												} else if (event.leaveStatus == 'REQUESTED') {
													color = '#FFC000';
													// barClassname = 'leaveProgressbar'
												}
											} else {
												return {
													title: ''
												}
											}

											return {
												title: title,
												start: event.from_date,
												end: endDate,
												color: color,
												// className: barClassname,
												// eventRender: function(event, element) { 
												// 	element.find('.fc-daygrid-day-bottom').append("<br/>" + name); 
												// }
											}
										}) :
										associateLeaveList.map((event) => {
											if (event.leaveStatus == "APPROVED" || event.leaveStatus == "REQUESTED") {
												var color = ''; var name = ''; var barClassname = ''; var total = ''; var title = '';

												if (event.userDetails !== undefined) {
													name = event.userDetails.firstName;
												}
												total = event.total;
												title = name + ' - ' + total;

												if (event.from_date === event.to_date) {
													var endDate = event.to_date;
												} else {
													var endDate = Moment.utc(event.to_date).utcOffset("+240").format('YYYY-MM-DD');
												}

												if (event.leaveStatus == "APPROVED" && event.ConfigLeaveType?.leaveTypeName == "WFH") {
													color = '#1a237e';
												} else if (event.leaveStatus == "APPROVED") {
													color = '#16978E';
													// barClassname = 'leaveProgressbar'
												} else if (event.leaveStatus == 'REQUESTED' && event.ConfigLeaveType?.leaveTypeName == "WFH") {
													color = '#b7aef1';
													// barClassname = 'leaveProgressbar'
												} else if (event.leaveStatus == 'REQUESTED') {
													color = '#FFC000';
													// barClassname = 'leaveProgressbar'
												}
											} else {
												return {
													title: ''
												}
											}

											return {
												title: title,
												start: event.from_date,
												end: endDate,
												color: color,
												// className: barClassname,
												// eventRender: function(event, element) { 
												// 	element.find('.fc-daygrid-day-bottom').append("<br/>" + name); 
												// }
											}
										}),
									(showAllAssociates || buName === 'IG-LONDON') ?
										allAssociateLeaveList.map((event) => {
											if (event.leaveStatus == "APPROVED" || event.leaveStatus == "REQUESTED") {
												var color = ''; var name = ''; var barClassname = ''; var total = ''; var title = '';

												if (event.userDetails !== undefined) {
													name = event.userDetails.firstName;
												}
												total = event.total;
												title = name + ' - ' + total;

												if (event.from_date === event.to_date) {
													var endDate = event.to_date;
												} else {
													var endDate = Moment.utc(event.to_date).utcOffset("+240").format('YYYY-MM-DD');
												}
												if (event.leaveStatus == "APPROVED" && event.ConfigLeaveType?.leaveTypeName == "WFH") {
													color = '#1a237e';
												} else if (event.leaveStatus == "APPROVED") {
													color = '#16978E';
													// barClassname = 'leaveProgressbar'
												} else if (event.leaveStatus == 'REQUESTED' && event.ConfigLeaveType?.leaveTypeName == "WFH") {
													color = '#b7aef1';
													// barClassname = 'leaveProgressbar'
												} else if (event.leaveStatus == 'REQUESTED') {
													color = '#FFC000';
													// barClassname = 'leaveProgressbar'
												}
											} else {
												return {
													title: ''
												}
											}

											return {
												title: title,
												start: event.from_date,
												end: endDate,
												color: color,
												// className: barClassname,
												// eventRender: function(event, element) { 
												// 	element.find('.fc-daygrid-day-bottom').append("<br/>" + name); 
												// }
											}
										}) :
										myLeaveCalendar.map((event) => {
											if (event.leaveStatus == "APPROVED" || event.leaveStatus == "REQUESTED") {
												var color = ''; var name = ''; var barClassname = ''; var total = ''; var title = '';

												if (event.userDetails !== undefined) {
													name = event.userDetails.firstName;
												}
												total = event.total;
												title = name + ' - ' + total;

												if (event.from_date === event.to_date) {
													var endDate = event.to_date;
												} else {
													var endDate = Moment.utc(event.to_date).utcOffset("+240").format('YYYY-MM-DD');
												}

												if (event.leaveStatus == "APPROVED" && event.ConfigLeaveType?.leaveTypeName == "WFH") {
													color = '#1a237e';
												} else if (event.leaveStatus == "APPROVED") {
													color = '#16978E';
													// barClassname = 'leaveProgressbar'
												} else if (event.leaveStatus == 'REQUESTED' && event.ConfigLeaveType?.leaveTypeName == "WFH") {
													color = '#b7aef1';
													// barClassname = 'leaveProgressbar'
												} else if (event.leaveStatus == 'REQUESTED') {
													color = '#FFC000';
													// barClassname = 'leaveProgressbar'
												}
											} else {
												return {
													title: ''
												}
											}

											return {
												title: title,
												start: event.from_date,
												end: endDate,
												color: color,
												// className: barClassname,
												// eventRender: function(event, element) { 
												// 	element.find('.fc-daygrid-day-bottom').append("<br/>" + name); 
												// }
											}
										})
								]
							}
						/>
					</div>
				</div>
				<div className="p-col-6 calendar d-mob-block">
					<CalendarElement
						name='date'
						placeholder='Select Date'
						value={date}
						view='date'
						dateFormat='dd/mm/yy'
						onChange={(e) => onChangeDate(e)}
					/>
				</div>
				<div className="p-col-6 text-right d-mob-block">
					<div className="d-flex justify-end">
						<div>
							<SingleSelectElement
								placeholder="Select"
								className="p-inputtext text-left align-center"
								options={employeesList}
								onChangeValue={(e) => onChangeEmployeeValue(e)}
								value={employeeDetails}
								optionLabel='name'
								filterBy='name'
							/>
						</div>
						<ButtonElement
							className="fs-13 font-normal h-35"
							label="New Request"
							icon="pi pi-plus"
							iconPos="left"
							onClickButton={onCLickNewRequestModal}
						/>
					</div>
				</div>
				<div className="p-col-12 p-lg-4 p-xl-3 pb-0">
					<div className="orderlist-demo">
						<div className="card">
							<TabView className='headerTab'>
								<TabPanel header={buName == 'IG-CHENNAI' ? "Leave" : "Annual"} headerTemplate={tabLeaveTemplate}>
									<div className="container">
										<TabView>
											<TabPanel header="My Leave">
												{
													myLeave.length > 0 ?
														<div>
															<OrderList value={myLeave} dragdrop={false} dataKey="id" itemTemplate={myLeaveTemplate} className="leave-order-list"></OrderList>
														</div> :
														<div className="sub-container align-center justify-center">
															<div className="text-center">
																<img src={EmptyImage} alt="footer" className="empty-image"
																/>
																<p className="empty-list">There may be no entries</p>
															</div>
														</div>
												}
												{
													leaveShowMore &&
													<ButtonElement
														className="fs-13 font-normal load-more"
														icon="pi pi-chevron-down"
														label="Show more"
														iconPos="right"
														onClickButton={() => onLeaveLoadMoreHandler()}
													/>
												}
											</TabPanel>
											{
												showAssociateTab &&
												<TabPanel header="Associates" headerTemplate={tabAssLeaveTemplate}>
													{
														assLeaveData &&
														<div className="p-0 mt-3 w-100">
															<span className="p-input-icon-left">
																<i className="pi pi-search" />
																<InputText
																	onChange={(e) => inputAssLeaveHandler(e)}
																	type="search"
																	placeholder="Search"
																/>
															</span>
														</div>
													}
													{
														assLeaveData.length > 0 ?
															<div>
																{/* <div className="d-flex select-bulk-item-content">
																	<div className="select-bulk-item">
																		<CheckboxElement
																			checked={checkedBulkLeave}
																			onChangeCheck={(e) => checkedMultiLeave(e)}
																		/>
																	</div>
																	<div>
																		<p className="m-0">Select All
																			<span>&nbsp;
																				{checkedBulkLeave ? leaveCheckedCount : 0}
																			</span> of
																			<span>&nbsp;
																				{associateLeaveList.length}
																			</span>
																		</p>
																	</div>

																	{leaveId.length > 0 &&
																		checkedBulkLeave &&
																		<div className="d-flex icon-position">
																			<i className="bi bi-check-lg" onClick={bulkLeaveApprove} ></i>
																			<i className="bi bi-x" onClick={bulkLeaveReject} ></i>

																		</div>
																	}

																</div> */}
																<OrderList value={assLeaveData} dragdrop={false} dataKey="id" itemTemplate={assLeaveTemplate}></OrderList>
															</div> :
															<div className="sub-container align-center justify-center">
																<div className="text-center">
																	<img src={EmptyImage} alt="footer" className="empty-image" />
																	<p className="empty-list">There may be no entries</p>
																</div>
															</div>
													}
													{
														leaveAssociateShowMore &&
														<ButtonElement
															className="fs-13 font-normal load-more"
															icon="pi pi-chevron-down"
															label="Show more"
															iconPos="right"
															onClickButton={() => onLeaveAssociateLoadMoreHandler()}
														/>
													}
												</TabPanel>
											}
											{/* {
												buName == 'JMAN-CHENNAI' ?
													<TabPanel headerTemplate={myWFHTemplate}></TabPanel> :
													''
											} */}
										</TabView>
									</div>
									<div>

									</div>
								</TabPanel>
								<TabPanel header={buName == 'IG-CHENNAI' ? "Earned" : "Taken"} headerTemplate={tabEarnedTemplate}>
									<div className="container">
										<TabView>
											<TabPanel header="Earned">
												{
													earnedLeave.length > 0 ?
														<div>
															<OrderList value={earnedLeave} dragdrop={false} dataKey="id" itemTemplate={myEarnedTemplate}></OrderList>
														</div> :
														<div className="sub-container align-center justify-center">
															<div className="text-center">
																<img src={EmptyImage} alt="footer" className="empty-image" />
																<p className="empty-list">There may be no entries</p>
															</div>
														</div>
												}
												{
													permissionShowMore &&
													<ButtonElement
														className="fs-13 font-normal load-more"
														icon="pi pi-chevron-down"
														label="Show more"
														iconPos="right"
														onClickButton={() => onPermissionLoadMoreHandler()}
													/>
												}
											</TabPanel>
											{
												showAssociateTab &&
												<TabPanel header="Associates" headerTemplate={tabAssPermissionTemplate}>
													{
														assPermissionData &&
														<div className="p-0 mt-3 w-100">
															<span className="p-input-icon-left">
																<i className="pi pi-search" />
																<InputText
																	onChange={(e) => inputAssPermissionHandler(e)}
																	type="search"
																	placeholder="Search"
																/>
															</span>
														</div>
													}
													{
														assPermissionData.length > 0 ?
															<div>
																{/* <div className="d-flex select-bulk-item-content">
																	<div className="select-bulk-item">
																		<CheckboxElement
																			checked={checkedBulkPermission}
																			onChangeCheck={(e) => checkedMultiPermission(e)}
																		/>
																	</div>
																	<div>
																		<p className="m-0">Select All
																			<span>&nbsp;
																				{checkedBulkPermission ? permissionCheckedCount : 0}
																			</span> of
																			<span>&nbsp;
																				{associatesPermissionList.length}
																			</span>
																		</p>
																	</div>
																	{permissionId.length > 0 &&
																		checkedBulkPermission &&
																		<div className="d-flex icon-position">
																			<i className="bi bi-check-lg" onClick={bulkPermissionApprove} ></i>
																			<i className="bi bi-x" onClick={bulkPermissionReject} ></i>
																		</div>
																	}
																</div> */}
																<OrderList value={assPermissionData} dragdrop={false} dataKey="id" itemTemplate={assPermissionTemplate}></OrderList>
															</div> :
															<div className="sub-container align-center justify-center">
																<div className="text-center">
																	<img src={EmptyImage} alt="footer" className="empty-image" />
																	<p className="empty-list">There may be no entries</p>
																</div>
															</div>
													}
													{
														permissionAssociateShowMore &&
														<ButtonElement
															className="fs-13 font-normal load-more"
															icon="pi pi-chevron-down"
															label="Show more"
															iconPos="right"
															onClickButton={() => onPermissionAssociateLoadMoreHandler()}
														/>
													}
												</TabPanel>
											}
										</TabView>
									</div>
								</TabPanel>
								{/* <TabPanel header={buName == 'JMAN-CHENNAI' ? "Permission" : "Taken"} headerTemplate={tabPermissionTemplate}>
									<div className="container">
										<TabView>
											<TabPanel header="Permission">
												{
													myPermission.length > 0 ?
														<div>
															<OrderList value={myPermission} dragdrop={false} dataKey="id" itemTemplate={myPermissionTemplate}></OrderList>
														</div> :
														<div className="sub-container align-center justify-center">
															<div className="text-center">
																<img src={EmptyImage} alt="footer" className="empty-image" />
																<p className="empty-list">There may be no entries</p>
															</div>
														</div>
												}
												{
													permissionShowMore &&
													<ButtonElement
														className="fs-13 font-normal load-more"
														icon="pi pi-chevron-down"
														label="Show more"
														iconPos="right"
														onClickButton={() => onPermissionLoadMoreHandler()}
													/>
												}
											</TabPanel>
											{
												showAssociateTab &&
												<TabPanel header="Associates" headerTemplate={tabAssPermissionTemplate}>
													{
														assPermissionData &&
														<div className="p-0 mt-3 w-100">
															<span className="p-input-icon-left">
																<i className="pi pi-search" />
																<InputText
																	onChange={(e) => inputAssPermissionHandler(e)}
																	type="search"
																	placeholder="Search"
																/>
															</span>
														</div>
													}
													{
														assPermissionData.length > 0 ?
															<div>
																<OrderList value={assPermissionData} dragdrop={false} dataKey="id" itemTemplate={assPermissionTemplate}></OrderList>
															</div> :
															<div className="sub-container align-center justify-center">
																<div className="text-center">
																	<img src={EmptyImage} alt="footer" className="empty-image" />
																	<p className="empty-list">There may be no entries</p>
																</div>
															</div>
													}
													{
														permissionAssociateShowMore &&
														<ButtonElement
															className="fs-13 font-normal load-more"
															icon="pi pi-chevron-down"
															label="Show more"
															iconPos="right"
															onClickButton={() => onPermissionAssociateLoadMoreHandler()}
														/>
													}
												</TabPanel>
											}
										</TabView>
									</div>
								</TabPanel>  */}
								<TabPanel header={buName == 'IG-CHENNAI' ? "Comp Off" : "Balance"} headerTemplate={tabCompOffTemplate}>
									<div className="container">
										<TabView>
											<TabPanel header="Comp Off" className="p-tabview-title">
												{
													myCompOff.length > 0 ?
														<div>
															<OrderList value={myCompOff} dragdrop={false} dataKey="id" itemTemplate={myCompOffTemplate}></OrderList>
														</div> :
														<div className="sub-container align-center justify-center">
															<div className="text-center">
																<img src={EmptyImage} alt="footer" className="empty-image" />
																<p className="empty-list">There may be no entries</p>
															</div>
														</div>
												}
												{
													compoffShowMore &&
													<ButtonElement
														className="fs-13 font-normal load-more"
														icon="pi pi-chevron-down"
														label="Show more"
														iconPos="right"
														onClickButton={() => onCompOffLoadMoreHandler()}
													/>
												}
											</TabPanel>
											{
												showAssociateTab &&
												<TabPanel header="Associates" headerTemplate={tabAssCompOffTemplate}>
													{
														assCompOffData &&
														<div className="p-0 mt-3 w-100">
															<span className="p-input-icon-left">
																<i className="pi pi-search" />
																<InputText
																	onChange={(e) => inputAssCompOffHandler(e)}
																	type="search"
																	placeholder="Search"
																/>
															</span>
														</div>
													}
													{
														assCompOffData.length > 0 ?
															<div>
																{/* <div className="d-flex select-bulk-item-content">
																	<div className="select-bulk-item">
																		<CheckboxElement
																			checked={checkedBulkComp}
																			onChangeCheck={(e) => checkedMultiCompOff(e)}
																		/>
																	</div>
																	<div>
																		<p className="m-0">Select All
																			<span>&nbsp;
																				{checkedBulkComp ? compCheckedCount : 0}
																			</span> of
																			<span>&nbsp;
																				{associatesCompOffList.length}
																			</span>
																		</p>
																	</div>
																	{
																		compId.length > 0 &&
																		checkedBulkComp &&
																		<div className="d-flex icon-position">
																			<i className="bi bi-check-lg" onClick={bulkCompApprove} ></i>
																			<i className="bi bi-x" onClick={bulkCompReject} ></i>
																		</div>
																	}
																</div> */}
																<OrderList value={assCompOffData} dragdrop={false} dataKey="id" itemTemplate={assCompOffTemplate}></OrderList>
															</div> :
															<div className="sub-container align-center justify-center">
																<div className="text-center">
																	<img src={EmptyImage} alt="footer" className="empty-image" />
																	<p className="empty-list">There may be no entries</p>
																</div>
															</div>
													}
													{
														compoffAssociateShowMore &&
														<ButtonElement
															className="fs-13 font-normal load-more"
															icon="pi pi-chevron-down"
															label="Show more"
															iconPos="right"
															onClickButton={() => onCompOffAssociateLoadMoreHandler()}
														/>
													}
												</TabPanel>
											}
										</TabView>
									</div>
								</TabPanel>
								<TabPanel headerTemplate={nameTemplate}>
								</TabPanel>
							</TabView>
						</div>
					</div>
				</div>
				{
					//New Request Modal Popup
					displayRequestModal &&
					<RequestModalElement visible={true} header="New Request" reportingManagerData={reportingManagerData} reportingManager={reportingManager} reporterId={reporterId} reporterGender={reporterGender} type='Request' addRequestHandler={addRequestHandler} onHide={(message, success) => onHide('displayRequestModal', message, success)} compOffId={compOffId} />
				}
				{
					//Edit Leave Request Modal Popup
					displayEditLeaveRequestModal &&
					<RequestModalElement visible={true} header="Edit Request" reportingManagerData={reportingManagerData} reportingManager={reportingManager} reporterId={reporterId} reporterGender={reporterGender} leaveEditData={editMyLeaveData} permissionHours={permissionHoursLimit} type='Update' leaveEditType={editMyLeaveType} addRequestHandler={addRequestHandler} onHide={(message, success) => onHide('displayEditLeaveRequestModal', message, success)} permissionBalance={permissionBalance} balanceLeaves={balanceLeaves} />
				}
				{
					//My Leave Cancel Modal Popup
					displayMyLeaveCancel &&
					<Dialog visible={true} header="Confirm Leave Cancellation" onHide={() => onHide('displayMyLeaveCancel')} footer={renderMyLeaveCancel()} breakpoints={{ '960px': '75vw' }} draggable={false}>
						<div className="cancel-request">
							<div className="p-grid">
								<div className="p-col-12">
									<p className="font-normal fs-13 text-lightgray m-0">Reason for cancellation<span className='text-red'>*</span></p>
									<SingleSelectElement name="cancel_desc" placeholder="Select" options={cancelReasons} value={assLeaveRejectInfo} onChangeValue={(e) => onChangeRejectInfo(e)} optionLabel="name"
										filterBy='name' />
								</div>
							</div>
						</div>
					</Dialog>
				}
				{
					//Associate Leave Approve Modal Popup
					displayAssociateLeaveApprove &&
					<Dialog visible={displayAssociateLeaveApprove} header="Confirmation" onHide={() => onHide('displayAssociateLeaveApprove')} footer={renderAssLeaveApprove()} breakpoints={{ '960px': '75vw' }} draggable={false}>
						<div className="cancel-request">
							<div className="p-grid">
								<div className="p-col-12">
									<p className="font-normal fs-13 text-lightgray m-0">Are you sure to approve this leave ?</p>
								</div>
							</div>
						</div>
					</Dialog>
				}
				{
					//Associate Leave Reject Modal Popup
					displayAssociateLeaveReject &&
					<Dialog visible={true} header="Reason for Rejection" onHide={() => onHide('displayAssociateLeaveReject')} footer={renderAssLeaveReject()} breakpoints={{ '960px': '75vw' }} draggable={false}>
						<div className="cancel-request">
							<div className="p-grid">
								<div className="p-col-12">
									<p className="font-normal fs-13 text-lightgray m-0">Reason<span className='text-red'>*</span></p>
									<SingleSelectElement name="cancel_desc" placeholder="Select" options={cancelReasons} value={assLeaveRejectInfo} onChangeValue={(e) => onChangeRejectInfo(e)} optionLabel="name"
										filterBy='name' />
								</div>
							</div>
						</div>
					</Dialog>
				}
				{
					//Associate Leave Cancel Modal Popup
					displayAssociateLeaveCancel &&
					<Dialog visible={displayAssociateLeaveCancel} header="Confirm Leave Cancellation" onHide={() => onHide('displayAssociateLeaveCancel')} footer={renderAssLeaveCancel()} breakpoints={{ '960px': '75vw' }} draggable={false}>
						<div className="cancel-request">
							<div className="p-grid">
								<div className="p-col-12">
									<p className="font-normal fs-13 text-lightgray m-0">Reason for cancellation<span className='text-red'>*</span></p>
									<SingleSelectElement name="cancel_desc" placeholder="Select" options={cancelReasons} value={assLeaveRejectInfo} onChangeValue={(e) => onChangeRejectInfo(e)} optionLabel="name"
										filterBy='name' />
								</div>
							</div>
						</div>
					</Dialog>
				}
				{
					//My Comp Off Cancel Modal Popup
					displayMyCompOffCancel &&
					<Dialog visible={displayMyCompOffCancel} header="Reason for Cancellation" onHide={() => onHide('displayMyCompOffCancel')} footer={renderMyCompCancel()} breakpoints={{ '960px': '75vw' }} draggable={false}>
						<div className="cancel-request">
							<div className="p-grid">
								<div className="p-col-12">
									<p className="font-normal fs-13 text-lightgray m-0">Reason<span className='text-red'>*</span></p>
									<SingleSelectElement name="cancel_desc" placeholder="Select" options={cancelReasons} value={assLeaveRejectInfo} onChangeValue={(e) => onChangeRejectInfo(e)} optionLabel="name"
										filterBy='name' />
								</div>
							</div>
						</div>
					</Dialog>
				}
				{
					//Associate Comp Off Approve Modal Popup
					displayAssociateCompOffApprove &&
					<Dialog visible={displayAssociateCompOffApprove} header="Confirmation" onHide={() => onHide('displayAssociateCompOffApprove')} footer={renderAssCompApprove()} breakpoints={{ '960px': '75vw' }} draggable={false}>
						<div className="cancel-request">
							<div className="p-grid">
								<div className="p-col-12">
									<p className="font-normal fs-13 text-lightgray m-0">Are you sure to approve this Comp Off ?</p>
								</div>
							</div>
						</div>
					</Dialog>
				}
				{
					//Associate Comp Off Reject Modal Popup
					displayAssociateCompOffReject &&
					<Dialog visible={displayAssociateCompOffReject} header="Reason for Rejection" onHide={() => onHide('displayAssociateCompOffReject')} footer={renderAssCompReject()} breakpoints={{ '960px': '75vw' }} draggable={false}>
						<div className="cancel-request">
							<div className="p-grid">
								<div className="p-col-12">
									{/* <p className="font-normal fs-13 text-lightgray m-0">Reason<span className='text-red'>*</span></p>
									<SingleSelectElement name="cancel_desc" placeholder="Select" options={cancelReasons} value={assLeaveRejectInfo} onChangeValue={(e) => onChangeRejectInfo(e)} optionLabel="name"
										filterBy='name' /> */}
									<p className="font-normal fs-13 text-lightgray m-0">Are you sure to reject this Comp Off ?</p>
								</div>
							</div>
						</div>
					</Dialog>
				}
				{
					//My Permission Cancel Modal Popup
					displayMyPermissionCancel &&
					<Dialog visible={displayMyPermissionCancel} header="Reason for Cancellation" onHide={() => onHide('displayMyPermissionCancel')} footer={renderMyPermissionCancel()} breakpoints={{ '960px': '75vw' }} draggable={false}>
						<div className="cancel-request">
							<div className="p-grid">
								<div className="p-col-12">
									<p className="font-normal fs-13 text-lightgray m-0">Reason<span className='text-red'>*</span></p>
									<SingleSelectElement name="cancel_desc" placeholder="Select" options={cancelReasons} value={assLeaveRejectInfo} onChangeValue={(e) => onChangeRejectInfo(e)} optionLabel="name"
										filterBy='name' />
								</div>
							</div>
						</div>
					</Dialog>
				}
				{
					//Associate Permission Approve Modal Popup
					displayAssociatePermissionApprove &&
					<Dialog visible={displayAssociatePermissionApprove} header="Confirmation" onHide={() => onHide('displayAssociatePermissionApprove')} footer={renderAssPermissionApprove()} breakpoints={{ '960px': '75vw' }} draggable={false}>
						<div className="cancel-request">
							<div className="p-grid">
								<div className="p-col-12">
									<p className="font-normal fs-13 text-lightgray m-0">Are you sure to approve this permission ?</p>
								</div>
							</div>
						</div>
					</Dialog>
				}
				{
					//Associate Permission Reject Modal Popup
					displayAssociatePermissionReject &&
					<Dialog visible={displayAssociatePermissionReject} header="Reason for Rejection" onHide={() => onHide('displayAssociatePermissionReject')} footer={renderAssPermissionReject()} breakpoints={{ '960px': '75vw' }} draggable={false}>
						<div className="cancel-request">
							<div className="p-grid">
								<div className="p-col-12">
									{/* <p className="font-normal fs-13 text-lightgray m-0">Reason<span className='text-red'>*</span></p>
									<SingleSelectElement name="cancel_desc" placeholder="Select" options={cancelReasons} value={assLeaveRejectInfo} onChangeValue={(e) => onChangeRejectInfo(e)} optionLabel="name"
										filterBy='name' /> */}
									<p className="font-normal fs-13 text-lightgray m-0">Are you sure to reject this permission ?</p>
								</div>
							</div>
						</div>
					</Dialog>
				}
				{
					//Associate Permission Cancel Modal Popup
					displayAssociatePermissionCancel &&
					<Dialog visible={displayAssociatePermissionCancel} header="Reason for Cancellation" onHide={() => onHide('displayAssociatePermissionCancel')} footer={renderAssPermissionCancel()} breakpoints={{ '960px': '75vw' }} draggable={false}>
						<div className="cancel-request">
							<div className="p-grid">
								<div className="p-col-12">
									<p className="font-normal fs-13 text-lightgray m-0">Reason<span className='text-red'>*</span></p>
									<SingleSelectElement name="cancel_desc" placeholder="Select" options={cancelReasons} value={assLeaveRejectInfo} onChangeValue={(e) => onChangeRejectInfo(e)} optionLabel="name"
										filterBy='name' />
								</div>
							</div>
						</div>
					</Dialog>
				}
			</div>
		</div>
	)

	/* Render View Return - End */

}

/* Function - End */

/* Export default functionName; */

export default Leave;