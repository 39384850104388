/**
 * Authentication Service
 */
import axios from 'axios';

let baseUrl = process.env.REACT_APP_API_URL;

/* New API for get BU List */
async function getBuList() {
	let response = await axios.get(`${baseUrl}/api/business-unit`);
	return response.data;
}

async function statusChangeBuId(statusData) {
	let response = await axios.post(`${baseUrl}/api/business-unit/buStatus/${statusData.id}`, statusData);
	return response.data;
}

/* New API for creating new BU */
async function createBu(data) {
	let response = await axios.post(`${baseUrl}/api/business-unit`, data);
	return response.data;
}

async function editBu(buId) {
	let response = await axios.get(`${baseUrl}/api/business-unit/${buId}`);
	return response.data;
}

async function updateBu(id, data) {
	let response = await axios.patch(`${baseUrl}/api/business-unit/${id}`, data);
	return response.data;
}

export default { getBuList, statusChangeBuId, createBu, editBu, updateBu };