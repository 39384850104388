/**
 * Authentication Service
 */
import axios from 'axios';

let baseUrl = process.env.REACT_APP_API_URL;

async function getEmployees(buId) {
	let response = await axios.get(`${baseUrl}/api/user/getAllUsers?buId=${buId}&direction=ASC&sortBy=firstName`);
	return response.data;
}
async function getFilterEmployees(buId, filter) {
	let response = await axios.get(`${baseUrl}/api/user/getAllUsers?buId=${buId}&direction=ASC&sortBy=firstName&accountStatus=${filter}`);
	return response.data;
}

async function getActiveBu() {
	let response = await axios.get(`${baseUrl}/api/business-unit?status=active`);
	return response.data;
}

async function getUserStatusDataByBuId(buId) {
	let response = await axios.get(`${baseUrl}/api/user-status/getUserStatusByBuid/${buId}`);
	return response.data;
}

async function getUserDesignationData(buId) {
	let response = await axios.get(`${baseUrl}/api/designation/userDesignationByBuId/${buId}`);
	return response.data;
}

async function listGradeByBuId(buId) {
	let response = await axios.get(`${baseUrl}/api/grade/userGradeByBuId/${buId}`);
	return response.data;
}

async function getDepartmentByBuId(buId) {
	let response = await axios.get(`${baseUrl}/api/department/getDepartmentByBuId/${buId}`);
	return response.data;
}

async function getRoleByBuId(buId) {
	let response = await axios.get(`${baseUrl}/api/role/roleByBuId/${buId}`);
	return response.data;
}

async function createUser(data) {
	let response = await axios.post(`${baseUrl}/api/user/`, data);
	return response.data;
}

async function updateUser(id, data) {
	let response = await axios.patch(`${baseUrl}/api/user/${id}`, data);
	return response.data;
}

async function editUser(id) {
	let response = await axios.get(`${baseUrl}/api/user/editUsers/${id}`);
	return response.data;
}

async function getAllBuUsers() {
	let response = await axios.get(`${baseUrl}/api/user/allBuUsers`);
	return response.data;
}

async function getUserData(userId) {
	let response = await axios.get(`${baseUrl}/api/user/getReporterDetails/${userId}`);
	return response.data;
}

async function employeeData(userId) {
	let response = await axios.get(`${baseUrl}/api/user/?userId=${userId}`);
	return response.data;
}

export default {employeeData,  getUserData, getEmployees, getFilterEmployees, getActiveBu, getUserStatusDataByBuId, getUserDesignationData, getDepartmentByBuId, listGradeByBuId, getRoleByBuId, createUser, updateUser, editUser, getAllBuUsers };