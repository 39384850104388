import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useRef, useState } from 'react'
import ToastElement from './ToastElement'


function FilterViews({ pageName, filter, filterFunction }) {

  const [showAddPopup, setShowAddPopup] = useState(false)
  const [viewName, setViewName] = useState("")
  const [savedViews, setSavedViews] = useState([])
  const [selectedView, setSelectedView] = useState(null)
  const [showSave, setShowSave] = useState(false)
  const toastRef = useRef()

  const onClickSaveView = () => {
    setShowAddPopup(true)
  }
  const onClickSavePopup = () => {
    if (filter == null) {
      let message = {
        type: 'error',
        summary: 'warning',
        message: 'This is the default view'
      }
      toastRef.current.showToast(message)
      return;
    }
    let obj = {
      label: viewName,
      value: savedViews.length,
      data: filter
    }
    let views = JSON.parse(localStorage.getItem("filterviews") ?? '{}')
    views = { ...views, [pageName]: [...views?.[pageName] ?? [], obj] }
    localStorage.setItem('filterviews', JSON.stringify(views))
    setShowAddPopup(false)
    let message = {
      type: 'success',
      summary: 'Success',
      message: 'View added successfully'
    }
    toastRef.current.showToast(message)
    setSavedViews(views[pageName])
    setShowSave(false)
  }

  const onChangeViewName = (e) => {
    setViewName(e.target.value)
  }

  const onClickItem = (e) => {
    if (e) {
      setSelectedView(e.value)
      filterFunction(e.data, true)
    }
  }

  const onClickDeleteView = (data) => {
    if (confirm("Are you sure you want to delete this view?") == true) {
      let views = JSON.parse(localStorage.getItem("filterviews") ?? '{}')
      views = { ...views, [pageName]: views?.[pageName]?.filter(item => item.value != data.value) }
      localStorage.setItem('filterviews', JSON.stringify(views))
      setSavedViews(views[pageName] ?? [])
    }
  }

  useEffect(() => {
    let views = JSON.parse(localStorage.getItem("filterviews") ?? '{}')
    setSavedViews(views[pageName] ?? [])
  }, [])

  useEffect(() => {
    setShowSave(true)
    if (!filter) {
      setSelectedView(null)
    }
  }, [filter])

  const DialogFooter = () => {
    return <>
      <div className='pb-8'>
        <Button className='create-button' label="Save View" onClick={onClickSavePopup} disabled={viewName ? false : true} />
      </div>
    </>
  }

  const viewItem = (data) => {
    return <>
      <div className='d-flex justify-between'>
        <div className='dropdown-item-custom1' onClick={() => onClickItem(data)}>
          {data.label}
        </div>
        <i className='pi pi-trash dropdown-item-custom1' onClick={() => onClickDeleteView(data)}></i>
      </div>
    </>
  }

  return <>
    <div className='d-flex mt-3 w-100'>
      <div className='mr-10 filter-view'>
        <Button icon="pi pi-save" onClick={onClickSaveView} tooltip="Save this view" size='small'/>
      </div>
      <div className='w-100'>
        <Dropdown options={savedViews} placeholder="Views" itemTemplate={viewItem} panelClassName='dropdown-no-padding' value={selectedView}  style={{ maxWidth: '90px' }} />
      </div>
    </div>

    <Dialog visible={showAddPopup} footer={DialogFooter} header="Save View" onHide={() => setShowAddPopup(false)} >
      <p className='font-normal fs-13 text-lightgray mt-0 mb-8'>
        Enter a name for this view
        <span className='text-red'>*</span>
      </p>
      <InputText onChange={onChangeViewName} />
    </Dialog>
    <ToastElement ref={toastRef} />
  </>
}

export default FilterViews