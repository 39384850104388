import axios from 'axios';

let baseUrl = process.env.REACT_APP_API_URL;

async function getLeaveType(buId) {
	let response = await axios.get(`${baseUrl}/api/leave-type/leaveTypeByBuId/${buId}`);
	return response.data;
}

async function getActiveBu() {
	let response = await axios.get(`${baseUrl}/api/business-unit?status=active`);
	return response.data;
}

async function updateLeaveStatus(id, data) {
	let response = await axios.post(`${baseUrl}/api/leave-type/leaveStatus/${id}`, data);
	return response.data;
}

async function leaveConfiguration(buId) {
	let response = await axios.get(`${baseUrl}/api/leave-config/${buId}`);
	return response.data;
}

async function editLeaveType(typeId) {
	let response = await axios.get(`${baseUrl}/api/leave-type/${typeId}`);
	return response.data;
}

async function updateLeaveConfig(leaveDataId, data, buId) {
	let response = await axios.patch(`${baseUrl}/api/leave-config/${leaveDataId}/${buId}`, data);
	return response.data;
}

async function addLeaveType(data) {
	let response = await axios.post(`${baseUrl}/api/leave-type/`, data);
	return response.data;
}

async function updateLeaveType(typeId, data) {
	let response = await axios.patch(`${baseUrl}/api/leave-type/${typeId}`, data);
	return response.data;
}

export default { getLeaveType, updateLeaveStatus, leaveConfiguration, editLeaveType, updateLeaveConfig, getActiveBu, addLeaveType, updateLeaveType };