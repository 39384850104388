import axios from 'axios';

let baseUrl = process.env.REACT_APP_API_URL2;

async function getActiveBu() {
    let response = await axios.get(`${baseUrl}/api/settings/getActiveBu`);
    return response.data;
}

async function getUserDetails(userId) {
    let response = await axios.get(`${baseUrl}/api/v1/users/${userId}`);
    return response.data;
}

async function getJobs(data) {
    let response = await axios.get(`${baseUrl}/api/v1/jobs?buId=${data.buId}&direction=${data.direction}&filter=${data.filter}&limit=${data.limit}&offset=${data.offset}&pattern=${data.pattern}&sortBy=${data.sortBy}`);
    return response.data;
}

async function getUsersList() {
    let response = await axios.get(`${baseUrl}/api/v1/listUsers`);
    return response.data;
}

async function getFilterData(buId) {
    let response = await axios.get(`${baseUrl}/api/v1/filterData/${buId}`);
    return response.data;
}

async function getBasicData() {
    let response = await axios.get(`${baseUrl}/api/v1/getBasicData`);
    return response.data;
}

async function getProjectsByClientId(clientId) {
    let response = await axios.get(`${baseUrl}/api/v1/getActiveProjectsByClientId/${clientId}`);
    return response.data;
}

async function getJobsByClientId(clientId) {
    let response = await axios.get(`${baseUrl}/api/v1/getJobsByClientId/${clientId}`);
    return response.data;
}

async function getJobDetails(jobId) {
    let response = await axios.get(`${baseUrl}/api/v1/getDetailedJobData/${jobId}`);
    return response.data;
}

async function createJob(data) {
    let response = await axios.post(`${baseUrl}/api/v1/createJob`, data);
    return response.data;
}

async function updateJobStatus(data) {
    let response = await axios.post(`${baseUrl}/api/v1/updateJobStatus`, data);
    return response.data;
}

async function getAllUserManualTimeLog(jobId) {
    let response = await axios.get(`${baseUrl}/api/v1/userTrackTime/${jobId}`);
    return response.data;
}

async function getSelectedUserTrackTime(jobId, userId) {
    let response = await axios.get(`${baseUrl}/api/v1/getuserTrackTime/${jobId}/${userId}`);
    return response.data;
};

async function submitManualJobTime(jobId, manualJobTime, userId) {
    let response = await axios.put(`${baseUrl}/api/v1/submitjobTime/${jobId}`, { manualJobTime: manualJobTime, userId: userId });
    return response.data;
};

async function getStopWatchTimeLog(jobId, userId, statusId) {
    let response = await axios.get(`${baseUrl}/api/v1/getJobTimeLogs/${jobId}/${userId}/${statusId}`);
    return response.data;
};

async function postStopLog(data) {
    let response = await axios.post(`${baseUrl}/api/job/postStopLogs`, data);
    return response.data;
};

async function getAllJobCompleteComment(jobId) {
    let response = await axios.get(`${baseUrl}/api/v1/getAllJobCompleteComment/${jobId}`);
    return response.data;
};

async function submitJobCompleteComment(data) {
    let response = await axios.post(`${baseUrl}/api/v1/submitJobCompleteComment`, data);
    return response.data;
};

async function getErrorTypes(buId) {
    let response = await axios.get(`${baseUrl}/api/v1/getErrorTypes/${buId}`);
    return response.data;
};

async function saveQcComments (data) {
    let response = await axios.post(`${baseUrl}/api/v1/saveQcComments`, data);
    return response.data;
};

async function downloadFile(fileId) {
    let response = await axios.post(`${baseUrl}/api/v1/downloadFile`, {fileId: fileId}, {
        headers: {
            'Content-type': 'application/json',
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer'
    });

    return response.data;
};

async function removeInputFile(fileId) {
    let response = await axios.get(`${baseUrl}/api/v1/removeInputFile/${fileId}`);
    return response.data;
};

async function uploadFile(fileData) {
    let response = await axios.post(`${baseUrl}/api/v1/attachmentOutFile`, fileData, {
        // headers: {
        //     'Content-Type': 'multipart/form-data'
        // }
    });

    return response.data;
};

async function saveOutputFiles(saveOutputFiles) {
    let response = await axios.post(`${baseUrl}/api/v1/saveOutputFile`, saveOutputFiles);
    return response.data;
};

async function removeOutputFile(fileId) {
    let response = await axios.get(`${baseUrl}/api/v1/removeOutputFile/${fileId}`);
    return response.data;
};

async function updateJob(jobId, data) {
    let response = await axios.put(`${baseUrl}/api/v1/updateJob/${jobId}`, { jobInfo: data });
    return response.data;
};

async function qcCommentsCheck(jobId, data) {
    let response = await axios.put(`${baseUrl}/api/v1/qcCommentsCheck/${jobId}`, { jobInfo: data });
    return response.data
};

async function saveInstructions(jobId, userId, data) {
    let response = await axios.put(`${baseUrl}/api/v1/saveDescription/${jobId}/${userId}`, data);
    return response.data;
}

async function getComments(jobId) {
    let response = await axios.get(`${baseUrl}/api/v1/getJobComments/${jobId}`);
    return response.data;
}

async function saveComments(data) {
    let response = await axios.post(`${baseUrl}/api/v1/postJobComments`, data);
    return response.data;
}

async function getWorkLogsById(jobId) {
    let response = await axios.get(`${baseUrl}/api/v1/getWorkLogsById/${jobId}`);
    return response.data;
}

async function getUserTimeLog(jobId) {
    let response = await axios.get(`${baseUrl}/api/v1/getUserLogsById/${jobId}`);
    return response.data;
};

async function allJobQcMetrics(jobId) {
    let response = await axios.get(`${baseUrl}/api/v1/allJobQcMetrics/${jobId}`);
    return response.data;
}

async function deleteQMComments(userId, jobId, data) {
    let response = await axios.post(`${baseUrl}/api/v1/deleteQMComment/${userId}/${jobId}`, data);
    return response.data;
}

async function getClients(buId,isGlobalUser) {
    const buIdString = JSON.stringify(buId);
    let response = await axios.get(`${baseUrl}/api/v1/clientData`, {
        params: {
            buId: buIdString,
            isGlobalJob: isGlobalUser
        }
    });
    return response.data;
}

async function getReportsfilterdata(filterData) {
    let response = await axios.post(`${baseUrl}/api/v1/getReportFilterData`,filterData);
    return response.data;
}

async function getReportsDownload(filterData) {
    try {
        const response = await axios.post(`${baseUrl}/api/v1/getReportFilterData`, filterData, {
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'blob'
        });
        return response.data;
    } catch (error) {
        // Handle error here
        console.error("Error occurred:", error);
        throw error; // Rethrow the error for the caller to handle
    }
}

 
async function getQcUserChart(filter, userId) {
    let response = await axios.get(`${baseUrl}/api/v1/getQcUserChart`, { params: { filter: filter, userId: userId } });
    return response.data;
}

async function getClientData(buId, isGlobalJob) {
    let response = await axios.get(`${baseUrl}/api/v1/clientData`, { params: { buId: buId, isGlobalJob: isGlobalJob } });
    return response.data;
};

async function getQcProjects(clientId, isGlobalJob) {
    let response = await axios.get(`${baseUrl}/api/v1/getProjects`, { params: { clientIds: clientId, isGlobalJob: isGlobalJob } });
    return response.data;
};

async function getQcJobs(clientId, projectId, isGlobalJob) {
    let response = await axios.get(`${baseUrl}/api/v1/getJobNumbers`, { params: { clientIds: clientId, projectIds: projectId, isGlobalJob: isGlobalJob } });
    return response.data;
};

async function getGlobalErrorTypes(buId, isGlobalJob) {
    let response = await axios.get(`${baseUrl}/api/v1/getGlobalErrorTypes`, { params: { buIds: buId, isGlobalJob: isGlobalJob } });
    return response.data;
};

async function getActDeactUsers(buId) {
    let response = await axios.get(`${baseUrl}/api/v1/actDeactUsers`, { params: { buId: buId } });
    return response.data;
};

async function getQcAdminChart(filter) {
    let response = await axios.get(`${baseUrl}/api/v1/adminDashboard`, { params: { filter: filter } });
    return response.data;
};

async function adminPermission(userId) {
    let response = await axios.get(`${baseUrl}/api/v1/adminPermission`, { params: { userId: userId } });
    return response.data;
};
  
export default { getClients,getReportsfilterdata,getActiveBu, getUserDetails, getUsersList, getJobs, getFilterData, getBasicData, getProjectsByClientId, getJobsByClientId, getJobDetails, 
    createJob, updateJobStatus, getAllUserManualTimeLog, getSelectedUserTrackTime, submitManualJobTime, getStopWatchTimeLog, postStopLog, getAllJobCompleteComment, submitJobCompleteComment, getErrorTypes, saveQcComments, downloadFile, removeInputFile, uploadFile, saveOutputFiles, removeOutputFile, updateJob, qcCommentsCheck,
    saveInstructions, getComments, saveComments, getWorkLogsById, getUserTimeLog, allJobQcMetrics, deleteQMComments, 
    getQcUserChart, getClientData, getQcProjects, getQcJobs, getActDeactUsers, getGlobalErrorTypes, getQcAdminChart, getReportsDownload, adminPermission
};
