/* Import Section - Start */

/* React Imports - Start */

import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';


/* React Imports - End */

/* Plugin Imports - Start */

import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import _ from "lodash";

/* Plugin Imports - End */

/* Project Components Imports - Start */

import TextboxElement from "../uiComponents/TextboxElement";
import SingleSelectElement from "../uiComponents/SingleSelectElement";
import ToastElement from "../uiComponents/ToastElement";
import ToggleElement from "../uiComponents/ToggleElement";
import BusettingsService from '../../services/buSettings/BusettingsService';
import option from "./Countries";
import Loader from "../uiComponents/Loader";
import { Tooltip } from 'primereact/tooltip';


/* Project Components Imports - End */

/* Import Section - End */

/* Function - Start */

function BusinessCreationPage(props) {

	/* State Declaration - Start */

	/* useState - Start */

	let headQuarter = props.editData ? props.editData.headQuarter : false;
	let buPrefix = props.editData ? props.editData.code.substr(0, 3) : "";
	let buCode = props.editData ? props.editData.code : "";
	let buName = props.editData ? props.editData.name : "";
	let location = props.editData ? _.find(option, { 'name': props.editData.location }) ? _.find(option, { 'name': props.editData.location }) : "" : "";
	let adminFirstName = props.editData ? props.editData.buAdminFirstName : "";
	let adminLastName = props.editData ? props.editData.buAdminLastName : "";
	let email = props.editData ? props.editData.buAdminMail : "";
	let contact = props.editData ? props.editData.buContact : "";
	const [loading, setLoading] = useState(false);
	const [addressList, setaddressList] = useState(props.editData ? props.editData.address[0].address1 ?
		props.editData.address.map(({ address1, zipcode, city, state }) => ({ address: address1, zipCode: zipcode, city: city, state: state })) : props.editData.address :
		[{ address: "", city: "", state: "", zipCode: "" }]);

	/* useState - End */

	/* navigate Declaration */

	let navigate = useNavigate();

	/* useRef - Start */

	const toastRef = useRef();

	/* useRef - End */

	const defaultValues = {
		buPrefix: buPrefix,
		buCode: buCode,
		buName: buName,
		location: location,
		adminFirstName: adminFirstName,
		adminLastName: adminLastName,
		email: email,
		contact: contact,
		addressList: addressList,
		headQuarter: headQuarter
	}

	const { control, handleSubmit, watch, setValue, getValues } = useForm({ defaultValues });

	const buPrefix_code = watch("buPrefix");

	useEffect(() => {
		const re = /^[A-Za-z]+$/;
        if(buPrefix_code !== '') {
            if ((buPrefix_code && buPrefix_code.length > 3) || !re.test(buPrefix_code)) {
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Error',
                    message: 'Prefix can have maximum 3 characters and Only characters are allowed'
                })
                setValue('buPrefix', `${buPrefix.substr(0, 3)}`);
            }
        }
	}, [buPrefix_code])

	const bu_Prefix = watch("buPrefix");

	useEffect(() => {
		if(props.editData) {
			setValue('buCode', `${props.editData.code}`);
		} else {
			setValue('buCode', `${bu_Prefix}`);
		}
    }, [bu_Prefix, props.editData])

	const contact_no = watch("contact");

	useEffect(() => {
		if(contact_no && contact_no.length > 10) {
			setValue('contact', `${contact_no.substr(0, 10)}`);
		}
    }, [contact_no])

	/* State Declaration - End */

	/* Service Method Call - Start */

	// Function for API hit - BU Save
	const savebutton = (FormData) => {
		setLoading(true);
		var save = {
			code: FormData.buCode,
			name: FormData.buName,
			location: FormData.location.name,
			headQuarter: FormData.headQuarter,
			buAdminFirstName: FormData.adminFirstName,
			buAdminLastName: FormData.adminLastName,
			buAdminMail: FormData.email,
			buContact: FormData.contact,
			address: FormData.address
		}

		BusettingsService.createBu(save).then(data => {
			setLoading(false);
			let create = false;
			let buEdit = false;
			let createProject = true;
			let updateProject = false;
			props.onSuccess(create, buEdit, data, createProject, updateProject);
			return data;
		})
		navigate(`/businessUnit`);
	}


	// Function for API hit - BU Update
	const editButton = (FormData) => {
		setLoading(true);
		let edit = {
			code: FormData.buCode,
			name: FormData.buName,
			location: FormData.location.name,
			headQuarter: FormData.headQuarter,
			buAdminFirstName: FormData.adminFirstName,
			buAdminLastName: FormData.adminLastName,
			buAdminMail: FormData.email,
			buContact: FormData.contact,
			address: FormData.address
		} 

		BusettingsService.updateBu(props.editData.id, edit).then(data => {
			setLoading(false);
			let create = false;
			let buEdit = false;
			let createProject = false;
			let updateProject = true;
			props.onSuccess(create, buEdit, data, createProject, updateProject);
			return data;
		})
		navigate(`/businessUnit`);
	}

	/* Service Method Call - End */

	/* Methods - Start */

	// To Add empty row to Address section when clicking '+'
	const handleAddClick = () => {
		const item = { address: "", city: "", state: "", zipCode: "" };
		setaddressList([...addressList, item]);
	};

	// To Remove specific row data from Address section when clicking '-'
	const handleRemoveClick = (i) => {
		const list = [...addressList];
		list.splice(i, 1);
		setaddressList(list);
		const val = getValues('address')
		val.splice(i, 1)
		setValue('address', val, { shouldValidate: true })
	};

	/* Methods - End */

	/* Render Templates - Start */

	/* Render Templates - End */

	/* Render View Return - Start */


	return (
		<div>
			<ToastElement ref={toastRef} />
			<Loader loading={loading} />
			<form onSubmit={props.type === 'create' ? handleSubmit(savebutton) : handleSubmit(editButton)}>
				<div className="p-grid buCreation">
					<div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
						<p className="font-normal fs-13 text-lightgray mt-0">Business Unit Prefix <span className="text-red">*</span></p>
						<div className="p-field">
							<Controller name="buPrefix" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
								<TextboxElement id={field.name} value={field.value} placeholder="Max 3 letters" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} />
							)} />
						</div>
					</div>
					<div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
						<p className="font-normal fs-13 text-lightgray mt-0">Business Unit Code</p>
						<div className="p-field disableButton">
							<Controller name="buCode" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
								<TextboxElement id={field.name} value={field.value} placeholder="BU Code" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} disabled />
							)} />
						</div>
					</div>
					<div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
						<p className="font-normal fs-13 text-lightgray mt-0">Business Unit Name <span className="text-red">*</span></p>
						<div className="p-field">
							<Controller name="buName" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
								<TextboxElement id={field.name} value={field.value} placeholder="Business Unit Name" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} />
							)} />
						</div>
					</div>
					<div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
						<p className="font-normal fs-13 text-lightgray mt-0">Location <span className="text-red">*</span></p>
						<div className="p-field">
							<Controller name="location" control={control} rules={{ required: "test" }} render={({ field, fieldState }) => (
								<SingleSelectElement id={field.name} placeholder="Select" optionLabel="name" options={option} value={field.value} onChangeValue={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })}
								filterBy='name' />
							)} />
						</div>
					</div>
				</div>
				<div className="p-grid buCreation">
					<div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
						<p className="font-normal fs-13 text-lightgray mt-0">Admin First Name <span className="text-red">*</span></p>
						<div className="p-field">
							<Controller name="adminFirstName" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
								<TextboxElement id={field.name} value={field.value} placeholder="First Name" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} />
							)} />
						</div>
					</div>
					<div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
						<p className="font-normal fs-13 text-lightgray mt-0">Last Name <span className="text-red">*</span></p>
						<div className="p-field">
							<Controller name="adminLastName" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
								<TextboxElement id={field.name} value={field.value} placeholder="Last Name" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} />
							)} />
						</div>
					</div>
					<div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
						<p className="font-normal fs-13 text-lightgray mt-0">Email <span className="text-red">*</span></p>
						<div className="p-field">
							<Controller name="email" control={control} rules={{ required: true, pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address. E.g. example@email.com' }}} render={({ field, fieldState }) => (
								<TextboxElement id={field.name} value={field.value} placeholder="name@imitorgraphica.com" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} />
							)} />
						</div>
					</div>
					<div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
						<p className="font-normal fs-13 text-lightgray mt-0">Contact # <span className="text-red">*</span></p>
						<div className="p-field">
							<Controller name="contact" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
								<TextboxElement type='number' id={field.name} value={field.value} placeholder="Contact" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} />
							)} />
						</div>
					</div>
					<div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
						<p className="font-normal fs-13 text-lightgray mt-0">Is Headquarter</p>
						<div className="p-field">
							<Controller name="headQuarter" control={control} render={({ field }) => (
								<div className="align-center toggle-button h-45">
									<Tooltip target=".toggleButton" />
									<span data-pr-tooltip={'Click YES or NO'}
										data-pr-position="right" className="toggleButton">
									<ToggleElement
										value={field.value}
										onChange={field.onChange}
										onIcon='pi pi-check'
										offIcon='pi pi-times'
										
									/>
									</span>
								</div>
							)} />
						</div>
					</div>
				</div>
				{addressList.map((x, i) => (
					<div className="p-grid buCreation grid-pos" key={`${x}${i}`}>
						<div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
							<p className="font-normal fs-13 text-lightgray mt-0">Address <span className="text-red">*</span></p>
							<Controller name={`address.${i}.address`} defaultValue={x.address} control={control} rules={{ required: true }} render={({ field, fieldState }) => (
								<TextboxElement name={field.name} id={field.id} value={field.value} placeholder="Address" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} />
							)} />
						</div>
						<div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
							<p className="font-normal fs-13 text-lightgray mt-0">City <span className="text-red">*</span></p>
							<Controller name={`address.${i}.city`} defaultValue={x.city} control={control} rules={{ required: true }} render={({ field, fieldState }) => (
								<TextboxElement name={field.name} id={field.id} value={field.value} placeholder="City" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} />
							)} />
						</div>
						<div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
							<p className="font-normal fs-13 text-lightgray mt-0">State <span className="text-red">*</span></p>
							<Controller name={`address.${i}.state`} defaultValue={x.state} control={control} rules={{ required: true }} render={({ field, fieldState }) => (
								<TextboxElement name={field.name} id={field.id} value={field.value} placeholder="State" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} />
							)} />
						</div>
						<div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
							<p className="font-normal fs-13 text-lightgray mt-0">Zip Code <span className="text-red">*</span></p>
							<div className="d-flex bu-last-input">
								<Controller name={`address.${i}.zipCode`} defaultValue={x.zipCode} control={control} rules={{ required: true }} render={({ field, fieldState }) => (
									<TextboxElement type='number' name={field.name} id={field.id} value={field.value} placeholder="000 000" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} />
								)} />
								<div className="align-center">
									<i className="pi pi-plus bu-plus-icon" onClick={() => handleAddClick()}></i>
									{
										i ?
											<i className="pi pi-minus bu-minus-icon" onClick={() => handleRemoveClick(i)}></i>
											: null
									}
								</div>
							</div>
						</div>
					</div>
				))}
				{
					props.type === 'create' &&
					<div className="p-grid">
						<div className="p-col-12">
							<Button type="submit" icon='pi pi-arrow-right' iconPos="right" label="CREATE" className="create-button" />
						</div>
					</div>
				}
				{
					props.type === 'edit' &&
					<div className="p-grid">
						<div className="p-col-12">
							<Button type="submit" icon='pi pi-arrow-right' iconPos="right" label="UPDATE" className="create-button" />
						</div>
					</div>
				}
			</form>
		</div>
	)

	/* Render View Return - End */

}

/* Function - End */

/* Export default functionName; */

export default BusinessCreationPage;