/* Import Section - Start */

/* React Imports - Start */

import React, { useEffect, useState } from "react";

/* React Imports - End */

/* Plugin Imports - Start */

import { Calendar } from 'primereact/calendar';

/* Plugin Imports - End */

/* Import Section - End */

/* Function - Start */

const CalendarElement = (props) => {

    /* State Declaration - Start */

    /* useState - Start */

    /* useState - End */

    /* State Declaration - End */

    /* Methods - Start */

    /* Methods - End */

    /* useEffect - Start */

    /* useEffect - End */

    /* Render View Return - Start */

    return (
        <>
            <Calendar
                id={props.id}
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                view={props.view}
                dateFormat={props.dateFormat}
                onChange={props.onChange}
                showIcon={props.showIcon}
                className={props.className}
                disabled={props.disabled}
                monthNavigator={true}
                yearNavigator={true}
                yearRange="1900:2030"
                showButtonBar={props.showButtonBar}
                minDate={props.minDate}
                readOnlyInput
                label={props.label}
                inline={props.inline}
                tooltip={props.tooltip}
            />
        </>
    );

    /* Render View Return - End */

};

/* Function - End */

/* Export default functionName; */

export default CalendarElement;