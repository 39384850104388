/* Import Section - Start */

/* React Imports - Start */

import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

/* React Imports - End */

/* Plugin Imports - Start */

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Cookies from 'universal-cookie';
import _, { filter } from 'lodash';
import Moment from 'moment';
import { classNames } from 'primereact/utils';

/* Plugin Imports - End */

/* Project Components Imports - Start */

import DataTableElement from "../uiComponents/DataTableElement";
import ProjectsService from "../../services/projects/ProjectsService";
import ToastElement from "../uiComponents/ToastElement";
import EmployeesService from "../../services/employees/EmployeesService";
import Loader from "../uiComponents/Loader";
import SingleSelectElement from "../uiComponents/SingleSelectElement";
import CalendarElement from "../uiComponents/CalendarElement";
import TextboxElement from "../uiComponents/TextboxElement";
import AdminFilterElement from '../uiComponents/AdminFilters';
import ClientsService from '../../services/clients/ClientsService';

/* Project Components Imports - End */

/* Import Section - End */

/* Function - Start */

const Projects = (props) => {

    /* State Declaration - Start */

    /* useState - Start */

    const [projectsList, setProjectsList] = useState([]);
    const [button, setButton] = useState(false);
    const [datatable, setDatatable] = useState(true);
    const [editData, setEditData] = useState();
    const [currentBu, setCurrentBU] = useState("JMD2");
    const [loading, setLoading] = useState(false);
    const [buUnits, setBuUnits] = useState([]);
    const [allProjectList, setAllProjectList] = useState(false);
    const [clientName, setClientName] = useState();
    const [clientsList, setClientsList] = useState();
    const [projectName, setProjectName] = useState();
    const [status, setStatus] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [displayEditProject, setDisplayEditProject] = useState(false);
    const [displayProject, setDisplayProject] = useState(false);

    /* useState - End */

    /* navigate Declaration */

    /* useRef - Start */

    const toastRef = useRef();
    const cookies = new Cookies();
    var curBuId = currentBu.id;
    var roles = cookies.get('roles');

    /* useRef - End */

    /* useEffect - Start */

    useEffect(() => {
        createButton();
        getActiveBu();
        getProjectList();
        getClientList();
    }, [])


    useEffect(() => {
        getProjectList();
    }, [allProjectList])

    /* useEffect - End */

    /* State Declaration - End */

    const defaultValues = {
        clientName: clientName,
        projectName: projectName,
        status: status,
        start_date: startDate,
        end_date: endDate
    }

    const { control, formState: { errors }, handleSubmit, reset, watch, setValue } = useForm({ defaultValues });

    /* Methods - Start */

    //getting active bu for rendering in the select element
    const getActiveBu = () => {
        EmployeesService.getActiveBu().then(data => {
            setBuUnits(data.data)
            var bu = cookies.get('buId')
            if (bu) {
                setCurrentBU(_.find(data.data, { id: bu }));
            }
        })
    }

    const onHide = (names, message, success) => {
        dialogFuncMap[`${names}`](false);
        message && toastRef.current.showToast(message)
        if (success) {
            setDisplayProject(false);
        }
        setDatatable(true);
    }

    const dialogFuncMap = {
        'displayProject': setDisplayProject,
        'displayEditProject': setDisplayEditProject
    }

    const getProjectList = (filter) => {
        setLoading(true);
        ProjectsService.getProjects(filter).then(data => {
            setLoading(false);
            data?.data?.forEach(element => {
                element.startDate = element.startDate ? Moment(element.startDate, 'DD-MM-YYYY', true).format('YYYY-MM-DD') : '';
                element.endDate = element?.endDate ? Moment(element.endDate, 'DD-MM-YYYY', true).format('YYYY-MM-DD') : ''
            });
            setProjectsList(_.sortBy(data.data, [item => item.name.toLowerCase()]));
        })
    }

    const getProjectFilterList = (filter) => {
        setLoading(true);
        var userId = cookies.get('userId');
        ProjectsService.getProjectsFilter(filter).then(data => {
            setLoading(false);
            data.data.forEach(element => {
                element.startDate = Moment(element.startDate, 'DD-MM-YYYY', true).format('YYYY-MM-DD');
                element.endDate = element?.endDate ? Moment(element.endDate, 'DD-MM-YYYY', true).format('YYYY-MM-DD') : ''
            });
            setProjectsList(data.data);
        })
    }

    const selectedId = (selectedId) => {
        return ''
    }

    const createButton = () => {
        setButton(true);
        setDatatable(true);
    }

    const onClickAddProject = () => {
        reset();
        setDisplayProject(true);
        setDatatable(false);
    }

    //On Click Edit Project Action Icon
    const editProject = (id) => {
        editProjects(id);
    }

    const editProjects = (projectId) => {
        setLoading(true);
        ProjectsService.editProject(projectId).then(data => {
            setLoading(false);
            setEditData(data.data);
            setValue('projectName', data.data.projectInfo.name)
            setValue('clientName', data.data.projectInfo.clientId)
            setValue('status', data.data.projectInfo.status)
            setValue('start_date', data.data.projectInfo.startDate != null ? new Date(data.data.projectInfo.startDate) : '')
            setValue('end_date', data.data.projectInfo.endDate != null ? new Date(data.data.projectInfo.endDate) : '')
            setProjectName(data.data.projectInfo.name)
            setDatatable(false);
            setDisplayEditProject(true);
        })
    }

    const saveButton = (FormData) => {
        setLoading(true);
        var save = {
            clientId: FormData.clientName,
            name: FormData.projectName,
            status: FormData.status,
            startDate: FormData.start_date ? Moment(FormData.start_date).format("YYYY-MM-DD") : null,
            endDate: FormData.end_date ? Moment(FormData.end_date).format("YYYY-MM-DD") : null,
            createdBy: cookies.get("userId")
        }
        ProjectsService.postProject(save).then(data => {
            getProjectList();
            setDisplayProject(false);
            setDatatable(true);
            !data.posted.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Adding failed',
                message: data.posted.message
            })
            data.posted.success && toastRef.current.showToast({
                type: 'success',
                summary: 'Added sucessfully',
                message: data.posted.message
            })
            reset();
        }).finally(() => {
            setLoading(false);
        })
    }

    const editButton = (FormData) => {
        setLoading(true);
        var edit = {
            id: editData.projectInfo.id,
            clientId: FormData.clientName,
            name: FormData.projectName,
            status: FormData.status,
            startDate: FormData.start_date ? Moment(FormData.start_date).format("YYYY-MM-DD") : null,
            endDate: FormData.end_date ? Moment(FormData.end_date).format("YYYY-MM-DD") : null,
        }
        ProjectsService.updateProject(editData.projectInfo.id, edit).then(data => {
            getProjectList();
            setDisplayEditProject(false);
            setDatatable(true);
            !data.patched.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: data.patched.message
            })
            data.patched.success && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated sucessfully',
                message: data.patched.message
            })
            reset();
        }).finally(() => {
            setLoading(false);
        })
    }

    /* Methods - End */

    /* Render Templates - Start */

    const ProjectInfoTemplate = (rowData) => {
        return (
            <div className="d-flex">
                <ToastElement ref={toastRef} />
                <span onClick={() => editProject(rowData.id)} className="action">
                    <i className="pi pi-pencil leave-edit"></i>
                </span>
                {(rowData.status == 'Propose' && roles.includes('Resource Manager')) ?
                    <span >
                        <i class="pi pi-ban leaveApproval-cancel ml-10" onClick={() => handleDeleteClick(rowData.id)}></i>
                    </span>
                    : ''
                }
            </div>
        );
    };

    const projectFilter = (filter) => {
        let filterData = {
            status: filter.status,
            startDate: Moment(filter.from).format('YYYY-MM-DD'),
            endDate: Moment(filter.to).format('YYYY-MM-DD'),
        }
        getProjectFilterList(filterData);
    }

    //Datatable columns header and templates
    const columns = [
        { field: "name", header: "Project Name", sortable: true, style: { width: '270px' } },
        { field: "Client.clientName", header: "Client", sortable: true, style: { width: '240px' } },
        { field: "startDate", header: "Start Date", sortable: true, style: { width: '140px' }, showFilter: true },
        { field: "endDate", header: "End Date", sortable: true, style: { width: '125px' }, showFilter: true },
        { field: "status", header: "Status", sortable: true },
        { field: " ", header: "Actions", body: ProjectInfoTemplate, sortable: false }
    ];

    const getClientList = async (filter, isTotalPages) => {
        ClientsService.getClients().then(data => {
            let clientData = data.data.data;
            let clientList = [];
            clientData?.forEach(client => {
                clientList.push({
                    name: client.clientName,
                    value: client.id,
                })
            });
            setClientsList(clientList)
        })
    }

    const projectStatus = [
        { name: "To Do", value: "To Do" },
        { name: "Awaiting Approval", value: "Awaiting Approval" },
        { name: "In Progress", value: "In Progress" },
        { name: "Maintenance", value: "Maintenance" },
        { name: "On Hold", value: "On Hold" },
        { name: "Completed", value: "Completed" },
        { name: "Active", value: "Active" },
        { name: "Inactive", value: "Inactive" }
    ]


    /* Render Templates - End */

    /* Render View Return - Start */

    return (
        <div className="mt-16 project" id="Projects">
            <ToastElement ref={toastRef} />
            <Loader loading={loading} />
            {
                datatable &&
                <DataTableElement
                    getList={getProjectList}
                    value={projectsList}
                    columns={columns}
                    placeholder="Search Name, Type"
                    selectedId={selectedId}
                    paginationView={false}
                    button={button}
                    createButton={
                        <div className="d-flex mb-8 employee-buttons">
                            {
                                <Button label="Create Project" onClick={onClickAddProject} icon="pi pi-plus" />}
                        </div>
                    }
                    filterButton={
                        <div>
                            {
                                <div className="userFilter">
                                    <AdminFilterElement
                                        statusList={projectStatus}
                                        label='projectStatus'
                                        filter={projectFilter}
                                        getList={getProjectList}
                                        isProjectList={true}
                                    />
                                </div>
                            }
                        </div>
                    }
                    header={true}
                    search={true}
                />
            }
            {
                displayProject &&
                
                <Dialog className='project-form' visible={displayProject} header="Create Project" onHide={() => onHide('displayProject')} breakpoints={{ '960px': '75vw' }} draggable={false}>
                    <form onSubmit={handleSubmit(saveButton)}>
                        <div className="leave-request project-create">
                            <div className="p-grid d-flex">
                                <div className="p-col-12  p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray">Client Name <span className="text-red">*</span></p>
                                    <Controller name="clientName" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                        <SingleSelectElement id={field.name} placeholder="Select" optionLabel="name" options={clientsList} value={field.value} onChangeValue={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                    )} />
                                </div>
                                <div className="p-col-12  p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray">Project Name <span className="text-red">*</span></p>
                                    <Controller name="projectName" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                        <TextboxElement id={field.name} value={field.value} placeholder="Project Name" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                    )} />
                                </div>
                            </div>
                            <div className="p-grid d-flex">
                                <div className="p-col-12  p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray">Status <span className="text-red">*</span></p>
                                    <Controller name="status" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                        <SingleSelectElement id={field.name} placeholder="Select" optionLabel="name" options={projectStatus} value={field.value} onChangeValue={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })}
                                            filterBy='name' />
                                    )} />
                                </div>
                                <div className="p-col-12  p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray">Start Date</p>
                                    <Controller name="start_date" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                                        <CalendarElement
                                            name={field.name}
                                            placeholder='Select'
                                            value={field.value}
                                            view='date'
                                            dateFormat='dd/mm/yy'
                                            onChange={field.onChange}
                                            showIcon
                                            className={classNames({ 'p-invalid': fieldState.invalid })}
                                        />
                                    )} />
                                </div>
                            </div>
                            <div className="p-grid d-flex">
                                <div className="p-col-12  p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray">End Date</p>
                                    <Controller name="end_date" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                                        <CalendarElement
                                            name={field.name}
                                            placeholder='Select'
                                            value={field.value}
                                            view='date'
                                            dateFormat='dd/mm/yy'
                                            onChange={field.onChange}
                                            showIcon
                                            className={classNames({ 'p-invalid': fieldState.invalid })}
                                            minDate={watch('start_date')}
                                        />
                                    )} />
                                </div>
                            </div>
                        </div>
                        <div className="cancel-footer">
                            <div className='align-center justify-end pt-16 pb-8'>
                                <Button type="submit" label="ADD" className="create-button request-button" />
                            </div>
                        </div>
                    </form>
                </Dialog>
            }

            {
                displayEditProject &&
                <Dialog className='project-form' visible={displayEditProject} header="Edit Project" onHide={() => onHide('displayEditProject')} breakpoints={{ '960px': '75vw' }} draggable={false}>
                    <form onSubmit={handleSubmit(editButton)}>
                        <div className="leave-request project-create">
                            <div className="p-grid d-flex">
                                <div className="p-col-12  p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray">Client Name <span className="text-red">*</span></p>
                                    <Controller name="clientName" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                        <SingleSelectElement id={field.name} placeholder="Select" optionLabel="name" options={clientsList} value={field.value} onChangeValue={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                    )} />
                                </div>
                                <div className="p-col-12  p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray">Project Name <span className="text-red">*</span></p>
                                    <Controller name="projectName" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                        <TextboxElement id={field.name} value={field.value} placeholder="Project Name" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                    )} />
                                </div>
                            </div>
                            <div className="p-grid d-flex">
                                <div className="p-col-12  p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray">Status <span className="text-red">*</span></p>
                                    <Controller name="status" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                        <SingleSelectElement id={field.name} placeholder="Select" optionLabel="name" options={projectStatus} value={field.value} onChangeValue={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })}
                                            filterBy='name' />
                                    )} />
                                </div>
                                <div className="p-col-12  p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray">Start Date</p>
                                    <Controller name="start_date" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                                        <CalendarElement
                                            name={field.name}
                                            placeholder='Select'
                                            value={field.value}
                                            view='date'
                                            dateFormat='dd/mm/yy'
                                            onChange={field.onChange}
                                            showIcon
                                            className={classNames({ 'p-invalid': fieldState.invalid })}
                                        />
                                    )} />
                                </div>
                            </div>
                            <div className="p-grid d-flex">
                                <div className="p-col-12  p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray">End Date</p>
                                    <Controller name="end_date" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                                        <CalendarElement
                                            name={field.name}
                                            placeholder='Select'
                                            value={field.value || undefined}
                                            view='date'
                                            dateFormat='dd/mm/yy'
                                            onChange={field.onChange}
                                            showIcon
                                            className={classNames({ 'p-invalid': fieldState.invalid })}
                                            minDate={watch('start_date')}
                                        />
                                    )} />
                                </div>
                            </div>
                        </div>
                        <div className="cancel-footer">
                            <div className='align-center justify-end pt-16 pb-8'>
                                <Button type="submit" label="UPDATE" className="create-button request-button" />
                            </div>
                        </div>
                    </form>
                </Dialog>
            }
        </div>
    )

    /* Render View Return - End */

}

/* Function - End */

/* Export default functionName; */

export default Projects;