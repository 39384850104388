/* Import Section - Start */

/* React Imports - Start */

import React, { useState, useEffect, useRef } from 'react';

/* React Imports - End */

/* Plugin Imports - Start */

import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

/* Plugin Imports - End */

/* Project Components Imports - Start */

import CheckboxElement from '../uiComponents/CheckboxElement';
// import "../../App.css";
import Cookies from 'universal-cookie';
import ReferenceDataService from '../../services/controlPanel/ReferenceDataService';
import ServiceDeskService from '../../services/serviceDesk/ServiceDeskService';
import TextboxElement from "../uiComponents/TextboxElement";
import ToastElement from "../uiComponents/ToastElement";
import SingleSelectElement from '../uiComponents/SingleSelectElement';
import DataTableElement from "../uiComponents/DataTableElement";
import Loader from '../uiComponents/Loader';
import RolesService from '../../services/controlPanel/RolesService';
import _ from 'lodash';

/* Project Components Imports - End */

/* Import Section - End */

/* Function - Start */

const ServiceDeskConfig = (props) => {

    /* State Declaration - Start */

    /* useState - Start */
    const [displayJobTrack, setDisplayJobTrack] = useState(false);
    const [displayJobStatus, setDisplayJobStatus] = useState(false);
    const [displayJobType, setDisplayJobType] = useState(false);
    const [editJobTrack, setEditJobTrack] = useState(false);
    const [editJobStatus, setEditJobStatus] = useState(false);
    const [editJobType, setEditJobType] = useState(false)
    const [success, setSuccess] = useState('');
    const [name, setName] = useState('');
    const [jobType, setJobType] =useState('');
    const [priorityList, setPriorityList] = useState();
    const [buUnit, setBuUnit] = useState([]);
    const [currentBu, setCurrentBU] = useState();
    const [buId, setBuId] = useState()
    const [jobData, setjobData] = useState([]);
    const [jobPriorityId, setJobPriorityId] = useState()
    const [jobStatusId, setJobStatusId] = useState();
    const [jobTypeId, setJobTypeId] = useState();
    const [roles, setRoles] = useState([]);
    const [jobStatus, setJobStatus] = useState('');
    const [jobStatusList, setJobStatusList] = useState([]);
    const [jobStatusIndex, setJobStatusIndex] = useState();
    const [jobTypesList, setJobTypeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [button, setButton] = useState(false);
    const [qualityMetricsList, setQualityMetricsList] = useState([]);
    const [displayQualityMetric, setDisplayQualityMetric] = useState(false);
    const [editQualityMetric, setIsEditQualityMetric] = useState(false);
    const [errorType, setErrorType] = useState('');
    const [metricPoints, setMetricPoints] = useState('');
    const [qualityMetricId, setQualityMetricId] = useState('');
    const [color, setColor] = useState(null);

    const cookies = new Cookies();

    /* useState - End */

    /* useRef - Start */

    const toastRef = useRef();

    /* useRef - End */

    /* useEffect - Start */

    useEffect(() => {
        createButton();
        getActiveBu();
    }, [success])

    /* useEffect - End */

    /* State Declaration - End */

    /* Static JSON - Start */
    const PriorityList = [
        { name: 'Arrow_upward', className: "pi pi-arrow-up", value: 'arrow_upward' },
        { name: 'Arrow_downward', className: "pi pi-arrow-down", value: 'arrow_downward' },
        { name: 'Vertical_align_center', className: "pi pi-arrows-v", value: 'Vertical_align_center' },
    ];

    /* Static JSON - End */

    /* Methods - Start */
    const dialogFuncMap = {
        "displayJobTrack": setDisplayJobTrack,
        "editJobTrack": setEditJobTrack,
        "displayJobStatus": setDisplayJobStatus,
        "displayJobType": setDisplayJobType,
        "editJobType": setEditJobType,
        "displayQualityMetric": setDisplayQualityMetric,
    }

    const onHide = (name, message, success) => {
        if (name) {
            dialogFuncMap[`${name}`](false);
            setJobStatus('');
            setJobStatusIndex('');
            setColor('');
            setErrorType('');
            setMetricPoints('');
        }
        message && toastRef.current.showToast(message)
        if (success) {
            setSuccess(success);
        }
    }

    const createButton = () => {
        setButton(true);
    }

    const getSelectedId = (id) => {
        return ''
    }

    //getting active bu for rendering in the select element
    const getActiveBu = () => {
        ReferenceDataService.getActiveBu().then(data => {
            setBuUnit(data.data)
            var bu = cookies.get('buId')
            if (bu) {
                setCurrentBU(_.find(data.data, { id: bu }))
                getJobPriorityData(bu);
                getJobStatus(bu);
                getRoles(bu);
                getJobTypes(bu);
                getQualityMetrics(bu);
            }
        })
    }

    const buChange = (e) => {
        setBuId(e.value.id);
        getJobPriorityData(e.value.id);
        getJobStatus(e.value.id);
        setCurrentBU(e.value);
        getRoles(e.value.id);
        getJobTypes(e.value.id);
        getQualityMetrics(e.value.id);
    }

    //getting JobPriority Data
    const getJobPriorityData = (bu) => {
        // setLoading(true);
        ReferenceDataService.getJobPriorityData(bu).then(data => {
            // setLoading(false);
            jobData.sort(function (a, b) {
                var a1 = a.priority.toLowerCase();
                var b1 = b.priority.toLowerCase();
                return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
            });
            setjobData(data.priorityBuId);
        })
    }

    const getJobStatus = (bu) => {
        // setLoading(true);
        ServiceDeskService.getJobStatus(bu).then(data => {
            // setLoading(false);
            setJobStatusList(data);
        })
    }

    const getJobTypes = (bu) => {
        // setLoading(true);
        ServiceDeskService.getJobTypes(bu).then((data => {
            // setLoading(false);
            setJobTypeList(data.jobTypes)
        }))
    }

    const getQualityMetrics = (bu) => {
        // setLoading(true);
        ServiceDeskService.getQualityMetrics(bu).then((data => {
            // setLoading(false);
            setQualityMetricsList(data.qualityMetrics);
        }))
    }
    
    // getting roles data
    const getRoles = (buId) => {
        // setLoading(true);
        RolesService.getRoles(buId).then(data => {
            // setLoading(false);
            let rolesData = data.data;
            var filteredData = [];
            rolesData.map(data => {
                if (data.isActive == true) {
                    filteredData.push(data);
                }
            })
            setRoles(filteredData);
        })
    }

    const onClickAddJobStatus = () => {
        setDisplayJobStatus(true);
        setEditJobStatus(false);
    }

    const onClickDisplayJobTrack = () => {
        setDisplayJobTrack(true);
        setName(null);
        setPriorityList(null);
    }

    const onClickAddJobType = () => {
        setDisplayJobType(true);
        setJobType(null);
    }

    const onClickAddQualityMetrics = () => {
        setDisplayQualityMetric(true);
        setIsEditQualityMetric(false);
    }

    const onClickEditJobTrack = (name, id) => {
        ReferenceDataService.editJobPriorityData(id).then(data => {
            setName(data.data.priority)
            setPriorityList(data.data.icon)
            setJobPriorityId(id)
            dialogFuncMap[`${name}`](true);
        })
        dialogFuncMap[`${name}`](true);
    }

    const onClickEditJobType = (name, id) => {
        ServiceDeskService.editJobTypeData(id).then(data => {
            setJobType(data.jobType);
            setJobTypeId(id);
            dialogFuncMap[`${name}`](true);
        })
        dialogFuncMap[`${name}`](true);
    }

    // editing JobStatus List
    const onClickEditJobStatus = (id) => {
        // setLoading(true);
        ServiceDeskService.editJobStatusData(id).then(data => {
            // setLoading(false);
            setJobStatus(data.status);
            setJobStatusIndex(data.statusIndex);
            setColor(data.badgeColor);
            setJobStatusId(id);
            setDisplayJobStatus(true);
            setEditJobStatus(true);
        })
    }

    //add jobPriority 
    const jobPriorityHandler = () => {
        // setLoading(true);
        let currBu = currentBu.id;
        const data = {
            priority: name,
            icon: priorityList,
            buId: buId ? buId : currBu,
            isActive: true
        }
        if (data.priority != null && data.icon != null) {
            ReferenceDataService.addJobPriority(data).then(data => {
                // setLoading(false);
                let message = {
                    type: data.success ? 'success' : 'error',
                    summary: data.success ? 'Success' : 'Something went wrong',
                    message: data.message
                }
                let success = data.success ? true : false
                getJobPriorityData(currentBu.id);
                if (data.success) { 
                    onHide('displayJobTrack', message, success);
                } else {
                    toastRef.current.showToast({
                        type: 'error',
                        summary: 'Warning',
                        message: data.message
                    })
                }
            })
        }
        else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Job Priority',
                message: 'Fill the mandatory fields'
            })
        }
    }


    //Update JobPriority
    const jobPriorityUpdateHandler = (jobPriorityId) => {
        // setLoading(true);
        var id = jobPriorityId
        const data = {
            priority: name,
            icon: priorityList,
            buId: buId,
            isActive: true
        }
        if (data.priority != '' && data.icon != '') {
            ReferenceDataService.updateJobPriority(data, id).then(data => {
                // setLoading(false);
                !data.success && toastRef.current.showToast({
                    type: 'error',
                    summary: 'Update failed',
                    message: data.message
                })
                data.success && toastRef.current.showToast({
                    type: 'success',
                    summary: 'Updated sucessfully',
                    message: data.message
                })
                if (data.success) { 
                    onHide('editJobTrack');
                } else {
                    toastRef.current.showToast({
                        type: 'error',
                        summary: 'Warning',
                        message: data.message
                    })
                }
                getJobPriorityData(currentBu.id);
            })
        }
        else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Job Priority',
                message: 'Fill the mandatory fields'
            })
        }
    }
    
    // add job type
    const jobTypeHandler = () => {
        // setLoading(true);
        let currBu = currentBu.id;
        const data = {
            jobType: jobType,
            buId: buId ? buId : currBu,
        }
        if(data.jobType != null) {
            // setLoading(true);
            ServiceDeskService.addJobType(data).then(data => {
                let message = {
                    type: data.success ? 'success' : 'error',
                    summary: data.success ? 'Success' : 'Something went wrong',
                    message: data.message
                }
                let success = data.success ? true : false
                getJobTypes(currentBu.id);
                if (data.success) { 
                    onHide('displayJobType');
                } else {
                    toastRef.current.showToast({
                        type: 'error',
                        summary: 'Warning',
                        message: data.message
                    })
                }
            })
        }
        else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Job Type',
                message: 'Fill the mandatory fields'
            })
        }
    }

    //update job type
    const jobTypeUpdateHandler = (jobTypeId) => {
        // setLoading(true);
        let id = jobTypeId;
        const data = {
            jobType: jobType,
            buId: buId ? buId : currentBu.id,
        }
        if(data.jobType != '') {
            ServiceDeskService.updateJobType(data, id).then(data => {
                // setLoading(false);
                !data.success && toastRef.current.showToast({
                    type: 'error',
                    summary: 'Update failed',
                    message: data.message
                })
                data.success && toastRef.current.showToast({
                    type: 'success',
                    summary: 'Updated sucessfully',
                    message: data.message
                })
                if (data.success) { 
                    onHide('editJobType');
                } else {
                    toastRef.current.showToast({
                        type: 'error',
                        summary: 'Warning',
                        message: data.message
                    })
                }
                getJobTypes(currentBu.id);
            })
        }
    }

    // Update job type status
    const checkboxJobTypeValueEdit = (e, dataId) => {
        let id = dataId;

        const data = {
            buId: buId ? buId : currentBu.id,
            isActive: e.checked
        }
        ServiceDeskService.updateJobTypeStatus(data, id).then(data => {
            !data.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: data.message
            })
            data.success && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated sucessfully',
                message: data.message
            })
            getJobTypes(currentBu.id)
        })
    }

    // Add quality metric
    const qualityMetricAddHandler = () => {
        let data = {
            buId: currentBu.id,
            errorType: errorType,
            metricPoints: metricPoints
        }
        if(data.errorType != '' && data.errorType != null && data.metricPoints != '' && data.metricPoints != ''){
            // setLoading(true);
            ServiceDeskService.addQualityMetric(data).then(data => {
                // setLoading(false);
                let message = {
                    type: data.success ? 'success' : 'error',
                    summary: data.success ? 'Success' : 'Something went wrong',
                    message: data.message
                }
                let success = data.success ? true : false;
                onHide('displayQualityMetric', message, success);
            })
        }
        else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Quality Metric Type',
                message: 'Fill the mandatory fields'
            })
        }
    }

    // Update quality metric
    const qualityMetricUpdateHandler = (id) => {
        // setLoading(true);
        let data = {
            buId: currentBu.id,
            errorType: errorType,
            metricPoints: metricPoints
        }
        if(data.errorType != '' && data.errorType != null && data.metricPoints != '' && data.metricPoints != ''){
            ServiceDeskService.updateQualityMetric(data,id).then(data => {
                // setLoading(false);
                let message = {
                    type: data.success ? 'success' : 'error',
                    summary: data.success ? 'Success' : 'Something went wrong',
                    message: data.message
                }
                let success = data.success ? true : false;
                onHide('displayQualityMetric', message, success);
            })
        }
        else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Quality Metric Type',
                message: 'Fill the mandatory fields'
            })
        }
    }

    //add jobStatus 
    const jobStatusHandler = () => {
        // setLoading(true);
        let currBu = currentBu.id;
        const data = {
            status: jobStatus,
            statusIndex: jobStatusIndex,
            badgeColor: color,
            buId: buId ? buId : currBu,
            isActive: true
        }
        if (data.status != null && data.status != '' && data.statusIndex !== null && data.statusIndex !== '' && data.badgeColor !== null && data.badgeColor !== '') {
            ServiceDeskService.addJobStatus(data).then(data => {
                // setLoading(false);
                let message = {
                    type: data.success ? 'success' : 'error',
                    summary: data.success ? 'Success' : 'Something went wrong',
                    message: data.message
                }
                let success = data.success ? true : false
                onHide('displayJobStatus', message, success);
            })
        }
        else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Job Status Priority',
                message: 'Fill the mandatory fields'
            })
        }
    }

    // Update JobStatus
    const jobStatusUpdateHandler = (jobStatusId) => {
        // setLoading(true);
        var id = jobStatusId
        let currBu = currentBu.id;
        const data = {
            status: jobStatus,
            statusIndex: jobStatusIndex,
            badgeColor: color,
            buId: buId ? buId : currBu,
        }
        if (data.status != '' && data.status != null && data.statusIndex !== null && data.statusIndex !== '' && data.badgeColor !== null && data.badgeColor !== '') {
            ServiceDeskService.updateJobStatus(id, data).then(data => {
                // setLoading(false);
                let message = {
                    type: data.success ? 'success' : 'error',
                    summary: data.success ? 'Success' : 'Something went wrong',
                    message: data.message
                }
                let success = data.success ? true : false;
                onHide('displayJobStatus', message, success);
                
            })
        }
        else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Job Status',
                message: 'Fill the mandatory fields'
            })
        }
    }

    //update JobStatus values
    const checkJobStatus = (e, dataId) => {
        // setLoading(true);
        let currBu = currentBu.id;
        const data = {
            // buId: buId ? buId : currBu,
            isActive: e.checked
        }
        ServiceDeskService.updateJobStatus(dataId, data).then(data => {
            // setLoading(false);
            !data.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: data.message
            })
            data.success && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated sucessfully',
                message: data.message
            })
            getJobStatus(currentBu.id)
        })
    }

    const checkJobStatusStopWatch = (e, dataId) => {
        // setLoading(true);
        const data = {
            isStopwatch: e.checked
        }
        ServiceDeskService.updateJobStatus(dataId, data).then(data => {
            // setLoading(false);
            !data.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: data.message
            })
            data.success && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated sucessfully',
                message: data.message
            })
            getJobStatus(currentBu.id)
        })
    }

    const checkJobStatusAutoStart = (e, dataId) => {
        // setLoading(true);
        const data = {
            isAutostart: e.checked
        }
        ServiceDeskService.updateJobStatus(dataId, data).then(data => {
            // setLoading(false);
            !data.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: data.message
            })
            data.success && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated sucessfully',
                message: data.message
            })
            getJobStatus(currentBu.id)
        })
    }

    /* Methods - End */

    /* Render Templates - Start */

    const renderJobPriority = () => {
        return (
            <div className="cancel-footer">
                <div className='align-center justify-end pb-0'>
                    <Button type="submit" label="ADD" className="create-button request-button" onClick={() => jobPriorityHandler()} />
                </div>
            </div>
        );
    }

    const renderUpdateJobPriority = () => {
        return (
            <div className="cancel-footer">
                <div className='align-center justify-end pb-0'>
                    <Button type="submit" label="UPDATE" className="create-button request-button" onClick={() => jobPriorityUpdateHandler(jobPriorityId)} />
                </div>
            </div>
        );
    }

    const renderJobType = () => {
        return (
            <div className='cancel-footer'>
                <div className='align-center justify-end pb-0'>
                    <Button type='submit' label='ADD' className='create-button request-button' onClick={() => jobTypeHandler()}></Button>
                </div>
            </div>
        )
    }

    const renderUpdateJobType = () => {
        return (
            <div className='cancel-footer'>
                <div className='align-center justify-end pb-0'>
                    <Button type="submit" label="UPDATE" className="create-button request-button" onClick={() => jobTypeUpdateHandler(jobTypeId)}></Button>
                </div>
            </div>
        )
    }

    const renderQualityMetric = () => {
        return (
            <div className="cancel-footer">
            <div className='align-center justify-end pb-0'>
                {
                    editQualityMetric ?
                    <Button type="submit" label="UPDATE" className="create-button request-button" onClick={() => qualityMetricUpdateHandler(qualityMetricId)} /> :
                    <Button type="submit" label="ADD" className="create-button request-button" onClick={() => qualityMetricAddHandler()} />
                }

            </div>
        </div>
        );
    }

    const checkQualityMetrics = (e, dataId) => {
        // setLoading(true);
        const data = {
            id: dataId,
            buId: currentBu.id,
            isActive: e.checked,
        }
        ServiceDeskService.updateQualityMetricStatus(dataId, data).then(data => {
            // setLoading(false);
            !data.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: data.message
            })
            data.success && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated sucessfully',
                message: data.message
            })
        })
        getQualityMetrics(currentBu.id);
    }

    const onClickEditQualityMetric = (id) => {
        // setLoading(true);
        ServiceDeskService.editQualityMetic(id).then(data => {
            // setLoading(false);
            setErrorType(data.type);
            setMetricPoints(data.weightage);
            setDisplayQualityMetric(true);
            setIsEditQualityMetric(true);
            setQualityMetricId(data.id);
        })
    }

    const metricsStatusTemplate = (rowData) => {
        return (
            <div>
                {
                    <div className="flex-items relative employee-is-active">
                        <CheckboxElement
                            key={rowData.id}
                            inputId={rowData.id}
                            checked={rowData.isActive}
                            onChangeCheck={(e, dataId) => checkQualityMetrics(e, dataId)} />
                    </div>
                }
            </div>
        )
    }

    const metricsActionsTemplate = (rowData) => {
        return (
            <span onClick={() => onClickEditQualityMetric(rowData.id)} className="action">
                <i className="pi pi-pencil leave-edit"></i>
            </span>
        )
    }

    const qualityMetricColumns = [
        { field: "isActive", body: metricsStatusTemplate, sortable: false },
        { field: "type", header: "Category", sortable: false },
        { field: "weightage", header: "Points", sortable: false},
        { field: "action", header: "Actions", body: metricsActionsTemplate, sortable: false },
    ];

    const jobStatusStatusTemplate = (rowData) => {
        return (
            <div>
                {
                    <div className='flex-items relative employee-is-active'>
                        <CheckboxElement 
                            key={rowData.id}
                            inputId={rowData.id}
                            checked={rowData.isActive}
                            onChangeCheck={(e, dataId) => checkJobStatus(e, dataId)}
                        />
                    </div>
                }
            </div>
        )
    }

    const jobStatusStopwatchTemplate = (rowData) => {
        return (
            <div>
                {
                    <div className='flex-items relative employee-is-lock'>
                        <CheckboxElement 
                            key={rowData.id}
                            inputId={rowData.id}
                            checked={rowData.isStopwatch}
                            onChangeCheck={(e, dataId) => checkJobStatusStopWatch(e, dataId)}
                        />
                    </div>
                }
            </div>
        )
    }

    const jobStatusAutostartTemplate = (rowData) => {
        return (
            <div>
                {
                    <div className='flex-items relative employee-is-lock'>
                        <CheckboxElement 
                            key={rowData.id}
                            inputId={rowData.id}
                            checked={rowData.isAutostart}
                            onChangeCheck={(e, dataId) => checkJobStatusAutoStart(e, dataId)}
                        />
                    </div>
                }
            </div>
        )
    }

    const jobStatusActionsTemplate = (rowData) => {
        return (
            <span onClick={() => onClickEditJobStatus(rowData.id)} className="action">
                <i className="pi pi-pencil leave-edit"></i>
            </span>
        )
    }

    const jobStatusColumns = [
        { field: "isActive", body: jobStatusStatusTemplate, sortable: false },
        { field: "statusIndex", header: "Order", sortable: false},
        { field: "status", header: "Status", sortable: false},
        { field: "isStopwatch", header: "Stopwatch", body: jobStatusStopwatchTemplate, sortable: false},
        { field: "isAutostart", header: "Auto Start", body: jobStatusAutostartTemplate, sortable: false},
        { field: "action", header: "Actions", body: jobStatusActionsTemplate, sortable: false }
    ]

    const renderJobStatus = () => {
        return (
            <div className="cancel-footer">
                <div className='align-center justify-end pb-0'>
                    {   editJobStatus ?
                        <Button type="submit" label="UPDATE" className="create-button request-button" onClick={() => jobStatusUpdateHandler(jobStatusId)} /> :
                        <Button type="submit" label="ADD" className="create-button request-button" onClick={() => jobStatusHandler()} />
                    }
                </div>
            </div>
        );
    }

    const onChangePriorityList = (e) => {
        setPriorityList(e.target.value)
    }

    /* Render Templates - End */

    /* Render View Return - Start */

    return (
        <div className="mt-20 reference-data">
            <ToastElement ref={toastRef} />
            <Loader loading={loading} />
            <div className="jobPriority d-flex employee-buttons bu-dropdown">
                <SingleSelectElement
                    placeholder="Select BU"
                    value={currentBu}
                    options={buUnit}
                    optionLabel="name"
                    onChangeValue={(e) => buChange(e)}
                    defaultValue="code"
                    filterBy='name'
                />
            </div>
            <div>
                <div className="card">
                    <Accordion className='accordian' activeIndex={[0]}>
                        <AccordionTab header="Job Status" className='servicedeskAccordian'>
                            <div className="employee-div">
                                <div className='mt-13 employeeTable'>
                                    <DataTableElement
                                        value={jobStatusList}
                                        columns={jobStatusColumns}
                                        button={button}
                                        createButton={
                                            <div>
                                                <Button label='Add Job Status' onClick={() => onClickAddJobStatus('displayJobStatus')} icon="pi pi-plus" />
                                            </div>
                                        }
                                        selectedId={getSelectedId}
                                        header={true}
                                    />
                                </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header="Job Types" className='servicedeskAccordian'>
                            <div className='addButton'>
                                <Button label='Add Job Type' onClick={() => onClickAddJobType('displayJobType')} icon="pi pi-plus"></Button>
                            </div>
                            <div className='reference-div referenceData'>
                                {
                                    jobTypesList.length > 0 && jobTypesList.map((data) => (
                                        <div className="jobCategory_flex-items">
                                            <div className='edit-items relative'>
                                                <div className='checkbox-align'>
                                                    <CheckboxElement
                                                        key={data.id}
                                                        inputId={data.id}
                                                        value={data.jobType}
                                                        checked={data.isActive}
                                                        onChangeCheck={(e, dataId) => checkboxJobTypeValueEdit(e, dataId)}
                                                    />
                                                </div>
                                                <div>
                                                    <span onClick={() => onClickEditJobType('editJobType', data.id)}><i className='pi pi-pencil job-edit'></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </AccordionTab>
                        <AccordionTab header="Job Priority" className='servicedeskAccordian'>
                            <div className="addButton">
                                <Button label="Add Job Priority" onClick={() => onClickDisplayJobTrack('displayJobTrack')} icon="pi pi-plus"></Button>
                            </div>
                            <div className='jobPriority'>
                                <div className="reference-div referenceData">
                                    {
                                        jobData.map((data) => (
                                            <div className='edit-items relative'>
                                                <span onClick={() => onClickEditJobTrack('editJobTrack', data.id)}><i className="pi pi-pencil job-edit"></i></span>
                                                <div className="flex-items grade-align">
                                                    {data.priority}
                                                    <span className="gradeitems-align"> {data.icon == "arrow_upward" ? <i className="pi pi-arrow-up"></i> : data.icon == "arrow_downward" ? <i className="pi pi-arrow-down"></i> : <i className="pi pi-arrows-v"></i>} </span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header="Quality Metrics" className='employeeAccordian'>
                            <div className="employee-div">
                                <div className='mt-13 employeeTable'>
                                    <DataTableElement
                                        value={qualityMetricsList}
                                        columns={qualityMetricColumns}
                                        button={button}
                                        createButton={
                                            <div>
                                                <Button label="Add Quality Metrics" onClick={() => onClickAddQualityMetrics('displayQualityMetric')} icon="pi pi-plus" />
                                            </div>
                                        }
                                        selectedId={getSelectedId}
                                        placeholder="Search"
                                        header={true}
                                        search={true}
                                    />
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>
                {
                    displayJobTrack &&
                    <Dialog visible={displayJobTrack} header="Job Priority" onHide={() => onHide('displayJobTrack')} footer={renderJobPriority()} breakpoints={{ '960px': '75vw' }} draggable={false}>
                        <div className="cancel-request">
                            <div className="p-grid d-flex">
                                <div className="p-col-12  p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray mt-0">Name<span className="text-red">*</span></p>
                                    <TextboxElement label="Service Desk" value={name} placeholder="Name" onChangeText={(e) => setName(e.target.value)}></TextboxElement>
                                </div>
                                <div className="p-col-12  p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray mt-0 ">Icon for Priority<span className="text-red">*</span></p>
                                    <SingleSelectElement className="arrow" name="cancel_desc" placeholder="Select" options={PriorityList} value={priorityList} onChangeValue={(e) => onChangePriorityList(e)} optionLabel="name"
                                        filterBy='name' />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                }
                {
                    editJobTrack &&
                    <Dialog visible={editJobTrack} header="Job Priority" onHide={() => onHide('editJobTrack')} footer={renderUpdateJobPriority()} breakpoints={{ '960px': '75vw' }} draggable={false}>
                        <div className="cancel-request">
                            <div className="p-grid d-flex">
                                <div className="p-col-12  p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray mt-0">Name<span className="text-red">*</span></p>
                                    <TextboxElement label="Service Desk" value={name} placeholder="Name" onChangeText={(e) => setName(e.target.value)}></TextboxElement>
                                </div>
                                <div className="p-col-12 p-md-6 p-sm-6 icon-job">
                                    <p className="font-normal fs-13 text-lightgray mt-0 ">Icon for Priority<span className="text-red">*</span></p>
                                    <SingleSelectElement className="arrow" name="cancel_desc" placeholder="Select" options={PriorityList} value={priorityList} onChangeValue={(e) => onChangePriorityList(e)} optionLabel="name"
                                        filterBy='name' />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                }
                {
                    displayJobType &&
                    <Dialog visible={displayJobType} header="Job Type" onHide={() => onHide('displayJobType')} footer={renderJobType()} breakpoints={{ '960px': '75vw' }} draggable={false}>
                        <div className="cancel-request">
                            <div className="p-grid d-flex">
                                <div className="p-col-12">
                                    <p className="font-normal fs-13 text-lightgray mt-0">Job Type<span className="text-red">*</span></p>
                                    <TextboxElement value={jobType} placeholder="Job Type" onChangeText={(e) => setJobType(e.target.value)}></TextboxElement>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                }
                {
                    editJobType &&
                    <Dialog visible={editJobType} header="Job Type" onHide={() => onHide('editJobType')} footer={renderUpdateJobType()} breakpoints={{ '960px': '75vw' }} draggable={false}>
                        <div className="cancel-request">
                            <div className="p-grid d-flex">
                                <div className="p-col-12">
                                    <p className="font-normal fs-13 text-lightgray mt-0">Job Type<span className="text-red">*</span></p>
                                    <TextboxElement value={jobType} placeholder="Job Type" onChangeText={(e) => setJobType(e.target.value)}></TextboxElement>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                }
                {
                    displayJobStatus &&
                    <Dialog visible={displayJobStatus} header={ editJobStatus ? "Edit Job Status" : "Add Job Status"} onHide={() => onHide('displayJobStatus')} footer={renderJobStatus('displayJobStatus')} breakpoints={{ '960px': '75vw' }} draggable={false}>
                        <div className="cancel-request">
                            <div className="align-center p-grid">
                                <div className="p-col-12  p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray mt-0">Job Status <span className="text-red">*</span></p>
                                    <TextboxElement label="Job Status" value={jobStatus} placeholder="Job Status" onChangeText={(e) => setJobStatus(e.target.value)}></TextboxElement>
                                </div>
                                <div className="p-col-12  p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray mt-0">Order <span className="text-red">*</span></p>
                                    <TextboxElement label="Job Status"  value={jobStatusIndex} placeholder="Job Status Index" onChangeText={(e) => setJobStatusIndex(e.target.value)}></TextboxElement>
                                </div>
                                <div className="p-col-12  p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray mt-0">Color for Priority <span className="text-red">*</span></p>
                                    <input type="color" value={color} onChange={e => setColor(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                }
                {
                    displayQualityMetric &&
                    <Dialog visible={displayQualityMetric} header={ editQualityMetric ? "Edit Quality Metric" : "Add Quality Metric"} onHide={() => onHide('displayQualityMetric')} footer={renderQualityMetric('displayQualityMetric')} breakpoints={{ '960px': '75vw' }} draggable={false}>
                        <div className="cancel-request">
                            <div className="p-grid d-flex">
                                <div className="p-col-12 p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray mt-0">Error Type<span className="text-red">*</span></p>
                                    <TextboxElement label="Error Type" value={errorType} placeholder="Error Type" onChangeText={(e) => setErrorType(e.target.value)}></TextboxElement>
                                </div>
                                <div className="p-col-12 p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray mt-0">Points<span className="text-red">*</span></p>
                                    <TextboxElement label="Points" value={metricPoints} placeholder="Points" onChangeText={(e) => setMetricPoints(e.target.value)}></TextboxElement>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                }
            </div>
        </div>
    )
}

/* Render View Return - End */

/* Function - End */

export default ServiceDeskConfig;

/* Export default functionName; */