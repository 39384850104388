import React, { useEffect, useState } from "react";
import Moment from "moment";
import { Accordion, AccordionTab } from 'primereact/accordion';
import SingleSelectElement from "../uiComponents/SingleSelectElement";
import MultiSelectElement from '../uiComponents/MultiSelectElement';
import TrackingSystemService from '../../services/trackingSystem/TrackingSystemService';
import ClientsService from '../../services/clients/ClientsService';
import EmployeesService from "../../services/employees/EmployeesService";
import { Button } from 'primereact/button';
import AdminFilterElement from '../uiComponents/AdminFilters';
import CheckboxElement from '../uiComponents/CheckboxElement';
import RadioButton from '../uiComponents/RadioElement';
import RadioElement from "../uiComponents/RadioElement";
import DataTableElement from "../uiComponents/DataTableElement";
import Loader from "../uiComponents/Loader";
import Cookies from "universal-cookie";
import XLSX from 'xlsx'; // Import the xlsx library
import { saveAs } from '../../assets/js/FileSaver'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import drilldown from 'highcharts/modules/drilldown'

const Reports = (props) => {

    drilldown(Highcharts);

    const [buUnit, setBuUnit] = useState([]);
    const [currentBu, setCurrentBU] = useState();
    const [clientsList, setClientsList] = useState([]);
    const [clientId, setClientId] = useState([]);
    const [parentLinks, setParentLinks] = useState([]);
    const [selectedJobIds, setSelectedJobIds] = useState([]);
    const [user, setUser] = useState(false);
    const [radio, setRadio] = useState('Invoice');
    const [loading, setLoading] = useState(false);
    const [buId, setbuId] = useState();
    const [isGlobalUser, setisGlobalUsesr] = useState(false);
    const [isGlobalpost, setisGlobalpost] = useState(true);
    const [jobids, setjobids] = useState([]);
    const [jobnames, setjobnames] = useState([]);
    const [allBuUsers, setallBuUsers] = useState();
    const [selectedUsers, setselectedUsers] = useState([]);
    const [reportsData, setreportsData] = useState();
    var defaultTitleXAxis = 'Client';
    var defaultTitleYAxis = 'Total number of jobs';
    var drilldownTitleXAxis = 'Job Numbers';
    var drilldownTitleYAxis = 'Time spent for job (hrs)';
    const [userChartOptions, setUserChartOptions] = useState({
        chart: {
            type: 'column',
            events: {
                drilldown: function (e) {
                    this.xAxis[0].setTitle({ text: drilldownTitleXAxis });
                    this.yAxis[0].setTitle({ text: drilldownTitleYAxis });
                },
                drillup: function (e) {
                    this.xAxis[0].setTitle({ text: defaultTitleXAxis });
                    this.yAxis[0].setTitle({ text: defaultTitleYAxis });
                }
            },
        },
        colors: ['#FF6969', '#E7ACF1', '#C00000', '#581845  ', '#FEBF00', '#0055ab', '#006ddc', '#007af5', '#3294f7', '#66aff9', '#99c9fb'],
        title: {
            text: 'Job Report'
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            type: 'category',
            title: {
                text: 'Client'
            }
        },
        yAxis: {
            title: {
                text: 'Total number of jobs'
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:f}'
                }
            }
        },

        tooltip: {
            // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            // pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:f}</b><br/>',
            formatter: function () {
                var point = this.point,
                    series = this.series,
                    s = '<span style="font-size:11px">' + series.name + '</span><br> <span style="color:' + point.color + '">' + point.name + '</span>: <b>' + ((point.y) ? point.y : 0) + '</b>';
                if (point.drilldown) {
                    s += ' Total number of jobs<br/>';
                } else {
                    s += ' Time spent for job (hrs)<br/>';
                }
                return s;
            }
        },

        series: [{
            name: 'Client',
            colorByPoint: true,
            data: []
        }],
        drilldown: {
            series: []
        }
    })

    const cookies = new Cookies();
    const userId = cookies.get('userId')

    useEffect(() => {
        getActiveBu();
        getUserDetail();
        getAllUsers()
        getClientList();
        onChangeOption();
    }, []);

    useEffect(() => {
        if (clientId.length > 0) {
            getParentLink(clientId);
        }
    }, [clientId]);

    const getActiveBu = () => {
        setLoading(true);
        TrackingSystemService.getActiveBu().then(data => {
            setLoading(false);
            setBuUnit(data)
        })
    };

    const getUserDetail = () => {
        const userId = cookies.get('userId')
        TrackingSystemService.getUserDetails(userId).then(data => {
            setbuId(data.business_unit.id)
            setisGlobalUsesr(data.isGlobalUser)
        })
    }

    const getAllUsers = () => {
        EmployeesService.getAllBuUsers().then(data => {
            const filteredData = data.data.map(item => ({
                id: item.id,
                fullName: `${item.firstName} ${item.lastName}`
            }));
            setallBuUsers(filteredData)
        })
    }

    const getClientList = async () => {
        ClientsService.getClients().then(data => {
            let clientData = data.data.data;
            let clientList = [];
            clientData && clientData.forEach(client => {
                clientList.push({
                    name: client.clientName,
                    value: client.id,
                })
            });
            setClientsList(clientList)
        })
    };

    const buChange = (e) => {
        setCurrentBU(e.value);
    };

    const handlecheckboxchange = (e) => {
        setisGlobalpost(!isGlobalpost)
    }

    const onClientChange = (e) => {
        const selectedClientIds = e.value;
        setClientId(selectedClientIds);

        if (selectedClientIds.length === 0) {
            setParentLinks([]);
            setSelectedJobIds([]);
            return;
        }
        getParentLink(selectedClientIds);
    };

    const onJobNumberChange = (e) => {
        setjobids(e.value)
    }

    const onJobNameChange = (e) => {
        setjobnames(e.value)
    }

    const onchangeUsers = (e) => {
        setselectedUsers(e.value)
    }

    const getParentLink = (clientIds) => {
        const promises = clientIds.map(clientId =>
            TrackingSystemService.getJobsByClientId(clientId)
        );

        Promise.all(promises).then(results => {
            const allJobs = results.flat(); // Flatten the array of arrays
            setParentLinks(allJobs);
            setSelectedJobIds([]); // Clear the previously selected job IDs
        });
    };

    // const handleMultiSelectChange = (e) => {
    //     // Update the selected job IDs state when the selection changes
    //     setSelectedJobIds(e.value);
    // };

    const handleMultiSelectChange = (e) => {
        const filteredSelectedJobIds = e.value.filter(jobId => parentLinks.some(job => job.id === jobId));

        setSelectedJobIds(filteredSelectedJobIds);
    };

    const onChangeOption = (e) => {
        setRadio(e ? e : 'Invoice');
        setUser(e ? e : 'Invoice');
    };

    const resetfilter = () => {
        setCurrentBU([]);
        setClientId([]);
        setjobids([]);
        setjobnames([]);
        setselectedUsers([]);
        setreportsData([])
    }

    const reportsfilter = (filter) => {
        setLoading(true)
        const buIds = []
        const jobnum = []
        const jobreference = []
        const usersdata = []
        if(currentBu) {
            buIds.push(currentBu.id)
        }
        if (jobids.length > 0) {
            jobids.forEach(jobnumber => {
                jobnum.push(jobnumber.jobNumber)
            })
        }
        if (jobnames.length > 0) {
            jobnames.forEach(jobname => {
                jobreference.push(jobname.jobReference)
            })
        }
        if(selectedUsers.length > 0) {
            selectedUsers.forEach(user => {
                usersdata.push(user.id)
            })
        }
        let filterData = {
            from: Moment(filter.from).format('YYYY-MM-DD'),
            to: Moment(filter.to).format('YYYY-MM-DD'),
            buId: buIds,
            clients: clientId,
            isGlobalJob: isGlobalpost,
            jobNumbers: jobnum,
            fileNames: [],
            users: usersdata,
            jobReference: jobreference,
            export: false,
            module: (radio === "Scheduled Users") ? "scheduled" : (radio === "Date Range") ? "dateRange" : (radio.toLowerCase())
        }
        TrackingSystemService.getReportsfilterdata(filterData).then(data => {
            if (radio == "Invoice") {
                setreportsData(data.reportJobs)
            } else if (radio == "User") {
                setreportsData(data.grouped)
            } else if (radio == "Scheduled Users") {
                setreportsData(data.scheduledData)
            } else {
                const daterangevalues = []
                setUserChartOptions(prevOptions => ({
					...prevOptions,
					series: [{
						...prevOptions.series[0],
						data: data.clientSeries
					}],
					drilldown: {
						...prevOptions.drilldown,
						series: data.drillDownSeries
					}
				}));
                data.grouped.forEach(daterange => {
                    const values = daterange.values
                    values.forEach(value => {
                        daterangevalues.push(value)
                    })
                })
                setreportsData(daterangevalues)
            }
        }).finally(() => setLoading(false))
    }

    const reportsdownload = (downloder) => {
        setLoading(true)
        const buIds = []
        const jobnum = []
        const jobreference = []
        const usersdata = []
        if(currentBu) {
            buIds.push(currentBu.id)
        }
        if (jobids.length > 0) {
            jobids.forEach(jobnumber => {
                jobnum.push(jobnumber.jobNumber)
            })
        }
        if (jobnames.length > 0) {
            jobnames.forEach(jobname => {
                jobreference.push(jobname.jobReference)
            })
        }
        if(selectedUsers.length > 0) {
            selectedUsers.forEach(user => {
                usersdata.push(user.id)
            })
        }
        let filterData = {
            from: Moment(downloder.from).format('YYYY-MM-DD'),
            to: Moment(downloder.to).format('YYYY-MM-DD'),
            buId: buIds,
            clients: clientId,
            isGlobalJob: isGlobalpost,
            jobNumbers: jobnum,
            fileNames: [],
            users: [],
            jobReference: jobreference,
            export: true,
            module: (radio === "Scheduled Users") ? "scheduled" : (radio === "Date Range") ? "dateRange" : (radio.toLowerCase())
        }
        TrackingSystemService.getReportsDownload(filterData).then(response => {
            if (radio == "Invoice") {
                var blob = new Blob([response], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                });
                saveAs(blob, 'Invoice_' +
                    (new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear() + '.xlsx');
            } else if (radio == "User") {
                var blob = new Blob([response], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                });
                saveAs(blob, 'User_' +
                    (new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear() + '.xlsx');
            } else if (radio == "Scheduled Users") {
                var blob = new Blob([response], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                });
                saveAs(blob, 'Scheduled_' +
                    (new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear() + '.xlsx');
            } else {
                var blob = new Blob([response], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                });
                saveAs(blob, 'DateRange_' +
                    (new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear() + '.xlsx');
            }
        }).finally(() => setLoading(false))


    }

    const selectedId = (selectedId) => {
        return ''
    }

    const options = [
        { key: "1", name: "Invoice" },
        { key: "2", name: "User" },
        { key: "3", name: "Scheduled Users" },
        { key: "4", name: "Date Range" }
    ]
    const columns = [
        { field: "jobReference", header: "Job Name", sortable: true },
        { field: "business_unit.name", header: "Business Unit", sortable: true },
        { field: "isGlobalJob", header: "Global Job", sortable: true },
        { field: "jobNumber", header: "Job Number", sortable: true },
        { field: "client.clientName", header: "Client", sortable: true },
        { field: "project.name", header: "Project", sortable: true },
        { field: "job_status.status", header: "Status", sortable: true },
        { field: "ER", header: "Estimation", sortable: true },
        { field: "totalTimeSpent", header: "Total Time", sortable: true },
    ]
    const usersColumns = [
        { field: "jobReference", header: "Job Name", sortable: true },
        { field: "jobNumber", header: "Job Number", sortable: false },
        { field: "jobStatus", header: "Job Status", sortable: true },
        { field: "client", header: "Client", sortable: true },
        { field: "project", header: "Project", sortable: true },
        { field: "totalTime", header: "Time Spent", sortable: true },
        { field: "jobBuName", header: "Job BU", sortable: true },
        { field: "productionJE", header: "Production JE", sortable: true },
    ]
    const sheduledcolumns = [
        { field: "createdAt", header: "Date", sortable: true },
        { field: "job_post.jobNumber", header: "Job Number", sortable: false },
        { field: "job_post.client.clientName", header: "Client", sortable: true },
        { field: "job_post.project.name", header: "Project", sortable: true },
        { field: "range", header: "Range", sortable: true },
        { field: "total", header: "Total", sortable: true },
        { field: "productionJE", header: "Production JE", sortable: true },
    ]
    const daterangecolumns = [
        { field: "jobReference", header: "Job Name", sortable: true },
        { field: "jobNumber", header: "Job Number", sortable: false },
        { field: "client.clientName", header: "Client", sortable: true },
        { field: "job_status.status", header: "Status", sortable: true },
    ]

    return (
        <div className="mt-20 tracking-create trackingsystem-reports">
            <Loader loading={loading} />
            <div className='tracking-system-card p-10'>
                <div className='p-grid'>
                    <div className="p-sm-2">
                        <p className="font-normal fs-13 text-lightgray mt-0">Business Unit <span className='text-red'>*</span></p>
                        <MultiSelectElement
                            placeholder="Select BU"
                            value={currentBu}
                            options={buUnit}
                            optionLabel="name"
                            onChangeValue={(e) => buChange(e)}
                            defaultValue="code"
                            filterBy='name'
                        />
                    </div>
                    <div className="p-sm-2">
                        <p className="font-normal fs-13 text-lightgray mt-0">Is Global Post</p>
                        <CheckboxElement
                            checked={isGlobalpost}
                            onChangeCheck={(e) => handlecheckboxchange(e)}

                        />
                    </div>

                    {(radio === 'User' || radio === 'Invoice' || radio === 'Scheduled Users' || radio != 'Date Range') &&
                        <div className="p-sm-2">
                            <p className="font-normal fs-13 text-lightgray mt-0">Client </p>
                            <MultiSelectElement
                                name=""
                                placeholder="Select"
                                optionLabel="name"
                                options={clientsList}
                                value={clientId}
                                onChangeValue={(e) => onClientChange(e)}
                            />
                        </div>
                    }
                    {(radio === 'User' || radio === 'Invoice' || radio === 'Scheduled Users' || radio != 'Date Range') &&
                        <div className='p-sm-2'>
                            <p className="font-normal fs-13 text-lightgray mt-0">Job Number</p>
                            <MultiSelectElement
                                placeholder="Select"
                                optionLabel="jobNumber"
                                optionValue="id"
                                value={jobids}
                                options={parentLinks}
                                onChangeValue={(e) => onJobNumberChange(e)} // Assign the onChange function here
                            />
                        </div>
                    }
                    {(radio === 'User' || radio === 'Invoice') && (radio != 'Scheduled Users' || radio != 'Date Range') &&
                        <div className='p-sm-2'>
                            <p className="font-normal fs-13 text-lightgray mt-0">Job Name</p>
                            <MultiSelectElement
                                placeholder="Select"
                                optionLabel="jobReference"
                                optionValue="id"
                                value={jobnames}
                                options={parentLinks}
                                onChangeValue={(e) => onJobNameChange(e)} // Assign the onChange function here
                            />
                        </div>
                    }
                    {(radio === 'Scheduled Users' || radio === 'User') && (radio != 'Date Range' && radio != 'Invoice') &&
                        <div className='p-sm-2'>
                            <p className="font-normal fs-13 text-lightgray mt-0">User <span className='text-red'>*</span></p>
                            <MultiSelectElement
                                placeholder="Select"
                                optionLabel="fullName"
                                optionValue="id"
                                value={selectedUsers}
                                options={allBuUsers}
                                onChangeValue={(e) => onchangeUsers(e)}
                            />
                        </div>
                    }
                </div>
                <div className='p-grid'>
                    <div className="filter-button mt-30">
                        <div className="userFilter">
                            <AdminFilterElement
                                reportsData={reportsData}
                                label='reportStatus'
                                filter={reportsfilter}
                                getList={resetfilter}
                                downloader={reportsdownload}
                            // isProjectList={true}
                            />
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-4 p-md-6 p-sm-6">
                        <div className="p-field mt-30 ml-30">
                            <div className="group-radio reports-radio-options">
                                <RadioElement
                                    options={options}
                                    value={user}
                                    onChangeRadio={onChangeOption}
                                    className="test"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {radio === "Invoice" && reportsData?.length > 0 &&
                    <DataTableElement
                        value={reportsData}
                        columns={columns}
                        selectedId={selectedId}
                    />
                }
                {radio === "User" && reportsData?.length > 0 &&
                    <div>
                        <Accordion className='accordian' activeIndex={[0]}>
                            {reportsData?.map((id, index) => (
                                <AccordionTab header={reportsData[index].name}>
                                    <DataTableElement
                                        value={reportsData[index].values}
                                        columns={usersColumns}
                                        selectedId={selectedId}
                                    />
                                </AccordionTab>
                            ))}
                        </Accordion>
                    </div>
                }
                {radio == 'Scheduled Users' && reportsData?.length > 0 &&
                    <div>
                        <Accordion className="accordian" activeIndex={[0]}>
                            {
                                reportsData?.map((id, index) => (
                                    <AccordionTab header={reportsData[index].name}>
                                        <DataTableElement
                                            value={reportsData[index].values}
                                            columns={sheduledcolumns}
                                            selectedId={selectedId}
                                        />
                                    </AccordionTab>
                                ))
                            }
                        </Accordion>
                    </div>
                }
                {radio == 'Date Range' && reportsData?.length > 0 &&
                    <div>
                        <HighchartsReact highcharts={Highcharts} options={userChartOptions} />
                        <DataTableElement
                            value={reportsData}
                            columns={daterangecolumns}
                            selectedId={selectedId}
                        />
                    </div>
                }

            </div>
        </div>
    );
};

export default Reports;