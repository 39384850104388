    /* React Imports - Start */

    import React, { useState, useEffect, useRef } from "react";

    /* React Imports - End */

    /* Plugin Imports - Start */

    import { Dialog } from 'primereact/dialog';
    import { Button } from 'primereact/button';
    import Cookies from 'universal-cookie';
    import _ from 'lodash';
    import { Tooltip } from 'primereact/tooltip';
    import Moment from 'moment';
    import moment from "moment";

    /* Plugin Imports - End */

    /* Project Components Imports - Start */

    import DataTableElement from "../uiComponents/DataTableElement";
    import LeaveApprovalService from "../../services/approval/LeaveApprovalService";
    import SingleSelectElement from '../uiComponents/SingleSelectElement';
    import CheckboxElement from "../uiComponents/CheckboxElement";
    import AdminFilterElement from "../uiComponents/AdminFilters";
    import ToastElement from "../uiComponents/ToastElement";
    import Loader from "../uiComponents/Loader";
    import LeaveService from "../../services/leave/LeaveService";
    import EmployeesService from "../../services/employees/EmployeesService";

    /* Project Components Imports - End */

    /* Import Section - End */

    /* Function - Start */

    const LeaveApproval = (props) => {

        /* State Declaration - Start */

        /* useState - Start */

        const [associateLeaveList, setAssociateLeaveList] = useState([]);
        const [assLeaveApproveData, setAssLeaveApproveData] = useState();
        const [displayAssociateLeaveApprove, setDisplayAssociateLeaveApprove] = useState(false);
        const [loading, setLoading] = useState(false);
        const [assLeaveRejectData, setAssLeaveRejectData] = useState();
        const [displayAssociateLeaveReject, setDisplayAssociateLeaveReject] = useState(false);
        const [assLeaveRejectInfo, setAssLeaveRejectInfo] = useState('');
        const [assLeaveCancelInfo, setAssLeaveCancelInfo] = useState('');
        const [assLeaveCancelData, setAssLeaveCancelData] = useState();
        const [displayAssociateLeaveCancel, setDisplayAssociateLeaveCancel] = useState(false);
        const [checkedBulkLeave, setCheckedBulkLeave] = useState(false);
        const [button, setButton] = useState(false);
        const [leaveId, setLeaveId] = useState([]);
        const [bulkSelection, setBulkSelection] = useState(false);
        const [reporteesList, setReporteesList] = useState([]);
        const [leaveBalanceData, setLeaveBalanceData] = useState({});

        /* useState - End */

        /* useRef - Start */

        const toastRef = useRef();
        const cookies = new Cookies();
        const userId = cookies.get('userId');
        /* useRef - End */

        /* useEffect - Start */

        useEffect(() => {
            getAllLeaveRequestByUser();
            createButton();
            getReporteesDetails();
        }, [])

        /* useEffect - End */

        /* State Declaration - End */

        /* Static JSON - Start */

        const cancelReasons = [
            { name: 'Transportation issue', value: 'Transportation issue' },
            { name: 'Need to Reschedule', value: 'Need to Reschedule' },
            { name: 'Permission', value: 'Permission' },
            { name: 'Discuss with Manager', value: 'Discuss with Manager' }
        ];

        const statusList = [
            { name: 'APPROVED', value: 'APPROVED' },
            { name: 'REJECTED', value: 'REJECTED' },
            { name: 'CANCELLED', value: 'CANCELLED' },
            { name: 'REQUESTED', value: 'REQUESTED' },
        ];

        const dialogFuncMap = {
            'displayAssociateLeaveApprove': setDisplayAssociateLeaveApprove,
            'displayAssociateLeaveReject': setDisplayAssociateLeaveReject,
            'displayAssociateLeaveCancel': setDisplayAssociateLeaveCancel,
        }

        let buId = cookies.get('buId');
        let roles = cookies.get("roles");

        /* Static JSON - End */

        /* Methods - Start */

        const createButton = () => {
            setButton(true);
        }

        const onClickAssLeaveApprove = (data) => {
            setAssLeaveApproveData(data);
            setDisplayAssociateLeaveApprove(true);
        }

        const onClickAssLeaveReject = (data) => {
            setAssLeaveRejectData(data);
            setDisplayAssociateLeaveReject(true);
        }

        const onClickAssLeaveCancel = (data) => {
            setAssLeaveCancelData(data);
            setDisplayAssociateLeaveCancel(true);
        }

        const leaveApprovalFilter = (filter) => {
            filter.from = Moment(filter.from).format('YYYY-MM-DD');
            filter.to = Moment(filter.to).format('YYYY-MM-DD');
            var reportingUserId = cookies.get('userId');
            LeaveApprovalService.getAssociatesLeaveList(reportingUserId, filter).then(data => {
                let orderedData = _.orderBy(data.data, ['updatedAt'], ['desc']);
                setAssociateLeaveList(orderedData);
            })
        }

        const getAllLeaveRequestByUser = () => {
            setLoading(true);
            let currentId = {
                buId: cookies.get('buId'),
                reportingUserId: cookies.get('userId'),
            }
            LeaveApprovalService.getAssociatesAllLeaveList(currentId.reportingUserId).then(data => {
                setLoading(false);
                let orderedData = _.orderBy(data.data, ['updatedAt'], ['desc']);
                let filteredData  = _.filter(orderedData,(item)=>item.ConfigLeaveType.leaveTypeName!='WFH')

                if(props.dashboard){
                    let dashboardOrderedData = [];
                    filteredData.map(value => {
                            if(value.leaveStatus === "REQUESTED"){
                                dashboardOrderedData.push(value);        
                            } 
                        })
                    setAssociateLeaveList(dashboardOrderedData);
                    props.leaveVerify(dashboardOrderedData.length > 0 ? true :false);
                }else{
                    setAssociateLeaveList(filteredData);
                }
            })
        }

        const getList = (e) => {
            getAllLeaveRequestByUser();
        }

        // setStatusOptions(temp)
        const onHide = (name, message, success) => {
            if (name) {
                dialogFuncMap[`${name}`](false);
            }
            message && toastRef.current.showToast(message)
            if (success) {
                getAllLeaveRequestByUser();
            }
        }

        const getReporteesDetails = () => {
            const reportingUserId = cookies.get('userId');
            if(roles.includes('Admin')) {
                EmployeesService.getEmployees(buId).then(data => {
                    let employeeList = [];
                    data.data.rows.forEach(emp => {
                        // Exculded loggedIn User an DeActivated
                        // if (emp.accountStatus == 'ACTIVATED' && emp.id != cookies.get('userId')) {
                        if (emp.accountStatus == 'ACTIVATED') {
                            employeeList.push({
                                name: emp.firstName + ' ' + emp.lastName,
                                value: emp.id
                            });
                        }
                    });
                    var filteredData = _.filter(employeeList, (item) => item.name.toLowerCase() !== 'admin user');
                    setReporteesList(filteredData);
                })
            } else {
                LeaveService.getReporteesDetails(reportingUserId,false).then(data => {
                    let userList = [];
                    data.data.forEach(user => {
                        userList.push({
                            name: user.firstName + ' ' + user.lastName,
                            value: user.id
                        })
                    });
                    setReporteesList(userList);
                });
            }
        }

        /* Methods - End */

        /* Render Templates - Start */

        //Template for Number of Days 
        const nameTemplate = (rowData) => {
            if (rowData.total <= 1) {
                return (
                    <div>
                        <p className="cell-name">{rowData.total + ' Day'}</p> 
                        <p className="cell-text">{moment(rowData.from_date).format("DD-MM-YYYY") + ' To ' + moment(rowData.to_date).format("DD-MM-YYYY")}</p>
                    </div>
                )
            }
            else {
                return (
                    <div>
                        <p className="cell-name">{rowData.total + ' days'}</p>
                        <p className="cell-text">{moment(rowData.from_date).format("DD-MM-YYYY") + ' To ' + moment(rowData.to_date).format("DD-MM-YYYY")}</p>
                    </div>
                )
            }

        }

        
        const updatedAtTemplate = (rowData) =>{
            return (
                <div>
                <p className="cell-name">{moment(rowData.updatedAt).format("DD-MM-YYYY")}</p>  
                </div>
                )
        }


        // const appliedTemplate = (rowData) => {
        //     let date = Moment(rowData.createdAt).format("DD MMMM YYYY");
        //     return (
        //         <div>
        //             <p>{date}</p>
        //         </div>
        //     )
        // }

        const descriptionTemplate = (rowData) => {
            let capitalizeData = rowData.description !== null && rowData.description.substr(0,1).toUpperCase()+ rowData.description.substr(1).toLowerCase() ;
            return (
                <div> 
                    <div> 
                        <p className="cell-name">{rowData.ConfigLeaveType?.leaveTypeName}</p>

                        <Tooltip target=".taButton" />
                        <p data-pr-tooltip={ capitalizeData} data-pr-position="right" className="taButton cell-desc" > { capitalizeData.length > 25 ? capitalizeData.substring(0,25)+'.....' : capitalizeData} </p>
                        {/* <p  className=""> {assLeaveRejectInfo}</p> */}
                    </div>
                </div>
            )
        }

        const sickleavecertificate = (rowData) => {
            const chooseOptions = {iconOnly: true,  icon: 'pi pi-fw pi-plus'};
            const uploadOptions = {iconOnly: true,  icon: 'pi pi-upload', className: 'p-button-success'};
            const cancelOptions = {iconOnly: true,  icon: 'pi pi-times', className: 'file-upload-cancel'};
            return (
                <div>
                    {(rowData.fileName == null)?
                        <div style={{ paddingLeft: "30px" }}>-</div>
                    : 
                    <a href={rowData.url} target="_blank"> {rowData.fileName}</a>
                    }
                </div>
            )
        }


        useEffect(() => {
            fetchLeaveBalanceData();
        }, [associateLeaveList]);
        
        const fetchLeaveBalanceData = () => {
            const promises = associateLeaveList.map((entry) => {
                const userDetails = entry.userDetails || {};
                const userId = userDetails.id;
        
                return LeaveService.getLeaveTypes(userId)
                    .then((data) => {
                        const leaveBalance = data?.data?.find((element) => element.ConfigLeaveType?.leaveTypeName === 'Casual / Sick Leave');
                        if (leaveBalance) {
                            return {
                                userId,
                                balanceData: {
                                    totalLeave: leaveBalance.eligibleLeaves,
                                    balanceLeave: leaveBalance.balanceLeaves,
                                    taken: leaveBalance.taken,
                                },
                            };
                        }
                    });
            });
        
            Promise.all(promises)
                .then((results) => {
                    const updatedBalanceData = results.reduce((acc, result) => {
                        if (result) {
                            acc[result.userId] = result.balanceData;
                        }
                        return acc;
                    }, {});
        
                    setLeaveBalanceData(updatedBalanceData);
                });
        };
        
        // const leavebalancetemplate = (rowData) => {
        //     const userId = rowData.userId;
        //     const balanceData = leaveBalanceData ? leaveBalanceData[userId] : null;
        //     return (
        //         <div>
        //             {balanceData &&
        //                 <p>{balanceData.balanceLeave}</p>
        //             }
        //         </div>
        //     );
        // }
        

        const LeaveApprovalNameTemplate = (rowData) => {
            let date = Moment(rowData.createdAt).format("DD MMMM YYYY");
            return (
                <div>
                    <p className="cell-name">{rowData.userDetails.firstName + ' ' + rowData.userDetails.lastName}</p>
                    <p className="cell-text">{date}</p>
                </div>
            )
        }

        const assLeaveApproveHandler = () => {
            setDisplayAssociateLeaveApprove(false);
            setLoading(true);
            let data = {
                leaveRequests: assLeaveApproveData ? [assLeaveApproveData.id] : null,
                status: "APPROVED",
                updateduserId: userId
            }
            LeaveApprovalService.assLeaveApproveRejectCancel(data).then(leaveData => {
                setLoading(false);
                let message = {
                    type: leaveData.patched.success ? 'success' : 'error',
                    summary: leaveData.patched.success ? 'Success' : 'Something went wrong',
                    message: leaveData.patched.message
                }
                let leaveSuccess = leaveData.patched.success ? true : false
                if(leaveSuccess){
                    setLeaveBalanceData(null);
                }
                onHide('displayAssociateLeaveApprove', message, leaveSuccess);
            })
        }

        const assLeaveRejectHandler = () => {
            setDisplayAssociateLeaveReject(false);
            setLoading(true);
            let data = {
                leaveRequests: assLeaveRejectData ? [assLeaveRejectData.id] : null,
                status: "REJECTED",
                updateduserId: userId

            }
            if (assLeaveRejectInfo !== '') {
                LeaveApprovalService.assLeaveApproveRejectCancel(data).then(leaveData => {
                    setLoading(false);
                    let message = {
                        type: leaveData.patched.success ? 'success' : 'error',
                        summary: leaveData.patched.success ? 'Success' : 'Something went wrong',
                        message: leaveData.patched.message
                    }
                    let leaveSuccess = leaveData.patched.success ? true : false
                    onHide('displayAssociateLeaveReject', message, leaveSuccess);
                })
            }
            else {
                setLoading(false);
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Select Leave',
                    message: 'Fill the mandatory fields'
                })
            }
            setAssLeaveRejectInfo('');
        }

        const assLeaveCancelHandler = () => {
            setDisplayAssociateLeaveCancel(false);
            setLoading(true);
            let data = {
                leaveRequests: assLeaveCancelData ? [assLeaveCancelData.id] : null,
                status: "CANCELLED",
                updateduserId: userId
            }
            if (assLeaveCancelInfo !== '') {
                LeaveApprovalService.assLeaveApproveRejectCancel(data).then(leaveData => {
                    setLoading(false);
                    let message = {
                        type: leaveData.patched.success ? 'success' : 'error',
                        summary: leaveData.patched.success ? 'Success' : 'Something went wrong',
                        message: leaveData.patched.message
                    }
                    let leaveSuccess = leaveData.patched.success ? true : false
                    onHide('displayAssociateLeaveCancel', message, leaveSuccess);
                })
            }
            else {
                setLoading(false);
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Select Leave',
                    message: 'Fill the mandatory fields'
                })
            }
        }

        const renderAssLeaveApprove = () => {
            return (
                <div className="cancel-footer">
                    <div className='align-center justify-end pb-0'>
                        <Button type="submit" label="Yes" className="create-button request-button" onClick={() => assLeaveApproveHandler()} />
                    </div>
                </div>
            );
        }

        const renderAssLeaveReject = () => {
            return (
                <div className="cancel-footer">
                    <div className='align-center justify-end pb-0'>
                        <Button type="submit" label="Yes" className="create-button request-button" onClick={() => assLeaveRejectHandler()} />
                    </div>
                </div>
            );
        }

        const renderAssLeaveCancel = () => {
            return (
                <div className="cancel-footer">
                    <div className='align-center justify-end pb-0'>
                        <Button type="submit" label="Yes" className="create-button request-button" onClick={() => assLeaveCancelHandler()} />
                    </div>
                </div>
            );
        }

        const LeaveapprovedbyTemplate = (rowData) => {
            let date = Moment(rowData.createdAt).format("DD MMMM YYYY");
            return (
                <div>
                    <p className="cell-name">{`${rowData.updatedByDetails?.firstName || ''} ${rowData.updatedByDetails?.lastName || ''}`}</p>
                </div>
            )
        }

        const onChangeRejectInfo = (e) => {
            setAssLeaveRejectInfo(e.target.value)
        }

        const onChangeCancelInfo = (e) => {
            setAssLeaveCancelInfo(e.target.value)
        }

        // Select Bulk Leave
        const checkedMultiLeave = (e) => {
            var leaveIds = [];
            let temp = associateLeaveList.map((leave) => {
                if (leave.leaveStatus === "REQUESTED") {
                    leaveIds.push(leave.id);
                    return {
                        ...leave,
                        checked: e.checked
                    }
                } else {
                    return {
                        ...leave,
                        checked: false
                    }
                }
            });
            e.checked ? setBulkSelection(true) : setBulkSelection(false)
            setAssociateLeaveList(temp);
            setCheckedBulkLeave(e.checked);
            if (e.checked) {
                setLeaveId(leaveIds);
            } else {
                setLeaveId([]);
            }
        }

        //bulk Approve
        const bulkLeaveApprove = () => {
            if (leaveId.length > 0) {
                setLoading(true);
                let data = {
                    leaveRequests: leaveId,
                    status: "APPROVED"
                }
                LeaveApprovalService.assLeaveApproveRejectCancel(data).then(data => {
                    setLoading(false);
                    let message = {
                        type: data.patched.success ? 'success' : 'error',
                        summary: data.patched.success ? 'Success' : 'Something went wrong',
                        message: data.patched.message
                    }
                    let success = data.patched.success ? true : false
                    onHide('', message, success);
                    setCheckedBulkLeave(false);
                    setBulkSelection(false);
                })
            }
        }

        //Bulk Leave Reject
        const bulkLeaveReject = () => {
            if (leaveId.length > 0) {
                setLoading(true);
                let data = {
                    leaveRequests: leaveId,
                    status: "REJECTED",
                    comment: "Bulk Rejection"
                }
                LeaveApprovalService.assLeaveApproveRejectCancel(data).then(data => {
                    setLoading(false);
                    let message = {
                        type: data.patched.success ? 'success' : 'error',
                        summary: data.patched.success ? 'Success' : 'Something went wrong',
                        message: data.patched.message
                    }
                    let success = data.patched.success ? true : false
                    onHide('', message, success);
                    setCheckedBulkLeave(false);
                    setBulkSelection(false);
                })
            }
        }

        const LeaveApprovalstatusTemplate = (rowData) => {
            let data = {
                id: rowData.id,
                leaveStatus: rowData.leaveStatus
            }
            if (rowData.userId != cookies.get('userId')) {
                return (
                    <div>
    
                        {
                            rowData.leaveStatus === "APPROVED" ?
                                <i className="pi pi-ban leaveApproval-cancel" onClick={() => onClickAssLeaveCancel(data)}></i> :
                                rowData.leaveStatus === 'REQUESTED' &&
                                <span>
                                    <span onClick={() => onClickAssLeaveApprove(data)}><i className="pi pi-check leaveApproval-check"></i></span>
                                    <span onClick={() => onClickAssLeaveReject(data)}><i className="pi pi-times leaveApproval-times"></i></span>
                                </span>
                        }
                        {
                            //Associate Leave Approve Modal Popup
                            displayAssociateLeaveApprove &&
                            <Dialog visible={displayAssociateLeaveApprove} header="Confirmation" onHide={() => onHide('displayAssociateLeaveApprove')} footer={renderAssLeaveApprove()} breakpoints={{ '960px': '75vw' }} draggable={false}>
                                <div className="cancel-request">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <p className="font-normal fs-13 text-lightgray m-0">Are you sure to approve this leave ?</p>
                                        </div>
                                    </div>
                                </div>
                            </Dialog>
                        }
                        {
                            //Associate Leave Reject Modal Popup
                            displayAssociateLeaveReject &&
                            <Dialog visible={true} header=" Reject Leave" onHide={() => onHide('displayAssociateLeaveReject')} footer={renderAssLeaveReject()} breakpoints={{ '960px': '75vw' }} draggable={false}>
                                <div className="cancel-request">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <p className="font-normal fs-13 text-lightgray m-0">Reason<span className='text-red'>*</span></p>
                                            <SingleSelectElement name="cancel_desc" placeholder="Select" options={cancelReasons} value={assLeaveRejectInfo} onChangeValue={(e) => onChangeRejectInfo(e)} optionLabel="name"
                                            filterBy='name' />
                                        </div>
                                    </div>
                                </div>
                            </Dialog>
                        }
                        {
                            //Associate Leave Cancel Modal Popup
                            displayAssociateLeaveCancel &&
                            <Dialog visible={displayAssociateLeaveCancel} header="Confirm Leave Cancellation" onHide={() => onHide('displayAssociateLeaveCancel')} footer={renderAssLeaveCancel()} breakpoints={{ '960px': '75vw' }} draggable={false}>
                                <div className="cancel-request">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <p className="font-normal fs-13 text-lightgray m-0">Reason for cancellation<span className='text-red'>*</span></p>
                                            <SingleSelectElement name="cancel_desc" placeholder="Select" options={cancelReasons} value={assLeaveCancelInfo} onChangeValue={(e) => onChangeCancelInfo(e)} optionLabel="name" 
                                            filterBy='name'/>
                                        </div>
                                    </div>
                                </div>
                            </Dialog>
                        }
                    </div>
                );
            }
        };

        const checkedSingleLeave = (e, checkedId) => {
            var leaveIds = [];
            let temp = associateLeaveList.map((list) => {
                if (list.id === checkedId) {
                    return {
                        ...list,
                        checked: e.checked
                    }
                } else {
                    return {
                        ...list
                    }
                }
            });
            temp.map((list) => {
                if (list.checked) {
                    leaveIds.push(list.id);
                }
            });
            setAssociateLeaveList(temp);
            if (leaveIds.length > 0) {
                setBulkSelection(true);
                setCheckedBulkLeave(true);
                setLeaveId(leaveIds);
            } else {
                setBulkSelection(false);
                setCheckedBulkLeave(false);
                setLeaveId([]);
            }
        }

        const activeTemplate = (item) => {
            return (
                <div>
                    {
                        <div className="flex-items relative desData">
                            {
                                item.leaveStatus === "REQUESTED" &&
                                <CheckboxElement
                                    checked={item.checked}
                                    inputId={item.id}
                                    onChangeCheck={(e, checkedId) => checkedSingleLeave(e, checkedId)}
                                />
                            }
                        </div>
                    }
                </div>
            )
        }

        const leavestatusTemplate = (rowData) => {
            let capitalizeStatus = rowData.leaveStatus.substr(0,1).toUpperCase()+ rowData.leaveStatus.substr(1).toLowerCase() ;
            return (
                <div>
                    {
                        <div>
                            {rowData.leaveStatus === "APPROVED" ? <div className="status status-approved"> {rowData.leaveStatus.toLowerCase()}</div> : rowData.leaveStatus === "REQUESTED" ? <div className="status status-requested">{rowData.leaveStatus.toLowerCase()}</div> : rowData.leaveStatus === "REJECTED" ? <div className="status status-rejected">{rowData.leaveStatus.toLowerCase()}</div> : rowData.leaveStatus === "TERMINATED" ? <div className="status status-terminated">{rowData.leaveStatus.toLowerCase()}</div>:<div className="status status-cancelled">{rowData.leaveStatus.toLowerCase()}</div>}
                        </div>
                    }
                </div>
            )
        }

        var columns;
        //Datatable columns header and templates
        if(props.dashboard){
            columns = [
                { field: "userDetails.firstName", header: "Applied By", body: LeaveApprovalNameTemplate, sortable: true },
                { field: "from_date", header: "Days / Period", body: nameTemplate, sortable: false },
                { field: "ConfigLeaveType.leaveTypeName", header: "Type / Description", body: descriptionTemplate, sortable: true },
                // { field: "createdAt", header: "Applied On", body: appliedTemplate, sortable: true },
                { field: "leaveBalance.balanceLeaves", header:"Leave Balance", sortable:false},
                { field: "filename", header: "Document", body: sickleavecertificate, sortable:false},
                { field: "updatedAt", header: "Updated At", body: updatedAtTemplate, sortable: false, style: { width: '100px' } },
                { field: "leaveStatus", header: "Status", body: leavestatusTemplate, sortable: false, style: { width: '100px' } },
                { field: " ", header: "Actions", body: LeaveApprovalstatusTemplate, sortable: false, style: { width: '100px' } },
            ];
        } else {
            columns = [
                { field: "leave", body: activeTemplate, style: { width: '25px' } },
                { field: "userDetails.firstName", header: "Applied By", body: LeaveApprovalNameTemplate, sortable: true },
                { field: "from_date", header: "Days / Period", body: nameTemplate, sortable: false },
                { field: "ConfigLeaveType.leaveTypeName", header: "Type / Description", body: descriptionTemplate, sortable: true },
                // { field: "createdAt", header: "Applied On", body: appliedTemplate, sortable: true },
                { field: "leaveBalance.balanceLeaves", header:"Leave Balance", sortable:false},
                { field: "filename", header: "Document", body: sickleavecertificate, sortable:false},
                { field: "updatedAt", header: "Updated At", body: updatedAtTemplate,  sortable: false},
                { field: "leaveStatus", header: "Status", body: leavestatusTemplate, sortable: false },
                { field: "updatedByDetails.firstName", header: "Updated By", body: LeaveapprovedbyTemplate, sortable:false},
                { field: " ", header: "Actions", body: LeaveApprovalstatusTemplate, sortable: false },
            ];
        }
        

        //Function for getting Selected id
        const getSelectedId = () => {
            return " "
        }

        /* Render Templates - End */

        /* Render View Return - Start */

        return (
            <div>
                <ToastElement ref={toastRef} />
                <Loader loading={loading} />
                <div className="bulkcomp d-flex justify-content-between">
                    <div>
                        { !props.dashboard &&
                            <CheckboxElement
                                checked={checkedBulkLeave}
                                onChangeCheck={(e) => checkedMultiLeave(e)}
                            /> 
                        }
                    </div>
                </div>
                <div className="leave-approval">
                    <DataTableElement
                        value={associateLeaveList}
                        columns={columns}
                        placeholder="Search Name, Type"
                        button={button}
                        createButton={ props.dashboard ? null :
                            <div className={bulkSelection && leaveId.length > 0 ? "bulk-icon enableButton" : "bulk-icon-disable disableButton"}>
                                <Tooltip target=".approveRejectButton"/>
                                <div>
                                    <span onClick={bulkLeaveApprove}>
                                        <i className="pi pi-check approval-bulk approveRejectButton" data-pr-tooltip="Approve" data-pr-position="left" >
                                        </i>
                                    </span>
                                </div>
                                <div className="approve">
                                    <span onClick={bulkLeaveReject}>
                                        <i className="pi pi-times reject-bulk approveRejectButton" data-pr-tooltip="Reject" data-pr-position="left">
                                        </i>
                                    </span>
                                </div>
                            </div>
                        }
                        selectedId={getSelectedId}
                        paginationView={false}
                        header={true}
                        search={false}
                        filterButton={ props.dashboard ? null :
                            <div>
                                {
                                    <div className="userFilter">
                                        <AdminFilterElement
                                            statusList={statusList}
                                            filter={leaveApprovalFilter}
                                            label='name'
                                            getList={getList}
                                            userList={reporteesList}
                                        />
                                    </div>
                                }
                            </div>
                        }
                    />
                </div>
            </div>
        )

        /* Render View Return - End */

    }

    /* Function - End */

    /* Export default functionName; */

    export default LeaveApproval;