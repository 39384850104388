/**
 * Authentication Service
 */
 import axios from 'axios';

let baseUrl = process.env.REACT_APP_API_URL2;

async function getJobStatus(buId) {
    let response = await axios.get(`${baseUrl}/api/settings/jobStatus/${buId}`);
    return response.data;
}

async function addJobStatus(data) {
    let response = await axios.post(`${baseUrl}/api/settings/jobStatus`, data);
    return response.data;
}

async function updateJobStatus(id,data) {
    let response = await axios.put(`${baseUrl}/api/settings/jobStatus/${id}`, data);
    return response.data;
}

async function editJobStatusData(id) {
    let response = await axios.get(`${baseUrl}/api/settings/jobStatusById/${id}`);
    return response.data;
}

async function getJobTypes(buId) {
    let response = await axios.get(`${baseUrl}/api/settings/getActiveBuIdjobType/${buId}`);
    return response.data;
}

async function addJobType(data) {
    let response = await axios.post(`${baseUrl}/api/settings/jobTypes`, data);
    return response.data;
}

async function editJobTypeData(id) {
    let response = await axios.get(`${baseUrl}/api/settings/jobTypeById/${id}`);
    return response.data;
}

async function updateJobType(data, id){
    let response = await axios.put(`${baseUrl}/api/settings/updateJobType/${id}`, data);
    return response.data;
}

async function updateJobTypeStatus(data, id){
    let response = await axios.put(`${baseUrl}/api/settings/updatejobType/status/${id}`, data);
    return response.data;
}

async function getQualityMetrics(buId) {
    let response = await axios.get(`${baseUrl}/api/settings/getActiveBuIdQualityMetric/${buId}`);
    return response.data;
}

async function addQualityMetric(data) {
    let response = await axios.post(`${baseUrl}/api/settings/qualityMetric`, data);
    return response.data;
}

async function editQualityMetic(id) {
    let response = await axios.get(`${baseUrl}/api/settings/errorMetrics/${id}`);
    return response.data;
}

async function updateQualityMetric(data, id) {
    let response = await axios.put(`${baseUrl}/api/settings/updateErrorMetric/${id}`,data);
    return response.data;
}

async function updateQualityMetricStatus(id, data) {
    let response = await axios.put(`${baseUrl}/api/settings/qualityMetric/status/${id}`,data);
    return response.data;
}

export default {getJobStatus,addJobStatus,updateJobStatus,editJobStatusData,
    getJobTypes,addJobType,editJobTypeData,updateJobType,updateJobTypeStatus,getQualityMetrics,addQualityMetric,editQualityMetic,updateQualityMetric,updateQualityMetricStatus};
