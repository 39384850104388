/* Import Section - Start */

/* React Imports - Start */

import React, { useState, useEffect, useRef } from "react";

/* React Imports - End */

/* Plugin Imports - Start */

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Cookies from 'universal-cookie';
import _ from 'lodash';
import { Tooltip } from 'primereact/tooltip';
import Moment from 'moment';
import moment from "moment";

/* Plugin Imports - End */

/* Project Components Imports - Start */

import DataTableElement from "../uiComponents/DataTableElement";
import CompoffService from "../../services/approval/CompoffService";
import SingleSelectElement from '../uiComponents/SingleSelectElement';
import ToastElement from "../uiComponents/ToastElement";
import CheckboxElement from "../uiComponents/CheckboxElement";
import AdminFilterElement from "../uiComponents/AdminFilters"
import Loader from "../uiComponents/Loader";
import LeaveService from "../../services/leave/LeaveService";
import EmployeesService from "../../services/employees/EmployeesService";

/* Project Components Imports - End */

/* Import Section - End */

/* Function - Start */

const CompApproval = (props) => {

    /* State Declaration - Start */

    const [associatesCompOffList, setAssociatesCompOffList] = useState([]);
    const [compoffAssociateLimitCount, setCompOffAssociateLimitCount] = useState(10);
    const [assCompApproveData, setAssCompApproveData] = useState();
    const [displayAssociateCompOffApprove, setDisplayAssociateCompOffApprove] = useState(false);
    const [assCompRejectData, setAssCompRejectData] = useState();
    const [displayAssociateCompOffReject, setDisplayAssociateCompOffReject] = useState(false);
    const [assLeaveRejectInfo, setAssLeaveRejectInfo] = useState('');
    const [compId, setCompId] = useState([]);
    const [checkedBulkComp, setCheckedBulkComp] = useState(false);
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [bulkSelection, setBulkSelection] = useState(false);
    const [button, setButton] = useState(false);
    const [reporteesList, setReporteesList] = useState([]);

    /* useState - End */

    /* useRef - Start */

    const toastRef = useRef();
    const cookies = new Cookies();
    const userId = cookies.get('userId');

    /* useRef - End */

    /* useEffect - Start */

    useEffect(() => {
        getAssociatesCompOffList();
        createButton();
        getReporteesDetails();
    }, [success])

    /* useEffect - End */

    /* State Declaration - End */

    /* Static JSON - Start */

    const statusList = [
        { name: 'APPROVED', value: 'APPROVED' },
        { name: 'REJECTED', value: 'REJECTED' },
        { name: 'CANCELLED', value: 'CANCELLED' },
        { name: 'REQUESTED', value: 'REQUESTED' },
    ];

    const cancelReasons = [
        { name: 'Transportation issue', value: 'Transportation issue' },
        { name: 'Need to Reschedule', value: 'Need to Reschedule' },
        { name: 'Permission', value: 'Permission' }, ,
        { name: 'Discuss with Manager', value: 'Discuss with Manager' }
    ];

    let buId = cookies.get('buId');
    let roles = cookies.get("roles");

    /* Static JSON - End */

    /* Methods - Start */

    const createButton = () => {
        setButton(true);
    }

    const dialogFuncMap = {
        'displayAssociateCompOffApprove': setDisplayAssociateCompOffApprove,
        'displayAssociateCompOffReject': setDisplayAssociateCompOffReject,
    }

    const compOffFilter = (filter) => {
        var reportingUserId = cookies.get('userId');
        CompoffService.getAssociatesCompOffList(reportingUserId, filter).then(data => {
            setAssociatesCompOffList(data.data);
        })
    }

    const getList = () => {
        getAssociatesCompOffList();
    }

    const getAssociatesCompOffList = () => {
        setLoading(true);
        let currentId = {
            reportingUserId: cookies.get('userId')
        }
        CompoffService.getAssociatesCompOffAllList(currentId.reportingUserId).then(data => {
            setLoading(false);
            let orderedData = _.orderBy(data.data, ['updatedAt'], ['desc']);
            if (props.dashboard) {
                let dashboardOrderedData = [];
                orderedData.map(value => {
                    if (value.compStatus === "REQUESTED") {
                        dashboardOrderedData.push(value);
                    }
                })
                setAssociatesCompOffList(dashboardOrderedData);
                props.compOffVerify(dashboardOrderedData.length > 0 ? true : false);

            } else {
                setAssociatesCompOffList(orderedData);
            }
        })

        let count = compoffAssociateLimitCount + 10;
        setCompOffAssociateLimitCount(count);
    }

    const onClickAssCompApprove = (data) => {
        setAssCompApproveData(data);
        setDisplayAssociateCompOffApprove(true);
    }

    const onClickAssCompReject = (data) => {
        setAssCompRejectData(data);
        setDisplayAssociateCompOffReject(true);
    }

    const onChangeRejectInfo = (e) => {
        setAssLeaveRejectInfo(e.target.value)
    }

    //Associate Comp Off Approve API call
    const assCompOffApproveHandler = () => {
        setDisplayAssociateCompOffApprove(false);
        let data = {
            compIds: assCompApproveData ? [assCompApproveData.id] : null,
            status: "APPROVED",
            updatedBy: userId,
        }
        CompoffService.assCompOffApproveReject(data).then(compdata => {
            let message = {
                type: compdata.patched.success ? 'success' : 'error',
                summary: compdata.patched.success ? 'Success' : 'Something went wrong',
                message: compdata.patched.message
            }
            let compSuccess = compdata.patched.success ? true : false
            onHide('displayAssociateCompOffApprove', message, compSuccess);
        })
    }

    const assCompOffRejectHandler = () => {
        setDisplayAssociateCompOffReject(false);
        let data = {
            compIds: assCompRejectData ? [assCompRejectData.id] : null,
            status: "REJECTED",
            updatedBy: userId
            // comment: assLeaveRejectInfo
        }
        // if (assLeaveRejectInfo !== '') {
        CompoffService.assCompOffApproveReject(data).then(compdata => {
            let message = {
                type: compdata.patched.success ? 'success' : 'error',
                summary: compdata.patched.success ? 'Success' : 'Something went wrong',
                message: compdata.patched.message
            }
            let compSuccess = compdata.patched.success ? true : false
            onHide('displayAssociateCompOffReject', message, compSuccess);
        })
        // }
        // else {
        //     setLoading(false);
        //     toastRef.current.showToast({
        //         type: 'error',
        //         summary: 'Select Leave',
        //         message: 'Fill the mandatory fields'
        //     })
        // }
    }

    //Associate Comp Off Approve Footer Button
    const renderAssCompApprove = () => {
        return (
            <div className="cancel-footer">
                <div className='align-center justify-end pb-0'>
                    <Button type="submit" label="Yes" className="create-button request-button" onClick={() => assCompOffApproveHandler()} />
                </div>
            </div>
        );
    }

    //Associate Comp Off Reject Footer Button
    const renderAssCompReject = () => {
        return (
            <div className="cancel-footer">
                <div className='align-center justify-end pb-0'>
                    <Button type="submit" label="Yes" className="create-button request-button" onClick={() => assCompOffRejectHandler()} />
                </div>
            </div>
        );
    }

    //Bulk Comp Off Approve
    const bulkCompApprove = () => {
        if (compId.length > 0) {
            let data = {
                compIds: compId,
                status: "APPROVED",
                updatedBy: userId
            }
            CompoffService.assCompOffApproveReject(data).then(bulkdata => {
                let message = {
                    type: bulkdata.patched.success ? 'success' : 'error',
                    summary: bulkdata.patched.success ? 'Success' : 'Something went wrong',
                    message: bulkdata.patched.message
                }
                let bulkSuccess = bulkdata.patched.success ? true : false
                onHide('', message, bulkSuccess);
                setCheckedBulkComp(false);
                setBulkSelection(false);
            })
        }
    }

    const bulkCompReject = () => {
        if (compId.length > 0) {
            let data = {
                compIds: compId,
                status: "REJECTED",
                comment: "Bulk Rejection",
                updatedBy: userId
            }
            CompoffService.assCompOffApproveReject(data).then(bulkdata => {
                let message = {
                    type: bulkdata.patched.success ? 'success' : 'error',
                    summary: bulkdata.patched.success ? 'Success' : 'Something went wrong',
                    message: bulkdata.patched.message
                }
                let bulkSuccess = bulkdata.patched.success ? true : false
                onHide('', message, bulkSuccess);
                setCheckedBulkComp(false);
                setBulkSelection(false);
            })
        }
    }

    // Select Bulk Comp Off
    const checkedMultiCompOff = (e) => {
        var compCheckedCountData = 0;
        var compIds = [];
        let temp = associatesCompOffList.map((comp) => {
            if (comp.compStatus === "REQUESTED") {
                compCheckedCountData = compCheckedCountData + 1;
                compIds.push(comp.id);
                return {
                    ...comp,
                    checked: e.checked
                }
            } else {
                return {
                    ...comp,
                    checked: false
                }
            }
        });
        e.checked ? setBulkSelection(true) : setBulkSelection(false)
        setAssociatesCompOffList(temp);
        setCheckedBulkComp(e.checked);
        if (e.checked) {
            setCompId(compIds);
        } else {
            setCompId([]);
        }
    }

    const onHide = (name, message, success) => {
        if (name) {
            dialogFuncMap[`${name}`](false);
        }
        message && toastRef.current.showToast(message)
        if (success) {
            setSuccess(success)
            getAssociatesCompOffList();
        }
    }

    const getReporteesDetails = () => {
        const reportingUserId = cookies.get('userId');
        if (roles.includes('Admin')) {
            EmployeesService.getEmployees(buId).then(data => {
                let employeeList = [];
                data.data.rows.forEach(emp => {
                    // Exculded loggedIn User an DeActivated
                    if (emp.accountStatus == 'ACTIVATED' && emp.id != cookies.get('userId')) {
                        employeeList.push({
                            name: emp.firstName + ' ' + emp.lastName,
                            value: emp.id
                        });
                    }
                });
                var filteredData = _.filter(employeeList, (item) => item.name.toLowerCase() !== 'admin user');
                setReporteesList(filteredData);
            })
        } else {
            //New WFH
            LeaveService.getReporteesDetails(reportingUserId,false).then(data => {
                let userList = [];
                data.data.forEach(user => {
                    userList.push({
                        name: user.firstName + ' ' + user.lastName,
                        value: user.id
                    })
                });
                setReporteesList(userList);
            });
        }
    }

    /* Methods - End */

    /* Render Templates - Start */

    const compnameTemplate = (rowData) => {
        if (rowData.total <= 1) {
            return (
                <div>
                    <p className="cell-name">{rowData.total + ' day'}</p>
                    <p className="cell-text">{moment(rowData.from_date).format("DD-MM-YYYY") + ' To ' + moment(rowData.to_date).format("DD-MM-YYYY")}</p>
                </div>
            )
        }
        else {
            return (
                <div>
                    <p className="cell-name">{rowData.total + ' days'}</p>
                    <p className="cell-text">{moment(rowData.from_date).format("DD-MM-YYYY") + ' To ' + moment(rowData.to_date).format("DD-MM-YYYY")}</p>
                </div>
            )
        }
    }

    const compdescriptionTemplate = (rowData) => {
        let CapitalizeDesc = rowData.description.substr(0, 1).toUpperCase() + rowData.description.substr(1).toLowerCase();
        return (
            <div>
                <p className="cell-name">{rowData.LeaveType && rowData.LeaveType.name}</p>

                <Tooltip target=".taButton" />
                <p data-pr-tooltip={CapitalizeDesc} data-pr-position="right" className="taButton cell-desc" > {CapitalizeDesc.length > 25 ? CapitalizeDesc.substring(0, 25) + '.....' : CapitalizeDesc} </p>
                {/* <p> {assLeaveRejectInfo}</p> */}
            </div>
        )
    }

    const compApprovalstatusTemplate = (rowData) => {
        let data = {
            id: rowData.id,
            compStatus: rowData.compStatus
        }
        return (
            <div>
                {
                    rowData.compStatus === 'REQUESTED' &&
                    <span >
                        <span onClick={() => onClickAssCompApprove(data)}><i className="pi pi-check leaveApproval-check"></i></span>
                        <span onClick={() => onClickAssCompReject(data)}><i className="pi pi-times leaveApproval-times"></i></span>
                    </span>
                }
                {
                    //Associate Comp Off Approve Modal Popup
                    displayAssociateCompOffApprove &&
                    <Dialog visible={displayAssociateCompOffApprove} header="Confirmation" onHide={() => onHide('displayAssociateCompOffApprove')} footer={renderAssCompApprove()} breakpoints={{ '960px': '75vw' }} draggable={false}>
                        <div className="cancel-request">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <p className="font-normal fs-13 text-lightgray m-0">Are you sure to approve this Comp Off ?</p>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                }
                {
                    //Associate Comp Off Reject Modal Popup
                    displayAssociateCompOffReject &&
                    <Dialog visible={displayAssociateCompOffReject} header="Confirmation" onHide={() => onHide('displayAssociateCompOffReject')} footer={renderAssCompReject()} breakpoints={{ '960px': '75vw' }} draggable={false}>
                        <div className="cancel-request">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <p className="font-normal fs-13 text-lightgray m-0">Reason<span className='text-red'>*</span></p>
                                    <SingleSelectElement name="cancel_desc" placeholder="Select" options={cancelReasons} value={assLeaveRejectInfo} onChangeValue={(e) => onChangeRejectInfo(e)} optionLabel="name"
                                        filterBy='name' />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                }
            </div>
        );
    };

    const compApprovalNameTemplate = (rowData) => {
        let date = Moment(rowData.createdAt).format('DD-MM-YYYY');
        return (
            <div>
                <p className="cell-name">{rowData.userDetails.firstName + ' ' + rowData.userDetails.lastName}</p>
                <p className="cell-text">{date}</p>
            </div>
        )
    }

    const checkedSingleCompOff = (e, checkedId) => {
        var compIds = [];
        let temp = associatesCompOffList.map((list) => {
            if (list.id === checkedId) {
                return {
                    ...list,
                    checked: e.checked
                }
            } else {
                return {
                    ...list
                }
            }
        });
        temp.map((list) => {
            if (list.checked) {
                compIds.push(list.id);
            }
        });
        setAssociatesCompOffList(temp);
        if (compIds.length > 0) {
            setBulkSelection(true);
            setCheckedBulkComp(true);
            setCompId(compIds);
        } else {
            setBulkSelection(false);
            setCheckedBulkComp(false);
            setCompId([]);
        }
    }

    const activeTemplate = (item) => {
        return (
            <div>
                {
                    <div className="flex-items relative desData">
                        {
                            item.compStatus === "REQUESTED" &&
                            <CheckboxElement
                                checked={item.checked}
                                inputId={item.id}
                                onChangeCheck={(e, checkedId) => checkedSingleCompOff(e, checkedId)}
                            />
                        }
                    </div>
                }
            </div>
        )
    }

    const compoffstatusTemplate = (rowData) => {
        let capitalizeStatus = rowData.compStatus.substr(0, 1).toUpperCase() + rowData.compStatus.substr(1).toLowerCase();
        return (
            <div>
                {
                    <div>
                        {rowData.compStatus === "APPROVED" ? <div className="status status-approved"> {rowData.compStatus.toLowerCase()}</div> : rowData.compStatus === "REQUESTED" ? <div className="status status-requested">{rowData.compStatus.toLowerCase()}</div> : rowData.compStatus === "REJECTED" ? <div className="status status-rejected">{rowData.compStatus.toLowerCase()}</div> : rowData.compStatus === "TERMINATED" ? <div className="status status-terminated">{rowData.compStatus.toLowerCase()}</div> : <div className="status status-cancelled">{rowData.compStatus.toLowerCase()}</div>}
                    </div>
                }
            </div>
        )
    }

    const CompapprovedbyTemplate = (rowData) => {
        let date = Moment(rowData.createdAt).format("DD MMMM YYYY");
        return (
            <div>
                <p className="cell-name">{`${rowData.updatedByDetails?.firstName || ''} ${rowData.updatedByDetails?.lastName || ''}`}</p>
            </div>
        )
    }

    var columns;

    if (props.dashboard) {
        columns = [
            { field: "userDetails.firstName", header: "Applied By", body: compApprovalNameTemplate, sortable: true },
            { field: "from_date", header: "Days / Period", body: compnameTemplate, sortable: false },
            { field: "LeaveType.name", header: "Type / Description", body: compdescriptionTemplate, sortable: false },
            // { field: "createdAt", header: "Applied On", body: appliedTemplate, sortable: true },
            { field: "compStatus", header: "Status", body: compoffstatusTemplate, sortable: false, style: { width: '100px' } },
            { field: " ", header: "Actions", body: compApprovalstatusTemplate, sortable: false, style: { width: '100px' } },
        ];
    } else {
        columns = [
            { field: "compoff", body: activeTemplate, style: { width: '25px' } },
            { field: "userDetails.firstName", header: "Applied By", body: compApprovalNameTemplate, sortable: true },
            { field: "from_date", header: "Days / Period", body: compnameTemplate, sortable: false },
            { field: "LeaveType.name", header: "Type / Description", body: compdescriptionTemplate, sortable: false },
            // { field: "createdAt", header: "Applied On", body: appliedTemplate, sortable: true },
            { field: "compStatus", header: "Status", body: compoffstatusTemplate, sortable: false },
            { field: "updatedByDetails.firstName", header: "Updated By", body: CompapprovedbyTemplate, sortable:false},
            { field: " ", header: "Actions", body: compApprovalstatusTemplate, sortable: false },
        ];
    }

    const getSelectedId = () => {
        return ""
    }

    /* Render Templates - End */

    /* Render View Return - Start */

    return (
        <div>
            <ToastElement ref={toastRef} />
            <Loader loading={loading} />
            <div className="bulkcomp d-flex justify-content-between ">
                {!props.dashboard &&
                    <div>
                        <CheckboxElement
                            checked={checkedBulkComp}
                            onChangeCheck={(e) => checkedMultiCompOff(e)}
                        />
                    </div>
                }
            </div>
            <div className="leave-approval">
                <DataTableElement
                    value={associatesCompOffList}
                    columns={columns}
                    button={button}
                    createButton={props.dashboard ? null :
                        <div className={bulkSelection && compId.length > 0 ? "bulk-icon enableButton" : "bulk-icon-disable disableButton"}>
                            <Tooltip target=".approveRejectButton" />
                            <div>
                                <span onClick={bulkCompApprove} >
                                    <i className="pi pi-check approval-bulk approveRejectButton" data-pr-tooltip="Approve" data-pr-position="left" >
                                    </i>
                                </span>
                            </div>
                            <div className="approve">
                                <span onClick={bulkCompReject}>
                                    <i className="pi pi-times reject-bulk approveRejectButton" data-pr-tooltip="Reject" data-pr-position="left">
                                    </i>
                                </span>
                            </div>
                        </div>
                    }
                    selectedId={getSelectedId}
                    header={true}
                    search={false}
                    filterButton={props.dashboard ? null :
                        <div>
                            {
                                <div className="userFilter">
                                    <AdminFilterElement
                                        statusList={statusList}
                                        filter={compOffFilter}
                                        getList={getList}
                                        userList={reporteesList}
                                    />
                                </div>
                            }
                        </div>
                    }
                />
            </div>
        </div>
    )

    /* Render View Return - End */

}

/* Function - End */

/* Export default functionName; */

export default CompApproval;