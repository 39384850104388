/* Import Section - Start */

/* React Imports - Start */

import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from 'react-hook-form';

/* React Imports - End */

/* Project Components Imports - Start */

import Cookies from 'universal-cookie';
import CheckboxElement from "../uiComponents/CheckboxElement";
import ButtonElement from "../uiComponents/ButtonElement";
import LeaveConfigService from "../../services/controlPanel/LeaveConfigService";
import ToastElement from "../uiComponents/ToastElement";
import SingleSelectElement from "../uiComponents/SingleSelectElement";
import Loader from "../uiComponents/Loader";
import LeaveConfigNew from "./LeaveConfigPopV1";

/* Project Components Imports - Start */

/* Plugin Imports - Start */

import _ from 'lodash';

/* Plugin Imports - End */
/* Import Section - End */

/* Function - Start */

const LeaveConfig = () => {

	/* State Declaration - Start */
	/* useState - Start */
	const cookies = new Cookies();
	const [buUnits, setBuUnits] = useState([]);
	const [leaveType, setLeaveType] = useState([]);
	const [currentBuName, setCurrentBuName] = useState("");
	const [displayAddLeaveType, setDisplayAddLeaveType] = useState(false);
	const [leaveConfigData, setLeaveConfigData] = useState({
		compOff: false,
		compOffExpiryDays: '',
		isLeaveMinDate: false,
		leaveMinimumDate: '',
		dojLeaveConfig: false,
		dojLeaveConfigDate: '',
		isDateFormat: false,
		isLeaveTakenLimit: false,
		leaveTakenLimit: '',
		ispermissionRequest: false,
		permissionDaysLimit: '',
		permissionHoursLimit: '',
		isPermissionApproval: false,
		isPrimary: false
	});
	const [displayEditLeaveType, setDisplayEditLeaveType] = useState(false);
	const [editLeaveTypeData, setEditLeaveTypeData] = useState();
	const [leaveDataId, setLeaveDataId] = useState();
	const [success, setSuccess] = useState('');
	const [loading, setLoading] = useState(false);

	/* useState - End */

	/* useForm - Start */

	const { control, formState: { errors }, handleSubmit, reset, watch, setValue } = useForm({});

	const watchAllFields = watch();

	/* useForm - End */

	/* useRef - Start */

	const toastRef = useRef();
	var currentBu = cookies.get('buId')

	/* useRef - End */

	/* useEffect - Start */

	useEffect(() => {
		getActiveBu();
	}, [])

	/* useEffect - End */
	/* State Declaration - End */

	/* Methods - Start */

	const getFormErrorMessage = (field) => {
		return errors[field] && <small className="p-error">{errors[field].message}</small>
	};

	const dialogFuncMap = {
		'displayAddLeaveType': setDisplayAddLeaveType,
		'displayEditLeaveType': setDisplayEditLeaveType
	}

	const onClickAddLeaveType = (name) => {
		dialogFuncMap[`${name}`](true);
	}

	//API call- Edit leave Type
	const onClickEditLeaveType = (name, typeId) => {
		setLoading(true);
		LeaveConfigService.editLeaveType(typeId).then(data => {
			setLoading(false);
			setEditLeaveTypeData(data.data)
			dialogFuncMap[`${name}`](true);
		})
	}

	const onHide = (name, message, success) => {
		message && toastRef.current.showToast(message)
		if (success) {
			dialogFuncMap[`${name}`](false);
			setSuccess(success);
			getLeaveTypes(currentBuName.id)
		} else {
			dialogFuncMap[`${name}`](true);
		}
	}

	const getActiveBu = () => {
		LeaveConfigService.getActiveBu().then(response => {
			setBuUnits(response.data)

			if (currentBu) {
				setCurrentBuName(_.find(response.data, { id: currentBu }))
				getLeaveTypes(currentBu)
				leaveConfiguration(currentBu);
			}
		})
	}

	const buChange = (e) => {
		getLeaveTypes(e.value.id)
		leaveConfiguration(e.value.id);
		setCurrentBuName(e.value)

	}

	const getLeaveTypes = (buId) => {
		setLoading(true);
		LeaveConfigService.getLeaveType(buId).then(data => {
			setLoading(false);
			var orderedData = _.orderBy(data.data, ['createdAt'], ['asc']);
			setLeaveType(orderedData);
		})
	}

	const checkboxValueEdit = (e, typeId) => {
		setLoading(true);
		var data = { isActive: e.checked }
		LeaveConfigService.updateLeaveStatus(typeId, data).then(leaveData => {
			setLoading(false);
			!leaveData.posted.success && toastRef.current.showToast({
				type: 'error',
				summary: 'Update failed',
				message: leaveData.posted.message
			})
			leaveData.posted.success && toastRef.current.showToast({
				type: 'success',
				summary: 'Updated successsfully',
				message: leaveData.posted.message
			})
			getLeaveTypes(currentBuName.id)
		})
	}

	const leaveConfiguration = (buId) => {
		setLoading(true);
		LeaveConfigService.leaveConfiguration(buId).then(data => {
			setLoading(false);
			var leaveData = data.data;
			leaveData.map((leaveData) => {
				setLeaveDataId(leaveData.id)
				if (leaveData.leavedata != null) {
					let dataVal = {
						...leaveConfigData,
						compOff: leaveData.leavedata.compOff === 'true' ? true : false,
						compOffExpiryDays: leaveData.leavedata.compOffExpiryDays,
						isLeaveMinDate: leaveData.leavedata.isLeaveMinDate === 'true' ? true : false,
						leaveMinimumDate: leaveData.leavedata.leaveMinimumDate,
						dojLeaveConfig: leaveData.leavedata.dojLeaveConfig === 'true' ? true : false,
						dojLeaveConfigDate: leaveData.leavedata.dojLeaveConfigDate,
						isDateFormat: leaveData.leavedata.isDateFormat === 'true' ? true : false,
						isLeaveTakenLimit: leaveData.leavedata.isLeaveTakenLimit === 'true' ? true : false,
						leaveTakenLimit: leaveData.leavedata.leaveTakenLimit,
						ispermissionRequest: leaveData.leavedata.ispermissionRequest === 'true' ? true : false,
						permissionDaysLimit: leaveData.leavedata.permissionDaysLimit,
						permissionHoursLimit: leaveData.leavedata.permissionHoursLimit,
						isPermissionApproval: leaveData.leavedata.isPermissionApproval === 'true' ? true : false,
						behalfofRequest: leaveData.leavedata.behalfofRequest === 'true' ? true : false,
					}
					setValue('leaveMinimumDate', leaveData.leavedata.leaveMinimumDate, { shouldValidate: true }) //setting value for form data because text fields in leave config are added under form control
					setLeaveConfigData(dataVal);
					reset(dataVal);
				}
			})
		})
	}

	/* Methods - End */

	/* Render View Return - Start */

	return (
		<div className="mt-9">
			<ToastElement ref={toastRef} />
			<Loader loading={loading} />
			<div className="p-grid leave-config">
				<div className="p-d-flex p-col-12 button-div">
					<div className=" mr-20">
						<SingleSelectElement
							placeholder="Select BU"
							value={currentBuName}
							options={buUnits}
							optionLabel="name"
							onChangeValue={(e) => buChange(e)}
							defaultValue="code"
							filterBy='name'
						/>
					</div>
					<ButtonElement
						label="Add Type"
						icon="pi pi-plus"
						iconPos="left"
						className="addRoles-button"
						onClickButton={() => onClickAddLeaveType('displayAddLeaveType')}
					/>
				</div>
				<div className="p-col-12 pb-0">
					<div className="p-col-12 leaveConfig-container-head pl-0 pb-14">
						<p className="leaveConfig-head m-0">Leave Type</p>
					</div>
					<div className="p-col p-grid leaveConfig-container-item pt-20">
						{
							leaveType.map((type) => (

								<div className="p-mr-1 leaveConfig-flex-item relative">
									<CheckboxElement key={type.id} inputId={type.id} value={type.leaveTypeName} checked={type.isActive} onChangeCheck={(e, typeId) => checkboxValueEdit(e, typeId)} />
									<span onClick={() => onClickEditLeaveType('displayEditLeaveType', type.id)}><i className="pi pi-pencil leave-edit"></i></span>
								</div>
							))
						}
					</div>
				</div>
			</div>
			{
				displayAddLeaveType &&
				<LeaveConfigNew
					visible={displayAddLeaveType}
					header="Add Leave Type"
					onHide={(message, success) => onHide('displayAddLeaveType', message, success)}
					currentBuName={currentBuName}
				/>
			}
			{
				displayEditLeaveType &&
				<LeaveConfigNew
					visible={displayEditLeaveType}
					header="Edit Leave Type"
					onHide={(message, success) => onHide('displayEditLeaveType', message, success)}
					editLeaveTypeData={editLeaveTypeData}
					currentBuName={currentBuName}
				/>
			}
		</div>
	);
	/* Render View Return - End */

};
/* Function - End */

/* Export default functionName; */

export default LeaveConfig;
