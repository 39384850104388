/* Import Section - Start */

/* React Imports - Start */

import React, { useState, useEffect, useRef } from 'react';

/* React Imports - End */

/* Plugin Imports - Start */

import { Button } from "primereact/button";
import Moment from 'moment';
import _ from 'lodash';
import Cookies from 'universal-cookie';

/* Plugin Imports - End */

/* Project Components Imports - Start */

import DataTableElement from '../uiComponents/DataTableElement';
import ToastElement from "../uiComponents/ToastElement";
import HolidayService from '../../services/controlPanel/HolidayService';
import SingleSelectElement from "../uiComponents/SingleSelectElement";
import LeaveConfigService from "../../services/controlPanel/LeaveConfigService";
import Loader from "../uiComponents/Loader";
import { Dialog } from 'primereact/dialog';
import CalendarElement from '../uiComponents/CalendarElement';
import TextareaElement from '../uiComponents/TextareaElement';
import TextboxElement from '../uiComponents/TextboxElement';

/* Project Components Imports - End */

/* Import Section - End */

/* Function - Start */

const HolidayConfig = () => {

    /* State Declaration - Start */

    /* useState - Start */
    const [displayHoliday, setDisplayHoliday] = useState(false)
    const [datatable, setDatatable] = useState(true);
    const [button, setButton] = useState(false);
    const [allHolidaysList, setallHolidaysList] = useState([]);
    const [currentBuName, setCurrentBuName] = useState("");
    const [loading, setLoading] = useState(false);
    const [buUnits, setBuUnits] = useState([]);


    /* Modal popup - Start */
    const [holidayDate, setholidayDate] = useState('');
    const [holidayType, setholidayType] = useState([]);
    const [holidayDescription, setholidayDescription] = useState([]);
    const [displayEditHoliday, setDisplayEditHoliday] = useState(false);
    const [allHolidaysListById, setallHolidaysListById] = useState([]);


    /* Modal popup  - End */

    /* useRef - Start */

    const toastRef = useRef();
    const cookies = new Cookies();

    /* useRef - End */


    const requestTypes = [
        { name: 'Declared', value: 'Declared' },
        { name: 'National', value: 'National' },
        { name: 'Public', value: 'Public' },
        { name: 'Restricted', value: 'Restricted' },
        { name: 'Official', value: 'Official' }
    ];

    /* useEffect - Start */

    useEffect(() => {
        createButton();
        getActiveBu();
        getallHolidaysList();
    }, [])

    /* useEffect - End */

    /* State Declaration - End */

    /* Static JSON - Start */

    var curBuId = currentBuName.id;
    /* Static JSON - End */

    /* Methods - Start */

    //API hit-Project task list
    const getallHolidaysList = (curBuId) => {
        setLoading(true);
        if (curBuId != undefined) {
            HolidayService.getallHolidaysList(curBuId).then(data => {
                setLoading(false);
                let taskData = _.orderBy(data.data, ['createdAt'], ['desc']);
                setallHolidaysList(taskData);
            })
        }
    }

    //getHolidaysListById
    const editHoliday = (id) => {
        setLoading(true);
        HolidayService.getHolidaysListById(id).then(data => {
            setLoading(false);
            setholidayDescription(data.data.description)
            setholidayDate(data.data.holiday_date)
            setholidayType(data.data.holiday_type)
            setallHolidaysListById(data.data)
        })
        setDisplayEditHoliday(true);
    }
    const editTemplate = (rowData) => {
        return (
            <span onClick={() => editHoliday(rowData.id)} className="action">
                <i className="pi pi-pencil leave-edit"></i>
            </span>
        )
    }

    /* Methods - End */

    /* Render Templates - Start */
    const selectedId = () => {
        return ''
    }

    const createButton = () => {
        setButton(true);
        setDatatable(true);
    }

    const getActiveBu = () => {
        LeaveConfigService.getActiveBu().then(response => {
            var currentBu = cookies.get('buId')
            setBuUnits(response.data);
            if (currentBu) {
                setCurrentBuName(_.find(response.data, { id: currentBu }))
                getallHolidaysList(currentBu);
            }
        })
    }

    const buChange = (e) => {
        setCurrentBuName(e.value)
        getallHolidaysList(e.value.id);
    }


    const columns = [
        { field: "description", header: "Description", sortable: true },
        { field: "holiday_type", header: "Holiday Type", sortable: true },
        { field: "holiday_date", header: "Date", sortable: true },
        { field: "icon", header: "Actions", body: editTemplate, sortable: false }

    ];

    const onClickAddProjectTask = () => {
        setDisplayHoliday(true);
        setholidayDate('');
        setholidayType('');
        setholidayDescription('');
    }

    const dialogFuncMap = {
        'displayHoliday': setDisplayHoliday,
        'displayEditHoliday': setDisplayEditHoliday
    }

    const onHide = (name, message, success) => {
        if (name) {
            dialogFuncMap[`${name}`](false);
        }
        message && toastRef.current.showToast(message)
        if (success) {
            setDisplayHoliday(false);
        }
    }

    const onChangeType = (e) => {
        setholidayType(e.target.value)
    }

    const onsetholidayDescription = (e) => {
        setholidayDescription(e.target.value)
    }

    const renderAddHoliday = () => {
        return (
            <div className="cancel-footer">
                <div className='align-center justify-end pb-0'>
                    {
                        <Button type="submit" label="ADD" className="create-button request-button" onClick={() => addHolidayHandler()} />
                    }
                </div>
            </div>
        );
    }

    const renderEditHoliday = () => {
        return (
            <div className="cancel-footer">
                <div className='align-center justify-end pb-0'>
                    {
                        <Button type="submit" label="UPDATE" className="create-button request-button" onClick={() => editHolidayHandler()} />
                    }
                </div>
            </div>
        );
    }

    const editHolidayHandler = () => {
        const data = {
            holiday_date: holidayDate,
            holiday_type: holidayType,
            description: holidayDescription,
            buId: curBuId,
            isActive: true
        }
        if (data.holiday_date !== '' && data.holiday_date !== null && data.holiday_type !== '' && data.holiday_type !== null && data.description !== '' && data.description !== null) {
            HolidayService.editHolidayList(allHolidaysListById.id, data).then(holidayData => {
                let message = {
                    type: holidayData.patched ? 'success' : 'error',
                    summary: holidayData.patched ? 'Success' : 'Something went wrong',
                    message: holidayData.patched,
                }
                let holidaySuccess = holidayData.posted ? true : false
                onHide('displayEditHoliday', message, holidaySuccess)
                getallHolidaysList(curBuId);
            });
        }
        else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Holiday Config',
                message: 'Fill the mandatory fields'
            })
        }
    }
    const addHolidayHandler = () => {
        const data = {
            holiday_date: Moment(holidayDate).format('YYYY-MM-DD'),
            holiday_type: holidayType,
            description: holidayDescription,
            buId: curBuId,
            isActive: true
        }
        if (data.holiday_date !== '' && data.holiday_date !== null && data.holiday_type !== '' && data.holiday_type !== null && data.description !== '' && data.description !== null) {
            HolidayService.addNewHolidayList(data).then(holidayData => {
                let message = {
                    type: holidayData.posted ? 'success' : 'error',
                    summary: holidayData.posted ? 'Success' : 'Something went wrong',
                    message: holidayData.posted,
                }
                let holidaySuccess = holidayData.posted ? true : false
                onHide('displayHoliday', message, holidaySuccess)
                getallHolidaysList(curBuId);
            });
        }
        else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Holiday Config',
                message: 'Fill the mandatory fields'
            })
        }
        setholidayDate('');
        setholidayType('');
        setholidayDescription('');
    }
    /* Render Templates - End */

    /* Render View Return - Start */

    return (
        <div className="mt-20">
            <ToastElement ref={toastRef} />
            <Loader loading={loading} />
            <div id='projectType'>
                <div className='project-task'>
                    {
                        datatable &&
                        <DataTableElement
                            value={allHolidaysList}
                            columns={columns}
                            button={button}
                            createButton={
                                <div className='d-flex'>
                                    <Button label="Add Holiday" className='add-projectype-btn' onClick={() => onClickAddProjectTask('displayHoliday')} icon="pi pi-plus" />
                                    <SingleSelectElement
                                        placeholder="Select BU"
                                        value={currentBuName}
                                        options={buUnits}
                                        optionLabel="name"
                                        onChangeValue={(e) => buChange(e)}
                                        defaultValue="code"
                                        filterBy='name'
                                    />
                                </div>
                            }
                            placeholder="Search Task"
                            selectedId={selectedId}
                            paginationView={false}
                            header={true}
                            search={true}
                        />
                    }
                    {
                        displayHoliday &&
                        <Dialog visible={displayHoliday} header="Add Holiday" breakpoints={{ '960px': '75vw' }} onHide={() => onHide('displayHoliday')} footer={renderAddHoliday()} draggable={false}>
                            <div className="project-task-dialog holiday-config">
                                <div className="p-grid">
                                    <div className="p-col-12 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0 mb-8">Date <span className='text-red'>*</span></p>
                                        <CalendarElement
                                            name='start_date'
                                            placeholder='Start Date'
                                            value={holidayDate}
                                            view='date'
                                            dateFormat='dd/mm/yy'
                                            onChange={(e) => setholidayDate(e.target.value)}
                                            showIcon='true'
                                        />
                                    </div>
                                    <div className="p-col-12 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0 mb-8">Holiday Type <span className='text-red'>*</span></p>
                                        <SingleSelectElement name="request_type" placeholder="Select" filter={false} optionLabel="name" options={requestTypes} value={holidayType} onChangeValue={(e) => onChangeType(e)}
                                            filterBy='name' />

                                    </div>
                                </div>
                                <div>
                                    <p className='fs-13 mb-0 text-lightgray'></p>
                                    <TextboxElement
                                        name="desc"
                                        value={holidayDescription}
                                        className='input-textarea-leave'
                                        onChangeText={(e) => onsetholidayDescription(e)}
                                        rows='4'
                                        cols='30'
                                        placeholder="Description *"
                                    />
                                </div>
                            </div>
                        </Dialog>
                    }
                    {
                        displayEditHoliday &&
                        <Dialog visible={displayEditHoliday} header="Edit Holiday" breakpoints={{ '960px': '75vw' }} onHide={() => onHide('displayEditHoliday')} footer={renderEditHoliday()} draggable={false}>
                            <div className="project-task-dialog holiday-config">
                                <div className="p-grid">
                                    <div className="p-col-12 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0 mb-8">Date <span className='text-red'>*</span></p>
                                        <CalendarElement
                                            name='start_date'
                                            placeholder='Start Date'
                                            value={allHolidaysListById ? new Date(allHolidaysListById.holiday_date) : holidayDate}
                                            view='date'
                                            dateFormat='dd/mm/yy'
                                            onChange={(e) => setholidayDate(e.target.value)}
                                            showIcon='true'
                                        />
                                    </div>
                                    <div className="p-col-12 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0 mb-8">Holiday Type <span className='text-red'>*</span></p>
                                        <SingleSelectElement name="request_type" placeholder="Select" filter={false} optionLabel="name" options={requestTypes} value={holidayType} onChangeValue={(e) => onChangeType(e)}
                                            filterBy='name' />
                                    </div>
                                </div>
                                <div>
                                    <p className='fs-13 mb-0 text-lightgray'></p>
                                    <TextboxElement
                                        name="desc"
                                        value={holidayDescription}
                                        className='input-textarea-leave'
                                        onChangeText={(e) => onsetholidayDescription(e)}
                                        rows='4'
                                        cols='30'
                                        placeholder="Description *"
                                    />
                                </div>
                            </div>
                        </Dialog>
                    }
                </div>
            </div>
        </div>
    )
    /* Render View Return - End */
}

/* Function - End */

/* Export default functionName; */

export default HolidayConfig;