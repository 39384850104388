/* Import Section - Start */

/* React Imports - Start */

import React, { useEffect, useState, useRef } from 'react';

/* React Imports - End */

/* Plugin Imports - Start */

import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

/* Plugin Imports - End */

/* Project Components Imports - Start */

import LoginService from '../../services/login/LoginService';
import ToastElement from "../uiComponents/ToastElement";
import Loader from "../uiComponents/Loader";
import Cookies from 'universal-cookie';
import TextboxElement from '../uiComponents/TextboxElement';
import JINlogo from '../../assets/images/Login-logo.png';
import password from '../../assets/images/Password.png';
import JMANfooter from '../../assets/images/JMANfooter1.png';
import JMANLogoBlue from '../../assets/images/JMANLogoBlue.png';



/* Project Components Imports - End */

/* Import Section - End */

/* Function - Start */

const ResetUserPassword = () => {

    /* State Declaration - Start */

    /* useState - Start */

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
	const [loading, setLoading] = useState(false); 
    const [userResetData, setUserResetData] = useState({});

    /* useState - End */

    /* useRef - Start */

    const toastRef = useRef();

    /* useRef - End */

    const cookies = new Cookies();
    let navigate = useNavigate();

    /* useForm - Start */


    /* useForm - End */

    /* useEffect - Start */

    useEffect(() => {
        getForgotData();
    }, [])

    /* useEffect - End */

    /* State Declaration - End */

    /* Methods - Start */

    
    const getForgotData = () =>{
        let data = location.pathname.split("/");
        let resetData = {
            userId: data[2],
            forgotId: data[3],
            token: data[4]
        }
        setUserResetData(resetData);
    }

    // To Reset form
    const onClickResetUserPassword = () => {
        if( newPassword != ''){
            if(newPassword.length > 7){
                if(newPassword === confirmPassword){
                    let passwordData = {
                        resetData : userResetData,
                        password: confirmPassword
                    }
                    setLoading(true);
                    LoginService.updateUserPassword(passwordData).then(data => {
                        setLoading(false);
                        if (data.patched.success) {
                            toastRef.current.showToast({
                                type: 'success',
                                summary: 'Password Update',
                                message: data.patched.message
                            }) 
                            navigate('/');
                        } else {
                            toastRef.current.showToast({
                                type: 'error',
                                summary: 'Password Update',
                                message: data.patched.message
                            })
                        }
                    })
                } else{
                    toastRef.current.showToast({
                        type: 'error',
                        summary: 'Please check your credentials',
                        message: 'Password and Confirm Password Should be same'
                    })
                }
            } else{
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Please check your credentials',
                    message: "Password length Should be minimum 8 letter"
                })
            }
        } else{
            toastRef.current.showToast({
                type: 'error',
                summary: 'Required Fields',
                message: "Please fill Mandatory fields"
            })
        }
    };
    

    /* Render Templates - End */

    /* Render View Return - Start */

    return (
        <div className='bg-white'>
            <ToastElement ref={toastRef} />
            <Loader loading={loading} />
            <div className="p-grid login-grid h-100 m-0">
                <div className="p-col-12 p-md-6 p-lg-6 login-bg p-0">
                    {/* <img src={JINlogo} alt="logo" className='jin-logo' /> */}
                </div>
                <div className="p-col-12 p-md-6 p-lg-6 flex-center">
                    <div className="p-fluid reset-content">
                        <div className="header">
                            <h1>Reset Password</h1>
                        </div>
                        <div className="main-content">
                            <div className="p-field">
                                <span className="p-input-icon-left">
                                    <img src={password} className='password-icon' />
                                    <TextboxElement type='password' className='input-text fs-13' value={newPassword} onChangeText={(e) => setNewPassword(e.target.value)} placeholder='New Password'/>
                                </span>
                            </div>
                            <div className="p-field">
                                <span className="p-input-icon-left">
                                    <img src={password} className='password-icon' />
                                    <TextboxElement type='password' className='input-text fs-13'  value={confirmPassword} onChangeText={(e) => setConfirmPassword(e.target.value )} placeholder='Confirm Password'/>
                                </span>
                            </div>
                        </div>
                        <div className="text-right">
                            <Button type="forget" label="RESET" id='resetBtn' className="p-mt-3 font-normal reset-button" onClick={onClickResetUserPassword} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='p-grid copyright-footer m-0'>
                <div className='p-col-4'>
                    <p className="font-normal fs-10 m-0 login-footer-text">Powered by</p>
                    <img src={JMANfooter} alt="footer" className='footer-image d-des-block' />
                    <img src={JMANLogoBlue} alt="footer" className='footer-image d-mob-block' />
                </div>
                <div className='p-col-4 pl-30 footer-border-1'>
                    <p className="font-normal fs-10 m-0 copyright-text">2.0</p>
                    <p className="font-normal fs-10 m-0 mt-5 login-footer-text no-wrap">IG JIN APPS Version</p>
                </div>
                <div className='p-col-4 pl-30 footer-border-2'>
                    <p className="font-normal fs-10 m-0 copyright-text">2022</p>
                    <p className="font-normal fs-10 m-0 mt-5 login-footer-text">&copy; Copyright</p>
                </div>
            </div>
        </div>
    );

    /* Render View Return - End */

}

/* Function - End */

/* Export default functionName; */

export default ResetUserPassword;