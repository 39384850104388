/* Import Section - Start */

/* React Imports - Start */

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

/* React Imports - End */

/* Plugin Imports - Start */

import { Button } from "primereact/button";

/* Plugin Imports - End */

/* Project Components Imports - Start */

import DataTableElement from "../uiComponents/DataTableElement";
import BusettingsService from "../../services/buSettings/BusettingsService";
import ToggleElement from "../uiComponents/ToggleElement";
import BusinessCreationPage from "./BusinessCreationPage";
import ToastElement from "../uiComponents/ToastElement";
import Loader from "../uiComponents/Loader";
import Cookies from 'universal-cookie';

/* Project Components Imports - End */

/* Import Section - End */

/* Function - Start */

function BusinessUnit(props) {

	/* State Declaration - Start */

	/* useState - Start */

	const [button, setButton] = useState(false);
	const [businessUnitList, setBusinessUnitList] = useState([]);
	const [create, setCreate] = useState(false);
	const [datatable, setDatatable] = useState(true);
	const [edit, setEdit] = useState(false);
	const [editData, setEditData] = useState();
	const [loading, setLoading] = useState(false);

	/* useState - End */

	/* navigate Declaration */

	let navigate = useNavigate();
	let location = useLocation();

	/* useRef - Start */

	const toastRef = useRef();

	const cookies = new Cookies();

	/* useRef - End */

	/* useEffect - Start */

	//Function call on load
	useEffect(() => {
		getBuList();
		createButton();
	}, [])

	//Function call when create and edit state is updated
	useEffect(() => {
		if (location.state == null) {
			setCreate(false);
			setEdit(false);
			setDatatable(true)
			props.buListPage(true);
			props.buCreationPage(false);
			props.buEditPage(false)
		} else if (create) {
			props.buCreationPage(true);
		} else if (edit) {
			props.buEditPage(true);
		} else if (!create) {
			props.buCreationPage(false);
		} else if (!edit) {
			props.buCreationPage(false);
		}
	}, [create, edit, location.state])

	/* useEffect - End */

	/* State Declaration - End */

	/* Service Method Call - Start */

	//Get BU List API call
	const getBuList = () => {
		setLoading(true);
		BusettingsService.getBuList().then(data => {
			setLoading(false);
			let buData = data.data;
			buData.sort(function (a, b) {
				var a1 = a.name.toLowerCase();
				var b1 = b.name.toLowerCase();
				return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
			});
			setBusinessUnitList(buData);
		})
	}

	//Status Change By Id API call
	const toggleChanged = (statusId, checked) => {
		setLoading(true);
		var statusData = {
			id: statusId,
			isActive: checked
		}
		BusettingsService.statusChangeBuId(statusData).then(data => {
			setLoading(false);
			!data.posted.success && toastRef.current.showToast({
				type: 'error',
				summary: 'Update failed',
				message: data.posted.message
			})
			data.posted.success && toastRef.current.showToast({
				type: 'success',
				summary: 'Updated successsfully',
				message: data.posted.message
			})
			getBuList();
		})
	}

	//Edit BU List By Id API call and redirecting to Edit Page
	const editBusinessUnit = (buId) => {
		setLoading(true);
		BusettingsService.editBu(buId).then(data => {
			setLoading(false);
			setEditData(data.data);
			setDatatable(false);
			setEdit(true);
		})
		navigate(`/businessUnit/edit`, { state: { isEdit: true } });
	}

	/* Service Method Call - End */

	/* Methods - Start */

	//On Click Edit BU Action Icon
	const editBu = (id) => {
		editBusinessUnit(id);
	}

	//Setting Create/Edit true/false based on BusinessCreation/Updation Page
	const onSuccess = (buCreate, buEdit, data, createProject, updateProject) => {
		setEdit(buEdit);
		setCreate(buCreate);
		setDatatable(true);
		if (createProject) {
			!data.posted.success && toastRef.current.showToast({
				type: 'error',
				summary: 'Failed',
				message: data.posted.message
			})
			data.posted.success && toastRef.current.showToast({
				type: 'success',
				summary: 'Created Successfully',
				message: data.posted.message
			})
		} else if (updateProject) {
			!data.patched.success && toastRef.current.showToast({
				type: 'error',
				summary: 'Failed',
				message: data.patched.message
			})
			data.patched.success && toastRef.current.showToast({
				type: 'success',
				summary: 'Updated Successfully',
				message: data.patched.message
			})
		}
		getBuList();
		if (data.posted?.buId) {
			cookies.set('buId', data.posted.buId);
		}
	}

	//Create a Button on top of Datatable if needed
	const createButton = () => {
		setButton(true);
		setDatatable(true);
	}

	//Redirecting to Create BU Page
	const createView = () => {
		setDatatable(false);
		setCreate(true);
		navigate(`/businessUnit/create`, { state: { isEdit: false } });
	}

	/* Methods - End */

	/* Render Templates - Start */

	//HeadQuarter template as No/Yes
	const headQuarterTemplate = (rowData) => {
		if (rowData.headQuarter === false) {
			return "No";
		} else {
			return "Yes";
		}
	}

	//Concatenate Name Template
	const nameTemplate = (rowData) => {
		return rowData.buAdminFirstName + ' ' + rowData.buAdminLastName;
	}

	//Toggle Button Template for Status
	const statusTemplate = (rowData) => {
		if (rowData.isActive === false) {
			return (
				<div className="d-flex toggle-button">
					<ToggleElement
						statusId={rowData.id}
						value={rowData.isActive}
						onChange={(e, v) => toggleChanged(e, v)}
						onIcon='pi pi-check'
						offIcon='pi pi-times'
						className="toggle-button-yes"
					/>
				</div>
			);
		} else if (rowData.isActive) {
			return (
				<div className="d-flex toggle-button">
					<ToggleElement
						statusId={rowData.id}
						value={rowData.isActive}
						onChange={(e, v) => toggleChanged(e, v)}
						onIcon='pi pi-check'
						offIcon='pi pi-times'
						className="toggle-button-yes"
					/>
				</div>
			);
		}
	};

	//Edit Icon for Action Template
	const editTemplate = (rowData) => {
		return (
			<span onClick={() => editBu(rowData.id)} className="action">
				<i className="pi pi-pencil leave-edit"></i>
			</span>
		)
	}

	const selectedId = () => {
		return ''
	}

	//Datatable columns header and templates
	const columns = [
		{ field: "code", header: "BU Code", sortable: true, style: { width: '120px'} },
		{ field: "name", header: "BU Name", sortable: true, style: { width: '120px'} },
		{ field: "location", header: "Location", sortable: true, style: { width: '110px'} },
		{ field: "headQuarter", header: "Is Headquater", body: headQuarterTemplate, sortable: true, style: { width: '150px'} },
		{ field: "buAdminFirstName, buAdminLastName", header: "Admin Name", body: nameTemplate, sortable: true, style: { width: '150px'} },
		{ field: "buAdminMail", header: "Email", sortable: true },
		{ field: "buContact", header: "Contact #", sortable: false },
		{ field: "isActive", header: "Active?", body: statusTemplate, sortable: false },
		{ field: "icon", header: "Actions", body: editTemplate, sortable: false }
	];

	/* Render Templates - End */

	/* Render View Return - Start */

	return (
		<div className="mt-20 business-unit">
			<ToastElement ref={toastRef} />
			<Loader loading={loading} />
			{
				datatable &&
				<DataTableElement
					value={businessUnitList}
					columns={columns}
					button={button}
					createButton={<Button label="Create Business Unit" onClick={createView} icon="pi pi-plus" />}
					placeholder="Search BU Name, Code"
					selectedId={selectedId}
					paginationView={false}
					header={true}
                    search={true}
				/>
			}
			{
				create &&
				<BusinessCreationPage type={'create'} onSuccess={(create, edit, data, createProject, updateProject) => onSuccess(create, edit, data, createProject, updateProject)} />
			}
			{
				edit &&
				<BusinessCreationPage type={'edit'} editData={editData} onSuccess={(create, edit, data, createProject, updateProject) => onSuccess(create, edit, data, createProject, updateProject)} />
			}
		</div>
	)

	/* Render View Return - End */

}

/* Function - End */

/* Export default functionName; */

export default BusinessUnit;