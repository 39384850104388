/**
 * Authentication Service
 */
import axios from 'axios';

let baseUrl = process.env.REACT_APP_API_URL;

async function loginUser(credentials) {
	let response = await axios.post(`${baseUrl}/api/user/authenticate/`, credentials);
	return response.data;
}

async function loginO365User() {
	let response = await axios.get(`${baseUrl}/api/oauth`, {
		withCredentials: true
	});
	return response.data;
}

async function loggedOut() {
	let response = await axios.get(`${baseUrl}/api/logout`);
	return response.data;
}

async function forgotPassword(data) {
	let response = await axios.post(`${baseUrl}/api/login/forgot`, data);
	return response.data;
}

async function updateUserPassword(data) {
	let response = await axios.patch(`${baseUrl}/api/login/confirm`, data);
	return response.data;
}

export default { loginUser, loginO365User, loggedOut, forgotPassword, updateUserPassword };