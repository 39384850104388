/* Import Section - Start */

/* React Imports - Start */

import React, { useEffect, useState, useRef } from "react";

/* React Imports - End */

/* Project Components Imports - Start */

import AdminService from "../../services/admin/AdminService";
import ButtonElement from "../uiComponents/ButtonElement";
import CheckboxElement from "../uiComponents/CheckboxElement";
import ToastElement from "../uiComponents/ToastElement";
import Loader from '../uiComponents/Loader';
import SingleSelectElement from '../uiComponents/SingleSelectElement';
import TextboxElement from "../uiComponents/TextboxElement";
import MultiSelectElement from "../uiComponents/MultiSelectElement";

/* Project Components Imports - End */

/* Plugin Imports - Start */

import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";

/* Plugin Imports - End */

/* Import Section - End */

/* Function - Start */

const AccessControl = (props) => {

    /* State Declaration - Start */

    /* useState - Start */

    const [resourcesData, setResourcesData] = useState([]);
    const [permissionsData, setPermissionsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [displayPermissionModal, setDisplayPermissionModal] = useState(false);
    const [resourceList, setResourceList] = useState([]);
    const [addPermission, setAddPermission] = useState({
        permissionCode: '',
        permissionName: '',
        resourcesId: ''
    });
    const [edit, setEdit] = useState(false);
    const [editId, setEditId] = useState('');
    const [menusData, setMenuData] = useState([]);
    const [displayMenuModal, setDisplayMenuModal] = useState(false);
    const [permissionList, setPermissionList] = useState([]);
    const [addMenu, setAddMenu] = useState({
        menuName: '',
        permissionCode: [],
        isSubMenu: false,
        menuId: '',
        isAdmin: false,
        menuIndex: ''
    });
    const [editMenuId, setEditMenuId] = useState('');
    const [menuList, setMenuList] = useState([]);

    /* useState - End */

    /* navigate Declaration */

    /* useRef - Start */

    const toastRef = useRef();

    /* useRef - End */

    /* useEffect - Start */

    useEffect(() => {
        getResources();
        getPermissions();
        getMenus();
    }, [])

    /* useEffect - End */

    /* State Declaration - End */

    /* Methods - Start */

    const getResources = () => {
        AdminService.getResources().then(data => {
            setResourcesData(data.data);
            let resourceList = [];
            data.data.forEach(resource => {
                if (resource.isActive) {
                    resourceList.push({
                        name: resource.resourceName,
                        value: resource.id
                    })
                }
            });
            setResourceList(resourceList);
        });
    }

    const getPermissions = () => {
        AdminService.getPermissions().then(data => {
            setPermissionsData(data.data);
            let permissionList = [];
            data.data.forEach(permission => {
                if (permission.isActive) {
                    permissionList.push({
                        name: permission.permissionCode,
                        value: permission.id
                    })
                }
            });
            setPermissionList(permissionList);
        });
    }

    const getMenus = () => {
        AdminService.getMenus().then(data => {
            setMenuData(data.data);
            let menuList = [];
            data.data.forEach(menu => {
                if (menu.isActive) {
                    menuList.push({
                        name: menu.menuName,
                        value: menu.id
                    })
                }
            });
            setMenuList(menuList);
        });
    }

    /* Methods - End */

    /* Render Templates - Start */

    const dialogFuncMap = {
        'displayPermissionModal': setDisplayPermissionModal,
        'displayMenuModal': setDisplayMenuModal
    }

    const onHide = (name, message, success) => {
        if (name) {
            dialogFuncMap[`${name}`](false);
        }
        message && toastRef.current.showToast(message)
        if (success) {
            getPermissions();
            getMenus();
        }
    }

    const onChangeResource = (e, dataId) => {
        setLoading(true);
        const data = {
            id: dataId,
            isActive: e.checked
        }
        AdminService.updateResources(data).then(data => {
            setLoading(false);
            !data.patched.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: data.patched.message
            })
            data.patched.success && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated sucessfully',
                message: data.patched.message
            })
            getResources();
        })
    }

    const onChangePermission = (e, dataId) => {
        setLoading(true);
        const data = {
            id: dataId,
            isActive: e.checked
        }
        AdminService.updatePermissionsStatus(data).then(data => {
            setLoading(false);
            !data.patched.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: data.patched.message
            })
            data.patched.success && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated sucessfully',
                message: data.patched.message
            })
            getPermissions();
        })
    }

    const onClickAddPermission = () => {
        setDisplayPermissionModal(true);
        setEdit(false);
        setAddPermission({
            permissionCode: '',
            permissionName: '',
            resourcesId: ''
        })
    }

    const onChangeResourceValue = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setAddPermission({
            ...addPermission,
            [name]: value
        })
    }

    const savePermission = () => {
        setLoading(true);
        let data = {
            permissionName: addPermission.permissionName,
            permissionCode: addPermission.permissionCode,
            resourcesId: addPermission.resourcesId
        }
        if (addPermission.permissionName !== '' && addPermission.permissionCode !== '' && addPermission.resourcesId !== '') {
            AdminService.addPermission(data).then(data => {
                setLoading(false);
                let message = {
                    type: data.posted.success ? 'success' : 'error',
                    summary: data.posted.success ? 'Success' : 'Something went wrong',
                    message: data.posted.message
                }
                let success = data.posted.success ? true : false
                onHide('displayPermissionModal', message, success);
            })
        } else {
            setLoading(false);
            toastRef.current.showToast({
                type: 'error',
                summary: 'Fill mandatory fields',
                message: 'Please fill all the mandatory fields'

            })
        }
    }

    const updatePermission = () => {
        setLoading(true);
        let data = {
            id: editId,
            permissionName: addPermission.permissionName,
            permissionCode: addPermission.permissionCode,
            resourcesId: addPermission.resourcesId
        }
        if (addPermission.permissionName !== '' && addPermission.permissionCode !== '' && addPermission.resourcesId !== '') {
            AdminService.updatePermission(data).then(data => {
                setLoading(false);
                let message = {
                    type: data.patched.success ? 'success' : 'error',
                    summary: data.patched.success ? 'Success' : 'Something went wrong',
                    message: data.patched.message
                }
                let success = data.patched.success ? true : false
                onHide('displayPermissionModal', message, success);
            })
        } else {
            setLoading(false);
            toastRef.current.showToast({
                type: 'error',
                summary: 'Fill mandatory fields',
                message: 'Please fill all the mandatory fields'

            })
        }
    }

    const renderPermission = (a) => {
        return (
            <div className="cancel-footer">
                <div className='align-center justify-end pb-0'>
                    {
                        edit ?
                            <Button type="submit" label="Update" className="create-button request-button" onClick={() => updatePermission()} /> :
                            <Button type="submit" label="Save" className="create-button request-button" onClick={() => savePermission()} />
                    }
                </div>
            </div>
        );
    }

    const onClickEditPermission = (name, id) => {
        setLoading(true);
        setEditId(id);
        AdminService.editPermission(id).then(data => {
            setLoading(false);
            setAddPermission({
                permissionName: data.data.permissionName,
                permissionCode: data.data.permissionCode,
                resourcesId: data.data.resourcesId
            })
            setEdit(true);
            dialogFuncMap[`${name}`](true);
        })
    }

    const onChangeMenu = (e, dataId) => {
        setLoading(true);
        const data = {
            id: dataId,
            isActive: e.checked
        }
        AdminService.updateMenusStatus(data).then(data => {
            setLoading(false);
            !data.patched.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: data.patched.message
            })
            data.patched.success && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated sucessfully',
                message: data.patched.message
            })
            getMenus();
        })
    }

    const onClickAddMenu = () => {
        setDisplayMenuModal(true);
        setEdit(false);
        setAddMenu({
            menuName: '',
            permissionCode: '',
            isSubMenu: false,
            menuId: '',
            isAdmin: false,
            menuIndex: ''
        })
    }

    const onChangePermissionValue = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setAddMenu({
            ...addMenu,
            [name]: value
        })
    }

    const onChangeSubMenu = (e) => {
        if(e.checked) {
            setAddMenu({
                ...addMenu,
                isSubMenu: e.checked
            })
        } else {
            setAddMenu({
                ...addMenu,
                isSubMenu: e.checked,
                menuId: ''
            })
        }
    }

    const onChangeMenuValue = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setAddMenu({
            ...addMenu,
            [name]: value
        })
    }

    const saveMenu = () => {
        setLoading(true);
        let data = {
            menuName: addMenu.menuName,
            permissionCode: addMenu.permissionCode,
            isSubMenu: addMenu.isSubMenu,
            menuId: addMenu.menuId,
            isAdmin: addMenu.isAdmin,
            menuIndex: addMenu.menuIndex
        }
        if (addMenu.menuName !== '' && addMenu.permissionCode !== '' && addMenu.menuIndex !== '' && (addMenu.isSubMenu === true ? addMenu.menuId !== '' : (addMenu.menuId === '' || addMenu.menuId == null))) {
            AdminService.addMenu(data).then(data => {
                setLoading(false);
                let message = {
                    type: data.posted.success ? 'success' : 'error',
                    summary: data.posted.success ? 'Success' : 'Something went wrong',
                    message: data.posted.message
                }
                let success = data.posted.success ? true : false
                onHide('displayMenuModal', message, success);
            })
        } else {
            setLoading(false);
            toastRef.current.showToast({
                type: 'error',
                summary: 'Fill mandatory fields',
                message: 'Please fill all the mandatory fields'

            })
        }
    }
    const updateMenu = () => {
        setLoading(true);
        let data = {
            id: editMenuId,
            menuName: addMenu.menuName,
            permissionCode: addMenu.permissionCode,
            isSubMenu: addMenu.isSubMenu,
            menuId: addMenu.menuId,
            isAdmin: addMenu.isAdmin,
            menuIndex: addMenu.menuIndex
        }
        if (addMenu.menuName !== '' && addMenu.permissionCode !== '' && addMenu.menuIndex !== '' && (addMenu.isSubMenu === true ? addMenu.menuId !== '' : (addMenu.menuId === '' || addMenu.menuId == null))) {
            AdminService.updateMenu(data).then(data => {
                setLoading(false);
                let message = {
                    type: data.patched.success ? 'success' : 'error',
                    summary: data.patched.success ? 'Success' : 'Something went wrong',
                    message: data.patched.message
                }
                let success = data.patched.success ? true : false
                onHide('displayMenuModal', message, success);
            })
        } else {
            setLoading(false);
            toastRef.current.showToast({
                type: 'error',
                summary: 'Fill mandatory fields',
                message: 'Please fill all the mandatory fields'

            })
        }
    }

    const renderMenu = (a) => {
        return (
            <div className="cancel-footer">
                <div className='align-center justify-end pb-0'>
                    {
                        edit ?
                            <Button type="submit" label="Update" className="create-button request-button" onClick={() => updateMenu()} /> :
                            <Button type="submit" label="Save" className="create-button request-button" onClick={() => saveMenu()} />
                    }
                </div>
            </div>
        );
    }

    const onClickEditMenu = (name, id) => {
        setLoading(true);
        setEditMenuId(id);
        AdminService.editMenu(id).then(data => {
            setLoading(false);
            setAddMenu({
                menuName: data.data.menuName,
                permissionCode: data.data.permissionCode,
                isSubMenu: data.data.isSubMenu,
                menuId: data.data.menuId,
                isAdmin: data.data.isAdmin,
                menuIndex: data.data.menuIndex
            })
            setEdit(true);
            dialogFuncMap[`${name}`](true);
        })
    }

    /* Render Templates - End */

    /* Render View Return - Start */

    return (
        <div>
            <ToastElement ref={toastRef} />
            <Loader loading={loading} />
            {/* Resource Code */}
            <p>Resources</p>
            <div className="roles-div">
                {
                    resourcesData.length > 0 && resourcesData.map((data) => (
                        <div className="flex-items relative">
                            <CheckboxElement
                                key={data.id}
                                inputId={data.id}
                                value={data.resourceName}
                                checked={data.isActive}
                                onChangeCheck={(e, dataId) => onChangeResource(e, dataId)}
                            />
                        </div>
                    ))
                }
            </div>
            {/* Permission Code */}
            <div className="d-flex justify-between">
                <p>Permissions</p>
                <div>
                    <ButtonElement
                        label="Create Permission"
                        icon="pi pi-plus"
                        onClickButton={() => onClickAddPermission()}
                    />
                </div>
            </div>
            <div className="roles-div">
                {
                    permissionsData.length > 0 && permissionsData.map((data) => (
                        <div className="flex-items relative">
                            <CheckboxElement
                                key={data.id}
                                inputId={data.id}
                                value={data.permissionName}
                                checked={data.isActive}
                                onChangeCheck={(e, dataId) => onChangePermission(e, dataId)}
                            />
                            <span onClick={() => onClickEditPermission('displayPermissionModal', data.id)}><i className="pi pi-pencil roles-edit"></i></span>
                        </div>
                    ))
                }
            </div>
            {
                displayPermissionModal &&
                <Dialog visible={true} header={edit ? "Update Permission" : "Add Permission"} onHide={() => onHide('displayPermissionModal')} footer={renderPermission('displayPermissionModal')} breakpoints={{ '960px': '75vw' }} draggable={false}>
                    <div className="cancel-request">
                        <div className="p-grid">
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray">Permission <span className='text-red'>*</span></p>
                                <TextboxElement
                                    placeholder="Permission"
                                    onChangeText={(e) => setAddPermission({ ...addPermission, permissionName: e.target.value })}
                                    value={addPermission.permissionName}
                                    name="permissionName"
                                />
                            </div>
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray">Code <span className='text-red'>*</span></p>
                                <TextboxElement
                                    placeholder="Code"
                                    onChangeText={(e) => setAddPermission({ ...addPermission, permissionCode: e.target.value })}
                                    value={addPermission.permissionCode}
                                    name="permissionCode"
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray m-0">Resource <span className='text-red'>*</span></p>
                                <SingleSelectElement
                                    name="resourcesId"
                                    placeholder="Select Resource"
                                    filter={false}
                                    optionLabel="name"
                                    options={resourceList}
                                    value={addPermission.resourcesId}
                                    onChangeValue={(e) => onChangeResourceValue(e)}
                                    filterBy='name'
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
            }
            {/* Menu Code */}
            <div className="d-flex justify-between">
                <p>Menus</p>
                <div>
                    <ButtonElement
                        label="Create Menu"
                        icon="pi pi-plus"
                        onClickButton={() => onClickAddMenu()}
                    />
                </div>
            </div>
            <div className="roles-div">
                {
                    menusData.length > 0 && menusData.map((data) => (
                        <div className="flex-items relative">
                            <CheckboxElement
                                key={data.id}
                                inputId={data.id}
                                value={data.menuName}
                                checked={data.isActive}
                                onChangeCheck={(e, dataId) => onChangeMenu(e, dataId)}
                            />
                            <span onClick={() => onClickEditMenu('displayMenuModal', data.id)}><i className="pi pi-pencil roles-edit"></i></span>
                        </div>
                    ))
                }
            </div>
            {
                displayMenuModal &&
                <Dialog visible={true} header={edit ? "Update Menu" : "Add Menu"} onHide={() => onHide('displayMenuModal')} footer={renderMenu('displayMenuModal')} breakpoints={{ '960px': '75vw' }} draggable={false}>
                    <div className="cancel-request">
                        <div className="p-grid">
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray mt-0 mb-6">Menu <span className='text-red'>*</span></p>
                                <TextboxElement
                                    placeholder="Menu"
                                    onChangeText={(e) => setAddMenu({ ...addMenu, menuName: e.target.value })}
                                    value={addMenu.menuName}
                                    name="menuName"
                                />
                            </div>
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray mt-0 mb-0">Permission Code <span className='text-red'>*</span></p>
                                <MultiSelectElement
                                    name="permissionCode"
                                    placeholder="Select Code"
                                    className="p-inputtext-multiselect"
                                    options={permissionList}
                                    onChangeValue={(e) => onChangePermissionValue(e)}
                                    value={addMenu.permissionCode}
                                    optionLabel='name'
                                    maxSelectLabel={1}
                                />
                            </div>
                        </div>
                        <div className="p-grid align-items-center">
                            <div className="p-col-12 p-sm-6 fs-13 text-lightgray">
                                <CheckboxElement
                                    key='1'
                                    inputId='1'
                                    checked={addMenu.isAdmin}
                                    onChangeCheck={(e) => setAddMenu({ ...addMenu, isAdmin: e.checked })}
                                    value='Is Admin'
                                />
                            </div>
                            <div className="p-col-12 p-sm-6 fs-13 text-lightgray">
                                <p className="font-normal fs-13 text-lightgray">Menu Index <span className='text-red'>*</span></p>
                                <TextboxElement
                                    placeholder="Menu Index"
                                    onChangeText={(e) => setAddMenu({ ...addMenu, menuIndex: e.target.value })}
                                    value={addMenu.menuIndex}
                                    name="menuIndex"
                                />
                            </div>
                        </div>
                        <div className="p-grid align-items-center">
                            <div className="p-col-12 p-sm-6 fs-13 text-lightgray">
                                <CheckboxElement
                                    key='2'
                                    inputId='2'
                                    checked={addMenu.isSubMenu}
                                    onChangeCheck={(e) => onChangeSubMenu(e)}
                                    value='Is Sub Menu'
                                />
                            </div>
                            {
                                addMenu.isSubMenu &&
                                <div className="p-col-12 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray m-0">Main menu <span className='text-red'>*</span></p>
                                    <SingleSelectElement
                                        name="menuId"
                                        placeholder="Select menu"
                                        filter={false}
                                        optionLabel="name"
                                        options={menuList}
                                        value={addMenu.menuId}
                                        onChangeValue={(e) => onChangeMenuValue(e)}
                                        filterBy='name'
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </Dialog>
            }
        </div>
    )

    /* Render View Return - End */

}

/* Function - End */

/* Export default functionName; */

export default AccessControl;