/* Import Section - Start */

/* React Imports - Start */

import React, { useEffect, useState } from "react";

/* React Imports - End */

/* Plugin Imports - Start */

import { RadioButton } from "primereact/radiobutton";

/* Plugin Imports - End */

/* Import Section - End */

/* Function - Start */

const RadioElement = (props) => {

    /* State Declaration - Start */

    /* useState - Start */

    const [radioOptions] = useState(props.options);
    const [selectedRadioValue, setSelectedValue] = useState("");

    /* useState - End */

    /* State Declaration - End */

    /* Methods - Start */

    const setRadio = (e) => {
        setSelectedValue(e.value)
        props.onChangeRadio(e.value)
        if(props.survey === true){
            props.radioClick(e)

        }
    }

    /* Methods - End */

    /* useEffect - Start */

    useEffect(() => {
        if(props.value != undefined) {
            setSelectedValue(props.value)
        }
	}, [props.value])

    /* useEffect - End */

    /* Render View Return - Start */

    return (
        radioOptions !== undefined && radioOptions.length > 0 && radioOptions.map((option) => {
            return (
                <div key={Math.random() * 10} className={`field-radiobutton ${props.className}`}>
                    <RadioButton
                        inputId={option.key}
                        name="radioGroup"
                        value={option.name}
                        checked={selectedRadioValue === option.name}
                        onChange={(e) => {setRadio(e)}}
                        disabled={props.disabled}
                    />
                    <label className="label-radio" htmlFor={option.key}>{option.name}</label>
                </div>
            )
        })
    );

    /* Render View Return - End */
};

/* Function - End */

/* Export default functionName; */

export default RadioElement;