/* Import Section - Start */

/* React Imports - Start */


import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

/* React Imports - End */

import { Button } from "primereact/button";

/* Project Components Imports - Start */

import DataTableElement from "../uiComponents/DataTableElement";
import SingleSelectElement from "../uiComponents/SingleSelectElement";
import EmployeesService from "../../services/employees/EmployeesService";
import ToastElement from "../uiComponents/ToastElement";
import Cookies from 'universal-cookie';
import Loader from "../uiComponents/Loader";
import Moment from 'moment';
import AdminFilterElement from '../uiComponents/AdminFilters';

/* Project Components Imports - End */

/* Plugin Imports - Start */

import _ from 'lodash';
import EmployeeCreationPage from "./EmployeeCreationPage";


/* Plugin Imports - End */

/* Import Section - End */

/* Function - Start */

const Employees = (props) => {

    /* State Declaration - Start */

    /* useState - Start */

    const [employeesList, setEmployeesList] = useState([]);
    const [button, setButton] = useState(false);
    const [datatable, setDatatable] = useState(true);
    const [create, setCreate] = useState(false);
    const [edit, setEdit] = useState(false);
    const [currentBu, setCurrentBU] = useState("");
    const [editData, setEditData] = useState([]);
    const [buUnit, setBuUnit] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dateFormat, setDateFormat] = useState("");
    const [buEnable, setBuEnable] = useState(false);

    /* useState - End */

    /* navigate Declaration */

    let navigate = useNavigate();
    let location = useLocation();
    const cookies = new Cookies();

    /* useRef - Start */

    const toastRef = useRef();

    /* useRef - End */

    /* useEffect - Start */

    useEffect(() => {
        createButton();
        getActiveBu();
    }, [])

    useEffect(() => {
        var roles = cookies.get('roles');
        if (roles.includes('Admin')) {
            setBuEnable(true);
        }
    }, [])

    //Function call when create and edit state is updated
    useEffect(() => {
        if (location.state == null) {
            setCreate(false);
            setEdit(false);
            setDatatable(true)
            props.employeeListPage(true);
            props.employeeCreationPage(false);
            props.employeeEditPage(false)
        } else if (create) {
            props.employeeCreationPage(true);
        } else if (edit) {
            props.employeeEditPage(true);
        } else if (!create) {
            props.employeeCreationPage(false);
        } else if (!edit) {
            props.employeeCreationPage(false);
        }
    }, [create, edit, location.state])

    /* useEffect - End */

    /* State Declaration - End */

    /* Methods - Start */

    var curBuId = currentBu?.id;

    //API hit-Employee list
    const getEmployeeList = (curBuId) => {
        setLoading(true);
        EmployeesService.getEmployees(curBuId).then(data => {
            setLoading(false);
            setEmployeesList(data.data.rows);
        })
    }
    //API hit-filter Employee list
    const getEmployeeFilterList = (curBuId, filter) => {
        setLoading(true);
            EmployeesService.getFilterEmployees(curBuId, filter).then(data => {
                setLoading(false);
                setEmployeesList(data.data.rows);
            })
        }
    //getting active bu for rendering in the select element
    const getActiveBu = () => {
        EmployeesService.getActiveBu().then(data => {
            setBuUnit(data.data)
            var bu = cookies.get('buId')
            if (bu) {
                setCurrentBU(_.find(data.data, { id: bu }))
                getEmployeeList(bu)
            }
        })
    }

    const getRoleByBuId = (edit) => {
        EmployeesService.getRoleByBuId(edit.BusinessUnit.id).then(data => {
            var filteredRole = [];
            edit && edit.roles?.map(role => {
                data.data.map(roles => {
                    if (roles.role == role) {
                        filteredRole.push(roles.id);
                    }
                })
            })
            setRoleList(filteredRole);
            setEditData(edit);
            var dateFormat = Moment(edit?.dateOfJoin, 'DD-MM-YYYY').format('MM-DD-YYYY');
            setDateFormat(dateFormat);
            setDatatable(false);
            setEdit(true);
        });
    }

    const selectedId = (selectedId) => {
        return ''
    }

    //Handling change bu from the top select element
    const buChange = (e) => {
        getEmployeeList(e.value.id)
        setCurrentBU(e.value)
    }

    //Edit User List By Id API call and redirecting to Edit Page
    const editUser = (id) => {
        setLoading(true);
        EmployeesService.editUser(id).then(data => {
            setLoading(false);
            console.log('edituser api res:', data)
            getRoleByBuId(data.data);
        })
        navigate(`/employees/edit`, { state: { isEdit: true } });
    }

    //On Click Edit User Action Icon
    const editEmployee = (id) => {
        editUser(id);
    }

    /* Methods - End */

    /* Render Templates - Start */

    //Template for giving space for first name and Last Name
    const nameTemplate = (rowData) => {
        return rowData.firstName + ' ' + rowData.lastName;
    }
    
    // New WFH
    // Leave balance Personal Time Off
    const leaveBalance = (rowData) => {
        const leaveBalance = rowData?.LeaveBalances
        for (var i = 0; i < leaveBalance?.length; i++) {
            if (leaveBalance[i]?.ConfigLeaveType?.isPrimary == true) {
                return leaveBalance[i]?.balanceLeaves
            }
        }
    }

    //Setting Create/Edit true/false based on BusinessCreation/Updation Page
    const onSuccess = (data, createUser, updateUser) => {
        if (createUser) {
            if (!data.posted.success) {
                setEdit(false);
                setCreate(true);
                setDatatable(false);
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Failed',
                    message: data.posted.message
                })
            } else {
                setEdit(false);
                setCreate(false);
                setDatatable(true);
                toastRef.current.showToast({
                    type: 'success',
                    summary: 'Successsfully',
                    message: data.posted.message
                })
                navigate(`/employees`);
            }
        } else if (updateUser) {
            if (!data.patched.success) {
                setEdit(true);
                setCreate(false);
                setDatatable(false);
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Failed',
                    message: data.patched.message
                })
            } else {
                setEdit(false);
                setCreate(false);
                setDatatable(true);
                toastRef.current.showToast({
                    type: 'success',
                    summary: 'Successsfully',
                    message: data.patched.message
                })
                navigate(`/employees`);
            }
        }
        getEmployeeList(curBuId);
    }

    //Create a Button on top of Datatable if needed
    const createButton = () => {
        setButton(true);
        setDatatable(true);
    }

    //Redirecting to Create User Page
    const createView = () => {
        setDatatable(false);
        setCreate(true);
        navigate(`/employees/create`, { state: { isEdit: false } });
    }

    //Edit Icon for Action Template
    const editTemplate = (rowData) => {
        return (
            <span onClick={() => editEmployee(rowData.id)} className="action">
                <i className="pi pi-pencil leave-edit"></i>
            </span>
        )
    }
    const employeeFilter = (filter) => {
        let status = filter.status;
        getEmployeeFilterList(curBuId, status);
    }
    const statusList = [
        { name: 'ACTIVATED', value: 'ACTIVATED' },
        { name: 'DEACTIVATED', value: 'DEACTIVATED' }
    ];

    const employeestatusTemplate = (rowData) => {
        return (
            <div>
                {
                    <div>
                        {rowData.accountStatus === "ACTIVATED" ? <div className="status status-approved"> {rowData.accountStatus.toLowerCase()}</div> : rowData.accountStatus === "DEACTIVATED" ? <div className="status status-cancelled">{rowData.accountStatus.toLowerCase()}</div> : <div className="status status-requested">{rowData.accountStatus.toLowerCase()}</div>}
                    </div>
                }
            </div>
        )
    }

    /* Render Templates - End */

    //Datatable columns header and templates

    const columns = [
        { field: "code", header: "Emp Id", sortable: true, style: { width: '100px'} },
        { field: "firstName", header: "Name", body: nameTemplate, sortable: true },
        { field: "UserStatus.status", header: "Employee Status", sortable: true, style: { width: '160px'} },
        { field: "Grade.grade", header: "Grade", sortable: true, style: { width: '100px'} },
        { field: "Designation.designation", header: "Designation", sortable: true },
        { field: "LeaveBalances.balanceLeaves", header: "Leave Balance", body: leaveBalance, sortable: false, style: { width: '140px'} }, //New WFH
        { field: "accountStatus", header: "Account Status", body: employeestatusTemplate, sortable: true, style: { width: '150px'} },
        { field: "icon", header: "Actions", body: editTemplate, sortable: false }
    ];

    /* Render View Return - Start */

    return (
        <div className="mt-10 business-unit employee buemployees">
            <ToastElement ref={toastRef} />
            <Loader loading={loading} />
            {
                datatable &&
                <DataTableElement
                    value={employeesList}
                    columns={columns}
                    button={button}
                    createButton={
                        <div className="d-flex employee-buttons">
                            <SingleSelectElement
                                placeholder="Select BU"
                                value={currentBu}
                                options={buUnit}
                                optionLabel="name"
                                onChangeValue={(e) => buChange(e)}
                                defaultValue="code"
                                disabled={buEnable ? false : true}
                                filterBy='name'
                            />
                            <Button label="Create User" onClick={createView} icon="pi pi-plus" />
                        </div>
                    }
                    filterButton={
                        <div>
                            {
                                <div className="userFilter" style={{marginRight: 20}}>
                                    <AdminFilterElement
                                        singleSelect={statusList}
                                        label='projectStatus'
                                        filter={employeeFilter}
                                        getList={getActiveBu}
                                    />
                                </div>
                            }
                        </div>
                    }
                    placeholder="Search Employee Id, Name"
                    selectedId={selectedId}
                    paginationView={false}
                    header={true}
                    search={true}
                />
            }
            {
                create &&
                <EmployeeCreationPage
                    type={'create'}
                    onSuccess={(create, edit, data, createUser, updateUser) => onSuccess(create, edit, data, createUser, updateUser)}
                    currentBu={currentBu}
                />
            }
            {
                edit &&
                <EmployeeCreationPage
                    type={'edit'}
                    editData={editData}
                    roleList={roleList}
                    dateFormat={dateFormat}
                    onSuccess={(create, edit, data, createUser, updateUser) => onSuccess(create, edit, data, createUser, updateUser)}
                />
            }
        </div>
    )
    /* Render View Return - End */
}
/* Function - End */

/* Export default functionName; */
export default Employees;