/* Import Section - Start */

/* React Imports - Start */

import React, { useEffect, useState } from 'react'

/* React Imports - End */

/* Plugin Imports - Start */

import { Dropdown } from 'primereact/dropdown';

/* Plugin Imports - End */

/* Import Section - End */

/* Function - Start */

const SingleSelectElement = (props) => {

    /* State Declaration - Start */

    /* useState - Start */

    const [selectedValue, setSelectedValue] = useState(props.value);

    /* useState - End */

    /* State Declaration - End */

    /* Methods - Start */

    useEffect(() => {
        setSelectedValue(props.value ? props.value : "")
    }, [props.value])

    const onValueChange = (e) => {
        setSelectedValue(e.value)
        props.onChangeValue(e)
    }

    /* Methods - End */

    /* Render View Return - Start */

    return (
        <div>
            <h5 className='dropdown-heading'>{props.dropdownName}</h5>
            <Dropdown
                name={props.name}
                id={props.id}
                value={selectedValue}
                options={props.options}
                onChange={(e) => onValueChange(e)}
                optionLabel={props.optionLabel}
                optionValue={props.optionValue}
                filter
                filterBy={props.filterBy}
                optionDisabled="true"
                placeholder={props.placeholder}
                className={props.className}
                disabled={props.disabled}
                defaultValue={props.defaultValue}
                tooltip={props.tooltip}
                showClear={props.showClear}
            />
        </div>
    );

    /* Render View Return - End */

};

/* Function - End */

/* Export default functionName; */

export default SingleSelectElement