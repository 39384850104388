/* Import Section - Start */
/* React Imports - Start */
import React from "react";
/* React Imports - End */

/* Plugin Imports - Start */
import { Button } from 'primereact/button';
/* Plugin Imports - End */
/* Import Section - End */

/* Function - Start */
const ButtonElement = (props) => {
    /* Methods - Start */
    const buttonClick = () => {
        props.onClickButton();
    }
    /* Methods - End */

    /* Render View Return - Start */
    return(
        <Button
            className={props.className}
            label={props.label}
            icon={props.icon}
            iconPos={props.iconPos}
            onClick={buttonClick}
            tooltip={props.tooltip}
            disabled={props.disabled}
        />
    );
    /* Render View Return - End */
}
/* Function - End */
/* Export default functionName; */
export default ButtonElement;