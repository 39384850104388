/* Import Section - Start */
/* React Imports - Start */
import React, { useEffect, useState } from "react";
/* React Imports - End */

/* Plugin Imports - Start */
import { ToggleButton } from 'primereact/togglebutton';
/* Plugin Imports - End */
/* Import Section - End */

/* Function - Start */
const ToggleElement = (props) => {
    /* State Declaration - Start */
    /* useState - Start */
    const [checked, setChecked] = useState(props.value);
    /* useState - End */

    /* useEffect - Start */
    useEffect(() => {
        //  if (props.statusId) {
        //     props.onChange(props.statusId, checked)
        // } else {
        //     props.onChange(checked)
        // }
    }, [checked])
    /* useEffect - End */
    /* State Declaration - End */
    /* Methods - Start */
    const toggleChange = (e) => {
        if (props.statusId) {
            props.onChange(props.statusId, e.value)
            setChecked(e.value)
        } else {
            props.onChange(e.value)
            setChecked(e.value)
        }
    }
    /* Methods - End */

    /* Render View Return - Start */
    return (
        <ToggleButton
            checked={checked}
            onChange={toggleChange}
            onIcon={props.onIcon}
            offIcon={props.offIcon}
            onLabel={props.onLabel}
            offLabel={props.offLabel}
            className={props.className}
            disabled={props.disabled}
        />
    );
    /* Render View Return - End */
};
/* Function - End */
/* Export default functionName; */
export default ToggleElement;
