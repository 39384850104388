import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Cookies from 'universal-cookie';
import Moment from 'moment';
import momentTz from 'moment-timezone';

import TrackingSystemService from '../../services/trackingSystem/TrackingSystemService';
import DateTimeFunctions from '../../util/DateTimeFunctions';
import DataService from '../../util/DataService';
import saveAs from '../../assets/js/FileSaver';

import Loader from '../uiComponents/Loader';
import ToastElement from '../uiComponents/ToastElement';
import DataTableElement from '../uiComponents/DataTableElement'
import MultiSelectElement from '../uiComponents/MultiSelectElement';
import SingleSelectElement from '../uiComponents/SingleSelectElement';
import TextboxElement from '../uiComponents/TextboxElement';
import CheckboxElement from '../uiComponents/CheckboxElement';
import ToggleElement from '../uiComponents/ToggleElement';
import ButtonElement from '../uiComponents/ButtonElement';
import { FileUpload } from 'primereact/fileupload';

import { Tooltip } from 'primereact/tooltip';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabView, TabPanel } from 'primereact/tabview';

import { useStopwatch } from 'react-timer-hook';

import ReactSummernoteLite from '@easylogic/react-summernote';
import '@easylogic/react-summernote/dist/index.css';

import avatarFemale from '../../assets/images/AvatarFemale.png';
import avatar from '../../assets/images/Avatar.png';

const JobDetailedView = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const selectedData = location.state.selectedData || {};
    const jobId = selectedData.id;
    const jobBuId = selectedData.buId;

    const [adminPermission, setAdminPermission] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filterData, setFilterData] = useState();
    const [jobData, setJobData] = useState(null);

    const [deadline, setDeadline] = useState('');
    const [isTentative, setIsTentative] = useState(false);
    const [status, setStatus] = useState();
    const [priority, setPriority] = useState();
    const [totalJobEstimate, setTotalJobEstimate] = useState();
    const [totalJobSpentTime, setTotalJobSpentTime] = useState();

    const [stopReason, setStopReason] = useState('');

    const [jobCompleteComments, setJobCompleteComments] = useState();
    const [comment, setComment] = useState('');

    const [qmData, setQmData] = useState([{ isEditable: true, jobUserValue: 0, user: {}, error: {}, comments: '' }]); // initialize with one empty element to display for first time
    const [qmUsers, setQmUsers] = useState();
    const [qmJobTypes, setQmJobTypes] = useState();
    const [errorTypes, setErrorTypes] = useState();

    const [manualTimeUsers, setManualTimeUsers] = useState();
    const [manualTimeData, setManualTimeData] = useState([{ user: {}, hours: 0, minutes: 0, seconds: 0, totalTimeSpent: { days: 0, hours: 0, minutes: 0, seconds: 0 } }]);

    const [outputFiles, setOutputFiles] = useState([]);

    const [requestData, setRequestData] = useState([]);
    const [usageData, setUsageData] = useState([]);
    const [clientId, setClientId] = useState('');
    const [parentLinks, setParentLinks] = useState();
    const [reviewJE, setReviewJE] = useState(null);
    const [productionJE, setProductionJE] = useState(null);
    const [qcJE, setQcJE] = useState(null);
    const [bpsUsers, setBpsUsers] = useState(null);
    const [qcUsers, setQcUsers] = useState(null);
    const [jobTypes, setJobTypes] = useState([
        {
            value: {},
            jobTypeValue: ''
        }
    ]);
    const [sheduler, setSheduler] = useState([{
        user: {},
        range: "",
        total: null,
        productionJE: null,
    }]);

    const [activeIndex, setActiveIndex] = useState();
    const [commentData, setCommentData] = useState([]);
    const [worklogData, setWorkLogData] = useState([]);
    const [recentActivityData, setRecentActivityData] = useState(null);
    const [qualityMetricsData, setQualityMetricsData] = useState([]);
    const [qmTotalRows, setQmTotalRows] = useState(null);
    const [deleteData, setDeleteData] = useState(null);

    const [showStopWatch, setShowStopWatch] = useState(false);
    const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
    const [displayStopReason, setDisplayStopReason] = useState(false);
    const [displayJobComment, setDisplayJobComment] = useState(false);
    const [displayQmComments, setDisplayQmComments] = useState(false);
    const [displayInput, setDisplayInput] = useState(false);
    const [displayTemplate, setDisplayTemplate] = useState(false);
    const [displayOutput, setDisplayOutput] = useState(false);
    const [displayManualTime, setDisplayManualTime] = useState(false);

    const [displayEditor, setDisplayEditor] = useState(false);
    const [displayComment, setDisplayComment] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        reset,
    } = useStopwatch({ autoStart: false });

    let timeZoneIST = false;
    if (Moment().utcOffset() === 330) {
        timeZoneIST = true;
    }

    const cookies = new Cookies();
    const buId = cookies.get('buId')
    const userId = cookies.get('userId');
    const currentDate = new Date();

    const toastRef = useRef();

    /* Summernote Reference */
    let editor = null;

    let commentEditor = null;

    useEffect(() => {
        getFilterData();
        getBasicData();
        getIsAdmin(userId);
    }, [])

    useEffect(() => {
        if (filterData) {
            getJobDetails();
            getComments(jobId);
            getWorkLogsById(jobId);
            allJobQcMetrics(jobId);
        }
    }, [filterData]);

    const getIsAdmin = (userId) => {
        TrackingSystemService.adminPermission(userId).then(data => {
            setAdminPermission(data.success);
        })
    }

    const getFilterData = () => {
        TrackingSystemService.getFilterData(jobBuId).then(data => {
            setFilterData(data);
        })
    }

    const getBasicData = () => {
        TrackingSystemService.getBasicData().then(data => {
            setRequestData(data.requestTypes);
            setUsageData(data.usages);
        })
    }

    const getJobDetails = () => {
        setLoading(true);
        TrackingSystemService.getJobDetails(jobId).then(data => {
            setJobData(data);

            let deadlineDate = new Date(parseInt(data.jobInfo.deadline_ms));
            data.jobInfo.offsetHrs = momentTz().tz('Europe/London').utcOffset();
            data.jobInfo.deadline = new Date(deadlineDate.getTime() + (deadlineDate.getTimezoneOffset() * 60000) + (data.jobInfo.offsetHrs) * 60000);
            data.jobInfo.offsetHrs1 = momentTz().tz('Asia/Kolkata').utcOffset();
            data.jobInfo.deadlineIST = new Date(deadlineDate.getTime() + (deadlineDate.getTimezoneOffset() * 60000) + (data.jobInfo.offsetHrs1) * 60000);

            if (timeZoneIST) {
                setDeadline(data.jobInfo.deadlineIST);
            }
            else {
                setDeadline(data.jobInfo.deadline);
            }

            setIsTentative(data.jobInfo.isTentative);

            const jobEstTime = DateTimeFunctions.minsToTimeObj(Number(data.jobInfo.ER) * 6);
            setTotalJobEstimate(jobEstTime);

            const jobSpentTime = DateTimeFunctions.msToTimeObj(Number(data.totalStopwatchTime));
            setTotalJobSpentTime(jobSpentTime);

            filterData?.statuses.forEach(element => {
                if (element.id === data.jobInfo.statusId) {
                    setStatus(element);
                    if (element.isStopwatch) {
                        setShowStopWatch(true);
                        getStopWatchTimeLog(data.jobInfo);
                    }
                    else {
                        setShowStopWatch(false);
                    }

                    // if (element.isAutostart) {
                    //     reset('', true)
                    // }
                    // else {
                    //     reset('', false)
                    // }
                }
            })

            filterData?.priorities.forEach(element => {
                if (element.id === data.jobInfo.priorityId) {
                    setPriority(element);
                }
            })

            setOutputFiles(data.outputFiles)

            setReviewJE(data.jobInfo.reviewJE);
            setProductionJE(data.jobInfo.productionJE);
            setQcJE(data.jobInfo.qcJE);

            if (data.jobsheduler.length > 0) {
                getSheduleUsers(data);
            }
            setClientId(data.jobInfo.clientId);
            getParentLink(data.jobInfo.clientId);
            if (data.jobInfo.jobType?.length > 0) {
                getJobTypes(data);
            }
            getBpsUsers(data);
            getQCUsers(data);
            if (data.recentActivity.length > 0) {
                getRecentActivities(data);
            }
        }).finally(() => setLoading(false))
    }

    const getStopWatchTimeLog = (jobInfo) => {
        TrackingSystemService.getStopWatchTimeLog(jobInfo.id, userId, jobInfo.statusId).then(data => {
            const stopwatchOffset = new Date();
            if (data && data.length > 0) {
                const sortedLogs = _.orderBy(data, ['createdAt'], ['desc']);
                const recentTime = parseInt((_.head(sortedLogs)).time);
                stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + recentTime / 1000);
            }
            else {
                stopwatchOffset.setSeconds(stopwatchOffset.getSeconds());
            }
            reset(stopwatchOffset, false);
        })
    }

    const getJobTypes = (data) => {
        const dataJobTypes = []
        data.jobInfo.jobType?.forEach(savedJobType => {
            data.jobTypes.forEach(mainJobType => {
                if (savedJobType.jobType === mainJobType.id) {
                    dataJobTypes.push({ value: mainJobType, jobTypeValue: savedJobType.jobTypeValue });
                }
            })
        })
        if (dataJobTypes.length == 0) {
            dataJobTypes.push({
                value: {},
                jobTypeValue: ''
            })
        }
        setJobTypes(dataJobTypes);
    }

    const getSheduleUsers = (data) => {
        const dataSchedulers = []
        data.jobsheduler?.forEach(savedUser => {
            data.bpsQCusers.forEach(mainUser => {
                if (savedUser.scheduleUserId == mainUser.id) {
                    dataSchedulers.push({ user: mainUser, id: savedUser.id, productionJE: savedUser.productionJE, range: savedUser.range, total: savedUser.total });
                }
            })
        })
        if (dataSchedulers.length == 0) {
            dataSchedulers.push({
                user: {},
                range: "",
                total: null,
                productionJE: null,
            })
        }
        setSheduler(dataSchedulers);
    }

    const getRecentActivities = (data) => {
        let recentData = data.recentActivity.map(activity => {
            if (activity.activity === 'deadline' || activity.activity === 'deadlineIST') {
                activity.oldValue = new Date(activity.oldValue);
                activity.newValue = new Date(activity.newValue);
            } else if (activity.activity === 'deadline_ms') {
                activity.oldValue = new Date(parseInt(activity.oldValue));
                activity.newValue = new Date(parseInt(activity.newValue));
            } else if (activity.activity === 'bpsUsers' || activity.activity === 'qcUsers') {
                if (activity.oldValue && activity.oldValue.length > 0) {
                    activity.userLen = activity.oldValue.length > 1;
                    activity.oldValue = activity.oldValue.map(u => u.username).join(', ');
                }
                if (activity.newValue && activity.newValue.length > 0) {
                    activity.userLen = activity.newValue.length > 1;
                    activity.newValue = activity.newValue.map(u => u.username).join(', ');
                }
            }
            return activity;
        });
        setRecentActivityData(_.orderBy(recentData, "changedTime", "desc"));
    }


    const getBpsUsers = (data) => {
        const dataBpsUsers = []
        data.jobInfo.bpsUsers?.forEach(savedBpsUsers => {
            data.bpsQCusers.forEach(mainUser => {
                if (savedBpsUsers === mainUser.id) {
                    dataBpsUsers.push(mainUser);
                }
            })
        })
        setBpsUsers(dataBpsUsers);
    }

    const getQCUsers = (data) => {
        const dataQCUsers = []
        data.jobInfo.qcUsers?.forEach(savedQCUsers => {
            data.bpsQCusers.forEach(mainUser => {
                if (savedQCUsers === mainUser.id) {
                    dataQCUsers.push(mainUser);
                }
            })
        })
        setQcUsers(dataQCUsers);
    }

    const getParentLink = (clientId) => {
        TrackingSystemService.getJobsByClientId(clientId).then(data => {
            setParentLinks(data);
        })
    }

    const addJobTypeRow = (index) => {
        const updatedRows = [...jobTypes];
        updatedRows.splice(index + 1, 0, {
            value: {},
            jobTypeValue: ''
        });
        setJobTypes(updatedRows)
    };

    const removeJobTypeRow = (index) => {
        const updatedRows = [...jobTypes];
        updatedRows.splice(index, 1);
        setJobTypes(updatedRows);
    };

    const usageOptions = usageData?.map((option) => {
        return {
            ...option,
            disabled: true
        }
    });

    const requestTypeOptions = requestData?.map((option) => {
        return {
            ...option,
            disabled: true
        }
    });

    const paretLinksOptions = parentLinks?.map((option) => {
        return {
            ...option,
            disabled: true
        }
    });

    const onChangeJobType = (e, index) => {

        let updatedRows = [...jobTypes];

        if (e.target.id == 'jobType') {
            updatedRows[index]['value'] = e.target.value;
        } else if (e.target.id == 'jobTypeValue') {
            updatedRows[index]['jobTypeValue'] = e.target.value;
        }
        setJobTypes(updatedRows);
    }

    const onChangeBpsUsers = (e) => {

        setBpsUsers(e.value);
        if (e.value.length == 0) {
            setSheduler([
                // {
                //     user: {},
                //     range: "",
                //     total: '',
                //     productionJE: "",
                // }
            ])
        }
        else if (sheduler.length == 0) {
            setSheduler([
                {
                    user: {},
                    range: "",
                    total: null,
                    productionJE: null,
                }
            ])
        }
    }

    const addSheduler = (index) => {
        const updatedRows = [...sheduler];
        updatedRows.splice(index + 1, 0, {
            user: {},
            range: "",
            total: null,
            productionJE: null,
        });
        setSheduler(updatedRows)
    };

    const removeSheduler = (index, row) => {
        const updatedRows = [...sheduler];
        if ((_.has(row.user, 'id'))) {
            const schedulerInfo = [row.user.id];
            TrackingSystemService.qcCommentsCheck(jobId, schedulerInfo).then(data => {
                if (data === false) {
                    toastRef.current.showToast({
                        type: 'error',
                        // summary: 'Update failed',
                        message: 'This user has QC comments'
                    })
                }
                else {
                    updatedRows.splice(index, 1);
                    setSheduler(updatedRows);
                }
            })
        }
        else {
            updatedRows.splice(index, 1);
            setSheduler(updatedRows);
        }
    };

    const onChangeShedule = (e, index) => {

        let updatedRows = [...sheduler];

        if (e.target.id == 'range') {
            updatedRows[index]['range'] = e.target.value;
        } else if (e.target.id === 'sheduleUser') {
            updatedRows[index]['user'] = e.target.value;
        } else {
            updatedRows[index][e.target.id] = e.value;
        }
        setSheduler(updatedRows);
    }

    const getTextEditor = () => {
        setDisplayEditor(true);
    }

    const onHide = (name, message, success) => {
        dialogFuncMap[`${name}`](false);
        message && toastRef.current.showToast(message)
        if (success) {
            setDisplayEditor(false);
        }
        if (name === 'displayQmComments') {
            setStatus(null);
            filterData?.statuses.forEach(element => {
                if (element.id === jobData?.jobInfo?.statusId) {
                    setStatus(element);
                }
            })
            setQmData([{ isEditable: true, jobUserValue: 0, user: {}, error: {}, comments: '' }]);
        }
        if (name === 'displayOutput') {
            getJobDetails();
        }
    }

    const dialogFuncMap = {
        'showConfirmationAlert': setShowConfirmationAlert,
        'displayStopReason': setDisplayStopReason,
        'displayJobComment': setDisplayJobComment,
        'displayQmComments': setDisplayQmComments,
        "displayInput": setDisplayInput,
        "displayTemplate": setDisplayTemplate,
        "displayOutput": setDisplayOutput,
        "displayManualTime": setDisplayManualTime,

        'displayEditor': setDisplayEditor,
        "displayComment": setDisplayComment,
        'deleteModal': setDeleteModal,
    }

    const renderInstructionsSubmit = () => {
        return (
            <div className="cancel-footer">
                <div className='align-center justify-end pb-0'>
                    {
                        <div className="p-grid">
                            <div className="p-col-12">
                                <Button type="submit" label="SAVE" className="create-button  request-button"
                                    onClick={() => saveInstructions()}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }

    const saveInstructions = () => {
        setDisplayEditor(false);
        // setLoading(true);
        let data = {
            description: editor.value
        }
        TrackingSystemService.saveInstructions(jobId, userId, data).then(data => {
            getJobDetails();
            getParentLink(clientId);
            !data.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: data.message
            })
            data.success && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated sucessfully',
                message: data.message
            })
            // setLoading(false);
        })
    };

    const renderComments = () => {
        return (
            <div className="cancel-footer">
                <div className='align-center justify-end pb-0'>
                    <ButtonElement type="submit" label="ADD" className="create-button request-button" onClickButton={() => jobCommentHandler()} />
                </div>
            </div>
        );
    }

    const onClickAddComment = () => {
        setDisplayComment(true);
    }

    const getComments = (jobId) => {
        TrackingSystemService.getComments(jobId).then(data => {
            setCommentData(data)
        })
    }

    const jobCommentHandler = () => {
        var data = {
            comment: commentEditor.summernote("code"),
            userId: userId,
            jobId: jobId,
        }
        if (commentEditor.summernote('isEmpty')) {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Comment',
                message: 'Fill the mandatory fields'
            })
        }
        else {
            setLoading(true);
            TrackingSystemService.saveComments(data).then(data => {
                setLoading(false);
                let message = {
                    type: data.success ? 'success' : 'error',
                    summary: data.success ? 'Success' : 'Something went wrong',
                    message: data.message
                }
                let success = data.success ? true : false
                onHide('displayComment', message, success);
                getComments(jobId);
                getJobDetails();
                getParentLink(clientId);
                getWorkLogsById(jobId);
            })
        }
    }

    const getWorkLogsById = (jobId) => {
        TrackingSystemService.getWorkLogsById(jobId).then(worklogs => {
            TrackingSystemService.getUserTimeLog(jobId).then(timelogs => {
                timelogs?.forEach((log, index) => {
                    timelogs[index].time = displayTime(log.timeSpent);
                });
                worklogs = _.orderBy(worklogs.concat(timelogs), ['createdAt'], ['asc']);
                setWorkLogData(worklogs);
            })
            // setWorkLogData(worklogs)
        })
    }

    function displayTime(duration) {

        var milliseconds = parseInt((duration % 1000) / 100),
            seconds = parseInt((duration / 1000) % 60),
            minutes = parseInt((duration / (1000 * 60)) % 60),
            hours = parseInt((duration / (1000 * 60 * 60)) % 24),
            days = parseInt((duration / (24 * 60 * 60 * 1000)));

        var hourVal = (hours === 1) ? hours + ' hr' : hours + ' hrs';
        var minuteVal = (minutes === 1) ? minutes + ' min' : minutes + ' mins';
        var secondVal = (seconds === 1) ? seconds + ' sec' : seconds + ' secs';
        var dayVal = (days === 1) ? days + ' day' : days + ' days';

        if (days > 0 && hours > 0 && minutes > 0) {
            return dayVal + ' ' + hourVal + ' ' + minuteVal;
        } else if (days === 0 && hours > 0 && minutes > 0) {
            return hourVal + ' ' + minuteVal;
        } else if (days === 0 && hours === 0 && minutes > 0) {
            return minuteVal;
        } else if (days === 0 && hours === 0 && minutes === 0) {
            return secondVal;
        }

    }

    const allJobQcMetrics = (jobId) => {
        TrackingSystemService.allJobQcMetrics(jobId).then(data => {
            setQualityMetricsData(data);
        })
    }

    const paginatorTemplate = {
        layout: `RowsPerPageDropdown CurrentPageReport ${qmTotalRows > 10 ? 'PrevPageLink  NextPageLink' : ''}`,
        'CurrentPageReport': (options) => {
            setQmTotalRows(options.totalRecords);
            if (qmTotalRows > 10) {
                return (
                    <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                        {options.first} - {options.last} of {options.totalRecords}
                    </span>
                )
            }
            else {
                return false;
            }
        }
    };

    const deleteTemplate = (rowData) => {
        return (
            <div>
                <Tooltip target=".custom-target-icon" />
                <span onClick={() => deleteReportData(rowData)}><i className="pi pi-trash report-remove"></i></span>
            </div>
        )
    }

    const deleteReportData = (data) => {
        setDeleteModal(true);
        setDeleteData(data);
    }

    const handleDeleteConfirmation = () => {
        let postData = {
            metric: deleteData
        };
        TrackingSystemService.deleteQMComments(userId, jobId, postData).then(data => {
            setDeleteModal(false);
            allJobQcMetrics(jobId);
            getJobDetails();
            getParentLink(clientId);
            !data.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Delete failed',
                message: data.message
            })
            data.success && toastRef.current.showToast({
                type: 'success',
                summary: 'Deleted sucessfully',
                message: data.message
            })
        })
    }

    const renderDelete = () => {
        return (
            <div className="cancel-footer">
                <div className='align-center justify-end pb-0'>
                    <Button type="submit" label="YES" className="create-button request-button" onClick={() => handleDeleteConfirmation()} />
                </div>
            </div>
        );
    }

    // Timer Stop Reason Form - Render Save Button Template
    const renderStopReasonFooter = () => {
        return (
            <div>
                <div className='p-grid request-footer'>
                    <div className='p-col-12 align-center justify-end pb-0'>
                        <Button type="submit" label="Submit" className="create-button request-button" onClick={() => postStopLog()} disabled={loading} />
                    </div>
                </div>
            </div>
        );
    }

    // Timer Stop Reason Form - Post Stop Watch Logs
    const postStopLog = () => {
        if (stopReason) {
            pause();
            const stopLogData = {
                jobId: jobId,
                reason: stopReason,
                statusId: jobData?.jobInfo?.statusId,
                time: totalSeconds * 1000, // converting seconds to milli seconds before sending to server
                userId: userId
            };

            TrackingSystemService.postStopLog(stopLogData).then(function (data) {
                let message = {
                    type: data.success ? 'success' : 'error',
                    summary: data.success ? 'Success' : 'Something went wrong',
                    message: data.message
                }
                let success = data.success ? true : false;
                onHide('displayStopReason', message, success);
                getJobDetails();
            });
        } else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: 'Please enter the reason'
            })
        }
    }

    // Confirmation Alert Modal - Render Ok Button Template
    const renderCloseConfirmationAlert = () => {
        return (
            <div>
                <div className='p-grid request-footer'>
                    <div className='p-col-12 align-center justify-end pb-0'>
                        <Button type="submit" label="Ok" className="create-button request-button" onClick={() => onCloseConfirmationAlert()} disabled={loading} />
                    </div>
                </div>
            </div>
        );
    }

    // Confirmation Alert Modal - Close the modal when click on Ok or Calncel
    const onCloseConfirmationAlert = () => {
        onHide('showConfirmationAlert');
        filterData?.statuses.forEach(element => {
            if (element.id === jobData?.jobInfo?.statusId)
                setStatus(element);
        })
    }

    // On Change Job Status
    const onChangeJobStatus = (e) => {
        if (isRunning) {
            setShowConfirmationAlert(true);
            setStatus(null);
        }
        else {
            let deliveryStatusId = '';
            let prodCompStatusId = '';

            filterData.statuses.forEach(element => {
                if (element.status === 'Delivery complete') {
                    deliveryStatusId = element.id;
                }
                else if (element.status === 'Production complete') {
                    prodCompStatusId = element.id;
                }
            })

            if (e.value.id === deliveryStatusId) {
                if ((jobData?.jobInfo?.qcUsers === null || jobData?.jobInfo?.qcUsers.length === 0) && (jobData?.jobInfo?.bpsUsers === null || jobData?.jobInfo?.bpsUsers.length === 0)) {
                    setStatus(null);
                    toastRef.current.showToast({
                        type: 'error',
                        summary: 'Update failed',
                        message: 'Please Assign BPS or QC Users to this Job and Save'
                    })
                    getJobDetails();
                }
                else {
                    setStatus(e.value);
                    if (jobData?.jobInfo?.isIGPortal) {
                        TrackingSystemService.getAllJobCompleteComment(jobId).then(data => {
                            setJobCompleteComments(data);
                            setDisplayJobComment(true);
                        })
                    }
                    else {
                        const jobStatus = {
                            jobId: jobId,
                            toStatusId: e.value.id,
                            userId: userId
                        }
                        TrackingSystemService.updateJobStatus(jobStatus).then(data => {
                            !data.success && toastRef.current.showToast({
                                type: 'error',
                                summary: 'Update failed',
                                message: data.message
                            })
                            data.success && toastRef.current.showToast({
                                type: 'success',
                                summary: 'Updated sucessfully',
                                message: data.message
                            })
                            getJobDetails();
                        });
                    }
                }
            }
            else if (e.value.id === prodCompStatusId) {
                if (jobData?.jobInfo?.bpsUsers === null || jobData?.jobInfo?.bpsUsers.length === 0) {
                    setStatus(null);
                    toastRef.current.showToast({
                        type: 'error',
                        summary: 'Update failed',
                        message: 'Please Assign BPS Users to this Job and Save'
                    })
                    getJobDetails();
                }
                else {
                    setStatus(e.value);
                    setDisplayQmComments(true);

                    let finalUsers = [];

                    let userIds = jobData?.jobsheduler?.map(sheduler => sheduler.scheduleUserId);

                    const uniqueUserIds = [...new Set(userIds)];

                    uniqueUserIds?.forEach(userId => {
                        jobData?.bpsQCusers?.forEach(user => {
                            if (userId === user.id) {
                                finalUsers.push({
                                    id: user.id,
                                    name: user.firstName + ' ' + user.lastName
                                })
                            }
                        })
                    })
                    setQmUsers(finalUsers);

                    let finalJobTypes = []
                    jobData?.jobInfo?.jobType.forEach(savedJobType => {
                        jobData?.jobTypes.forEach(mainJobType => {
                            if (savedJobType.jobType === mainJobType.id) {
                                finalJobTypes.push({
                                    id: mainJobType.id,
                                    jobType: mainJobType.jobType,
                                    jobTypeValue: savedJobType.jobTypeValue
                                })
                            }
                        })
                    })

                    setQmJobTypes(finalJobTypes);

                    TrackingSystemService.getErrorTypes(jobBuId).then(data => {
                        setErrorTypes(data);
                    })

                }
            }
            else {
                setStatus(e.value);
                const jobStatus = {
                    jobId: jobId,
                    toStatusId: e.value.id,
                    userId: userId
                }
                TrackingSystemService.updateJobStatus(jobStatus).then(data => {
                    !data.success && toastRef.current.showToast({
                        type: 'error',
                        summary: 'Update failed',
                        message: data.message
                    })
                    data.success && toastRef.current.showToast({
                        type: 'success',
                        summary: 'Updated sucessfully',
                        message: data.message
                    })
                    getJobDetails();
                });
            }
        }
    }

    // Job Complete Comment Form - Render Save Button Template
    const renderJobCompleteCommentFooter = () => {
        return (
            <div>
                <div className='p-grid request-footer'>
                    <div className='p-col-12 align-center justify-end pb-0'>
                        <Button type="submit" label="SAVE" className="create-button request-button" onClick={() => postJobCompleteComment('Submit')} disabled={loading} />
                    </div>
                </div>
            </div>
        );
    }

    // Job Complete Comment Form - Post Job Complete Comment
    const postJobCompleteComment = (commentAction) => {
        const jobStatus = {
            jobId: jobId,
            toStatusId: status.id,
            userId: userId
        }

        if (comment.trim().length == 0 || commentAction === 'Cancel') {

            TrackingSystemService.updateJobStatus(jobStatus).then(data => {
                !data.success && toastRef.current.showToast({
                    type: 'error',
                    summary: 'Update failed',
                    message: data.message
                })
                data.success && toastRef.current.showToast({
                    type: 'success',
                    summary: 'Updated sucessfully',
                    message: data.message
                })
                getJobDetails();
            });
        }
        else {
            const jobComment = {
                comment: comment.trim(),
                userId: userId,
                jobId: jobId,
            }

            TrackingSystemService.submitJobCompleteComment(jobComment).then(res => {
                if (res.success) {
                    TrackingSystemService.updateJobStatus(jobStatus).then(data => {
                        !data.success && toastRef.current.showToast({
                            type: 'error',
                            summary: 'Update failed',
                            message: data.message
                        })
                        data.success && toastRef.current.showToast({
                            type: 'success',
                            summary: 'Updated sucessfully',
                            message: data.message
                        })
                        getJobDetails();
                    });
                }
                else {
                    toastRef.current.showToast({
                        type: 'error',
                        summary: 'Update failed',
                        message: res.message
                    })
                }
            })
        }
        onHide('displayJobComment')
    }

    // Quality Metrics Form - add row
    const addQmRow = (index) => {
        let updatedRows = [...qmData];
        updatedRows.splice(index + 1, 0, { isEditable: true, jobUserValue: 0, user: {}, error: {}, comments: '' });
        setQmData(updatedRows);
    }

    // Quality Metrics From - remove row
    const removeQmRow = (index) => {
        const updatedRows = [...qmData];
        updatedRows.splice(index, 1);
        setQmData(updatedRows);
    }

    // Quality Metrics Form - on change user
    const onChangeQmUser = (e, index) => {

        const updatedRows = [...qmData];
        updatedRows[index].user = e.value;
        updatedRows[index].bpsUserId = e.value.id;
        updatedRows[index].qcUserId = userId;
        updatedRows[index].jobId = jobId;
        updatedRows[index].jobUserValue = 0;
        updatedRows[index].userJobRange = [];
        updatedRows[index].rangeOptions = [];

        jobData?.jobsheduler?.forEach(sheduler => {
            if (sheduler.scheduleUserId === e.value.id) {
                updatedRows[index].userJobRange.push({ range: sheduler.range, value: sheduler.total }); // this is to send to backend
                updatedRows[index].rangeOptions.push({ id: sheduler.id, range: sheduler.range, total: sheduler.total }); // this is to display options for job range field
            }
        });
        setQmData(updatedRows);
    }

    // Quality Metrics Form - on change job type
    const onChangeQmJobType = (e, index) => {
        const updatedRows = [...qmData]
        updatedRows[index].jobTypeId = e.value.id
        setQmData(updatedRows);
    }

    // Quality Metrics Form - on change job range
    const onChaneQmJobRange = (e, index) => {
        const updatedRows = [...qmData];
        updatedRows[index].jobScheduledUserId = e.value.id;
        updatedRows[index].jobRange = e.value.range
        updatedRows[index].jobUserValue = e.value.total; // Total Value
        setQmData(updatedRows);
    }

    // Quality Metrics Form - on change error type
    const onChangeErrorType = (e, index) => {
        const updatedRows = [...qmData];
        updatedRows[index].error = e.value;
        updatedRows[index].errorTypeId = e.value.id;
        setQmData(updatedRows);
    }

    // Quality Metrics Form - on change no of errors
    const onChangeNoOfErrors = (e, index) => {
        const updatedRows = [...qmData];
        updatedRows[index].noOfError = e.value;
        setQmData(updatedRows);
    }

    // Quality Metrics Form - on change qm comment
    const onChangeQmComment = (e, index) => {
        const updatedRows = [...qmData];
        updatedRows[index].comments = e.target.value;
        setQmData(updatedRows);
    }

    // Quality Metrics Form - Render Save Button Template
    const renderQmCommentsFooter = () => {
        return (
            <div>
                <div className='p-grid request-footer'>
                    <div className='p-col-12 align-center justify-end pb-0'>
                        <Button type="submit" label="SAVE" className="create-button request-button" onClick={() => postQmComments()} disabled={loading} />
                    </div>
                </div>
            </div>
        )
    }

    // Quality Metrics Form - Post QM Data
    const postQmComments = () => {

        const jobStatus = {
            jobId: jobId,
            toStatusId: status.id,
            userId: userId
        };

        const qmDataValid = isValidQmData();

        if (qmDataValid) {

            TrackingSystemService.saveQcComments(qmData).then(res => {
                if (res.success) {
                    TrackingSystemService.updateJobStatus(jobStatus).then(data => {
                        !data.success && toastRef.current.showToast({
                            type: 'error',
                            summary: 'Update failed',
                            message: data.message
                        })
                        data.success && toastRef.current.showToast({
                            type: 'success',
                            summary: 'Updated sucessfully',
                            message: data.message
                        })
                        getJobDetails();
                        allJobQcMetrics(jobId);
                    });
                }
                else {
                    toastRef.current.showToast({
                        type: 'error',
                        summary: 'Update failed',
                        message: res.message
                    })
                }
            })
            onHide('displayQmComments')
        }
        else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: 'Please fill the mandatory fields'
            })
        }
    }

    // Quality Metrics Form - Data validation before sending to backend Post API
    const isValidQmData = () => {
        let isValid = true;
        qmData.forEach(data => {

            if (!(_.has(data, 'bpsUserId')) || !(_.has(data, 'jobTypeId')) || !(_.has(data, 'jobRange')) || !(_.has(data, 'errorTypeId')) || !(_.has(data, 'noOfError'))) {
                isValid = false;
                return;
            }

            if (data.bpsUserId === null || data.errorTypeId === null || data.noOfError === null || data.jobTypeId === null || data.jobRange === null) {
                isValid = false;
                return;
            }

            if (data.bpsUserId === '' || data.errorTypeId === '' || data.noOfError === '' || data.jobTypeId === '' || data.jobRange === '') {
                isValid = false;
                return;
            }
        });

        return isValid;
    }

    const selectData = (selectData) => {
        return ''
    }

    // Input, Template, Output Files Download
    const downloadFile = (fileData) => {
        TrackingSystemService.downloadFile(fileData.id).then(data => {
            let blob = new Blob([data]);
            saveAs(blob, fileData.fileName);
        })
    }

    // Input, Template Files Delete
    const deleteFile = (fileData) => {
        TrackingSystemService.removeInputFile(fileData.id).then(data => {
            if (data['success']) {
                toastRef.current.showToast({
                    type: 'success',
                    summary: 'Updated successfully',
                    message: data.message
                })
                getJobDetails();
            } else {
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Update failed',
                    message: data.message
                })
            }
        })
    }

    // Input, Template, Output Files Download Template
    const fileDownloadTemplate = (rowData) => {
        return (
            <span onClick={() => downloadFile(rowData)}><i className="pi pi-arrow-circle-down"></i></span>
        )
    }

    // Input, Template Files Delete Template
    const fileDeleteTemplate = (rowData) => {
        return (
            <span onClick={() => deleteFile(rowData)}><i className="pi pi-trash"></i></span>
        )
    }

    // Output Files Set Reference Lable
    const onChangeReferenceLabel = (e, index) => {
        const updatedRows = [...outputFiles];
        updatedRows[index.rowIndex].referenceLabel = e.target.value;
        setOutputFiles(outputFiles);
    }

    // Output Files Upload
    const uploadFile = (e, index) => {

        const allTypeFiles = DataService.allTypeFiles();

        if (allTypeFiles.indexOf(e.files[0].type) === -1) {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: 'This file type is not supported in our system'
            })
        }
        else if (e.files[0].name.length > 100) {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: 'File Name should not exceed more than 100 characters'
            })
        }
        else {
            setLoading(true);

            const formData = new FormData();
            formData.append('attachmentOutFile', e.files[0]);

            TrackingSystemService.uploadFile(formData).then(data => {
                const updatedRows = [...outputFiles];
                if (data.success) {
                    updatedRows[index.rowIndex].progress = 'done';
                    updatedRows[index.rowIndex].progressColor = '#419841';
                    updatedRows[index.rowIndex].url = data.url;
                    updatedRows[index.rowIndex].fileName = e.files[0].name;
                    updatedRows[index.rowIndex].fileType = 'Output';
                    updatedRows[index.rowIndex].fileSize = data.fileSize;
                    updatedRows[index.rowIndex].fileKey = data.key;
                    updatedRows[index.rowIndex].jobId = jobData?.jobInfo?.id;
                    updatedRows[index.rowIndex].userId = userId;
                    updatedRows[index.rowIndex].progressStarted = false;
                }
                else {
                    updatedRows[index.rowIndex].progress = 'error';
                    updatedRows[index.rowIndex].progressColor = '#f1353b';
                    updatedRows[index.rowIndex].progressStarted = false;
                }
                setOutputFiles(updatedRows);
            }).finally(() => setLoading(false))
        }
    }

    // Output Files Add New Row
    const addOutRow = (index) => {
        const updatedRows = [...outputFiles];
        updatedRows.splice(index.rowIndex + 1, 0, {});
        setOutputFiles(updatedRows);
    }

    // Output Files Remove Row (for new rows)
    const removeOutRow = (index) => {
        const updatedRows = [...outputFiles];
        updatedRows.splice(index.rowIndex, 1);
        setOutputFiles(updatedRows);
    }

    // Output Files Delete File (for exsting output files)
    const deleteOutputFile = (fileData, index) => {
        if (!fileData.id) {
            removeOutRow(index);
        }
        else {
            TrackingSystemService.removeOutputFile(fileData.id).then(data => {
                if (data['success']) {
                    toastRef.current.showToast({
                        type: 'success',
                        summary: 'Updated successfully',
                        message: data.message
                    })
                    getJobDetails();
                }
                else {
                    toastRef.current.showToast({
                        type: 'error',
                        summary: 'Update failed',
                        message: data.message
                    })
                }
            })
        }
    }

    // Save all output files
    const saveOutputFiles = () => {
        TrackingSystemService.saveOutputFiles(outputFiles).then(data => {
            if (data.success) {
                toastRef.current.showToast({
                    type: 'success',
                    summary: 'Updated successfully',
                    message: data.message
                })
            }
            else {
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Update failed',
                    message: data.message
                })
            }
            onHide('displayOutput');
        })
    }

    // Output Files Reference Label Template 
    const referenceLabelTemplate = (rowData, index) => {
        return (
            <>
                <div className="p-col-12 p-md-12 p-sm-12 reference-label">
                    {
                        _.has(rowData, 'fileKey') ? <span>-</span> :
                            <TextboxElement label="Reference label" placeholder="Enter Reference" onChangeText={(e) => onChangeReferenceLabel(e, index)}></TextboxElement>
                    }
                </div>
            </>
        )
    }

    // Output Files File Upload Template
    const fileUploadTemplate = (index) => {
        const chooseOptions = { iconOnly: true, icon: 'pi pi-plus' };
        const uploadOptions = { className: 'file-upload-cancel' };
        const cancelOptions = { className: 'file-upload-cancel' };
        return (
            <div>
                <FileUpload
                    name="file"
                    multiple={false}
                    chooseOptions={chooseOptions}
                    uploadOptions={uploadOptions}
                    cancelOptions={cancelOptions}
                    uploadHandler={(e) => uploadFile(e, index)}
                    customUpload
                    auto
                    style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}
                />
            </div>
        )
    }

    // Output Files Actions Templete (Add, Delete/Remove, )
    const actionsTemplate = ((rowData, index) => {
        return (
            <>
                <span onClick={() => addOutRow(index)}><i className="pi pi-plus mr-10"></i></span>
                <span onClick={() => deleteOutputFile(rowData, index)}>
                    {
                        rowData?.id ?
                            <i className="pi pi-trash"></i> : (index.rowIndex > 0 && <i className="pi pi-minus"></i>)
                    }
                </span>
            </>
        )
    })

    const renderOutputFilesFooter = () => {
        return (
            <div>
                <div className='p-grid request-footer'>
                    <div className='p-col-12 align-center justify-end'>
                        <Button type="submit" label="SAVE" className="create-button request-button" onClick={() => saveOutputFiles()} disabled={loading} />
                    </div>
                </div>
            </div>
        );
    }

    // Input Modal Data Table Columns
    const inputColumns = [
        { field: 'fileName', header: 'File Name' },
        { field: 'referenceLabel', header: 'Reference Label' },
        { field: 'download', header: 'Download', body: fileDownloadTemplate },
        { field: 'delete', header: 'Delete', body: fileDeleteTemplate }
    ]

    // Output Files Modal Dta Table Columns
    const outputColumns = [
        { field: 'fileName', header: 'File Name' },
        { field: 'referenceLabel', header: 'Reference Label', body: referenceLabelTemplate },
        {
            field: 'downloadUpload', header: 'Download/Upload',
            body: (rowData, index) => {
                if (_.has(rowData, 'fileKey')) {
                    return fileDownloadTemplate(rowData);
                } else {
                    return fileUploadTemplate(index);
                }
            }
        },
        { field: 'action', header: 'Actions', body: actionsTemplate }
    ]

    // Manual Time Form - get and show manual time previous data when click on Manual Button
    const onClickManualTime = () => {

        let manualUsers = [];
        let manualData = [];

        jobData?.bpsQCusers.forEach(user => {
            if (jobData?.jobInfo?.bpsUsers.includes(user.id) || jobData?.jobInfo?.qcUsers.includes(user.id)) {
                manualUsers.push({
                    id: user.id,
                    name: user.firstName + ' ' + user.lastName
                })
            }
        })

        setManualTimeUsers(manualUsers);

        TrackingSystemService.getAllUserManualTimeLog(jobId).then(userTimeLogs => {

            userTimeLogs.forEach(userLog => {

                jobData?.jobInfo?.manualJobTime.forEach(manualTime => {

                    if (userLog.userId === manualTime.scheduleUserId) {

                        const user = manualUsers.filter(element => userLog.userId === element.id);

                        const totalTimeSpent = DateTimeFunctions.msToTimeObj(userLog.timeSpent);

                        manualData.push({
                            user: user[0],
                            isEdit: false,
                            hours: 0,
                            minutes: 0,
                            seconds: 0,
                            manualJobTime: { hours: manualTime.hours, minutes: manualTime.minutes, seconds: manualTime.seconds },
                            totalTimeSpent: totalTimeSpent,
                            scheduleUserId: manualTime.scheduleUserId,
                            userId: manualTime.userId,
                            jobId: jobId,
                            statusId: manualTime.statusId
                        })
                    }
                })
            })

            if (manualData.length == 0) {
                manualData.push({ isEdit: true });
            }

            setManualTimeData(manualData);
            setDisplayManualTime(true);
        });
    }

    // Manual Time Form - add row
    const addManualTimeRow = (index) => {
        let updatedRows = [...manualTimeData];
        updatedRows.splice(index + 1, 0, {
            isEdit: true,
            user: {},
            hours: 0,
            minutes: 0,
            seconds: 0,
            totalTimeSpent: { days: 0, hours: 0, minutes: 0, seconds: 0 },
            scheduleUserId: '',
            userId: '',
            jobId: '',
            statusId: '',
        });
        setManualTimeData(updatedRows);
    }

    // Manual Time Form - remove row
    const removeManualTimeRow = (index) => {
        const updatedRows = [...manualTimeData];
        updatedRows.splice(index, 1);
        setManualTimeData(updatedRows);
    }

    // Manual Time Form - On change user
    const onChangeManualUser = (e, index) => {
        const updatedRows = [...manualTimeData];
        const user = e.target.value;
        TrackingSystemService.getSelectedUserTrackTime(jobId, user.id).then(function (res) {
            updatedRows[index].user = user
            updatedRows[index].totalTimeSpent = DateTimeFunctions.msToTimeObj(res.timeSpent);
            updatedRows[index].manualJobTime = res.manualTime[0];
            updatedRows[index].scheduleUserId = user.id;
            updatedRows[index].userId = user.id;
            updatedRows[index].jobId = jobId;
            updatedRows[index].statusId = jobData?.jobInfo?.statusId;
            setManualTimeData(updatedRows);
        });

    }

    // Manual Time Form - On change hours, minutues, seconds
    const onChangeManualTime = (e, index) => {
        let updatedRows = [...manualTimeData];

        if (e.target.id === 'hoursManual')
            updatedRows[index].hours = e.target.value;
        else if (e.target.id === 'minutesManual')
            updatedRows[index].minutes = e.target.value;
        else if (e.target.id === 'secondsManual')
            updatedRows[index].seconds = e.target.value;

        setManualTimeData(updatedRows);
    }

    // Manual Time Form Footer - Save Button Render Template
    const renderManualTimeFooter = () => {
        return (
            <div>
                <div className='p-grid d-flex request-footer'>
                    <div className='p-col-12 align-center p-md-9'>
                        <p className="font-normal fs-13 text-lightgray mt-0"><i className="pi pi-info-circle mr-5"></i>User can only add additional time to BPS or QC user.</p>
                    </div>
                    <div className='p-col-12 p-md-3 align-center justify-end'>
                        <Button type="submit" label="Submit" className="create-button request-button" onClick={() => submitManualJobTime()} disabled={loading} />
                    </div>
                </div>
            </div>
        );
    }

    // Manual Time Form - Post Manual Time Data
    const submitManualJobTime = () => {
        const manualFormValid = isValidManualJobTime(manualTimeData);
        if (manualFormValid.isSuccess) {
            var originalTime = _.cloneDeep(manualTimeData);
            for (var i = 0; i < originalTime.length; i++) {
                if (_.has(originalTime[i], 'hours')) {
                    if (!_.isEmpty(originalTime[i].hours)) {
                        originalTime[i].hours = parseInt(originalTime[i].manualJobTime.hours) + parseInt(originalTime[i].hours);
                    } else {
                        originalTime[i].hours = parseInt(originalTime[i].manualJobTime.hours);
                    }
                }
                if (_.has(originalTime[i], 'minutes')) {
                    if (!_.isEmpty(originalTime[i].minutes)) {
                        originalTime[i].minutes = parseInt(originalTime[i].manualJobTime.minutes) + parseInt(originalTime[i].minutes);
                    } else {
                        originalTime[i].minutes = parseInt(originalTime[i].manualJobTime.minutes);
                    }
                }
                if (_.has(originalTime[i], 'seconds')) {
                    if (!_.isEmpty(originalTime[i].seconds)) {
                        originalTime[i].seconds = parseInt(originalTime[i].manualJobTime.seconds) + parseInt(originalTime[i].seconds);
                    } else {
                        originalTime[i].seconds = parseInt(originalTime[i].manualJobTime.seconds);
                    }
                }
                originalTime[i] = _.omit(originalTime[i], ['totalTimeSpent', 'manualJobTime', 'isEdit', 'user']);
            }

            TrackingSystemService.submitManualJobTime(jobId, originalTime, userId).then(function (res) {
                let message = {
                    type: res.success ? 'success' : 'error',
                    summary: res.success ? 'Success' : 'Something went wrong',
                    message: res.message
                }
                let success = res.success ? true : false;
                onHide('displayManualTime', message, success);
                getJobDetails();
            });

        } else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: manualFormValid.message
            })
        }
    }

    // Manual Time Form - Validate Manual Time Data before sending to Post API
    const isValidManualJobTime = (data) => {

        for (var i = 0; i < data.length; i++) {
            if (!(_.has(data[i], 'scheduleUserId'))) {
                return { isSuccess: false, message: 'Please fill the mandatory fields' };
            }

            if (data[i].scheduleUserId === null) {
                return { isSuccess: false, message: 'Please fill the mandatory fields' };
            }

            if (data[i].scheduleUserId === '') {
                return { isSuccess: false, message: 'Please fill the mandatory fields' };
            }

            if (_.has(data[i], 'hours')) {
                if (data[i].hours === null || data[i].hours === undefined || data[i].hours === '') {
                    return { isSuccess: false, message: 'Please fill the mandatory fields' };
                }
            }

            if (_.has(data[i], 'minutes')) {
                if (data[i].minutes === null || data[i].minutes === undefined || data[i].minutes === '') {
                }
            }

            if (_.has(data[i], 'seconds')) {
                if (data[i].seconds === null || data[i].seconds === undefined || data[i].seconds === '') {
                    return { isSuccess: false, message: 'Please fill the mandatory fields' };
                }
            }
        }

        var uniqData = _.uniqBy(data, function (item) {
            return item.scheduleUserId;
        });

        if (uniqData.length != data.length) {
            return { isSuccess: false, message: 'Manual time added for same user more than once' };
        }

        return { isSuccess: true, message: 'Done' };

    }

    const updateJob = () => {
        const updateJobValid = isValidUpdateJob();
        if (updateJobValid.isSuccess) {

            const data = jobData?.jobInfo;

            data.updaterId = userId;

            data.timeZoneIST = timeZoneIST;

            data.deadline_ms = new Date(deadline).getTime();
            if (timeZoneIST) {
                data.deadlineIST = deadline;
            } else {
                data.deadline = deadline;
            }

            data.isTentative = isTentative;
            data.priorityId = priority?.id;

            data.status = data.job_status.status;

            data.reviewJE = reviewJE;
            data.productionJE = productionJE;
            data.qcJE = qcJE;
            data.ER = Number(reviewJE) + Number(productionJE) + Number(qcJE);

            data.jobType = [];

            jobTypes.forEach(element => {
                if (_.has(element.value, 'id')) {
                    data.jobType.push({
                        jobTypeId: element.value.id,
                        jobType: element.value.jobType,
                        jobTypeValue: element.jobTypeValue,
                        undefined: {}
                    })
                }
            })

            data.bpsUsers = [];

            bpsUsers.forEach(element => {
                data.bpsUsers.push(element.id)
            })

            data.qcUsers = [];

            qcUsers.forEach(element => {
                data.qcUsers.push(element.id)
            })

            data.scheduler = [];

            sheduler.forEach(element => {
                if (_.has(element.user, 'id')) {
                    const schedulerData = {
                        scheduleUserId: element.user.id,
                        productionJE: element.productionJE,
                        range: element.range,
                        total: element.total,
                        jobId: jobId,
                    };

                    // this is for already saved schedulers
                    if (_.has(element, 'id')) {
                        schedulerData.id = element.id;
                    }
                    data.scheduler.push(schedulerData);
                }
            })

            TrackingSystemService.updateJob(jobId, data).then(res => {
                if (res.success) {
                    if (isRunning) {
                        const stopLogData = {
                            jobId: jobId,
                            reason: 'job updation',
                            statusId: jobData?.jobInfo?.statusId,
                            time: totalSeconds * 1000, // converting seconds to milli seconds before sending to server
                            userId: userId
                        };

                        TrackingSystemService.postStopLog(stopLogData).then(function (timeRes) {
                            toastRef.current.showToast({
                                type: 'success',
                                // summary: 'Update successfully',
                                message: res.message
                            });
                            getJobDetails();
                        });
                    }
                    else {
                        toastRef.current.showToast({
                            type: 'success',
                            // summary: 'Update successfully',
                            message: res.message
                        });
                        getJobDetails();
                    }
                }
                else {
                    toastRef.current.showToast({
                        type: 'error',
                        // summary: 'Update failed',
                        message: res.message
                    });
                }
            })
        }
        else {
            toastRef.current.showToast({
                type: 'error',
                // summary: 'Update failed',
                message: updateJobValid.message
            })
        }
    }

    const isValidUpdateJob = () => {

        var shedulerSumPJE = _.sumBy(sheduler, function (obj) {
            if (obj.productionJE !== null && !isNaN(obj.productionJE)) {
                return parseInt(obj.productionJE);
            } else {
                return 0;
            }
        });

        if ((shedulerSumPJE > Number(productionJE)) && Number(productionJE) !== 0) {
            return {
                isSuccess: false,
                message: "Sum of the user's production JE should be equal or less than that of total production JE"
            }
        }

        if (sheduler.length > 0) {
            for (var i = 0; i < sheduler.length; i++) {
                if (sheduler[i].hasOwnProperty('user') || sheduler[i].hasOwnProperty('range') || sheduler[i].hasOwnProperty('total') || sheduler[i].hasOwnProperty('productionJE')) {
                    if (!sheduler[i].user.hasOwnProperty('id') && (sheduler[i].range || sheduler[i].total || sheduler[i].productionJE)) {
                        return {
                            isSuccess: false, message: 'Please select schedule user'
                        };
                    }
                }
            }
        }

        return {
            isSuccess: true, message: 'Done'
        }
    }

    return (
        <div className="mt-20 service-desk-edit">
            <ToastElement ref={toastRef} />
            <Loader loading={loading} />
            <div className='p-grid'>
                <div className="p-col-12 p-md-10 p-sm-10 jobNum d-flex jobtracktitle">
                    <div>
                        <p>{jobData?.jobInfo?.jobReference} - </p>
                    </div>
                    <div className="job-num-space">
                        {
                            jobData?.jobInfo?.client &&
                            <p>{jobData?.jobInfo?.client.clientName} - </p>
                        }
                    </div>
                    <div className="job-num-space">
                        {
                            jobData?.jobInfo?.project?.name &&
                            <p>{jobData?.jobInfo?.project?.name} - </p>
                        }
                    </div>
                    <div className="job-num-space">
                        {
                            jobData?.jobInfo?.jobNumber &&
                            <p>{jobData?.jobInfo?.jobNumber} </p>
                        }
                    </div>
                </div>
                <div className='p-col-12 p-md-2 p-sm-2 align-center justify-end'>
                    <Button type="submit" label="Back" icon='pi pi-arrow-left' className="request-button mr-10" iconPos="left" onClick={() => navigate("/trackingSystem")} />
                    <Button label='SAVE' className='request-button' onClick={() => updateJob()}></Button>
                </div>
            </div>
            {/* Timer Card */}
            <div className='tracking-system-card track-first-card p-10'>
                <div className='p-grid'>
                    <div className="p-col-12 p-md-2 p-sm-3 job-deadline">
                        <p className="font-normal fs-13 text-lightgray m-0"> {timeZoneIST ? 'Deadline (IST)' : 'Deadline (UK)'} <span className='text-red'>*</span></p>
                        <h5 className='dropdown-heading'></h5>
                        <Calendar
                            name='deadline'
                            className='leave-request '
                            placeholder='Select'
                            view='date'
                            dateFormat='M dd, yy'
                            onChange={(e) => setDeadline(e.target.value)}
                            value={new Date(deadline)}
                            minDate={currentDate}
                            showTime={true}
                            stepMinute={5}
                            showIcon
                        />
                    </div>
                    <div className="p-col-12 p-md-2 p-sm-3">
                        <p className="font-normal fs-13 text-lightgray m-0">Tentative Date</p>
                        <h5 className='dropdown-heading'></h5>
                        <div className="align-center toggle-button">
                            <Tooltip target=".toggleButton" />
                            <span data-pr-tooltip={'Click YES or NO'} data-pr-position="right" className="toggleButton">
                                {
                                    isTentative == true &&
                                    <ToggleElement
                                        value={true}
                                        onChange={(e) => setIsTentative(e)}
                                        onIcon='pi pi-check'
                                        offIcon='pi pi-times'
                                        className="toggle-button-yes mt-3"
                                    />
                                }
                                {
                                    isTentative == false &&
                                    <ToggleElement
                                        value={false}
                                        onChange={(e) => setIsTentative(e)}
                                        onIcon='pi pi-check'
                                        offIcon='pi pi-times'
                                        className="toggle-button-yes mt-3"
                                    />
                                }
                            </span>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-2 p-md-2 p-sm-3">
                        <p className="font-normal fs-13 text-lightgray m-0">Total Job Estimate</p>
                        <h5 className='dropdown-heading'></h5>
                        <div className="service-desk-view-det">
                            <div className="align-center">
                                <div className="time-spent-box">
                                    <TextboxElement
                                        className="time-box"
                                        value={totalJobEstimate?.days}
                                        disabled
                                    />
                                    {<p className='m-0 text-center'><small>Days</small></p>}
                                </div>
                                <div className="time-spent-box">
                                    <TextboxElement
                                        className="time-box"
                                        value={totalJobEstimate?.hours}
                                        disabled
                                    />
                                    {<p className='m-0 text-center'><small>Hrs</small></p>}
                                </div>
                                <div className="time-spent-box">
                                    <TextboxElement
                                        className="time-box"
                                        value={totalJobEstimate?.minutes}
                                        disabled
                                    />
                                    {<p className='m-0 text-center'><small>Mins</small></p>}
                                </div>
                                <div className="time-spent-box">
                                    <TextboxElement
                                        className="time-box"
                                        value={totalJobEstimate?.seconds}
                                        disabled
                                    />
                                    {<p className='m-0 text-center'><small>Secs</small></p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-2 p-md-2 p-sm-3">
                        <p className="font-normal fs-13 text-lightgray m-0">Total Job Time</p>
                        <h5 className='dropdown-heading'></h5>
                        <div className="service-desk-view-det">
                            <div className="align-center">
                                <div className="time-spent-box">
                                    <TextboxElement
                                        className="time-box"
                                        value={totalJobSpentTime?.days}
                                        disabled
                                    />
                                    {<p className='m-0 text-center'><small>Days</small></p>}
                                </div>
                                <div className="time-spent-box">
                                    <TextboxElement
                                        className="time-box"
                                        value={totalJobSpentTime?.hours}
                                        disabled
                                    />
                                    {<p className='m-0 text-center'><small>Hrs</small></p>}
                                </div>
                                <div className="time-spent-box">
                                    <TextboxElement
                                        className="time-box"
                                        value={totalJobSpentTime?.minutes}
                                        disabled
                                    />
                                    {<p className='m-0 text-center'><small>Mins</small></p>}
                                </div>
                                <div className="time-spent-box">
                                    <TextboxElement
                                        className="time-box"
                                        value={totalJobSpentTime?.seconds}
                                        disabled
                                    />
                                    {<p className='m-0 text-center'><small>Secs</small></p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-2 p-sm-3 leave-request">
                        <p className="font-normal fs-13 text-lightgray m-0">Status</p>
                        <SingleSelectElement
                            placeholder="Select"
                            optionLabel="status"
                            options={filterData?.statuses?.sort((a, b) => new Date(a.statusIndex) - new Date(b.statusIndex))}
                            onChangeValue={(e) => onChangeJobStatus(e)}
                            value={status}
                        />
                    </div>
                    <div className="p-col-12 p-md-2 p-sm-3 align-center direction-column">
                        {
                            showStopWatch &&
                            <div className='align-center direction-column'>
                                {
                                    !isRunning && days == 0 && hours == 0 && minutes == 0 && seconds == 0 &&
                                    <ButtonElement className='mb-8 cancel-button' label='START' onClickButton={start} />
                                }
                                {
                                    !isRunning && (days > 0 || hours > 0 || minutes > 0 || seconds > 0) &&
                                    <ButtonElement className='mb-8 cancel-button' label='RESUME' onClickButton={start} />
                                }
                                {
                                    isRunning &&
                                    <ButtonElement className='mb-8 cancel-button' label="STOP" onClickButton={() => { setDisplayStopReason(true) }} />
                                }
                                {/* <div>
                                    <span className='font-normal fs-11 text-lightgray m-0'><span className='fs-13'>{days}</span> Days, <span className='fs-13'>{hours}</span> Hrs, <span className='fs-13'>{minutes}</span> Mins, <span className='fs-13'>{seconds}</span> Secs</span>
                                </div> */}
                                <div>
                                    {/* <span className='font-normal fs-12 text-lightgray m-0'>{days} Days, {hours} Hrs, {minutes} Mins, {seconds} Secs</span> */}
                                    <span className='font-normal fs-13 m-0'>{days} d : {hours} h : {minutes} m : {seconds} s</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-md-2 p-sm-3 leave-request">
                        <p className="font-normal fs-13 text-lightgray m-0">Priority</p>
                        <SingleSelectElement
                            placeholder="Select"
                            optionLabel="priority"
                            options={filterData?.priorities?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))}
                            onChangeValue={(e) => { setPriority(e.value) }}
                            value={priority}
                        />
                    </div>
                    <div className="p-col-12 p-md-2 p-sm-3 leave-request tracking-system-multiselect">
                        <p className="font-normal fs-13 text-lightgray m-0">Request Type</p>
                        <MultiSelectElement
                            placeholder="Select Request"
                            className="p-inputtext-multiselect tracking-system-multiselect"
                            options={requestTypeOptions}
                            optionLabel='name'
                            value={jobData?.jobInfo?.requestType}
                            maxSelectLabel={1}
                        />
                    </div>
                    {
                        jobData?.jobInfo?.isIGPortal &&
                        <>
                            <div className='p-col-12 p-md-2 p-sm-3 align-center align-center direction-column justify-center pb-0 mt-12'>
                                <Button label="INPUT FILE" className="cancel-button create-button" onClick={() => setDisplayInput(true)} />
                            </div>
                            <div className='p-col-12 p-md-2 p-sm-3 align-center align-center direction-column justify-center pb-0 mt-12'>
                                <Button label="TEMPLATE" className="cancel-button create-button" onClick={() => setDisplayTemplate(true)} />
                            </div>
                            <div className='p-col-12 p-md-2 p-sm-3 align-center align-center direction-column justify-center pb-0 mt-12'>
                                <Button label="OUTPUT FILE" className="cancel-button create-button" onClick={() => setDisplayOutput(true)} />
                            </div>
                        </>
                    }

                    {
                        adminPermission == true && 
                        <div className='p-col-12 p-md-2 p-sm-3 align-center align-center direction-column justify-center pb-0 mt-12'>
                            <Button label="MANUAL TIME" className="cancel-button create-button w-120" onClick={() => onClickManualTime()} />
                        </div>
                    }
                </div>
            </div>
            {/* Modal Popups and Forms used in Timer Card - Start */}
            {
                // Timer Stop Reason Form
                displayStopReason &&
                <Dialog visible={displayStopReason} header="Reason For Hold/Stop" onHide={() => onHide('displayStopReason')} footer={renderStopReasonFooter()} breakpoints={{ '960px': '75vw' }} draggable={false}>
                    <div className="cancel-request">
                        <div className="p-grid d-flex">
                            <div className="p-col-12 p-md-12 p-sm-12">
                                <p className="font-normal fs-13 text-lightgray mt-0">Reason<span className="text-red">*</span></p>
                                <TextboxElement label="" placeholder="" onChangeText={(e) => setStopReason(e.target.value)}></TextboxElement>
                            </div>
                        </div>
                    </div>
                </Dialog>
            }
            {
                // Confirmation Alert Modal - Show when timer isRunning and user trying to change job status
                showConfirmationAlert &&
                <Dialog visible={showConfirmationAlert} header="Confirmation Alert" onHide={() => onCloseConfirmationAlert()} footer={renderCloseConfirmationAlert()} breakpoints={{ '960px': '75vw' }} draggable={false}>
                    <div className="cancel-request">
                        <div className="p-grid d-flex">
                            <div className="p-col-12 p-md-12 p-sm-12">
                                <p className="font-normal fs-13 text-lightgray mt-0">Please ensure that the stopwatch is stopped.</p>
                            </div>
                        </div>
                    </div>
                </Dialog>
            }
            {
                // Job Complete Comment Form - Shown on change job status to delivery complete for the jobs created in IG Portal
                displayJobComment &&
                <Dialog visible={displayJobComment} header="Job Complete Comment" onHide={() => postJobCompleteComment('Cancel')} footer={renderJobCompleteCommentFooter()} breakpoints={{ '960px': '75vw' }} draggable={false}>
                    <div className="cancel-request">
                        <div className="p-grid d-flex">
                            <div className="p-col-12 p-md-12 p-sm-12">
                                <p className="font-normal fs-13 text-lightgray mt-0">Comment</p>
                                <TextboxElement label="" placeholder="" onChangeText={(e) => setComment(e.target.value)}></TextboxElement>
                            </div>
                        </div>
                        <div className='pt-10'>
                            {
                                jobCompleteComments?.map((element, index) => (
                                    <div className='tracking-system-card pl-10' key={index}>
                                        <p className="font-normal fs-13 text-lightgray mt-0"><strong>{element.user.firstName} {' '} {element.user.lastName}</strong> added this comment - <strong>{Moment(element.createdAt).format('MMM DD, YYYY HH:mm')}</strong></p>
                                        <p className="font-normal fs-13 text-lightgray mt-0">{element.comment}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </Dialog>
            }
            {
                displayQmComments &&
                <Dialog visible={displayQmComments} header='QM Comments' onHide={() => onHide('displayQmComments')} footer={renderQmCommentsFooter()} breakpoints={{ '960px': '75vw' }} draggable={false}>
                    {
                        qmData?.map((row, index) => (
                            <div className="cancel-request" key={index}>
                                <div className="p-grid d-flex">
                                    <div className="p-col-12 p-md-6 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0">User <span className='text-red'>*</span></p>
                                        <SingleSelectElement
                                            placeholder="Select"
                                            options={qmUsers}
                                            optionLabel="name"
                                            value={row?.user}
                                            onChangeValue={(e) => onChangeQmUser(e, index)}

                                        />
                                    </div>
                                    <div className="p-col-12 p-md-6 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0">Job Type <span className='text-red'>*</span></p>
                                        <SingleSelectElement
                                            placeholder="Select"
                                            options={row?.bpsUserId ? qmJobTypes : ''} // show job types only if user selected
                                            optionLabel="jobType"
                                            // value={''}
                                            onChangeValue={(e) => onChangeQmJobType(e, index)}
                                        />
                                    </div>
                                </div>
                                <div className="p-grid d-flex">
                                    <div className="p-col-12 p-md-6 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0">Job Range <span className='text-red'>*</span></p>
                                        <SingleSelectElement
                                            placeholder="Select"
                                            options={row?.rangeOptions}
                                            optionLabel="range"
                                            // value={''}
                                            onChangeValue={(e) => onChaneQmJobRange(e, index)}
                                        />
                                    </div>
                                    <div className="p-col-12 p-md-6 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0">Total Value</p>
                                        <TextboxElement
                                            value={row?.jobUserValue}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="p-grid d-flex">
                                    <div className="p-col-12 p-md-6 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0">Type of Error <span className='text-red'>*</span></p>
                                        <SingleSelectElement
                                            placeholder="Select"
                                            options={errorTypes}
                                            optionLabel="type"
                                            value={row?.error}
                                            onChangeValue={(e) => onChangeErrorType(e, index)}
                                        />
                                    </div>
                                    <div className="p-col-12 p-md-6 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0">No. of Errors <span className='text-red'>*</span></p>
                                        <InputNumber id="noOfErrors"
                                            onChange={(e) => onChangeNoOfErrors(e, index)}
                                            value={row?.noOfError}
                                        />
                                    </div>
                                </div>
                                <div className="p-grid d-flex">
                                    <div className="p-col-12 p-md-8 p-sm-8">
                                        <p className="font-normal fs-13 text-lightgray mt-0">Comment (optional)</p>
                                        <TextboxElement value={row?.comments} onChangeText={(e) => onChangeQmComment(e, index)}></TextboxElement>
                                    </div>
                                    <div className="p-col-12 p-md-3 align-center">
                                        <i className="pi pi-plus bu-plus-icon" onClick={() => addQmRow(index)}></i>
                                        {
                                            // index > 0 && (
                                            qmData?.length > 1 && (
                                                <i className="pi pi-minus bu-minus-icon" onClick={() => removeQmRow(index)}></i>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Dialog>
            }
            {
                // Input Modal
                displayInput &&
                <Dialog visible={displayInput} header="Input File" onHide={() => onHide('displayInput')} breakpoints={{ '960px': '75vw' }} draggable={false} className='manual-time-dialog'>
                    <div className="cancel-request">
                        <DataTableElement
                            value={jobData?.inputFiles}
                            columns={inputColumns}
                            selectionMode="single"
                            selectedId={selectData}
                            inputOutputFiles={true}
                        />
                    </div>
                </Dialog>
            }
            {
                // Templete Modal
                displayTemplate &&
                <Dialog visible={displayTemplate} header="Input Template File" onHide={() => onHide('displayTemplate')} breakpoints={{ '960px': '75vw' }} draggable={false} className='manual-time-dialog'>
                    <div className="cancel-request">
                        <DataTableElement
                            value={jobData?.tempFiles}
                            columns={inputColumns}
                            selectionMode="single"
                            selectedId={selectData}
                            inputOutputFiles={true}
                        />
                    </div>
                </Dialog>
            }
            {
                // Output Modal
                displayOutput &&
                <Dialog visible={displayOutput} header="Output File" onHide={() => onHide('displayOutput')} footer={renderOutputFilesFooter()} breakpoints={{ '960px': '75vw' }} draggable={false} className='output-file-dialog'>
                    <div className="cancel-request output-files">
                        <DataTableElement
                            value={outputFiles}
                            columns={outputColumns}
                            selectionMode="single"
                            selectedId={selectData}
                            inputOutputFiles={true}
                        />
                    </div>
                </Dialog>
            }
            {
                // Manual Time Form
                displayManualTime &&
                <Dialog visible={displayManualTime} header="Manual Time" onHide={() => onHide('displayManualTime')} footer={renderManualTimeFooter()} breakpoints={{ '960px': '75vw' }} draggable={false} className='manual-time-dialog'>
                    <div className="fs-13 text-lightgray service-desk-display-section">
                        {
                            manualTimeData?.map((row, index) => (
                                <div key={index}>
                                    <div className="p-grid service-desk-display-user">
                                        <div className='p-col-12 p-md-4'>
                                            <p className="font-normal fs-13 text-lightgray">Select User <span className='text-red'>*</span></p>
                                            <SingleSelectElement
                                                id="userDetails"
                                                className="servicedesk-textbox"
                                                placeholder="Select User"
                                                options={manualTimeUsers}
                                                optionLabel="name"
                                                onChangeValue={(e) => onChangeManualUser(e, index)}
                                                value={row?.user}
                                                disabled={!row?.isEdit}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-3 p-sm-3 align-center">
                                            <div className="service-desk-view-det">
                                                <p>Manual Time</p>
                                                <div className="align-center">
                                                    <div className="time-spent-box">
                                                        {/* <h5 className='dropdown-heading'></h5> */}
                                                        <TextboxElement
                                                            id="hoursManual"
                                                            className="time-box"
                                                            value={row?.hours}
                                                            onChangeText={(e) => onChangeManualTime(e, index)}
                                                        />
                                                        {<p className='m-0 text-center'><small>Hrs <span className='text-red'>*</span></small></p>}
                                                    </div>
                                                    <div className="time-spent-box">
                                                        {/* <h5 className='dropdown-heading'></h5> */}
                                                        <TextboxElement
                                                            id="minutesManual"
                                                            className="time-box"
                                                            value={row?.minutes}
                                                            onChangeText={(e) => onChangeManualTime(e, index)}
                                                        />
                                                        {<p className='m-0 text-center'><small>Mins <span className='text-red'>*</span></small></p>}
                                                    </div>
                                                    <div className="time-spent-box">
                                                        {/* <h5 className='dropdown-heading'></h5> */}
                                                        <TextboxElement
                                                            id="secondsManual"
                                                            className="time-box"
                                                            value={row?.seconds}
                                                            onChangeText={(e) => onChangeManualTime(e, index)}
                                                        />
                                                        {<p className='m-0 text-center'><small>Secs <span className='text-red'>*</span></small></p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-3 p-sm-3 align-center">
                                            <div className="service-desk-view-det">
                                                <p>Total Time</p>
                                                <div className="align-center">
                                                    <div className="time-spent-box">
                                                        {/* <h5 className='dropdown-heading'></h5> */}
                                                        <TextboxElement
                                                            className="time-box"
                                                            value={row?.totalTimeSpent?.days}
                                                            disabled
                                                        />
                                                        {<p className='m-0 text-center'><small>Days</small></p>}
                                                    </div>
                                                    <div className="time-spent-box">
                                                        {/* <h5 className='dropdown-heading'></h5> */}
                                                        <TextboxElement
                                                            className="time-box"
                                                            value={row?.totalTimeSpent?.hours}
                                                            disabled
                                                        />
                                                        {<p className='m-0 text-center'><small>Hrs</small></p>}
                                                    </div>
                                                    <div className="time-spent-box">
                                                        {/* <h5 className='dropdown-heading'></h5> */}
                                                        <TextboxElement
                                                            className="time-box"
                                                            value={row?.totalTimeSpent?.minutes}
                                                            disabled
                                                        />
                                                        {<p className='m-0 text-center'><small>Mins</small></p>}
                                                    </div>
                                                    <div className="time-spent-box">
                                                        {/* <h5 className='dropdown-heading'></h5> */}
                                                        <TextboxElement
                                                            className="time-box"
                                                            value={row?.totalTimeSpent?.seconds}
                                                            disabled
                                                        />
                                                        {<p className='m-0 text-center'><small>Secs</small></p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-2 align-center">
                                            <i className="pi pi-plus bu-plus-icon" onClick={() => addManualTimeRow(index)}></i>
                                            {
                                                // index > 0 && row.isEdit && (
                                                manualTimeData?.length > 1 && row.isEdit && (
                                                    <i className="pi pi-minus bu-minus-icon" onClick={() => removeManualTimeRow(index)}></i>
                                                )
                                            }
                                        </div>
                                    </div>

                                </div>
                            ))
                        }
                    </div>
                </Dialog>

            }
            {/* <div className='tracking-system-card p-10 leave-request mt-10'>
                <div className='p-grid'>
                    <div className='p-col-12 p-md-3 tracking-system-multiselect'>
                        <p className="font-normal fs-13 text-lightgray mt-0">Request Type</p>
                        <MultiSelectElement
                            placeholder="Select Request"
                            className="p-inputtext-multiselect"
                            options={requestTypeOptions}
                            optionLabel='name'
                            value={jobData?.jobInfo?.requestType}
                            maxSelectLabel={1}
                        />
                    </div>
                    <div className='p-col-12 p-md-3'>
                        <p className="font-normal fs-13 text-lightgray mt-0">Parent Link To</p>
                        <SingleSelectElement
                            placeholder="Select Parent Link"
                            optionLabel="jobNumber"
                            optionValue="id"
                            options={paretLinksOptions}
                            value={jobData?.jobInfo?.parentLink}
                        />
                    </div>
                    <div className='p-col-12 p-md-3 tracking-system-multiselect'>
                        <p className="font-normal fs-13 text-lightgray mt-0">Usage</p>
                        <MultiSelectElement
                            placeholder="Select Usage"
                            className="p-inputtext-multiselect"
                            options={usageOptions}
                            optionLabel='name'
                            value={jobData?.jobInfo?.usage}
                            maxSelectLabel={1}
                        />
                    </div>
                    <div className='p-col-12 p-md-3'>
                        <p className="font-normal fs-13 text-lightgray mt-0">Size/Dimensions</p>
                        <TextboxElement name="sizeDimensions" value={jobData?.jobInfo?.sizeDimensions} readOnly />
                    </div>
                </div>
                <div className='p-grid'>
                    <div className='p-col-12 p-md-3'>
                        <p className="font-normal fs-13 text-lightgray mt-0">Font Family</p>
                        <TextboxElement name="fontFamily" value={jobData?.jobInfo?.fontFamily} readOnly />
                    </div>
                    <div className='p-col-12 p-md-3'>
                        <p className="font-normal fs-13 text-lightgray mt-0">Output File Type</p>
                        <TextboxElement name="opfileType" value={jobData?.jobInfo?.opfileType} readOnly />
                    </div>
                    <div className='p-col-12 p-md-3'>
                        <p className="font-normal fs-13 text-lightgray mt-0">Print Specifications</p>
                        <TextboxElement name="printSpec" value={jobData?.jobInfo?.printSpec} readOnly />
                    </div>
                    <div className='p-col-12 p-md-3 align-center'>
                        <CheckboxElement
                            className='font-normal fs-13 text-lightgray'
                            value={"Please tick this box if IG are supplying printing"}
                            checked={jobData?.jobInfo?.printCheck} readOnly />
                    </div>
                </div>
            </div> */}
            <div className='tracking-system-card p-10 mt-10 leave-request'>
                <div className='p-grid'>
                    <div className='p-col-12 p-md-2'>
                        <p className="font-normal fs-13 text-lightgray mt-0">Review JE</p>
                        <InputNumber value={jobData?.jobInfo?.reviewJE} onChange={(e) => setReviewJE(e.value)} />
                    </div>
                    <div className='p-col-12 p-md-2'>
                        <p className="font-normal fs-13 text-lightgray mt-0">Production JE</p>
                        <InputNumber value={jobData?.jobInfo?.productionJE} onChange={(e) => setProductionJE(e.value)} />
                    </div>
                    <div className='p-col-12 p-md-2'>
                        <p className="font-normal fs-13 text-lightgray mt-0">QC JE</p>
                        <InputNumber value={jobData?.jobInfo?.qcJE} max={999} onChange={(e) => setQcJE(e.value)} />
                    </div>
                    <div className='p-col-12 p-md-3 tracking-system-multiselect'>
                        <p className="font-normal fs-13 text-lightgray mt-0">BPS User</p>
                        <MultiSelectElement
                            placeholder="Select BPS User"
                            className="p-inputtext-multiselect"
                            options={jobData?.bpsQCusers}
                            optionLabel={(option) => `${option.firstName} ${option.lastName}`}
                            value={bpsUsers}
                            maxSelectLabel={1}
                            onChangeValue={(e) => onChangeBpsUsers(e)}
                        />
                    </div>
                    <div className='p-col-12 p-md-3 tracking-system-multiselect'>
                        <p className="font-normal fs-13 text-lightgray mt-0">QC User</p>
                        <MultiSelectElement
                            placeholder="Select QC User"
                            className="p-inputtext-multiselect"
                            options={jobData?.bpsQCusers}
                            optionLabel={(option) => `${option.firstName} ${option.lastName}`}
                            value={qcUsers}
                            maxSelectLabel={1}
                            onChangeValue={(e) => setQcUsers(e.value)}
                        />
                    </div>
                </div>
                {

                    jobTypes?.map((row, index) => (
                        <div key={index}>
                            <div className='p-grid'>
                                <div className='p-col-12 p-md-3'>
                                    <p className="font-normal fs-13 text-lightgray mt-0">Job Type</p>
                                    <SingleSelectElement
                                        id="jobType"
                                        placeholder="Select Job Type"
                                        options={jobData?.jobTypes}
                                        optionLabel="jobType"
                                        onChangeValue={(e) => onChangeJobType(e, index)}
                                        value={row.value}
                                    />
                                </div>
                                <div className='p-col-12 p-md-3 p-sm-6'>
                                    <p className="font-normal fs-13 text-lightgray mt-0">Value</p>
                                    <InputText id="jobTypeValue" onChange={(e) => onChangeJobType(e, index)} value={row.jobTypeValue} />
                                </div>
                                <div className="p-col-12 p-md-4 align-center">
                                    <i className="pi pi-plus bu-plus-icon" onClick={() => addJobTypeRow(index)}></i>
                                    {
                                        // index > 0 && (
                                        jobTypes?.length > 1 && (
                                            <i className="pi pi-minus bu-minus-icon" onClick={() => removeJobTypeRow(index)}></i>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
                {
                    bpsUsers && bpsUsers.length > 0 && (
                        <>
                            {sheduler?.map((row, index) => (
                                <div key={index}>
                                    <div className='p-grid'>
                                        <div className='p-col-12 p-md-3'>
                                            <p className="font-normal fs-13 text-lightgray mt-0">Select User</p>
                                            <SingleSelectElement
                                                id="sheduleUser"
                                                placeholder="Select User"
                                                options={bpsUsers}
                                                optionLabel={(option) => `${option.firstName} ${option.lastName}`}
                                                onChangeValue={(e) => onChangeShedule(e, index)}
                                                value={row.user}
                                            />
                                        </div>
                                        <div className='p-col-12 p-md-3'>
                                            <p className="font-normal fs-13 text-lightgray mt-0">Range</p>
                                            <InputText id="range" value={row.range} onChange={(e) => onChangeShedule(e, index)} />
                                        </div>
                                        <div className='p-col-12 p-md-2'>
                                            <p className="font-normal fs-13 text-lightgray mt-0">Total</p>
                                            <InputNumber id="total" value={row.total} onValueChange={(e) => onChangeShedule(e, index)} />
                                        </div>
                                        <div className='p-col-12 p-md-2'>
                                            <p className="font-normal fs-13 text-lightgray mt-0">Production JE</p>
                                            <InputNumber id="productionJE" value={row.productionJE} onValueChange={(e) => onChangeShedule(e, index)} />
                                        </div>
                                        <div className="p-col-12 p-md-2 align-center">
                                            <i className="pi pi-plus bu-plus-icon" onClick={() => addSheduler(index)}></i>
                                            {
                                                // index > 0 && (
                                                //     <i className="pi pi-minus bu-minus-icon" onClick={() => removeSheduler(index)}></i>
                                                // )

                                                sheduler?.length > 1 && (
                                                    <i className="pi pi-minus bu-minus-icon" onClick={() => removeSheduler(index, row)}></i>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    )
                }

            </div>
            <div className='tracking-system-card p-10 mt-10'>
                <span className='jobNum'>Instructions</span>
                {
                    !jobData?.jobInfo?.isIGPortal && (
                        <Button className='travel-submit' label="EDIT" onClick={getTextEditor} />
                    )
                }
                <p className='tracking-system-instructions' dangerouslySetInnerHTML={{ __html: jobData && jobData.jobInfo ? jobData.jobInfo.description : '' }}></p>
            </div>
            {
                displayEditor && (
                    <Dialog visible={displayEditor} header="Instructions" onHide={() => onHide('displayEditor')} breakpoints={{ '960px': '75vw' }} draggable={false} footer={renderInstructionsSubmit()}>
                        <ReactSummernoteLite height={200}
                            onInit={({ note }) => {
                                editor = note;
                                editor.summernote('code', jobData?.jobInfo?.description ?? "");
                            }}
                            onChange={(e) => { editor.value = e }}
                        />
                    </Dialog>
                )
            }
            <div className='tracking-system-card p-10 mt-10' id="serviceDesk">
                <div className='tracking-system-tabview'>
                    <TabView activeIndex={activeIndex} onTabChange={(e) => { setActiveIndex(e.index) }}>
                        <TabPanel header="Comments">
                            <div className=' tracking-system-tab-card'>
                                <div className="d-flex justify-end comments-button-width">
                                    <ButtonElement type="submit" label="Comment" onClickButton={() => onClickAddComment('displayComment')} className="attachment-button1 mb-8" icon="pi pi-plus"></ButtonElement>
                                </div>
                                {commentData.length > 0 && commentData.map(data => {
                                    return (
                                        <div className="servicedesk-commentsection">
                                            <p className="servicedesk-para"><span className="comment-fn">{data.user?.firstName} {data.user?.lastName}</span> - <small className="comment-created">{Moment(data.createdAt).format('MMM D, YYYY HH:mm:ss')}</small></p>
                                            <div className="servicedesk-para" dangerouslySetInnerHTML={{ __html: data.comment }} />
                                        </div>
                                    )
                                })}
                                {
                                    displayComment &&
                                    <Dialog visible={displayComment} header="Comment" onHide={() => onHide('displayComment')} footer={renderComments()} breakpoints={{ '960px': '75vw' }} draggable={false}>
                                        <div className="cancel-request">
                                            <div className="p-grid d-flex">
                                                <div className="p-col-12">
                                                    <ReactSummernoteLite onInit={({ note }) => { commentEditor = note; }} />
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog>
                                }
                            </div>
                        </TabPanel>
                        <TabPanel header="Work Log">
                            <div className='tracking-system-tab-card'>
                                <div className="worklog-content d-flex justify-between comments-button-width">
                                    <p><span className="comment-fnn"></span>{jobData?.jobInfo?.creater?.firstName} {jobData?.jobInfo?.creater?.lastName} <span className="worklog-status"> Created</span> this job <span className="worklog-created" style={{ color: '#0576b9' }}>{Moment(jobData?.jobInfo?.createdAt).format("'MMM D, YYYY HH:mm:ss")}</span></p>
                                    {jobData?.jobInfo?.ER && (
                                        <p>Job Estimate: <span>{parseInt(jobData?.jobInfo?.ER) * 6}mins</span></p>
                                    )}
                                </div>
                                {worklogData?.length > 0 && worklogData.map(data => {
                                    return (
                                        <>
                                        {
                                            data?.fromStatus && !data?.status && (
                                                <div className="servicedesk-component">
                                                    <div className="servicedesk-worklog">
                                                        <p className="worklog-para"><span className="worklog-fn">{data?.userName}</span> transitioned the Job Status from <span className="worklog-status">{data?.fromStatus}</span> to <span className="worklog-status">{data?.toStatus}</span> on
                                                            <span className="worklog-createdAt" style={{ color: '#0576b9' }}> {Moment(data?.createdAt).format("MMM D, YYYY HH:mm:ss")}</span></p>
                                                    </div>
                                                    <div className="worklog-duration">
                                                        <p>{data?.diff}</p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            data?.status && !data.fromStatus && (
                                                <div className="servicedesk-component">
                                                    <div className="servicedesk-worklog">
                                                        <p className="worklog-para"><span className="worklog-fn">{data?.user}</span> has spent
                                                            <span className="worklog-createdAt" style={{ color: '#0576b9' }}> {data?.time}</span> time in job status <span className="worklog-status">{data?.status}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        </>
                                    )
                                })}
                            </div>
                        </TabPanel>
                        <TabPanel header="Recent Activities">
                            <div className='tracking-system-tab-card'>
                                {recentActivityData && recentActivityData.map(activity => {
                                    return (
                                        <div className="recentactivity-component">
                                            {
                                                (activity.activity === 'bpsUsers' || activity.activity === 'qcUsers' || activity.activity === 'priority' || activity.activity === 'status'
                                                    || activity.activity === 'jobType' || activity.activity === 'jobTypeValue' || activity.activity === 'deadline_ms' || activity.activity === 'productionJE'
                                                    || activity.activity === 'qcJE' || activity.activity === 'attachment' || activity.activity === 'jobValueType' || activity.activity === 'comment'
                                                    || activity.activity === 'manualJobTime' || activity.activity === 'stopwatch' || activity.activity === 'description' || activity.activity === 'Scheduled'
                                                    || activity.activity === 'parentLink' || activity.activity === 'docType' || activity.activity === 'fileName' || activity.activity === 'deleteQMComment'
                                                    || activity.activity === 'manualTime') && (
                                                    <div className="p-col-12 d-flex">
                                                        <div className='p-col-1'>
                                                            {
                                                                activity.modifiedByGender === 'Female' && (
                                                                    <div>
                                                                        <img src={avatarFemale} className='associates-avatar' size="xlarge" shape="circle" />
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.modifiedByGender === 'Male' && (
                                                                    <div>
                                                                        <img src={avatar} className='associates-avatar' size="xlarge" shape="circle" />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>

                                                        <div className="p-col-8 ra-modifiedby">
                                                            {
                                                                activity.activity === 'stopwatch' && (
                                                                    <div>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        {
                                                                            activity.action === 'started' && (
                                                                                <span>
                                                                                    {activity.action} the {activity.activity} and the status is set to {activity.timerStatus}
                                                                                </span>
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'bpsUsers' && (
                                                                    <div>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        {
                                                                            activity.action === 'added' && !activity.userLen && (
                                                                                <span>
                                                                                    assigned a new BPS user {activity.newValue} to the JOB
                                                                                </span>
                                                                            )
                                                                        }
                                                                        {
                                                                            activity.action === 'added' && activity.userLen && (
                                                                                <span>
                                                                                    assigned new BPS users {activity.newValue} to the JOB
                                                                                </span>
                                                                            )
                                                                        }
                                                                        {
                                                                            activity.action === 'removed' && !activity.userLen && (
                                                                                <span>
                                                                                    removed a BPS user {activity.oldValue} from the JOB
                                                                                </span>
                                                                            )
                                                                        }
                                                                        {
                                                                            activity.action === 'removed' && activity.userLen && (
                                                                                <span>
                                                                                    removed BPS users {activity.oldValue} from the JOB
                                                                                </span>
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'qcUsers' && (
                                                                    <div>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        {
                                                                            activity.action === 'added' && !activity.userLen && (
                                                                                <span>
                                                                                    assigned a new QC user {activity.newValue} to the JOB
                                                                                </span>
                                                                            )
                                                                        }
                                                                        {
                                                                            activity.action === 'added' && activity.userLen && (
                                                                                <span>
                                                                                    assigned new QC users {activity.newValue} to the JOB
                                                                                </span>
                                                                            )
                                                                        }
                                                                        {
                                                                            activity.action === 'removed' && !activity.userLen && (
                                                                                <span>
                                                                                    removed a QC user {activity.oldValue} from the JOB
                                                                                </span>
                                                                            )
                                                                        }
                                                                        {
                                                                            activity.action === 'removed' && activity.userLen && (
                                                                                <span>
                                                                                    removed QC users {activity.oldValue} from the JOB
                                                                                </span>
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'priority' && (
                                                                    <div>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        {activity.action} the priority {
                                                                            activity.oldValue != '' ? 'from ' +
                                                                                activity.oldValue : ''
                                                                        } to {activity.newValue}
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'jobType' && (
                                                                    <div>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        {activity.action} the job type from {activity.oldValue} to {activity.newValue}
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'docType' && (
                                                                    <div>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        {
                                                                            activity.action === 'added' && (
                                                                                <span>{activity.action} the Doc type {activity.newValue}</span>
                                                                            )
                                                                        }
                                                                        {
                                                                            activity.action === 'removed' && (
                                                                                <span>{activity.action} the Doc type {activity.oldValue}</span>
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'fileName' && (
                                                                    <div>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        {activity.action} the Filename from {activity.oldValue} to {activity.newValue}
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'deleteQMComment' && (
                                                                    <div>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        {activity.action} the Quality metric {activity.newValue}
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'comment' && (
                                                                    <div>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        {activity.action} a new comment
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'description' && (
                                                                    <div>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        <span>{activity.action} the description from </span>
                                                                        {activity.oldValue ? <span dangerouslySetInnerHTML={{ __html: activity.oldValue }}></span> : "null"}
                                                                        <span> to</span>
                                                                        <span dangerouslySetInnerHTML={{ __html: activity.newValue }}></span>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'jobValueType' && (
                                                                    <div>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        <span>
                                                                            {activity.action} the job type {activity.newValue.jobType} with value {activity.newValue.jobTypeValue}
                                                                        </span>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'jobTypeValue' && (
                                                                    <div>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        <span>{activity.action} the job type value from {activity.oldValue} to {activity.newValue} </span>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'parentLink' && (
                                                                    <div>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        {
                                                                            activity.action === 'added' && (
                                                                                <span>{activity.action} the Parent Link {activity.newValue}</span>
                                                                            )
                                                                        }
                                                                        {
                                                                            activity.action === 'changed' && (
                                                                                <span>{activity.action} the Parent Link {activity.oldValue} to {activity.newValue} </span>
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'deadline_ms' && (
                                                                    <div>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        {activity.action} the deadline from {Moment(activity.oldValue).format('MMM D, YYYY h:mm:ss A')} to
                                                                        {Moment(activity.newValue).format('MMM D, YYYY h:mm:ss A')}
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'ER' && (
                                                                    <div>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        {
                                                                            activity.oldValue && activity.newValue && (
                                                                                <span>{activity.action} the Job Estimate(JE) from {activity.oldValue} to {activity.newValue}</span>
                                                                            )
                                                                        }
                                                                        {
                                                                            activity.oldValue && !activity.newValue && (
                                                                                <span>{activity.action} the Job Estimate(JE) {activity.oldValue}</span>
                                                                            )
                                                                        }
                                                                        {
                                                                            !activity.oldValue && activity.newValue && (
                                                                                <span>{activity.action} the Job Estimate(JE) {activity.newValue}</span>
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'productionJE' && (
                                                                    <div>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        {
                                                                            activity.oldValue && activity.newValue && (
                                                                                <span>{activity.action} the Production Job Estimatefrom {activity.oldValue} to {activity.newValue}</span>
                                                                            )
                                                                        }
                                                                        {
                                                                            activity.oldValue && !activity.newValue && (
                                                                                <span>{activity.action} the Production Job Estimate {activity.oldValue}</span>
                                                                            )
                                                                        }
                                                                        {
                                                                            !activity.oldValue && activity.newValue && (
                                                                                <span>{activity.action} the Production Job Estimate {activity.newValue}</span>
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'qcJE' && (
                                                                    <div>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        {
                                                                            activity.oldValue && activity.newValue && (
                                                                                <span>{activity.action} the QC Job Estimatefrom {activity.oldValue} to {activity.newValue}</span>
                                                                            )
                                                                        }
                                                                        {
                                                                            activity.oldValue && !activity.newValue && (
                                                                                <span>{activity.action} the QC Job Estimate {activity.oldValue}</span>
                                                                            )
                                                                        }
                                                                        {
                                                                            !activity.oldValue && activity.newValue && (
                                                                                <span>{activity.action} the QC Job Estimate {activity.newValue}</span>
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'attachment' && (
                                                                    <div>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        {activity.action} a new attachment
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'status' && (
                                                                    <div>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        {activity.action} the job status from {activity.oldValue} to {activity.newValue}
                                                                        {
                                                                            activity.newValue === 'Delivery complete' && (
                                                                                <span>and updated the Feedback</span>
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'Scheduled' && (
                                                                    <span>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        {activity.action} the Scheduled User {activity.oldValue} {activity.newValue}
                                                                    </span>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'manualJobTime' && (
                                                                    <span>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        {activity.action}  manual time for the JOB
                                                                    </span>
                                                                )
                                                            }
                                                            {
                                                                activity.activity === 'manualTime' && (
                                                                    <span>
                                                                        <strong>{activity.modifiedBy} </strong>
                                                                        <span>
                                                                            {activity.action} manual time {activity.newValue.hours > 0 ? activity.newValue.hours == 1 ?
                                                                                activity.newValue.hours + ' Hr' : activity.newValue.hours + ' Hrs' : ''}
                                                                            {activity.newValue.minutes > 0 ? activity.newValue.minutes == 1 ?
                                                                                activity.newValue.minutes + ' Min' : activity.newValue.minutes + ' Mins' : ''}
                                                                            {activity.newValue.seconds > 0 ? activity.newValue.seconds == 1
                                                                                ? activity.newValue.seconds + ' Sec' : activity.newValue.seconds + ' Secs' : ''} for {activity.user}
                                                                        </span>
                                                                    </span>
                                                                )
                                                            }
                                                        </div>
                                                        <div className="p-col-3 worklog-updated">
                                                            <p> {Moment(activity.changedTime).format("MMM D, YYYY HH:mm:ss")}</p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        </TabPanel>
                        {
                            adminPermission == true && 
                            <TabPanel header="Quality Metrics">
                                <div className='tracking-system-tab-card qulaity-metrics'>
                                    <DataTable p-paginator-rpp-options={'top'} value={qualityMetricsData} responsiveLayout="scroll"
                                        paginator rows={10} paginatorTemplate={paginatorTemplate}
                                        emptyMessage="No entries found">
                                        <Column field="bpsUser.firstName" header="Users"></Column>
                                        <Column field="job_type.jobType" header="Job Type"></Column>
                                        <Column field="jobRange" header="Range"></Column>
                                        <Column field="jobUserValue" header="Value"></Column>
                                        <Column field="job_quality_error_type.type" header="Error Type"></Column>
                                        <Column field="comments" header="Comments"></Column>
                                        <Column field="noOfError" header="No Of Error(s)"></Column>
                                        <Column field="weightage" header="Points"></Column>
                                        <Column header="Action" body={deleteTemplate}></Column>
                                    </DataTable>
                                    {
                                        deleteModal && (
                                            <Dialog visible={deleteModal} header="Confirmation Alert" onHide={() => onHide('deleteModal')} footer={renderDelete()} breakpoints={{ '960px': '75vw' }} draggable={false}>
                                                <div className="cancel-request">
                                                    <div className="p-grid">
                                                        <div className="p-col-12">
                                                            <p className="font-normal fs-13 text-lightgray m-0">Are you sure you want to delete the metric?</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Dialog>
                                        )
                                    }
                                </div>
                            </TabPanel>
                        }
                    </TabView>
                </div>
            </div>
        </div>
    )
}

export default JobDetailedView
