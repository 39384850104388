/**
 * Authentication Service
 */
import axios from 'axios';

let baseUrl = process.env.REACT_APP_API_URL;

async function getResources() {
	let response = await axios.get(`${baseUrl}/api/resources`);
	return response.data;
}

async function updateResources(data) {
	let response = await axios.patch(`${baseUrl}/api/resources/${data.id}`, data);
	return response.data;
}

async function getPermissions() {
	let response = await axios.get(`${baseUrl}/api/permission`);
	return response.data;
}

async function updatePermissionsStatus(data) {
	let response = await axios.patch(`${baseUrl}/api/permission/status/${data.id}`, data);
	return response.data;
}

async function addPermission(data) {
	let response = await axios.post(`${baseUrl}/api/permission/`, data);
	return response.data;
}

async function editPermission(id) {
	let response = await axios.get(`${baseUrl}/api/permission/${id}`);
	return response.data;
}

async function updatePermission(data) {
	let response = await axios.patch(`${baseUrl}/api/permission/${data.id}`, data);
	return response.data;
}

async function getMenusByUserId(userId) {
	let response = await axios.get(`${baseUrl}/api/menu/getByUserId/${userId}`);
	return response.data;
}

async function getMenus() {
	let response = await axios.get(`${baseUrl}/api/menu/`);
	return response.data;
}

async function updateMenusStatus(data) {
	let response = await axios.patch(`${baseUrl}/api/menu/status/${data.id}`, data);
	return response.data;
}

async function addMenu(data) {
	let response = await axios.post(`${baseUrl}/api/menu/`, data);
	return response.data;
}

async function editMenu(id) {
	let response = await axios.get(`${baseUrl}/api/menu/${id}`);
	return response.data;
}

async function updateMenu(data) {
	let response = await axios.patch(`${baseUrl}/api/menu/${data.id}`, data);
	return response.data;
}

export default { getResources, updateResources, getPermissions, updatePermissionsStatus, addPermission, editPermission, updatePermission, getMenusByUserId, getMenus, updateMenusStatus, addMenu, editMenu, updateMenu };