/*
* Util service for localStorage
*
*/
import Cookies from 'universal-cookie';
const cookies = new Cookies();
function set(key,token){
    cookies.set(key,token);
}
function getToken(token){
    return cookies.get(token);
}
function clearAll(){
    return localStorage.clear();
}

export default { getToken, set, clearAll};