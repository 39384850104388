/* Import Section - Start */
/* React Imports - Start */
import React, { useState, useEffect } from "react";
/* React Imports - End */
/* Import Section - End */

/* Plugin Imports - Start */
import { InputTextarea } from "primereact/inputtextarea";
/* Plugin Imports - End */

/* Function - Start */
const TextareaElement = (props) => {
    /* State Declaration - Start */
	/* useState - Start */
    const [inputValue, setInputValue] = useState(" ");
    /* useState - End */
	/* State Declaration - End */

    useEffect(() => {
        setInputValue(props.value ? props.value :"");
    });
    
    /* Methods - Start */
    const onValueChange = (e) => {
        setInputValue(e.target.value);
        props.onTextareaChange(e);
    };
    /* Methods - End */

    /* Render View Return - Start */
    return (
        <div>
            <InputTextarea
                key={props.name}
                name={props.name}
                value={inputValue}
                rows={props.rows}
                cols={props.cols}
                onChange={(e) => onValueChange(e)}
                placeholder={props.placeholder}
                className={props.className}
                disabled={props.disabled?props.disabled:false}
            ></InputTextarea>
        </div>
    );
    /* Render View Return - End */
};
/* Function - End */
/* Export default functionName; */
export default TextareaElement;
