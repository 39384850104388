/* Import Section - Start */
/* React Imports - Start */
import React, { useState, useEffect } from "react";
/* React Imports - End */

/* Plugin Imports - Start */
import { InputText } from "primereact/inputtext";
/* Plugin Imports - End */
/* Import Section - End */

/* Function - Start */
const TextboxElement = (props) => {
    /* State Declaration - Start */
    /* useState - Start */
    const [inputValue, setInputValue] = useState('');
    /* useState - End */
    /* State Declaration - End */

    /* useState - Start */
    useEffect(() => {
        setInputValue(props.value);


    });
    /* useState - End */
    /* Methods - Start */
    const handleChange = (e) => {
        setInputValue(e.target.value);
        props.onChangeText(e);
    };
    /* Methods - End */

    /* Render View Return - Start */
    return (
        <div>
            <InputText
                id={props?.id}
                name={props.name}
                value={inputValue}
                onChange={(e) => handleChange(e)}
                placeholder={props.placeholder}
                className={props.className}
                key={props.key}
                type={props.type}
                keyfilter={props.keyfilter}
                disabled={props.disabled}
                readOnly={props.readOnly}
            />
        </div>
    );
    /* Render View Return - End */
};
/* Function - End */
/* Export default functionName; */
export default TextboxElement;
