/**
 * Authentication Service
 */
import axios from 'axios';

let baseUrl = process.env.REACT_APP_API_URL;

async function getProjects() {
	let response = await axios.get(`${baseUrl}/api/projects/`);
	return response.data;
}

async function editProject(projectId) {
	let response = await axios.get(`${baseUrl}/api/projects/${projectId}`);
	return response.data;
}

// Delete Propose project
async function deleteProject(projectId) {
	let response = await axios.put(`${baseUrl}/api/projects/deleteProject/${projectId}`);;
	return response.data;
}


async function postProject(data) {
	let response = await axios.post(`${baseUrl}/api/projects/`, data);
	return response.data;
}

async function updateProject(id, data) {
	let response = await axios.patch(`${baseUrl}/api/projects/${id}`, data);
	return response.data;
}

async function cloneProjects(cloneData) {
	let response = await axios.post(`${baseUrl}/api/v1/cloneProject`, cloneData);
	return response.data;
}

async function getSurvey(data, projectId) {
	let response = await axios.get(`${baseUrl}/api/v1/surveyProjectfilterdata/7f5d4cda-1d4b-43dc-bd24-6fd4b94fd400`);
	return response.data;
}

async function getHubspotProjects(filterData) {
	let response = await axios.get(`${baseUrl}/api/projects/hubspot/getHubspotRecords?isProject=${filterData.isProject}`);
	return response.data;
}

async function getProjectAllocation(data) {
	let response = await axios.get(`${baseUrl}/api/projects/getAllocation/${data.userId}/${data.buId}?startDate=${data.startDate}&endDate=${data.endDate}&dashboard=${data.dashboard}`);
	return response.data;
}

async function getHubspotProjectById(hubspotId) {
	let response = await axios.get(`${baseUrl}/api/projects/getHubspotProjectId/${hubspotId}`);
	return response.data;
}

async function getClientId() {
	let response = await axios.get(`${baseUrl}/api/clients/clientId/getClientDetails`);
	return response.data;
}

async function getCode(type) {
	let response = await axios.get(`${baseUrl}/api/projects/getCode/${type}`);
	return response.data;
}

async function getClientCode(clientId) {
	let response = await axios.get(`${baseUrl}/api/projects/getClientCode/${clientId}`);
	return response.data;
}

async function getBAUProjectTasks(projectId) {
	let response = await axios.get(`${baseUrl}/api/tasks/getByProjectId/${projectId}`);
	return response.data;
}

async function getSalesProjectTasks(projectId, data) {
	let response = await axios.get(`${baseUrl}/api/tasks/getByProjectId/${projectId}/sales?start=${data.start}&end=${data.end}`);
	return response.data;
}

async function getProjectsFilter(filter) {
	let response = await axios.get(`${baseUrl}/api/projects/?status=${filter.status}&startDate=${filter.startDate}&endDate=${filter.endDate}`);
	return response.data;
}


async function getResourcePlanner() {
	let response = await axios.get(`${baseUrl}/api/resourcePlanner/`);
	return response.data;
}

async function triggerEmail() {
	let response = await axios.get(`${baseUrl}/api/resourcePlanner/triggerEmail`)
	return response.data
}

async function getResourcingByFilter(filter) {

	if (filter['filterType'] == 'Allocation_start'){
		filter['projectAllocateStartDateFrom'] = filter['projectStartDate'];
		filter['projectAllocateStartDateTo'] = filter['projectEndDate'];
		filter['projectAllocateEndDateFrom'] = '';
		filter['projectAllocateEndDateTo'] = '';
		filter['projectStartDateFrom'] = ''
		filter['projectStartDateTo'] = ''
		filter['projectEndDateFrom'] = ''
		filter['projectEndDateTo'] = ''
	}
	else if (filter['filterType'] == 'Allocation_end') {
		filter['projectAllocateStartDateFrom'] = '';
		filter['projectAllocateStartDateTo'] = '';
		filter['projectAllocateEndDateFrom'] = filter['projectStartDate'];
		filter['projectAllocateEndDateTo'] = filter['projectEndDate'];
		filter['projectStartDateFrom'] = ''
		filter['projectStartDateTo'] = ''
		filter['projectEndDateFrom'] = ''
		filter['projectEndDateTo'] = ''
	}
	else if (filter['filterType'] == 'Project_start') {
		filter['projectAllocateStartDateFrom'] = '';
		filter['projectAllocateStartDateTo'] = '';
		filter['projectAllocateEndDateFrom'] = '';
		filter['projectAllocateEndDateTo'] = '';
		filter['projectStartDateFrom'] = filter['projectStartDate'];
		filter['projectStartDateTo'] = filter['projectEndDate'];
		filter['projectEndDateFrom'] = ''
		filter['projectEndDateTo'] = ''
	}
	else {
		filter['projectAllocateStartDateFrom'] = '';
		filter['projectAllocateStartDateTo'] = '';
		filter['projectAllocateEndDateFrom'] = '';
		filter['projectAllocateEndDateTo'] = '';
		filter['projectStartDateFrom'] = ''
		filter['projectStartDateTo'] = ''
		filter['projectEndDateFrom'] = filter['projectStartDate'];
		filter['projectEndDateTo'] = filter['projectEndDate'];
	}

	if (filter.selectedGroupedStatus) {
		var statusFilter = {
			current: filter.selectedGroupedStatus.filter(t => t.includes('Current')).map(t => { return t.split('_').pop('') }),
			nextweek: filter.selectedGroupedStatus.filter(t => t.includes('After2Weeks')).map(t => { return t.split('_').pop('') }),
			next: filter.selectedGroupedStatus.filter(t => t.includes('Next')).map(t => { return t.split('_').pop('') }),
			future: filter.selectedGroupedStatus.filter(t => t.includes('Future')).map(t => { return t.split('_').pop('') })
		};
	} else {
		var statusFilter = {
			current: [],
			nextweek: [],
			next: [],
			future: []
		}
	}

	let response = await axios.get(`${baseUrl}/api/resourcePlanner/getResourcingByFilter?userId=${filter.user}&projectId=${filter.project}&status=${filter.status}&from=${filter.from}&bu_units=${filter.bu_units}&statusFilter=${JSON.stringify(statusFilter)}&client=${filter.client}&designationId=${filter.designationId}&projectStartDateFrom=${filter.projectStartDateFrom}&projectStartDateTo=${filter.projectStartDateTo}&projectEndDateFrom=${filter.projectEndDateFrom}&projectEndDateTo=${filter.projectEndDateTo}&allocationStartDateFrom=${filter.projectAllocateStartDateFrom}&allocationStartDateTo=${filter.projectAllocateStartDateTo}&allocationEndDateFrom=${filter.projectAllocateEndDateFrom}&allocationEndDateTo=${filter.projectAllocateEndDateTo}`);
	return response.data;
}

export default { getProjects, cloneProjects, getSurvey, editProject, deleteProject, postProject, updateProject, getHubspotProjects, getProjectAllocation, getHubspotProjectById, getClientId, getCode, getClientCode, getBAUProjectTasks, getSalesProjectTasks, getProjectsFilter, getResourcePlanner, getResourcingByFilter, triggerEmail };