import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
// import { MsalProvider } from "@azure/msal-react";
// import { msalConfig } from './components/login/LoginAuthConfig';
// import { PublicClientApplication } from '@azure/msal-browser'; 

// const msalInstance = new PublicClientApplication(msalConfig);

// try {
//   Sentry.init({
//     // dsn: "https://47bcb30576d64c39a589fd7721e76ad0@o346478.ingest.sentry.io/6776831",
//     // integrations: [new BrowserTracing()],
//     // environment: "development",
  
//     dsn: "https://47bcb30576d64c39a589fd7721e76ad0@o346478.ingest.sentry.io/6776831",
//     integrations: [new BrowserTracing()],
  
//    // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   });
// } catch(e) {
//   console.log(e, "error")
// }

ReactDOM.render(
  <BrowserRouter>
    {/* <MsalProvider instance={msalInstance}> */}
      <App />
    {/* </MsalProvider> */}
  </BrowserRouter>
  , 
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
