/* Import Section - Start */

/* React Imports - Start */

import React, { useState, useEffect, useRef } from 'react';

/* React Imports - End */

/* Plugin Imports - Start */

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import _ from 'lodash';
import moment from 'moment';
import Moment from 'moment';
import Cookies from 'universal-cookie';
import Datetime from 'react-datetime';

/* Plugin Imports - End */

/* Project Components Imports - Start */

import CheckboxElement from '../uiComponents/CheckboxElement';
import SingleSelectElement from '../uiComponents/SingleSelectElement';
import LeaveService from "../../services/leave/LeaveService";
import TextareaElement from '../uiComponents/TextareaElement';
import avatar from '../../assets/images/Avatar.png';
import ToastElement from "../uiComponents/ToastElement";
import avatarFemale from '../../assets/images/AvatarFemale.png';
import "react-datetime/css/react-datetime.css";
import CalendarElement from '../uiComponents/CalendarElement';
import RadioElement from "../uiComponents/RadioElement";
import EmployeesService from '../../services/employees/EmployeesService';
import FileUploadElement from '../uiComponents/FileUpload';
// import ExpenseService from "../../services/expense/ExpenseService";
import { formatDate } from '@fullcalendar/react';


/* Project Components Imports - End */

/* Function - Start */

const RequestModalElement = (props) => {

    /* State Declaration - Start */

    /* useState - Start */

    let start_date_half = [{ key: "1", name: "start_date" }]
    let end_date_half = [{ key: "2", name: "end_date" }]
    let start_half_day = props.leaveEditData && new Date(props.leaveEditData.from_date).getTime();
    let half_date = props.leaveEditData && new Date(props.leaveEditData.half_day_date).getTime();

    const [leaveTypes, setLeaveTypes] = useState([]);
    const [requestType, setRequestType] = useState([]);
    const [userList, setUserList] = useState([]);
    const [reporteesList, setReporteesList] = useState([]);
    const [formData, setFormData] = useState({
        request_type: props.leaveEditType ? props.leaveEditType : '',
        leave_type: props.leaveEditData ? props.leaveEditData.leaveTypeId : '',
        start_date: props.leaveEditData ? new Date(props.leaveEditData.from_date) : '',
        end_date: props.leaveEditData ? new Date(props.leaveEditData.to_date) : '',
        half_day: props.leaveEditData ? props.leaveEditData.isHalfDay : false,
        desc: props.leaveEditData ? props.leaveEditData.description : '',
        total_days: props.leaveEditData ? props.leaveEditData.total : '',
        on_date: props.leaveEditData ? new Date(props.leaveEditData.on_date) : '',
        from_time: props.leaveEditData ? new Date(props.leaveEditData.from_time) : '',
        to_time: props.leaveEditData ? new Date(props.leaveEditData.to_time) : '',
        onBehalf: props.leaveEditData ? props.leaveEditData.onBehalf : {},
        leaveStatus: props.leaveStatus !== undefined ? props.leaveStatus : 'REQUESTED',
        compStatus: props.compStatus ? props.compStatus : 'REQUESTED',
        permissionStatus: props.permissionStatus ? props.permissionStatus : 'REQUESTED',
        whoFor: props.leaveEditData ? props.leaveEditData.userId : '',
        isWfh: props.leaveEditData ? props.leaveEditData.isWfh : {},
        half_day_date: (props.leaveEditData && props.leaveEditData.isHalfDay) ? ((half_date === start_half_day) ? 'start_date':'end_date'): '',
        fileName: props.leaveEditData ? props.leaveEditData.fileName : null,
        fileSize: props.leaveEditData ? props.leaveEditData.fileSize : 0,
        url: props.leaveEditData ? props.leaveEditData.url : '',
    });
    const [permissionHoursLimit, setPermissionHoursLimit] = useState(props.permissionHours ? props.permissionHours : '');
    const [monthlyLimit, setMonthlyLimit] = useState(props.permissionHours ? props.permissionHours : '')
    const [reporterId, setReporterId] = useState('');
    const [deliveryHeadId, setDeliveryHeadId] = useState([]);
    const [reportingManager, setReportingManager] = useState('');
    const [permissionCount, setPermissionCount] = useState({
        totalPermission: '',
        balancePermission: ''
    });
    const [leaveType,setLeaveType] = useState('');
    const [permissionBalance, setPermissionBalance] = useState();
    const [balanceLeaves, setBalanceLeaves] = useState(props.balanceLeaves ? props.balanceLeaves : '');
    const [totalNoOfLeaves,setTotalNoOfLeaves] = useState(0)
    const [earnedbalanceLeaves, setearnedBalanceLeaves] = useState('');
    const [earnedtotalNoOfLeaves,setearnedTotalNoOfLeaves] = useState(0)
    const [wfhleaveBalance, setWfhleaveBalance] = useState(0)
    const [endTime, setEndTime] = useState(new Date());
    const [showBalance, setShowBalance] = useState(false);
    const [halfDay, setHalfDay] = useState(false);
    const [visible, setVisible] = useState(props.visible);
    const [perm, setPerm] = useState(false);
    const [balance, setBalance] = useState(false);
    const [limit, setLimit] = useState(0);
    const initialCasualSickLeave = props.leaveEditData
    const [casualSickLeave, setCasualSickLeave] = useState(false);
    const [loading, setLoading] = useState(false);
    const [casualLeaveLimit, setCasualLeaveLimit] = useState();
    const [disable, setDisable] = useState(false);
    const [reporterGender, setReporterGender] = useState('');
    /* useState - End */

    /* useRef - Start */

    const toastRef = useRef();
    const cookies = new Cookies();
    let buId = cookies.get('buId');
	let buName = cookies.get('buName');

    /* useRef - End */

    // Constant request type json for Request type dropdown
    const requestTypes = [];
    if(props.parent === 'BeyondBalance') {
        requestTypes.push({
            name: 'Beyond Balance', value: 'Beyond Balance'
        })
    } else {
        requestTypes.push(
            { name: 'Leave', value: 'leave' },
            { name: 'Compensatory', value: 'Compensatory' },
            // { name: 'Permission', value: 'Permission' },
            { name: 'Behalf Of', value: 'behalfof' }
        )
    }

    
    // if(props.parent === 'BeyondBalance') {
    //     requestTypes.push({
    //         name: 'Beyond Balance', value: 'Beyond Balance'
    //     })
    // }

    const requestTypesUK = [
        { name: 'Leave', value: 'leave' }
    ];

    /* useEffect - Start */

    // const leave_types = watch("leaveTypes");

    useEffect(() => {
        getRequestTypes();
        getUsersListByBuId();
        // getPermissionBalance();
        getReporteesDetails();
        getLeaveTypes();
    }, [])

    useEffect(() => {
        if(formData.half_day && halfDay && props.type === 'Request') {
            let total_days;
            total_days = formData.total_days - 0.5;

            setFormData({
                ...formData,
                ['total_days']: total_days
            })
        }
    },[formData.half_day])

    useEffect(() => {
        if(props.leaveEditType && leaveTypes.length > 0) {
            var filterData = _.filter(leaveTypes, (type) => type.value === formData.leave_type && (type.name === 'Casual / Sick Leave' || type.name === 'Annual Leave' || type.name === 'Earned Leave'));

            if(filterData.length > 0) {
                setHalfDay(true);
            } else {
                setHalfDay(false);
            }

            var filteredPermissionData = _.filter(leaveTypes, (type) => type.value === formData.leave_type && type.name === 'Permission');

            if(filteredPermissionData.length > 0) {
                setPerm(true);
            } else {
                setPerm(false);
            }
        }
    }, [halfDay, leaveTypes])

    /* useEffect - End */

    /* Methods - Start */

    const getRequestTypes = () => {
        var roles = cookies.get('roles');
        //Calculating work exp on the basis of date of join +previous work exp
        var userId = cookies.get('userId')
            var filteredData = [];
            if (roles.includes('Admin') || roles.includes('Super Admin')) {
                filteredData = requestTypes
            }  else {

                    filteredData = _.filter(requestTypes, (item) => item.name !== 'WFH' && item.name != 'Behalf Of');
            }
            setRequestType(filteredData);
            leaveTypes.map(type => {
                setFormData({
                    ['leave_type']: type.value
                })
            })

    }

    //Get Permission Balance API call
    // const getPermissionBalance = () => {
    //     var currentUserId = cookies.get('userId');
    //     LeaveService.getPermissionBalance(currentUserId).then(data => {
    //         if (data.data && data.data.balancePermissions) {
    //             setPermissionBalance(data.data.balancePermissions);
    //             setPermissionCount({
    //                 totalPermission: data.data.eligiblePermissions,
    //                 balancePermission: data.data.balancePermissions
    //             })
    //         }
    //     })
    // }

    const getLeaveTypes = async (name, userId) => {
       var yearsOfExperience
        let leave_types_data = {};
        var currentUserId = userId ? userId : cookies.get('userId');
       await EmployeesService.employeeData(currentUserId).then((data)=>{
        const doj = data.data.dateOfJoin;
        const [day, month, year] = doj.split('-');
        const dateOfJoining = new Date(`${year}-${month}-${day}`);
        const currentDate = new Date();
        const timeDifference = currentDate - dateOfJoining;
        const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25; // accounting for leap years
         yearsOfExperience = timeDifference / millisecondsInYear;

        })


        LeaveService.getLeaveTypes(currentUserId).then(data => {
            let leave_types = [];

            leave_types_data = data.data;
            var filteredData = [];
            if (name === 'leave' || props.leaveEditType === 'leave') {
                filteredData = _.filter(leave_types_data, (item) => item.ConfigLeaveType?.leaveTypeName !== 'WFH');
            } else if (props.parent === 'BeyondBalance') {
                const filteredLeave = leave_types_data.filter((object) => {
                    return object.ConfigLeaveType?.leaveTypeName === 'WFH'
                })
                filteredData = filteredLeave;
            } else {
                filteredData = leave_types_data
            }
            // To form dropdown data
            for (var i = 0; i < filteredData.length; i++) {
                if (filteredData[i].ConfigLeaveType?.isActive) {
                    if(yearsOfExperience>1)
                    {
                    leave_types.push({
                        name: filteredData[i].ConfigLeaveType?.leaveTypeName.toString(),
                        value: filteredData[i].ConfigLeaveType?.id,
                        type: filteredData[i].ConfigLeaveType?.balanceType
                    });
                }
                else{
                    if(filteredData[i].ConfigLeaveType?.leaveTypeName.toString() !='Earned Leave')
                    leave_types.push({
                        name: filteredData[i].ConfigLeaveType?.leaveTypeName.toString(),
                        value: filteredData[i].ConfigLeaveType?.id,
                        type: filteredData[i].ConfigLeaveType?.balanceType
                    });

                }
                }
                if (filteredData[i].ConfigLeaveType?.leaveTypeName === 'Permission') {
                    setPermissionHoursLimit(filteredData[i].ConfigLeaveType?.hoursPerDayLimit)
                }
                if (filteredData[i].ConfigLeaveType?.leaveTypeName === 'WFH') {
                    setMonthlyLimit(filteredData[i].ConfigLeaveType?.hoursPerDayLimit)
                }
            }
            setLeaveTypes(leave_types);
        });
    }

    // To get the users list - API
    const getUsersListByBuId = () => {
        var buId = cookies.get('buId');
        LeaveService.getUsersListByBuId(buId).then(data => {
            let userList = [];
            data.data.forEach(user => {
                userList.push({
                    name: user.firstName + ' ' + user.lastName,
                    value: user.id
                })
            });
            setUserList(userList);
        });
    }

    const getReporteesDetails = () => {
        const reportingUserId = cookies.get('userId');
        LeaveService.getReporteesDetails(reportingUserId, true).then(data => {
            let userList = [];
            //Calculating work exp on the basis of date of join +previous work exp
            for (var i = 0; i < data.data.length; i++) {
                var previousWorkExp= data.data[i].previousWorkExp
                var dateOfJoin = data.data[i].dateOfJoin
                var date = dateOfJoin.substring(0,2)
                var month = dateOfJoin.substring(3,5)
                var year = dateOfJoin.substring(6,10)
                var JoiningDate =moment(new Date(year, month-1, date, 0, 0));
                var currentDate = moment(new Date())
                var diffInDays = currentDate.diff(JoiningDate,'days')
                let jmanWorkExp = diffInDays/365

                var workExperience = jmanWorkExp +previousWorkExp
                if (workExperience >= 1) {
                    userList.push({
                        name: data.data[i].firstName + ' ' + data.data[i].lastName,
                        value: data.data[i].id
                    })
                }
            }
            setReporteesList(userList);

        });
    }

    // Get and set the request type
    const onRequestTypeChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setFormData({
            ...formData,
            [name]: value,
            ['start_date']: '',
            ['end_date']: '',
            ['desc']: '',
            ['leave_type']: '',
            ['half_day']: false,
            ['total_days']: ''
        })
        setHalfDay(false);
        setPermissionBalance('');
        setBalanceLeaves({
            balanceLeaves: '',
            advanceLeaves: '',
            previousMonthBalance: ''
        });
        getLeaveTypes(value);
        setShowBalance(false);
        setPerm(false);
    }

    const getLeaveBalanceByTypeAndUser = (leaveTypeId) => {
        var currentUserId = (formData.wfh || formData.behalfOf) ? (formData.wfh || formData.behalfOf) : cookies.get('userId');
        LeaveService.getLeaveBalanceByTypeAndUser(leaveTypeId, currentUserId).then(data => {
            var advanceBal = '';
            if (data.data.userStatus.UserStatus.leaveLimit === 0 || data.data.userStatus.UserStatus.leaveLimit > 1) {
                advanceBal = data.data.advanceBalance;
            }

            if(formData.request_type === 'Beyond Balance' && data.data.balanceLeaves > 0 && props.type === 'Request') {
                setDisable(true);
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Error',
                    message: 'This user already has balance for applying '+data.data.ConfigLeaveType.leaveTypeName+'. Please apply in Behalf Of Request.'
                })
            } else {
                setDisable(false);
            }

            setBalanceLeaves({
                balanceLeaves: data.data.balanceLeaves,
                advanceLeaves: advanceBal,
                balanceNextYear: data.data.balanceNextYear,
                leaveTypeName: data.data.ConfigLeaveType.leaveTypeName,
                userStatus: data.data.userStatus.UserStatus.status,
                previousMonthBalance: data.data.previousMonthBalance
            });
            setTotalNoOfLeaves(data.data.ConfigLeaveType.noOfDays)
        })
    }

    const getearnedleavebalance = (leaveTypeId) => {
        var currentUserId = (formData.wfh || formData.behalfOf) ? (formData.wfh || formData.behalfOf) : cookies.get('userId');
        LeaveService.getLeaveBalanceByTypeAndUser(leaveTypeId, currentUserId).then(data => {
            var advanceBal = '';
            if (data.data.userStatus.UserStatus.leaveLimit === 0 || data.data.userStatus.UserStatus.leaveLimit > 1) {
                advanceBal = data.data.advanceBalance;
            }
            setearnedBalanceLeaves({
                balanceLeaves: data.data.balanceLeaves,
                advanceLeaves: advanceBal,
                balanceNextYear: data.data.balanceNextYear,
                leaveTypeName: data.data.ConfigLeaveType.leaveTypeName,
                userStatus: data.data.userStatus.UserStatus.status,
                previousMonthBalance: data.data.previousMonthBalance
            });
            setearnedTotalNoOfLeaves(data.data.ConfigLeaveType.noOfDays)
        })
    }

    // const monthlyLeaveBalance = (leaveTypeId) => {
    //     var currentUserId = (formData.wfh || formData.behalfOf) ? (formData.wfh || formData.behalfOf) : cookies.get('userId');
    //     //Calculating current month wfh
    //     const getCurrentMonth = new Date().getMonth(); //have to add one more
    //     const currentMonth = getCurrentMonth + 1
    //     var currentMonthWfhTaken = 0;
    //     LeaveService.getAllLeaveRequestByUser(currentUserId).then(data => {
    //         for (var i = 0; i < data.data.length; i++) {
    //             if (data.data[i].ConfigLeaveType.leaveTypeName == 'WFH' && data.data[i].leaveStatus == 'APPROVED' && currentMonth == data.data[i].from_date.substring(5, 7)) {
    //                 currentMonthWfhTaken = currentMonthWfhTaken + data.data[i].total
    //             }
    //         }
    //         var currentWfh = monthlyLimit - currentMonthWfhTaken
    //         if(currentWfh>0)
    //         {
    //         setWfhleaveBalance(currentWfh)
    //         }
    //         else
    //         {
    //             setWfhleaveBalance(0)
    //         }
    //     })
    // }


    // Get and set the leave type
    const onLeaveTypeChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setFormData({
            ...formData,
            [name]: value,
            ['start_date']: '',
            ['end_date']: '',
            ['desc']: '',
            ['half_day']: false,
            ['total_days']: ''
        });
        setBalance(false);
        var filteredData = _.filter(leaveTypes, (type) => type.value === value && type.type === 'Unlimited');
        var filteredHalfDay = _.filter(leaveTypes, (type) => type.value === value && (type.name === 'Casual / Sick Leave' || type.name === 'Annual Leave' || type.name === 'Earned Leave'));
        var earnedLeave = _.filter(leaveTypes, (type) => (type.name === 'Earned Leave'));
        var filteredPermissionData = _.filter(leaveTypes, (type) => type.value === value && type.name === 'Permission');
        if(filteredHalfDay.length>0 && filteredHalfDay[0]?.name==='Casual / Sick Leave') {
            setCasualSickLeave(true);
        }
        else{
            setCasualSickLeave(false);
        }
        if (filteredData.length > 0) {
            setShowBalance(false);
        } else {
            setShowBalance(true);
        }

        if(filteredHalfDay.length > 0) {
            setHalfDay(true);
            leaveTypes.forEach((type) => {
                if (type.name === 'Casual / Sick Leave') {
                    // setCasualSickLeave(true);
                    LeaveService.getLeaveTypeByLeaveTypeId(type.value).then((data)=>{
                        setCasualLeaveLimit(data.data.hoursPerDayLimit)
                    })
                } 
                else if(type.name === 'Earned Leave'){
                    // setCasualSickLeave(false);
                    getearnedleavebalance(earnedLeave[0]?.value)
                }
            });
        } else {
            setHalfDay(false);
        }

        if(filteredPermissionData.length > 0) {
            setPerm(true);
        } else {
            setPerm(false);
        }
        
        getLeaveBalanceByTypeAndUser(value);
        // monthlyLeaveBalance(value)
        getLeaveTypeName(value)
    }

    useEffect(()=>{
        if(props?.leaveEditData) {
            getLeaveTypeName(props.leaveEditData?.leaveTypeId)
            LeaveService.getLeaveTypeByLeaveTypeId(props.leaveEditData?.leaveTypeId).then((data)=>{
                setCasualLeaveLimit(data.data.hoursPerDayLimit)
            })
        }
    },[leaveType])

    const getLeaveTypeName = (value) =>{
        // LeaveService.
        // setLeaveType
        LeaveService.getLeaveTypeByLeaveTypeId(value).then(data=>{
            setLeaveType(data.data.leaveTypeName )
            setCasualSickLeave(data.data.leaveTypeName==='Casual / Sick Leave')
        })
    }

    // Get and set the user
    const onUserChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        let userId = value;
        var typeName = name === 'wfh' ? 'WFH' : name
        getLeaveTypes(typeName);

        // name !== 'wfh' && LeaveService.getPermissionBalance(userId).then(data => {
        //     setPermissionBalance(data.data.balancePermissions);
        //     setPermissionCount({
        //         totalPermission: data.data.eligiblePermissions,
        //         balancePermission: data.data.balancePermissions
        //     })
        // })

        LeaveService.getDeliveryUserDetails(buId).then(data => {
            var reporterIds = [];
            data.data.forEach(user => {
                reporterIds.push(user.id);
            })
            setDeliveryHeadId(reporterIds);
        })

        LeaveService.getReporterDetails(value).then(data => {
            setReporterId(data.data.id);
            setReportingManager(data.data.firstName + ' ' + data.data.lastName)
            setReporterGender(data.data.gender)
        })

        setFormData({
            ...formData,
            [name]: value,
            ['leave_type']: ''
        });
        setBalanceLeaves({
            balanceLeaves: '',
            advanceLeaves: '',
            previousMonthBalance: ''
        });
        setShowBalance(false);
    }

    const onChangeStartHalf = (e) => {
        setFormData({
            ...formData,
            ['half_day_date']: 'start_date',
            ['half_day']: true
        })
    }

    const onChangeEndHalf = (e) => {
        setFormData({
            ...formData,
            ['half_day_date']: 'end_date',
            ['half_day']: true
        })
    }

    // Get and set the start date calcualting total days requested
    const onChangeStartDate = (e) => {
        let total_days = calculateleaveDays(e.target.value, formData.end_date, formData.half_day);
        // formData['end_date'] = ' ';
        formData['start_date'] = e.target.value;
        formData['total_days'] = total_days;
        if(formData['half_day'] === true){
            // formData['half_day_date'] = 'start_date';//e.target.value;
        }

        var current_year = new Date().getFullYear();
        var year = moment(e.target.value, "YYYY-MM-DD").year();

        if(current_year != year) {
            setBalance(true);
        } else {
            setBalance(false);
        }

        setFormData({
            ...formData,
            ['start_date']: e.target.value,

        })
        setEndTime(e.target.value);
    }

    // Get and set the end date and calcualting total days requested
    const onChangeEndDate = (e) => {
        let total_days = calculateleaveDays(formData.start_date, e.target.value, formData.half_day);

        formData['end_date'] = e.target.value ? e.target.value : endTime;
        formData['total_days'] = total_days;
        if(formData['half_day'] === true){
            // formData['half_day_date'] = e.target.value ? 'end_date' : 'start_date';
        }

        setFormData({
            ...formData,
            ['end_date']: e.target.value,
        })
    }

    const onChangeTime = (e, name) => {
        if (name === 'start') {
            if(e._d){
                setFormData({
                    ...formData,
                    ['from_time']: e._d,
                })
            }
            formData['from_time'] = e._d;
            let total_hours = calculatePermissionHours(e._d, formData.to_time);
            formData['total_days'] = total_hours;
        } else {
            formData['to_time'] = e._d;
            let total_hours = calculatePermissionHours(formData.from_time, e._d);
            formData['total_days'] = total_hours;
        }
    }

    // Checking the state of half a day toggle
    const onHalfDayChange = (e, inputId) => {
        let total_days;
        if (e.target.checked) {
            total_days = formData.total_days - 0.5;
        } else {
            total_days = formData.total_days + 0.5;
        }

        setFormData({
            ...formData,
            ['half_day']: e.target.checked,
            ['total_days']: total_days
        })
    }
    useEffect(()=>{
        LeaveService.getLeaveTypeByLeaveTypeId(formData.leave_type).then((data)=>{
            setLimit(data.data.hoursPerDayLimit)
        })

    },[leaveType])

    const myUploader = (e) => {
        // var index = index.rowIndex;
        var form_details = formData;
        setLoading(true);
        LeaveService.uploadFile(e.files, 'leave').then(data=>{
            setLoading(false);
            if (data.posted.success) {
                let file_data = data.posted; 
                setFormData({
                    ...formData,
                    fileName: file_data.file,
                    fileSize: file_data.size,
                    url: file_data.url
                });
                toastRef.current.showToast({
                    type:'success',
                    summary:'File uploaded successfully',
                    message: `File uploaded successfully`
                })
            } else {
                toastRef.current.showToast({
                    type:'error',
                    summary: data.posted.message,
                    message: data.posted.message
                })
            }
        });
    }


    const CasualLeaveAttachment = () => {
        const chooseOptions = { iconOnly: true, icon: 'pi pi-fw pi-plus', className:'medicalfileupload'};
        // const uploadOptions = { iconOnly: true, icon: 'pi pi-upload', className: 'p-button-success' };
        const cancelOptions = { iconOnly: true, icon: 'pi pi-times', className: 'file-upload-cancel' };
        return (
            <div id="leave-file-upload" className='d-flex file-upload-container'>
                {formData?.fileName == null ? (
                    <>
                        <p className="font-normal fs-13 text-lightgray mt-0 mb-8">Upload Medical Document <span className='text-red'>*</span></p>
                        <FileUploadElement
                            name="file"
                            chooseOptions={chooseOptions}
                            // uploadOptions={uploadOptions}
                            cancelOptions={cancelOptions}
                            OnUpload={(e) => myUploader(e)}
                            customUpload
                            // style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}
                            accept=".jpg,.jpeg,.pdf,.png"
                        />
                    </>
                ) : props.leaveEditData?.fileName == null ? (
                    <>
                        <p className="font-normal fs-13 text-lightgray mt-0 mb-8">Medical Document <span className='text-red'>*</span></p>
                        <a href={formData.url} target="_blank" className='leave-attachment-upload'> {formData.fileName}</a>
                    </>
                ) : null}
            </div>

        )
    }

    const calculateleaveDays = (startDate, endDate, isHalfDay) => {
        endDate = (endDate == '') ? startDate : endDate;
        let leaveData = {
            from_date: Moment(startDate).format('YYYY-MM-DD'),
            to_date: Moment(endDate).format('YYYY-MM-DD'),
            buId: cookies.get('buId'),
            leaveTypeId: formData.leave_type
        }
        if (startDate !== '' && endDate !== '' && startDate !== undefined && endDate !== undefined) {
            if (formData.request_type == "leave" || formData.request_type == 'behalfof' || formData.request_type == 'WFH' || formData.request_type == 'Beyond Balance') {
                if(leaveData.from_date <= leaveData.to_date) {
                    LeaveService.getTotalLeave(leaveData).then(data => {
                        let total_days;
                        if (data.posted.success) {
                            if (formData.half_day) {
                                total_days = data.posted.total_days - 0.5;
                            } else {
                                total_days = data.posted.total_days;
                            }
                        }
                        return (
                            setFormData({
                                ...formData,
                                ['total_days']: total_days
                            })
                        )
                    });
                } else {
                    toastRef.current.showToast({
                        type: 'error',
                        summary: 'Error',
                        message: 'End date should be greater than start date'
                    })
                }
            }
            else if (formData.request_type == "Compensatory") {
                var from_date = moment(startDate);
                var end_date = moment(endDate);
                let total_days;
                if (formData.half_day) {
                    var diff_days = end_date.diff(from_date, 'days') + 1;
                    total_days = diff_days - 0.5;
                } else {
                    total_days = end_date.diff(from_date, 'days') + 1;
                }
                return total_days;
                // (
                //     setFormData({
                //         ...formData,
                //         ['end_date']: endDate,
                //         ['total_days']: total_days
                //     })
                // )
            }
        }
    }

    const calculatePermissionHours = (fromTime, toTime) => {
        var currentBuId = cookies.get('buId');
        // LeaveService.leaveConfiguration(currentBuId).then(data => {
        //     setPermissionHoursLimit(data.data[0].leavedata.permissionHoursLimit);
        // });
        toTime = (toTime == '')? fromTime:toTime;
        if (fromTime !== '' && toTime !== '' && fromTime !== undefined && toTime !== undefined) {
            var on_date = moment(fromTime).format('YYYY-MM-DD');
            var now = moment(toTime);
            var then = moment(fromTime);
            var ms = moment(now, 'HH:mm').diff(moment(then, 'HH:mm'));
            var d = moment.duration(ms);
            var total = Math.floor(d.asHours()) + moment.utc(ms).format(':mm');
            return (
                setFormData({
                    ...formData,
                    ['on_date']: on_date,
                    ['to_time']: toTime,
                    ['from_time']: fromTime,
                    ['total_days']: total
                })
            );
        }
    }

    const leaveRequest = () => {


        // Check Validations here. Toast.
        const valid = leaveValidation();
        if (valid) {
            event.stopPropagation();
            if (formData.request_type == 'Beyond Balance') {
                var data = {
                    buId: cookies.get("buId"),
                    description: formData.desc,
                    from_date: Moment(formData.start_date).format('YYYY-MM-DD'),
                    to_date: Moment(formData.end_date).format('YYYY-MM-DD'),
                    leaveTypeId: formData.leave_type,
                    wfh_reportingUserIds: [reporterId],
                    reportingUserId: reporterId,
                    total: formData.total_days,
                    userId: formData.behalfOf,
                    isHalfDay: formData.half_day,
                    leaveStatus: formData.leaveStatus?formData.leaveStatus:'REQUESTED',
                    onBehalf: {
                        isBeyondBalance: true,
                        behalfOfUserId:  formData.behalfOf
                    }
                }

                LeaveService.leaveRequest(data).then((data) => {
                    let message = {
                        type: data.posted.success ? 'success' : 'error',
                        summary: data.posted.success ? 'Success' : 'Something went wrong',
                        message: data.posted.message
                    }
                    let success = data.posted.success ? true : false
                    if (data.posted.success) {
                        setVisible(false);
                        props.onHide(message, success)
                    } else {
                        toastRef.current.showToast({
                            type: 'error',
                            summary: 'Error',
                            message: data.posted.message
                        })
                    }
                })
            } else if(leaveType == 'WFH'){
                var data = {
                    buId: cookies.get("buId"),
                    description: formData.desc,
                    from_date: Moment(formData.start_date).format('YYYY-MM-DD'),
                    to_date: Moment(formData.end_date).format('YYYY-MM-DD'),
                    leaveTypeId: formData.leave_type,
                    wfh_reportingUserIds: [reporterId],
                    reportingUserId: reporterId,
                    total: formData.total_days,
                    userId: formData.behalfOf,
                    isHalfDay: formData.half_day,
                    leaveStatus: 'REQUESTED',
                    onBehalf: {
                        isBehalfRequest: true,
                        behalfOfUserId: cookies.get("userId"),
                        behalfOfUserName: cookies.get("userName")
                    },
                    isWfh: {
                        isWfh: true,
                        wfhOfUserId: formData.behalfOf
                    }
                }
                LeaveService.leaveRequest(data).then((data) => {
                    let message = {
                        type: data.posted.success ? 'success' : 'error',
                        summary: data.posted.success ? 'Success' : 'Something went wrong',
                        message: data.posted.message
                    }
                    let success = data.posted.success ? true : false
                    if (data.posted.success) {
                        setVisible(false);
                        props.onHide(message, success)
                    } else {
                        toastRef.current.showToast({
                            type: 'error',
                            summary: 'Error',
                            message: data.posted.message
                        })
                    }
                })
            }
            else if (formData.request_type == 'leave' && !perm) {

                if(leaveType === 'Casual / Sick Leave' && formData.total_days > limit)
                {
                    if((formData.url === '' || formData.url === undefined) && formData.total_days>casualLeaveLimit){
                        if(earnedbalanceLeaves.balanceLeaves>=1) {
                            toastRef.current.showToast({
                                type: 'error',
                                summary: 'Error',
                                message: `Document is mandatory, If it is not provided apply request in Earned Leave`
                            })
                        }
                        else{
                            toastRef.current.showToast({
                                type: 'error',
                                summary: 'Error',
                                message: `Please reach out to people team`
                            })
                        }
                    }else {
                        var data = {
                            buId: cookies.get("buId"),
                            description: formData.desc,
                            from_date: Moment(formData.start_date).format('YYYY-MM-DD'),
                            to_date: Moment(formData.end_date).format('YYYY-MM-DD'),
                            leaveTypeId: formData.leave_type,
                            reportingUserId: props.reporterId,
                            total: formData.total_days,
                            userId: cookies.get("userId"),
                            isHalfDay: formData.half_day,
                            leaveStatus: formData.leaveStatus,
                            onBehalf: {
                                isBehalfRequest: false
                            },
                            half_day_date: (formData.half_day === true )?Moment(formData[formData.half_day_date]).format('YYYY-MM-DD'): "",
                            fileName: formData.fileName,
                            fileSize: formData.fileSize,
                            url: formData.url
                        }
                        var leaveTypeName = '';
                        leaveTypes.map(type => {
                            if (type.value === data.leaveTypeId) {
                                leaveTypeName = type.name;
                            }
                        })
                        LeaveService.leaveRequest(data).then((data) => {
                            let message = {
                                type: data.posted.success ? 'success' : 'error',
                                summary: data.posted.success ? 'Success' : 'Something went wrong',
                                message: data.posted.message
                            }
                            let success = data.posted.success ? true : false
                            if (data.posted.success) {
                                setVisible(false);
                                props.onHide(message, success)
                            } else {
                                toastRef.current.showToast({
                                    type: 'error',
                                    summary: 'Error',
                                    message: data.posted.message
                                })
                            }
                        })
                    }
                } else if (leaveType === 'Family Time Off' && formData.total_days < balanceLeaves.balanceLeaves) {
                    toastRef.current.showToast({
                        type: 'error',
                        summary: 'Error',
                        message: `You Cannot Apply less than ${balanceLeaves.balanceLeaves} Days Of Leave`
                    })
                }
                else{
                var data = {
                    buId: cookies.get("buId"),
                    description: formData.desc,
                    from_date: Moment(formData.start_date).format('YYYY-MM-DD'),
                    to_date: Moment(formData.end_date).format('YYYY-MM-DD'),
                    leaveTypeId: formData.leave_type,
                    reportingUserId: props.reporterId,
                    total: formData.total_days,
                    userId: cookies.get("userId"),
                    isHalfDay: formData.half_day,
                    leaveStatus: formData.leaveStatus,
                    onBehalf: {
                        isBehalfRequest: false
                    },
                    half_day_date: (formData.half_day === true )?Moment(formData[formData.half_day_date]).format('YYYY-MM-DD'): "",
                    fileName: formData.fileName,
                    fileSize: formData.fileSize,
                    url: formData.url
                }
                var leaveTypeName = '';
                leaveTypes.map(type => {
                    if (type.value === data.leaveTypeId) {
                        leaveTypeName = type.name;
                    }
                })
                LeaveService.leaveRequest(data).then((data) => {
                    let message = {
                        type: data.posted.success ? 'success' : 'error',
                        summary: data.posted.success ? 'Success' : 'Something went wrong',
                        message: data.posted.message
                    }
                    let success = data.posted.success ? true : false
                    if (data.posted.success) {
                        setVisible(false);
                        props.onHide(message, success)
                    } else {
                        toastRef.current.showToast({
                            type: 'error',
                            summary: 'Error',
                            message: data.posted.message
                        })
                    }
                })
            }
            } else if (formData.request_type == 'Compensatory') {
                var leaveTypeId;
                leaveTypes.map(type => {
                    if (type.name === 'Compensated Time Off') {
                        leaveTypeId = type.value;
                    }
                })
                var data = {
                    buId: cookies.get("buId"),
                    description: formData.desc,
                    from_date: Moment(formData.start_date).format('YYYY-MM-DD'),
                    to_date: Moment(formData.end_date).format('YYYY-MM-DD'),
                    leaveTypeId: leaveTypeId,
                    reportingUserId: props.reporterId,
                    total: formData.total_days,
                    userId: cookies.get("userId"),
                    isHalfDay: formData.half_day,
                    compStatus: formData.compStatus,
                    expiryDate: Moment(formData.start_date).add(30, 'days').format('YYYY-MM-DD'),
                }
                LeaveService.compOffRequest(data).then((data) => {
                    let message = {
                        type: data.posted.success ? 'success' : 'error',
                        summary: data.posted.success ? 'Success' : 'Something went wrong',
                        message: data.posted.message
                    }
                    let success = data.posted.success ? true : false
                    if (data.posted.success) {
                        setVisible(false);
                        props.onHide(message, success)
                    } else {
                        toastRef.current.showToast({
                            type: 'error',
                            summary: 'Error',
                            message: data.posted.message
                        })
                    }
                })
            } else if (formData.request_type == 'leave' && perm) {
                var data = {
                    buId: cookies.get("buId"),
                    description: formData.desc,
                    on_date: Moment(formData.on_date).format('YYYY-MM-DD'),
                    from_time: formData.from_time,
                    to_time: formData.to_time,
                    email_from_time: Moment(formData.from_time).format('YYYY-MM-DD HH:mm:ss'),
                    email_to_time: Moment(formData.to_time).format('YYYY-MM-DD HH:mm:ss'),
                    reportingUserId: props.reporterId,
                    total: formData.total_days,
                    userId: cookies.get("userId"),
                    permissionStatus: formData.permissionStatus,
                    leaveTypeId: formData.leave_type,
                }
                var givenTime = moment.duration(data.total).asSeconds();
                var startTime = moment.duration('00:00:00').asSeconds();
                var endTime = moment.duration('0' + permissionHoursLimit + ':00:00').asSeconds();

                if (givenTime <= startTime || givenTime > endTime) {
                    toastRef.current.showToast({
                        type: 'error',
                        summary: 'Limit',
                        message: 'Permission Time Is Invalid'
                    })
                } else {
                    LeaveService.permissionRequest(data).then((data) => {
                        let message = {
                            type: data.posted.success ? 'success' : 'error',
                            summary: data.posted.success ? 'Success' : 'Something went wrong',
                            message: data.posted.message
                        }
                        let success = data.posted.success ? true : false
                        if (data.posted.success) {
                            setVisible(false);
                            props.onHide(message, success)
                        } else {
                            toastRef?.current?.showToast({
                                type: 'error',
                                summary: 'Error',
                                message: data.posted.message
                            })
                        }
                    })
                }
            } else if (formData.request_type == 'behalfof') {
                var data = {
                    buId: cookies.get("buId"),
                    description: formData.desc,
                    from_date: Moment(formData.start_date).format('YYYY-MM-DD'),
                    to_date: Moment(formData.end_date).format('YYYY-MM-DD'),
                    leaveTypeId: formData.leave_type,
                    reportingUserId: reporterId,
                    total: formData.total_days,
                    userId: formData.behalfOf,
                    isHalfDay: formData.half_day,
                    leaveStatus: (formData.leaveStatus ? formData.leaveStatus : 'REQUESTED'),
                    onBehalf: {
                        isBehalfRequest: true,
                        behalfOfUserId: formData.behalfOf,
                        behalfOfUserName: cookies.get("userName")
                    }
                }
                LeaveService.leaveRequest(data).then((data) => {
                    let message = {
                        type: data.posted.success ? 'success' : 'error',
                        summary: data.posted.success ? 'Success' : 'Something went wrong',
                        message: data.posted.message
                    }
                    let success = data.posted.success ? true : false
                    if (data.posted.success) {
                        setVisible(false);
                        props.onHide(message, success)
                    } else {
                        toastRef.current.showToast({
                            type: 'error',
                            summary: 'Error',
                            message: data.posted.message
                        })
                    }
                })
            }
        } else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Fill mandatory fields',
                message: 'Please fill all the mandatory fields'

            })
        }
    };

    const editLeave = (e) => {
        // Check Validations here. Toast.
        const valid = leaveValidation()
        if (valid) {
            if (props.leaveEditType === "leave" && !perm) {
                if(leaveType === 'Casual / Sick Leave' && formData.total_days > limit)
                {
                    if((formData.url === '' || formData.url === undefined) && formData.total_days>casualLeaveLimit){
                        if(earnedbalanceLeaves.balanceLeaves>=1) {
                            toastRef.current.showToast({
                                type: 'error',
                                summary: 'Error',
                                message: `You can request leave in earned leave`
                            })
                        }
                        else{
                            toastRef.current.showToast({
                                type: 'error',
                                summary: 'Error',
                                message: `Please reach out to people team`
                            })
                        }
                    }
                    else {
                        var data = props.leaveEditData
                        data.from_date = Moment(formData.start_date).format('YYYY-MM-DD')
                        data.to_date = Moment(formData.end_date).format('YYYY-MM-DD')
                        data.isHalfDay = formData.half_day
                        data.description = formData.desc
                        data.total = formData.total_days
                        data.half_day_date = (formData.half_day === true ) ? Moment(formData[formData.half_day_date]).format('YYYY-MM-DD') : "";
                        data.fileName = formData.fileName,
                        data.fileSize = formData.fileSize,
                        data.url = formData.url
                        LeaveService.updateLeave(data.id, data).then(data => {
                            let message = {
                                type: data.patched.success ? 'success' : 'error',
                                summary: data.patched.success ? 'Success' : 'Something went wrong',
                                message: data.patched.message
                            }
                            let success = data.patched.success ? true : false
                            if (data.patched.success) {
                                setVisible(false);
                                props.onHide(message, success)
                            } else {
                                toastRef.current.showToast({
                                    type: 'error',
                                    summary: 'Error',
                                    message: data.patched.message
                                })
                            }
                        })
                    }
                } else if (leaveType === 'Family Time Off' && formData.total_days < balanceLeaves.balanceLeaves) {
                    toastRef.current.showToast({
                        type: 'error',
                        summary: 'Error',
                        message: `You Cannot Apply less than ${balanceLeaves.balanceLeaves} Days Of Leave`
                    })
                }
                else {
                    var data = props.leaveEditData
                    data.from_date = Moment(formData.start_date).format('YYYY-MM-DD')
                    data.to_date = Moment(formData.end_date).format('YYYY-MM-DD')
                    data.isHalfDay = formData.half_day
                    data.description = formData.desc
                    data.total = formData.total_days
                    data.half_day_date = (formData.half_day === true ) ? Moment(formData[formData.half_day_date]).format('YYYY-MM-DD') : "";
                    data.fileName = formData.fileName,
                    data.fileSize = formData.fileSize,
                    data.url = formData.url
                    LeaveService.updateLeave(data.id, data).then(data => {
                        let message = {
                            type: data.patched.success ? 'success' : 'error',
                            summary: data.patched.success ? 'Success' : 'Something went wrong',
                            message: data.patched.message
                        }
                        let success = data.patched.success ? true : false
                        if (data.patched.success) {
                            setVisible(false);
                            props.onHide(message, success)
                        } else {
                            toastRef.current.showToast({
                                type: 'error',
                                summary: 'Error',
                                message: data.patched.message
                            })
                        }
                    })
                }
            } else if (props.leaveEditType === "Compensatory") {
                var data = props.leaveEditData
                data.from_date = Moment(formData.start_date).format('YYYY-MM-DD')
                data.to_date = Moment(formData.end_date).format('YYYY-MM-DD')
                data.isHalfDay = formData.half_day
                data.description = formData.desc
                data.total = formData.total_days
                data.expiryDate = Moment(formData.start_date).add(30, 'days').format('YYYY-MM-DD')
                LeaveService.updateCompOff(data.id, data).then(data => {
                    let message = {
                        type: data.patched.success ? 'success' : 'error',
                        summary: data.patched.success ? 'Success' : 'Something went wrong',
                        message: data.patched.message
                    }
                    let success = data.patched.success ? true : false
                    if (data.patched.success) {
                        setVisible(false);
                        props.onHide(message, success)
                    } else {
                        toastRef.current.showToast({
                            type: 'error',
                            summary: 'Error',
                            message: data.patched.message
                        })
                    }
                })
            } else if (props.leaveEditType === "leave" && perm) {
                var data = props.leaveEditData
                data.on_date = Moment(formData.on_date).format('YYYY-MM-DD')
                data.from_time = formData.from_time
                data.to_time = formData.to_time
                data.description = formData.desc
                data.total = formData.total_days
                data.buId = cookies.get('buId')
                var givenTime = moment.duration(data.total).asSeconds();
                var startTime = moment.duration('00:00:00').asSeconds();
                var endTime = moment.duration('0' + permissionHoursLimit + ':00:00').asSeconds();

                if (givenTime <= startTime || givenTime > endTime) {
                    toastRef.current.showToast({
                        type: 'error',
                        summary: 'Limit',
                        message: 'Permission Time Is Invalid'
                    })
                } else {
                    LeaveService.updatePermission(data.id, data).then(data => {
                        let message = {
                            type: data.patched.success ? 'success' : 'error',
                            summary: data.patched.success ? 'Success' : 'Something went wrong',
                            message: data.patched.message
                        }
                        let success = data.patched.success ? true : false
                        if (data.patched.success) {
                            setVisible(false);
                            props.onHide(message, success)
                        } else {
                            toastRef.current.showToast({
                                type: 'error',
                                summary: 'Error',
                                message: data.patched.message
                            })
                        }
                    })
                }
            } else if (props.leaveEditType === "behalfof") {
                var data = props.leaveEditData
                data.from_date = Moment(formData.start_date).format('YYYY-MM-DD')
                data.to_date = Moment(formData.end_date).format('YYYY-MM-DD')
                data.isHalfDay = formData.half_day
                data.description = formData.desc
                data.total = formData.total_days
                data.onBehalf = formData.behalfOf
                LeaveService.updateLeave(data.id, data).then(data => {
                    let message = {
                        type: data.patched.success ? 'success' : 'error',
                        summary: data.patched.success ? 'Success' : 'Something went wrong',
                        message: data.patched.message
                    }
                    let success = data.patched.success ? true : false
                    if (data.patched.success) {
                        setVisible(false);
                        props.onHide(message, success)
                    } else {
                        toastRef.current.showToast({
                            type: 'error',
                            summary: 'Error',
                            message: data.patched.message
                        })
                    }
                })
            } else if (formData.request_type == 'Beyond Balance') {

                var data = props.leaveEditData
                data.from_date = Moment(formData.start_date).format('YYYY-MM-DD')
                data.to_date = Moment(formData.end_date).format('YYYY-MM-DD')
                data.isHalfDay = formData.half_day
                data.description = formData.desc
                data.total = formData.total_days
                data.onBehalf = formData.behalfOf

                LeaveService.updateLeave(data.id, data).then((data) => {
                    let message = {
                        type: data.patched.success ? 'success' : 'error',
                        summary: data.patched.success ? 'Success' : 'Something went wrong',
                        message: data.patched.message
                    }
                    let success = data.patched.success ? true : false
                    if (data.patched.success) {
                        setVisible(false);
                        props.onHide(message, success)
                    } else {
                        toastRef.current.showToast({
                            type: 'error',
                            summary: 'Error',
                            message: data.patched.message
                        })
                    }
                })
        } else if (props.leaveEditType === 'WFH') {
                var data = props.leaveEditData;
                data.from_date = Moment(formData.start_date).format('YYYY-MM-DD')
                data.to_date = Moment(formData.end_date).format('YYYY-MM-DD')
                data.isHalfDay = formData.half_day
                data.description = formData.desc
                data.total = formData.total_days
                data.isWfh = formData.wfh
                LeaveService.updateLeave(data.id, data).then(data => {
                    let message = {
                        type: data.patched.success ? 'success' : 'error',
                        summary: data.patched.success ? 'Success' : 'Something went wrong',
                        message: data.patched.message
                    }
                    let success = data.patched.success ? true : false
                    if (data.patched.success) {
                        setVisible(false);
                        props.onHide(message, success)
                    } else {
                        toastRef.current.showToast({
                            type: 'error',
                            summary: 'Error',
                            message: data.patched.message
                        })
                    }
                })
            }
        } else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Fill mandatory fields',
                message: 'Please fill all the mandatory fields'
            })
        }
    };

    const leaveValidation = () => {
        let validCheck = {
            leaveType: false,
            requestType: false,
            startDate: false,
            endDate: false,
            onBehalf: false,
            startTime: false,
            endTime: false,
            description: false,
            isWfh: false,
            // url: false
        };
        if (formData.request_type === 'Compensatory') {
            validCheck.isWfh = true
            validCheck.onBehalf = true
            validCheck.leaveType = true
            validCheck.startTime = true
            validCheck.endTime = true
            formData.request_type ? validCheck.requestType = true : validCheck.requestType = false
            formData.start_date ? validCheck.startDate = true : validCheck.startDate = false
            formData.end_date ? validCheck.endDate = true : validCheck.endDate = false
            formData.desc.trim() ? validCheck.description = true : validCheck.description = false
        }
        if ((formData.request_type === 'leave' || formData.request_type === 'Beyond Balance') && !perm) {
            const isCasualOrSickLeave = leaveTypes.some(type => type.name === 'Casual / Sick Leave');

            // if (isCasualOrSickLeave && formData.total_days > casualLeaveLimit) {
            //     validCheck.url = !!formData.url;
            // }
            
            validCheck.isWfh = true
            validCheck.onBehalf = true
            validCheck.startTime = true
            validCheck.endTime = true
            formData.leave_type ? validCheck.leaveType = true : validCheck.leaveType = false
            formData.request_type ? validCheck.requestType = true : validCheck.requestType = false
            formData.start_date ? validCheck.startDate = true : validCheck.startDate = false
            formData.end_date ? validCheck.endDate = true : validCheck.endDate = false
            formData.desc.trim() ? validCheck.description = true : validCheck.description = false
        }
        if (leaveType === 'WFH') {
            validCheck.onBehalf = true
            validCheck.startTime = true
            validCheck.endTime = true
            validCheck.isWfh = true
            formData.leave_type ? validCheck.leaveType = true : validCheck.leaveType = false
            formData.request_type ? validCheck.requestType = true : validCheck.requestType = false
            formData.start_date ? validCheck.startDate = true : validCheck.startDate = false
            formData.end_date ? validCheck.endDate = true : validCheck.endDate = false
            formData.desc.trim() ? validCheck.description = true : validCheck.description = false
        }
        else if (formData.request_type === 'behalfof') {
            validCheck.isWfh = true
            validCheck.startTime = true
            validCheck.endTime = true
            formData.behalfOf ? validCheck.onBehalf = true : validCheck.onBehalf = false
            formData.leave_type ? validCheck.leaveType = true : validCheck.leaveType = false
            formData.request_type ? validCheck.requestType = true : validCheck.requestType = false
            formData.start_date ? validCheck.startDate = true : validCheck.startDate = false
            formData.end_date ? validCheck.endDate = true : validCheck.endDate = false
            formData.desc.trim() ? validCheck.description = true : validCheck.description = false
        }
        if (formData.request_type === 'leave' && perm) {
            validCheck.isWfh = true
            validCheck.onBehalf = true
            validCheck.startDate = true
            validCheck.endDate = true
            validCheck.leaveType = true
            formData.request_type ? validCheck.requestType = true : validCheck.requestType = false
            formData.from_time ? validCheck.startTime = true : validCheck.startTime = false
            formData.to_time ? validCheck.endTime = true : validCheck.endTime = false
            formData.desc.trim() ? validCheck.description = true : validCheck.description = false
        }
        let isValid = Object.keys(validCheck).every((k) => validCheck[k])
        return isValid
    }
    /* Methods - End */

    /* Render Templates - Start */

    // Footer section UI and Frontend Functions
    const renderFooter = (name) => {
        if (props.type === 'Request') {
            return (
                <div>
                    <div className='p-grid request-footer'>
                        <div className='p-col-6 align-center pb-0'>
                            {props.reporterGender ? 
                                props.reporterGender === "Male" ?
                                <img src={avatar} className='associates-avatar' /> :
                                <img src={avatarFemale} className='associates-avatar' />
                                :
                                reporterGender === "Male" ?
                                <img src={avatar} className='associates-avatar' /> :
                                <img src={avatarFemale} className='associates-avatar' />
                            }
                            <div className='pl-8 d-mob-block'>
                                {
                                    formData.request_type === "behalfof" || formData.request_type === 'WFH' || formData.request_type === "Beyond Balance" ?
                                        <div>
                                            <p className="font-bold fs-12 m-0 request-footer-text">{reportingManager}</p>
                                            <p className="font-normal fs-11 m-0 mt-6 text-lightgray">Reporting Manager</p>
                                        </div> :
                                        <div>
                                            <p className="font-bold fs-12 m-0 request-footer-text">{props.reportingManager}</p>
                                            <p className="font-normal fs-11 m-0 mt-6 text-lightgray">Reporting Manager</p>
                                        </div>
                                }

                            </div>
                        </div>
                        <div className='p-col-6 align-center justify-end pb-0'>
                            <Button type="submit" label="Send Request" className="create-button request-button" onClick={() => leaveRequest()} />
                        </div>
                    </div>
                </div>
            );
        } else if (props.type === 'Update') {
            return (
                <div>
                    <div className='p-grid request-footer'>
                        <div className='p-col-6 align-center pb-0'>
                            {props.reporterGender === "Male" ?
                                <img src={avatar} className='associates-avatar' /> :
                                <img src={avatarFemale} className='associates-avatar' />
                            }
                            <div className='pl-8 d-mob-block'>
                                <p className="font-bold fs-12 m-0 request-footer-text">{props.reportingManager}</p>
                                <p className="font-normal fs-11 m-0 mt-6 text-lightgray">Reporting Manager</p>
                            </div>
                        </div>
                        <div className='p-col-6 align-center justify-end pb-0'>
                            <Button type="submit" label="Update Leave" className="create-button request-button" onClick={() => editLeave()} />
                        </div>
                    </div>
                </div>
            );
        }
    }

    /* Render Templates - End */

    /* Render View Return - Start */

    return (
        <div className="dialog-demo">
            <ToastElement ref={toastRef} />
            <div className="card">
                <Dialog header={props.header} visible={visible} onHide={() => props.onHide()} breakpoints={{ '960px': '75vw' }} footer={renderFooter('displayRequestModal')} draggable={false} className='request-leave-modal'>
                    <div className='leave-request'>
                        <div className="p-grid">
                            {
                                buName == 'IG-CHENNAI' ?
                                    <div className={formData.request_type === 'Beyond Balance' || formData.request_type === 'behalfof' || formData.request_type === 'WFH' ? "p-col-12 p-sm-4" : "p-col-12 p-sm-6"}>
                                        <p className="font-normal fs-13 text-lightgray m-0">Request Type <span className='text-red'>*</span></p>
                                        <SingleSelectElement
                                            name="request_type"
                                            placeholder="Select"
                                            filter={false}
                                            optionLabel="name"
                                            options={requestType}
                                            value={formData.request_type}
                                            onChangeValue={(e) => onRequestTypeChange(e)}
                                            disabled={props.leaveEditType ? true : false}
                                            filterBy='name'
                                        />
                                    </div> :
                                    <div className={formData.request_type === 'behalfof' || formData.request_type === 'WFH' ? "p-col-12 p-sm-4" : "p-col-12 p-sm-6"}>
                                        <p className="font-normal fs-13 text-lightgray m-0">Request Type <span className='text-red'>*</span></p>
                                        <SingleSelectElement
                                            name="request_type"
                                            placeholder="Select"
                                            filter={false}
                                            optionLabel="name"
                                            options={requestTypesUK}
                                            value={formData.request_type}
                                            onChangeValue={(e) => onRequestTypeChange(e)}
                                            disabled={props.leaveEditType ? true : false}
                                            filterBy='name'
                                        />
                                    </div>
                            }
                            {
                                formData.request_type === 'behalfof' && (
                                    <div className={formData.request_type === 'behalfof' ? "p-col-12 p-sm-4" : "p-col-12 p-sm-6"}>
                                        <p className="font-normal fs-13 text-lightgray m-0">Who for <span className='text-red'>*</span></p>
                                        <SingleSelectElement
                                            name="behalfOf"
                                            placeholder="Select"
                                            filter={false}
                                            optionLabel="name"
                                            options={userList}
                                            value={formData.whoFor}
                                            onChangeValue={(e) => onUserChange(e)}
                                            disabled={(props.leaveEditData && props.leaveEditData.userId) ? true : false}
                                            filterBy='name'
                                        />
                                    </div>
                                )}
                            {
                                formData.request_type === 'Beyond Balance' && (
                                    <div className={formData.request_type === 'Beyond Balance' ? "p-col-12 p-sm-4" : "p-col-12 p-sm-6"}>
                                        <p className="font-normal fs-13 text-lightgray m-0">Who for <span className='text-red'>*</span></p>
                                        <SingleSelectElement
                                            name="behalfOf"
                                            placeholder="Select"
                                            filter={false}
                                            optionLabel="name"
                                            options={userList}
                                            value={formData.whoFor}
                                            onChangeValue={(e) => onUserChange(e)}
                                            disabled={(props.leaveEditData && props.leaveEditData.userId) ? true : false}
                                            filterBy='name'
                                        />
                                    </div>
                                )}
                           

                            {
                                formData.request_type === 'Beyond Balance' && (
                                    <div className={formData.request_type === 'Beyond Balance' ? "p-col-12 p-sm-4" : "p-col-12 p-sm-6"}>
                                        <p className="font-normal fs-13 text-lightgray m-0">Leave Type <span className='text-red'>*</span></p>
                                        <SingleSelectElement
                                            name="leave_type"
                                            placeholder="Select"
                                            filter={false}
                                            optionLabel="name"
                                            options={leaveTypes}
                                            value={formData.leave_type}
                                            onChangeValue={(e) => onLeaveTypeChange(e)}
                                            disabled={(props.leaveEditData && props.leaveEditData.leaveTypeId) ? true : false}
                                            filterBy='name'
                                        />
                                    </div>
                                )}
                            {
                                formData.request_type === 'behalfof' && 
                                    <div className={formData.request_type === 'behalfof' ? "p-col-12 p-sm-4" : "p-col-12 p-sm-6"}>
                                        <p className="font-normal fs-13 text-lightgray m-0">Leave Type <span className='text-red'>*</span></p>
                                        <SingleSelectElement
                                            name="leave_type"
                                            placeholder="Select"
                                            filter={false}
                                            optionLabel="name"
                                            options={leaveTypes}
                                            value={formData.leave_type}
                                            onChangeValue={(e) => onLeaveTypeChange(e)}
                                            disabled={(props.leaveEditData && props.leaveEditData.leaveTypeId) ? true : false}
                                            filterBy='name'
                                        />
                                    </div>
                                }
                            {
                                formData.request_type === 'leave' && (
                                    <div className={formData.request_type === 'behalfof' ? "p-col-12 p-sm-4" : "p-col-12 p-sm-6"}>
                                        <p className="font-normal fs-13 text-lightgray m-0">Leave Type <span className='text-red'>*</span></p>
                                        <SingleSelectElement
                                            name="leave_type"
                                            placeholder="Select"
                                            filter={false}
                                            optionLabel="name"
                                            options={leaveTypes}
                                            value={formData.leave_type}
                                            onChangeValue={(e) => onLeaveTypeChange(e)}
                                            disabled={(props.leaveEditData && props.leaveEditData.leaveTypeId) ? true : false}
                                            filterBy='name'
                                        />
                                    </div>
                                )}


                        </div>
                        {
                            !perm && !halfDay && (
                                <div className="p-grid">
                                    <div className="p-col-12 p-sm-4">
                                        <p className="font-normal fs-13 text-lightgray mt-0 mb-8">Start Date <span className='text-red'>*</span></p>
                                        <CalendarElement
                                            name='start_date'
                                            placeholder='Start Date'
                                            minDate={ formData.request_type === 'WFH' ? new Date() : ''}
                                            maxDate={formData.end_date}
                                            value={formData.start_date}
                                            view='date'
                                            dateFormat='dd/mm/yy'
                                            onChange={(e) => onChangeStartDate(e)}
                                            showIcon='true'
                                            disabled={(formData.leave_type !== '' || formData.request_type === 'Compensatory') && disable === false ? false : true}
                                        />
                                    </div>
                                    <div className="p-col-12 p-sm-4">
                                        <p className="font-normal fs-13 text-lightgray mt-0 mb-8">End Date <span className='text-red'>*</span></p>
                                        <CalendarElement
                                            name='end_date'
                                            placeholder='End Date'
                                            minDate={formData.start_date}
                                            value={formData.end_date == '' ? formData.start_date && setFormData({ ...formData, ['end_date']: formData.start_date }) : formData.end_date}
                                            view='date'
                                            dateFormat='dd/mm/yy'
                                            onChange={(e) => onChangeEndDate(e)}
                                            showIcon='true'
                                            disabled={(formData.leave_type || formData.request_type === 'Compensatory') && formData.start_date ? false : true}
                                        />
                                    </div>
                                    <div className="p-col-12 p-sm-4 half-day">
                                        <CheckboxElement
                                            inputId="halfDay"
                                            value="Half Day"
                                            checked={formData.half_day}
                                            onChangeCheck={(e, halfDayId) => onHalfDayChange(e, halfDayId)}
                                            disabled={(formData.request_type === 'leave' && leaveType == 'Family Time Off') ? true :  false}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        {
                            (formData.request_type == "leave" || formData.request_type == "behalfof" || formData.request_type == "Beyond Balance") && halfDay && (
                                <div className="p-grid">
                                    <div className="p-col-12 p-sm-6">
                                        <div className='d-flex justify-between'>
                                            <p className="font-normal fs-13 text-lightgray mt-0 mb-8">Start Date <span className='text-red'>*</span></p>
                                            <div className='d-flex'>
                                                <RadioElement
                                                    id="start_date_half_day"
                                                    options={start_date_half}
                                                    value={formData.half_day_date}
                                                    onChangeRadio={(e) => onChangeStartHalf(e)}
                                                />
                                                <p className="font-normal fs-13 text-lightgray mt-0 mb-8">Half Day</p>
                                            </div>
                                        </div>
                                        <CalendarElement
                                            name='start_date'
                                            placeholder='Start Date'
                                            value={formData.start_date}
                                            view='date'
                                            dateFormat='dd/mm/yy'
                                            onChange={(e) => onChangeStartDate(e)}
                                            showIcon='true'
                                            disabled={(formData.leave_type !== '' || formData.request_type === 'Compensatory') && disable === false ? false : true}
                                        />
                                    </div>
                                    <div className="p-col-12 p-sm-6">
                                        <div className='d-flex justify-between'>
                                            <p className="font-normal fs-13 text-lightgray mt-0 mb-8">End Date <span className='text-red'>*</span></p>
                                            <div className='d-flex'>
                                                <RadioElement
                                                    id="start_date_half_day"
                                                    options={end_date_half}
                                                    value={formData.half_day_date}
                                                    onChangeRadio={(e) => onChangeEndHalf(e)}
                                                />
                                                <p className="font-normal fs-13 text-lightgray mt-0 mb-8">Half Day</p>
                                            </div>
                                        </div>
                                        <CalendarElement
                                            name='end_date'
                                            placeholder='End Date'
                                            minDate={formData.start_date}
                                            value={formData.end_date == '' ? formData.start_date && setFormData({ ...formData, ['end_date']: formData.start_date }) : formData.end_date}
                                            view='date'
                                            dateFormat='dd/mm/yy'
                                            onChange={(e) => onChangeEndDate(e)}
                                            showIcon='true'
                                            disabled={(formData.leave_type || formData.request_type === 'Compensatory') && formData.start_date ? false : true}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        {(formData.fileName != null) && props.leaveEditData?.fileName != null &&
                        <div className="p-grid">
                            <div className='p-col-12 pt-16'>
                                <div id="leave-file-upload">
                                    <>
                                    {/* <p className="font-normal fs-13 text-lightgray mt-0 mb-8">Medical Document<span className='text-red'>*</span></p> */}
                                        <a href={formData.url} target="_blank" className='leave-attachment-upload'> {formData.fileName}</a>
                                        </>
                                </div>
                            </div>
                        </div>
                        }
                        <div className="p-grid">
                            <div className='p-col-12 pt-16'>
                                {
                                    formData.request_type == "leave" &&  casualSickLeave && formData.total_days >= casualLeaveLimit && (
                                        <>
                                            {CasualLeaveAttachment()}
                                        </>
                                    )
                                }
                            </div>
                        </div>
                        {
                            perm && (
                                <div className="p-grid">
                                    <div className="p-col-12 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0 mb-8">Start Time <span className='text-red'>*</span></p>
                                        <div className='datetime'>
                                            <Datetime
                                                value={formData.from_time}
                                                onChange={(e) => onChangeTime(e, "start")}
                                                inputProps={{ placeholder: 'From Time' }}
                                                dateFormat="DD/MM/YYYY"
                                            />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0 mb-8">End Time <span className='text-red'>*</span></p>
                                        <div className='datetime'>
                                            <Datetime
                                                value={formData.to_time == '' ? formData.from_time : formData.to_time}
                                                onChange={(e) => onChangeTime(e, "end")}
                                                inputProps={{ placeholder: 'To Time' }}
                                                dateFormat="DD/MM/YYYY"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        <div className="p-grid">
                            <div className='p-col-12 pt-16'>
                                <TextareaElement
                                    name="desc"
                                    value={formData.desc}
                                    className='input-textarea-leave'
                                    onTextareaChange={(e) => setFormData({ ...formData, ['desc']: e.target.value })}
                                    rows='4'
                                    cols='30'
                                    placeholder="Description *"
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-8 leave-balance-col">
                                {
                                    leaveType == "Permission" ?
                                        <div className='d-flex p-0'>
                                            <div className='p-col-5 p-0 balanceView'>
                                                <div className='p-col-12 total-balance-leave'>
                                                    <p className="fs-13 m-0 text-center text-white">Balance (Current)</p>
                                                    <p className='m-0 pt-8 fs-13 text-center text-white'>{balance ? balanceLeaves.balanceNextYear !== null ? balanceLeaves.balanceNextYear : 0 : balanceLeaves.balanceLeaves}</p>
                                                </div>
                                            </div>
                                            <div className='p-col-5 p-0 balanceView ml-10'>
                                                <div className='p-col-12 total-balance-leave'>
                                                    <p className="fs-13 m-0 text-center text-white">Balance (Previous)</p>
                                                    <p className='m-0 pt-8 fs-13 text-center text-white'>{balanceLeaves.previousMonthBalance !== null ? balanceLeaves.previousMonthBalance : 0}</p>
                                                </div>
                                            </div>
                                        </div> :
                                        leaveType == "WFH" ? <>
                                            {
                                                showBalance &&
                                                <div className='d-flex p-0'>
                                                    <div className='p-col-6 p-0 balanceView'>
                                                        <div className='p-col-12 total-balance-leave'>
                                                            <p className="fs-13 m-0 text-center text-white">Balance {balance ? '(Next year)' : '(Current year)'}</p>
                                                            <p className='m-0 pt-8 fs-13 text-center text-white'>{balance ? balanceLeaves.balanceNextYear !== null ? balanceLeaves.balanceNextYear : 0 : balanceLeaves.balanceLeaves}/{totalNoOfLeaves}</p>
                                                        </div>
                                                    </div>
                                                    {/* <div className='p-col-6 p-0 balanceView ml-10'>
                                                        <div className='p-col-12 total-balance-leave'>
                                                            <p className="fs-13 m-0 text-center text-white">Balance (Current month) </p>
                                                            <p className='m-0 pt-8 fs-13 text-center text-white'>{wfhleaveBalance}/{monthlyLimit}</p>
                                                        </div>
                                                    </div> */}
                                                </div>

                                            }

                                        </> :
                                            <>
                                                {
                                                    showBalance &&
                                                    <div className='d-flex p-0'>
                                                        <div className='p-col-5 p-0 balanceView'>
                                                            <div className='p-col-12 total-balance-leave'>
                                                                <p className="fs-13 m-0 text-center text-white">Balance {balance ? '(Next year)' : '(Current year)'}</p>
                                                                <p className='m-0 pt-8 fs-13 text-center text-white'>{balance ? balanceLeaves.balanceNextYear !== null ? balanceLeaves.balanceNextYear : 0 : balanceLeaves.balanceLeaves}</p>
                                                            </div>
                                                        </div>
                                                        {/* {
                                                        balanceLeaves.leaveTypeName === 'Personal Time Off' && balanceLeaves.userStatus !== 'In Probation' &&
                                                        <div className='p-col-5 p-0 balanceView ml-20'>
                                                            <div className='p-col-12 total-balance-leave'>
                                                                <p className="fs-13 m-0 text-center text-white">Advance Balance</p>
                                                                <p className='m-0 pt-8 fs-13 text-center text-white'>{balanceLeaves.advanceLeaves}</p>
                                                            </div>
                                                        </div>
                                                    } */}
                                                    </div>
                                                }
                                            </>
                                }
                            </div>
                            <div className='p-col-4 align-center justify-end pb-0'>
                                {formData.request_type === "leave" && perm ? <p className="font-normal fs-13 text-lightgray m-0">Total hours &nbsp;</p> : <p className="font-normal fs-13 text-lightgray m-0">Total days &nbsp;</p>}
                                {formData.total_days !== '' ?
                                    <div>
                                        <p className="font-bold fs-13 text-green m-0">{formData.total_days}</p>
                                    </div> : <p className="font-bold fs-13 text-green m-0">0</p>
                                }
                            </div>
                        </div>
                        
                        
                    </div>
                </Dialog>
            </div >
        </div>
    )

    /* Render View Return - End */

}

/* Function - End */

/* Export default functionName; */

export default RequestModalElement;