/* Import Section - Start */

/* React Imports - Start */

import React from "react";

/* React Imports - End */

/* Plugin Imports - Start */

/* Plugin Imports - End */

/* Project Components Imports - Start */

import LoaderImage from "../../assets/images/loader-img.gif";

/* Project Components Imports - End */

/* Import Section - End */

/* Function - Start */

const Loader = (props) => {

    /* State Declaration - Start */

    /* useState - Start */

    /* useState - End */

    /* State Declaration - End */

    /* Methods - Start */

    /* Methods - End */

    /* Render Templates - Start */

    /* Render Templates - End */

    /* Render View Return - Start */

    return (
        <div>
            {
                props.loading &&
                <div className="loader-div">
                    <img src={LoaderImage} className="loader-img" />
                </div>
            }
        </div>
    );

    /* Render View Return - End */

};

/* Function - End */

/* Export default functionName; */

export default Loader;