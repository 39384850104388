/**
 * Interceptor Service
 */
import axios from 'axios';
import Cookies from 'universal-cookie';

import localStorageService from '../util/localStorage/LocalStorage';
// React Router
// import { Route, Routes, useNavigate } from 'react-router-dom';
// let navigate = useNavigate();
let cookies = new Cookies()

export function jwtInterceptor() {
    
    axios.interceptors.request.use(request => {        
        // add auth header with jwt if account is logged in and request is to the api url
        const account = localStorageService.getToken('token');
        const userId = localStorageService.getToken('userId');
        const accessToken = localStorageService.getToken('accessToken');
        const isApiUrl = request.url.startsWith(process.env.REACT_APP_API_URL);  
        
        
        if (account && isApiUrl) {
            request.headers.common['X-Auth-Token'] = `${account}`;
              
        } else {
            request.headers.common['X-Auth-Token'] = `${accessToken}`;
            request.headers.common['Access-Token'] = true;
        }
        // console.log(request.headers.common);
        request.headers['user-id'] = `${userId}`;
        request.headers.common['email'] = cookies.get('email'); 
        return request;
    });

    axios.interceptors.response.use(response => {        
        // add auth header with jwt if account is logged in and request is to the api url
        // const account = localStorageService.getToken('token');
        // const isApiUrl = request.url.startsWith(process.env.REACT_APP_API_URL);
        
        // if (account && isApiUrl) {
        //     response.headers.common['X-Auth-Token'] = `${account}`;
        // }
        try{
            if(response.data.statusCode === 403 ){
                // console.log(response.status == 403," response");
                cookies.remove('token', 'none');
                cookies.remove('userId', 'none');
                cookies.remove('buId', 'none');
                cookies.remove('userName', 'none');
                cookies.remove('roles', 'none');
                cookies.remove('email', 'none');
                cookies.remove('idToken', 'none');
                cookies.remove('accessToken', 'none');
                cookies.remove('isAuth', 'none');
                cookies.remove('buName', 'none');
                window.location.href=`/login`; 
            }
    
            return response;
        } catch(e){
            console.log('Error',e);
        }
    });

}