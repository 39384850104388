/* Import Section - Start */

/* React Imports - Start */
import React, { useState, useEffect, useRef } from 'react';

/* React Imports - End */

/* Plugin Imports - Start */

import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

/* Plugin Imports - End */

/* Project Components Imports - Start */

import CheckboxElement from '../uiComponents/CheckboxElement';
import Cookies from 'universal-cookie';
import ReferenceDataService from '../../services/controlPanel/ReferenceDataService';
import TextboxElement from "../uiComponents/TextboxElement";
import ToastElement from "../uiComponents/ToastElement";
import DataTableElement from "../uiComponents/DataTableElement";
import Loader from '../uiComponents/Loader';
import SingleSelectElement from '../uiComponents/SingleSelectElement';

/* Project Components Imports - End */

/* Import Section - End */

/* Function - Start */

const ReferenceData = () => {

    /* State Declaration - Start */

    /* useState - Start */

    const [activeIndex, setActiveIndex] = useState(null);
    const [designationData, setDesignationData] = useState([]);
    const [departmentData, setDepartmentData] = useState([]);
    const [gradeData, setGradeData] = useState([]);
    const [employeeStatusData, setEmployeeStatusData] = useState([]);
    const [displayDesignation, setDisplayDesignation] = useState(false);
    const [displayDepartment, setDisplayDepartment] = useState(false);
    const [displayGrade, setDisplayGrade] = useState(false);
    const [displayEmployee, setDisplayEmployee] = useState(false);
    const [desData, setDesdata] = useState('');
    const [success, setSuccess] = useState('');
    const [depData, setDepdata] = useState('');
    const [graData, setGradata] = useState('');
    const [bandData, setBanddata] = useState('');
    const [descriptionData, setDescriptionData] = useState('');
    const [employeeStatus, setEmployeeStatus] = useState('');
    const [leaveLimit, setLeaveLimit] = useState('');
    const [lock, setLock] = useState(false);
    const [leave, setLeave] = useState(false);
    const [button, setButton] = useState(false);
    const [datatable, setDatatable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [metricsData, setMetricsData] = useState([]);
    const [displayMetrics, setDisplayMetrics] = useState(false);
    const [datatableMetrics, setDatatableMetrics] = useState(true);
    const [performanceType, setPerformanceType] = useState('');
    const [performanceCategory, setPerformanceCategory] = useState('');
    const [performanceKra, setPerformanceKra] = useState([]);
    const [performanceKraText, setPerformanceKraText] = useState(['']);
    const [edit, setEdit] = useState(false);
    const [editId, setEditId] = useState('');
    const [buUnit, setBuUnit] = useState([]);
    const [currentBu, setCurrentBU] = useState();

    /* useState - End */

    /* useRef - Start */

    const toastRef = useRef();
    const cookies = new Cookies();

    /* useRef - End */

    /* useEffect - Start */

    useEffect(() => {
        createButton();
        getActiveBu();
    }, [])

    /* useEffect - End */

    /* State Declaration - End */

    /* Methods - Start */

    const dialogFuncMap = {
        'displayDesignation': setDisplayDesignation,
        "displayDepartment": setDisplayDepartment,
        "displayGrade": setDisplayGrade,
        "displayEmployee": setDisplayEmployee,
        "displayMetrics": setDisplayMetrics,
    }

    const onHide = (name, message, success) => {
        if (name) {
            dialogFuncMap[`${name}`](false);
            setDepdata('');
            setGradata('');
            setBanddata('');
            setDescriptionData('');
            setDesdata('');
            setEmployeeStatus('');
            setLeaveLimit('');
            setLock(false);
            setLeave(false);
            setPerformanceCategory('');
            setPerformanceType('');
            setPerformanceKra([]);
            setPerformanceKraText(['']);
        }
        message && toastRef.current.showToast(message)
        if (success) {
            setSuccess(success);
            getDepartmentData();
            getDesignationData();
            getGradeData();
            getEmployeeStatusData();
            getMetricsData();
        }
    }

    //getting active bu for rendering in the select element
    const getActiveBu = () => {
        ReferenceDataService.getActiveBu().then(data => {
            setBuUnit(data.data)
            var bu = cookies.get('buId')
            if (bu) {
                setCurrentBU(_.find(data.data, { id: bu }))
                getDepartmentData(bu);
                getDesignationData(bu);
                getGradeData(bu);
                getEmployeeStatusData(bu);
                getMetricsData(bu);
            }
        })
    }

    //  Onchange BU
    const buChange = (e) => {
        getDesignationData(e.value.id)
        getDepartmentData(e.value.id);
        getGradeData(e.value.id);
        getEmployeeStatusData(e.value.id);
        getMetricsData(e.value.id);
        setCurrentBU(e.value)
    }

    const getDesignationData = (buId) => {
        setLoading(true);
        let designationBuid = buId ? buId : currentBu.id;
        ReferenceDataService.getDesignationData(designationBuid).then(data => {
            setLoading(false);
            let desData = data.data;
            desData.sort(function (a, b) {
                var a1 = a.designation.toLowerCase();
                var b1 = b.designation.toLowerCase();
                return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
            });
            setDesignationData(desData);
        });
    }

    const createButton = () => {
        setButton(true);
        setDatatable(true);
    }

    const getDepartmentData = (buId) => {
        setLoading(true);
        let departmentBuid = buId ? buId : currentBu.id;
        ReferenceDataService.getDepartmentData(departmentBuid).then(response => {
            let deptData = response.data;
            deptData.sort(function (a, b) {
                var a1 = a.department.toLowerCase();
                var b1 = b.department.toLowerCase();
                return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
            });
            setLoading(false);
            setDepartmentData(deptData);
        })
    }

    const getGradeData = (buId) => {
        setLoading(true);
        let gradeBuid = buId ? buId : currentBu.id;
        ReferenceDataService.getGradeData(gradeBuid).then(response => {
            setLoading(false);
            let graphData = response.data;
            graphData.sort(function (a, b) {
                var a1 = a.grade.toLowerCase();
                var b1 = b.grade.toLowerCase();
                return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
            });
            setGradeData(graphData);
        })
    }

    const getEmployeeStatusData = (buId) => {
        setLoading(true);
        let employeeeBuid = buId ? buId : currentBu.id;
        ReferenceDataService.getEmployeeStatusData(employeeeBuid).then(response => {
            setLoading(false);
            let empData = response.data;
            empData.sort(function (a, b) {
                var a1 = a.status.toLowerCase();
                var b1 = b.status.toLowerCase();
                return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
            });
            setEmployeeStatusData(empData);
        })
    }

    const getMetricsData = (buId) => {
        setLoading(true);
        const metricsBuId = buId ? buId : currentBu.id;
        ReferenceDataService.getMetricsData(metricsBuId).then(response => {
            setLoading(false);
            setMetricsData(response.data);
        })
    }

    //Designation Checkbox Editing
    const checkboxValueEdit = (e, dataId) => {
        setLoading(true);
        var id = dataId
        const data = {
            id: dataId,
            buId: currentBu.id,
            isActive: e.checked
        }
        ReferenceDataService.updateDesignationData(id, data).then(data => {
            setLoading(false);
            !data.patched.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: data.patched.message
            })
            data.patched && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated sucessfully',
                message: data.patched.message
            })
            getDesignationData();
        })
    }

    //Department Checkbox value Edit
    const checkboxDepartmentValueEdit = (e, dataId) => {
        setLoading(true);
        var id = dataId
        const data = {
            id: dataId,
            buId: currentBu.id,
            isActive: e.checked
        }
        ReferenceDataService.updateDepartmentData(id, data).then(data => {
            setLoading(false);
            !data.patched.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: data.patched.message
            })
            data.patched && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated sucessfully',
                message: data.patched.message
            })
            getDepartmentData();
        })
    }

    //Grade Checkbox value Edit
    const checkboxGradeValueEdit = (e, dataId) => {
        setLoading(true);
        var id = dataId
        const data = {
            id: dataId,
            buId: currentBu.id,
            isActive: e.checked
        }
        ReferenceDataService.updateGradeData(id, data).then(data => {
            setLoading(false);
            !data.patched.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: data.patched.message
            })
            data.patched && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated sucessfully',
                message: data.patched.message
            })
            getGradeData();
        })
    }

    //EmployeeStatus Checkbox value Edit
    const checkboxEmployeeValueEdit = (e, dataId) => {
        setLoading(true);
        var id = dataId
        const data = {
            id: dataId,
            buId: currentBu.id,
            isActive: e.checked,
        }
        ReferenceDataService.updateEmployeeStatusData(id, data).then(data => {
            setLoading(false);
            !data.patched.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: data.patched.message
            })
            data.patched && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated sucessfully',
                message: data.patched.message
            })
            getEmployeeStatusData();
        })
    }

    const checkboxLockValueEdit = (e, dataId, leave, status, leavelimit, isActive) => {
        setLoading(true);
        var id = dataId
        const data = {
            id: dataId,
            status: status,
            isLock: e.checked,
            isLeave: leave,
            leaveLimit: leavelimit,
            buId: currentBu.id,
            isActive: isActive
        }
        ReferenceDataService.updateEmployeeLockData(id, data).then(data => {
            setLoading(false);
            data.patched && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated sucessfully',
                message: data.patched
            })
            getEmployeeStatusData();
        })
    }

    const checkboxLeaveValueEdit = (e, dataId, lock, status, leavelimit, isActive) => {
        setLoading(true);
        var id = dataId
        const data = {
            id: dataId,
            status: status,
            isLock: lock,
            isLeave: e.checked,
            leaveLimit: leavelimit,
            buId: currentBu.id,
            isActive: isActive
        }
        ReferenceDataService.updateEmployeeLockData(id, data).then(data => {
            setLoading(false);
            data.patched && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated sucessfully',
                message: data.patched
            })
            getEmployeeStatusData();
        })
    }

    const onClickAddDesignation = (leave) => {
        setDisplayDesignation(true);
    }

    const onClickAddDepartment = (leave) => {
        setDisplayDepartment(true);
    }

    const onClickAddGrade = (leave) => {
        setDisplayGrade(true);
    }

    const onClickAddEmployee = (leave) => {
        setDisplayEmployee(true);
    }

    const onClickAddMetrics = () => {
        setDisplayMetrics(true);
        setEdit(false);
    }

    const assLeaveCancelHandler = () => {
        var data = {
            designation: desData,
            buId: currentBu.id,
            isActive: false
        }
        if (desData.trim() != '') {
            setLoading(true);
            ReferenceDataService.addDesignationData(data).then(data => {
                setLoading(false);
                let message = {
                    type: data.posted.success ? 'success' : 'error',
                    summary: data.posted.success ? 'Success' : 'Something went wrong',
                    message: data.posted.message
                }
                let success = data.posted.success ? true : false
                onHide('displayDesignation', message, success);
                setDesdata('');
            })
        } else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Select Designation',
                message: 'Fill the mandatory fields'
            })
        }
    }

    const departmentHandler = () => {
        var data = {
            department: depData,
            buId: currentBu.id,
            isActive: true
        }
        if (depData != '') {
            setLoading(true);
            ReferenceDataService.addDepartmentData(data).then(data => {
                setLoading(false);
                let message = {
                    type: data.posted.success ? 'success' : 'error',
                    summary: data.posted.success ? 'Success' : 'Something went wrong',
                    message: data.posted.message
                }
                let success = data.posted.success ? true : false
                onHide('displayDepartment', message, success);
                setDepdata('');
            })
        } else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Select Department',
                message: 'Fill the mandatory fields'
            })
        }
    }

    const gradeHandler = () => {
        var data = {
            grade: graData,
            band: bandData,
            buId: currentBu.id,
            isActive: true,
            description: descriptionData,
        }
        if (graData && bandData && descriptionData != '') {
            setLoading(true);
            ReferenceDataService.addGradeData(data).then(data => {
                setLoading(false);
                let message = {
                    type: data.posted.success ? 'success' : 'error',
                    summary: data.posted.success ? 'Success' : 'Something went wrong',
                    message: data.posted.message
                }
                let success = data.posted.success ? true : false
                onHide('displayGrade', message, success);
                setGradata('');
                setBanddata('');
                setDescriptionData('');
            })
        } else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Select Department',
                message: 'Fill the mandatory fields'
            })
        }
    }

    const employeeHandler = () => {
        var data = {
            status: employeeStatus,
            isLock: lock,
            isLeave: leave,
            leaveLimit: leaveLimit,
            buId: currentBu.id,
            isActive: true,
        }
        if (data.status != '' && leaveLimit != '') {
            setLoading(true);
            ReferenceDataService.addEmployeeData(data).then(data => {
                setLoading(false);
                let message = {
                    type: data.posted.success ? 'success' : 'error',
                    summary: data.posted.success ? 'Success' : 'Something went wrong',
                    message: data.posted.message
                }
                let success = data.posted.success ? true : false
                onHide('displayEmployee', message, success);
                setEmployeeStatus('')
                setLeaveLimit('')
                setEmployeeStatus('')
            })
        }
        else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Select Department',
                message: 'Fill the mandatory fields'
            })
        }
        getEmployeeStatusData();
    }

    const checkMetrics = (e, dataId) => {
        setLoading(true);
        const data = {
            id: dataId,
            buId: currentBu.id,
            isActive: e.checked,
        }
        ReferenceDataService.updateMetricsStatus(data).then(data => {
            setLoading(false);
            !data.patched.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: data.patched.message
            })
            data.patched && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated sucessfully',
                message: data.patched.message
            })
            getMetricsData();
        })
    }

    const editMetrics = (id) => {
        setLoading(true);
        ReferenceDataService.editMetrics(id).then(data => {
            setLoading(false);
            setPerformanceType(data.data.type);
            setPerformanceCategory(data.data.category);
            setPerformanceKra(data.data.kra);
            setPerformanceKraText(data.data.kra);
            setDisplayMetrics(true);
            setEdit(true);
            setEditId(id);
        })
    }

    const metricsAddHandler = () => {
        var data = {
            buId: currentBu.id,
            category: performanceCategory,
            kra: performanceKra,
            type: performanceType,
            isActive: true,
        }
        if (data.category !== '' || data.kra !== '' || data.type !== '') {
            setLoading(true);
            ReferenceDataService.addMetrics(data).then(data => {
                setLoading(false);
                let message = {
                    type: data.posted.success ? 'success' : 'error',
                    summary: data.posted.success ? 'Success' : 'Something went wrong',
                    message: data.posted.message
                }
                let success = data.posted.success ? true : false
                onHide('displayMetrics', message, success);
            })
        }
        else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Select Department',
                message: 'Fill the mandatory fields'
            })
        }
        getMetricsData();
    }

    const metricsUpdateHandler = (editId) => {
        var data = {
            id: editId,
            buId: currentBu.id,
            category: performanceCategory,
            kra: performanceKra,
            type: performanceType,
            isActive: true,
        }
        if (data.category !== '' || data.kra !== '' || data.type !== '') {
            setLoading(true);
            ReferenceDataService.updateMetrics(data).then(data => {
                setLoading(false);
                let message = {
                    type: data.patched.success ? 'success' : 'error',
                    summary: data.patched.success ? 'Success' : 'Something went wrong',
                    message: data.patched.message
                }
                let success = data.patched.success ? true : false
                onHide('displayMetrics', message, success);
            })
        }
        else {
            toastRef.current.showToast({
                type: 'error',
                summary: 'Select Department',
                message: 'Fill the mandatory fields'
            })
        }
        getMetricsData();
    }

    /* Methods - End */

    /* Render Templates - Start */

    const renderAssLeaveCancel = () => {
        return (
            <div className="cancel-footer">
                <div className='align-center justify-end pb-0'>
                    <Button type="submit" label="ADD" className="create-button request-button" onClick={() => assLeaveCancelHandler()} />
                </div>
            </div>
        );
    }

    const renderDepartment = () => {
        return (
            <div className="cancel-footer">
                <div className='align-center justify-end pb-0'>
                    <Button type="submit" label="ADD" className="create-button request-button" onClick={() => departmentHandler()} />
                </div>
            </div>
        );
    }

    const renderGrade = () => {
        return (
            <div className="cancel-footer">
                <div className='align-center justify-end pb-0'>
                    <Button type="submit" label="ADD" className="create-button request-button" onClick={() => gradeHandler()} />
                </div>
            </div>
        );
    }

    const renderEmployee = () => {
        return (
            <div className="cancel-footer">
                <div className='align-center justify-end pb-0'>
                    <Button type="submit" label="ADD" className="create-button request-button" onClick={() => employeeHandler()} />
                </div>
            </div>
        );
    }

    const activeTemplate = (activedata) => {
        return (
            <div>
                {
                    <div className="flex-items relative employee-is-active">
                        <CheckboxElement
                            key={activedata.id}
                            inputId={activedata.id}
                            checked={activedata.isActive}
                            onChangeCheck={(e, dataId) => checkboxEmployeeValueEdit(e, dataId)} />
                    </div>
                }
            </div>
        )
    }

    const leaveTemplate = (leavedata) => {
        return (
            <div>
                {
                    <div className="flex-items relative employee-is-leave">
                        <CheckboxElement
                            key={leavedata.id}
                            inputId={leavedata.id}
                            checked={leavedata.isLeave}
                            onChangeCheck={(e, dataId) => checkboxLeaveValueEdit(e, dataId, leavedata.isLock, leavedata.status, leavedata.leaveLimit, leavedata.isActive)}
                        />
                    </div>
                }
            </div>
        )
    }

    const lockTemplate = (lockdata) => {
        return (
            <div>
                {
                    <div className="flex-items relative employee-is-lock">
                        <CheckboxElement
                            key={lockdata.id}
                            inputId={lockdata.id}
                            checked={lockdata.isLock}
                            onChangeCheck={(e, dataId) => checkboxLockValueEdit(e, dataId, lockdata.isLeave, lockdata.status, lockdata.leaveLimit, lockdata.isActive)}
                        />
                    </div>

                }
            </div>
        )
    }

    const renderMetrics = () => {
        return (
            <div className="cancel-footer">
                <div className='align-center justify-end pb-0'>
                    {
                        edit ?
                            <Button type="submit" label="UPDATE" className="create-button request-button" onClick={() => metricsUpdateHandler(editId)} /> :
                            <Button type="submit" label="ADD" className="create-button request-button" onClick={() => metricsAddHandler()} />
                    }

                </div>
            </div>
        );
    }

    const metricsStatusTemplate = (rowData) => {
        return (
            <div>
                {
                    <div className="flex-items relative desData">
                        <CheckboxElement
                            key={rowData.id}
                            inputId={rowData.id}
                            checked={rowData.isActive}
                            onChangeCheck={(e, dataId) => checkMetrics(e, dataId)} />
                    </div>
                }
            </div>
        )
    }

    const metricsActionsTemplate = (rowData) => {
        return (
            <span onClick={() => editMetrics(rowData.id)} className="action">
                <i className="pi pi-pencil leave-edit"></i>
            </span>
        )
    }

    const onClickKra = (e, i) => {
        var performance_kra = [...performanceKra];
        performance_kra[i] = e.target.value;
        setPerformanceKra(performance_kra);
    }

    const onClickAddKra = () => {
        const item = '';
        setPerformanceKraText([...performanceKraText, item]);
    }

    const onClickRemoveKra = (i) => {
        const list = [...performanceKraText];
        list.splice(i, 1);
        setPerformanceKraText(list);
        const listKra = [...performanceKra];
        listKra.splice(i, 1);
        setPerformanceKra(listKra);
    }

    /* Static JSON - Start */

    const performanceMatrix = [
        { name: "Focus Areas", value: "Focus Areas" }
    ];

    const employeeColumns = [
        { field: "isActive", body: activeTemplate, sortable: false, bodyClassName: "employee-is-active" },
        { field: "status", header: "Status", sortable: true },
        { field: "isLeave", header: "Leave", body: leaveTemplate, sortable: false },
        { field: "isLock", header: "isLock", body: lockTemplate, sortable: false },
        { field: "leaveLimit", header: "Leave Limit", sortable: false },
    ];

    const metricsColumns = [
        { field: "isActive", body: metricsStatusTemplate, sortable: false },
        { field: "category", header: "Category", sortable: true },
        { field: "action", header: "Actions", body: metricsActionsTemplate, sortable: false },
    ];

    /* Static JSON - End */

    //Function for getting Selected id
    const getSelectedId = (id) => {
        return ''
    }

    /* Render Templates - End */

    /* Render View Return - Start */

    return (
        <div className="mt-20 reference-data">
            <ToastElement ref={toastRef} />
            <Loader loading={loading} />
            <div className="jobPriority d-flex employee-buttons bu-dropdown">
                <SingleSelectElement
                    placeholder="Select BU"
                    value={currentBu}
                    options={buUnit}
                    optionLabel="name"
                    onChangeValue={(e) => buChange(e)}
                    defaultValue="code"
                />
            </div>
            <div>
                <div className="card" >
                    <Accordion className='accordian' activeIndex={[0]}>
                        <AccordionTab header="Designation">
                            <div>
                                <div className="addButton">
                                    <Button label="Add Designation" onClick={() => onClickAddDesignation('displayDesignation')} icon="pi pi-plus"></Button>
                                </div>
                                <div className="reference-div referenceData">
                                    {
                                        designationData.length > 0 && designationData.map((data) => (
                                            <div className="flex-items relative">
                                                <CheckboxElement
                                                    key={data.id}
                                                    inputId={data.id}
                                                    value={data.designation}
                                                    checked={data.isActive}
                                                    onChangeCheck={(e, dataId) => checkboxValueEdit(e, dataId)}
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header="Department">
                            <div className="addButton">
                                <Button label="Add Department" onClick={() => onClickAddDepartment('displayDepartment')} icon="pi pi-plus"></Button>
                            </div>
                            <div className="reference-div referenceData">
                                {
                                    departmentData.length > 0 && departmentData.map((data) => (
                                        <div className="flex-items relative desData">
                                            <CheckboxElement
                                                key={data.id}
                                                inputId={data.id}
                                                value={data.department}
                                                checked={data.isActive}
                                                onChangeCheck={(e, dataId) => checkboxDepartmentValueEdit(e, dataId)} />
                                        </div>
                                    ))
                                }
                            </div>
                        </AccordionTab>
                        <AccordionTab header="Grade">
                            <div>
                                <div className="addButton">
                                    <Button label="Add Grade" onClick={() => onClickAddGrade('displayGrade')} icon="pi pi-plus"></Button></div>
                                <div className="reference-div referenceData grade">
                                    {
                                        gradeData.length > 0 && gradeData.map((data) => (
                                            <div className="flex-items relative desData d-flex grade-align ">
                                                <CheckboxElement
                                                    key={data.id}
                                                    inputId={data.id}
                                                    value={data.band}
                                                    checked={data.isActive}
                                                    onChangeCheck={(e, dataId) => checkboxGradeValueEdit(e, dataId)} />
                                                <span className="gradeitems-align">( {data.grade} )</span>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header="Employee Status" className='employeeAccordian'>
                            <div className="employee-div">
                                {
                                    datatable &&
                                    <div className='mt-13 employeeTable'>
                                        <DataTableElement
                                            value={employeeStatusData}
                                            columns={employeeColumns}
                                            button={button}
                                            createButton={<div><Button label="Add Employee Status" onClick={() => onClickAddEmployee('displayEmployee')} icon="pi pi-plus" /></div>}
                                            selectedId={getSelectedId}
                                            placeholder="Search Status"
                                            header={true}
                                            search={true}
                                        />
                                    </div>
                                }
                            </div>
                        </AccordionTab>
                        {/* <AccordionTab header="Performance Metrics" className='employeeAccordian'>
                            <div className="employee-div">
                                {
                                    datatableMetrics &&
                                    <div className='mt-13 employeeTable'>
                                        <DataTableElement
                                            value={metricsData}
                                            columns={metricsColumns}
                                            button={button}
                                            createButton={
                                                <div>
                                                    <Button label="Add Metrics" onClick={() => onClickAddMetrics('displayMetrics')} icon="pi pi-plus" />
                                                </div>
                                            }
                                            selectedId={getSelectedId}
                                            placeholder="Search"
                                            header={true}
                                            search={true}
                                        />
                                    </div>
                                }
                            </div>
                        </AccordionTab> */}
                    </Accordion>
                </div>
                {
                    displayDesignation &&
                    <Dialog visible={displayDesignation} header="Designation" onHide={() => onHide('displayDesignation')} footer={renderAssLeaveCancel()} breakpoints={{ '960px': '75vw' }} draggable={false}>
                        <div className="cancel-request">
                            <div className="p-grid">
                                <div className="p-col-12 designationText">
                                    <p className="font-normal fs-13 text-lightgray mt-0">Designation <span className="text-red">*</span></p>
                                    <TextboxElement label="Designation" value={desData} placeholder="Designation" onChangeText={(e) => setDesdata(e.target.value)}></TextboxElement>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                }
                {
                    displayDepartment &&
                    <Dialog visible={displayDepartment} header="Department" onHide={() => onHide('displayDepartment')} footer={renderDepartment()} breakpoints={{ '960px': '75vw' }} draggable={false}>
                        <div className="cancel-request">
                            <div className="p-grid">
                                <div className="p-col-12 designationText">
                                    <p className="font-normal fs-13 text-lightgray mt-0">Department <span className="text-red">*</span></p>
                                    <TextboxElement label="Department" value={depData} placeholder="Department" onChangeText={(e) => setDepdata(e.target.value)}></TextboxElement>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                }
                {
                    displayGrade &&
                    <Dialog visible={displayGrade} header="Grade" onHide={() => onHide('displayGrade')} footer={renderGrade()} breakpoints={{ '960px': '75vw' }} draggable={false}>
                        <div className="cancel-request">
                            <div className="p-grid d-flex" >
                                <div className="p-col-12  p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray mt-0">Band<span className="text-red">*</span></p>
                                    <TextboxElement label="Band" value={bandData} placeholder="Band" onChangeText={(e) => setBanddata(e.target.value)}></TextboxElement>
                                </div>
                                <div className="p-col-12  p-md-6 p-sm-6">
                                    <p className="font-normal fs-13 text-lightgray mt-0">Grade<span className="text-red">*</span></p>
                                    <TextboxElement label="Grade" value={graData} placeholder="Grade" onChangeText={(e) => setGradata(e.target.value)}></TextboxElement>
                                </div>
                                <div className="p-col-12">
                                    <p className="font-normal fs-13 text-lightgray mt-0">Description <span className="text-red">*</span></p>
                                    <TextboxElement label="Description" value={descriptionData} placeholder="Description" onChangeText={(e) => setDescriptionData(e.target.value)}></TextboxElement>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                }
                {
                    displayEmployee &&
                    <Dialog visible={displayEmployee} header="Employee Status" onHide={() => onHide('displayEmployee')} footer={renderEmployee()} breakpoints={{ '960px': '75vw' }} draggable={false}>
                        <div className="cancel-request">
                            <div className="p-grid">
                                <div className="p-col-12  p-md-6 p-sm-6 ">
                                    <p className="font-normal fs-13 text-lightgray mt-0">Status <span className="text-red">*</span></p>
                                    <TextboxElement label="Status" value={employeeStatus} placeholder="Status" onChangeText={(e) => setEmployeeStatus(e.target.value)}></TextboxElement>
                                </div>
                                <div className="p-col-12  p-md-6 p-sm-6 ">
                                    <p className="font-normal fs-13 text-lightgray mt-0">LeaveLimit <span className="text-red">*</span></p>
                                    <TextboxElement label="Leave Limit" value={leaveLimit} placeholder="Leave Limit" onChangeText={(e) => setLeaveLimit(e.target.value)}></TextboxElement>
                                </div>
                                <div className="p-col-12 p-md-6 p-sm-6">
                                    <div className='d-flex align-items-center'>
                                        <div className="font-normal fs-13 text-lightgray p-mt-0">Is Lock <span className="text-red">*</span></div>
                                        <div className='p-ml-2'>
                                            <CheckboxElement
                                                checked={lock}
                                                onChangeCheck={(e) => setLock(e.checked)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6 p-sm-6">
                                    <div className='d-flex  align-items-center'>
                                        <div className="font-normal fs-13 text-lightgray p-mt-0">Is Leave <span className="text-red">*</span></div>
                                        <div className='p-ml-2'>
                                            <CheckboxElement
                                                checked={leave}
                                                onChangeCheck={(e) => setLeave(e.checked)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                }
                {
                    //Metrics Modal Popup
                    displayMetrics &&
                    <Dialog visible={displayMetrics} header={edit ? "Edit Metrics" : "Add Metrics"} onHide={() => onHide('displayMetrics')} footer={renderMetrics('displayMetrics')} breakpoints={{ '960px': '75vw' }} draggable={false}>
                        <div className="performance-metrics">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-6 p-sm-6 ">
                                    <p className="font-normal fs-13 text-lightgray mt-0">Types
                                        <span className="text-red">*</span></p>
                                    <SingleSelectElement
                                        placeholder="Select Type"
                                        options={performanceMatrix}
                                        onChangeValue={(e) => setPerformanceType(e.value)}
                                        value={performanceType}
                                        optionLabel='name'
                                        filterBy='name'
                                    />
                                </div>
                                <div className="p-col-12 p-md-6 p-sm-6 ">
                                    <p className="font-normal fs-13 text-lightgray mt-0">Category
                                        <span className="text-red">*</span></p>
                                    <TextboxElement
                                        placeholder="Category"
                                        onChangeText={(e) => setPerformanceCategory(e.target.value)}
                                        value={performanceCategory}
                                        name="category"
                                    />
                                </div>
                                {
                                    performanceKraText.length > 0 && performanceKraText.map((x, i) => (
                                        <div className="p-col-12 p-md-12 p-sm-12" key={`${x}${i}`}>
                                            {
                                                i == 0 && <div className="font-normal fs-13 text-lightgray mt-0">KRA
                                                    <span className="text-red">*</span></div>
                                            }
                                            <div className='align-center'>
                                                <div className="p-col-12 p-md-10 p-sm-10 pl-0">
                                                    <TextboxElement
                                                        placeholder="KRA"
                                                        onChangeText={(e) => onClickKra(e, i)}
                                                        value={performanceKra[i]}
                                                        name="kra"
                                                    />
                                                </div>
                                                <div className="p-col-12 p-md-2 p-sm-2">
                                                    <div className='d-flex'>
                                                        <i className="pi pi-plus bu-plus-icon" onClick={() => onClickAddKra()}></i>
                                                        {
                                                            i ?
                                                                <i className="pi pi-minus bu-minus-icon" onClick={() => onClickRemoveKra(i)}></i>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}


                            </div>
                        </div>
                    </Dialog>
                }
            </div>
        </div>
    )

    /* Render View Return - End */

}

/* Function - End */

/* Export default functionName; */

export default ReferenceData;