/* Import Section - Start */

/* React Imports - Start */

import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'universal-cookie';
/* React Imports - End */

/* Project Components Imports - Start */

import CheckboxElement from '../uiComponents/CheckboxElement';
import ButtonElement from '../uiComponents/ButtonElement';
import RolesService from '../../services/controlPanel/RolesService';
import RoleModalPop from './RoleModalPop';
import ToastElement from "../uiComponents/ToastElement";
import SingleSelectElement from "../uiComponents/SingleSelectElement";
import Loader from '../uiComponents/Loader';


/* Project Components Imports - End */

/* Plugin Imports - Start */

import _ from 'lodash';

/* Plugin Imports - End */

/* Import Section - End */

/* Function - Start */

const RolesConfig = () => {

    /* State Declaration - Start */

    /* useState - Start */

    const [roles, setRoles] = useState([]);
    const [editRole, setEditRole] = useState([])
    const [editText, setEditText] = useState()
    const [buUnit, setBuUnit] = useState([]);
    const [displayAddRoleModal, setDisplayAddRoleModal] = useState(false);
    const [displayEditRoleModal, setDisplayEditRoleModal] = useState(false);
    const [editRoleId, setEditRoleId] = useState()
    const [currentBu, setCurrentBU] = useState("JMD2")
    const [success,setSuccess] = useState('')
    const [loading,setLoading] = useState(false);

    /* useState - End */

    /* useRef - Start */

    const toastRef = useRef();
    const cookies = new Cookies();

    /* useRef - Start */

    /* useEffect - End */

    useEffect(() => {
        getActiveBu();
    }, [success])

    /* useEffect - Start */

    /* State Declaration - End */

    /* Methods - Start */

    const dialogFuncMap = {
        'displayAddRoleModal': setDisplayAddRoleModal,
        'displayEditRoleModal': setDisplayEditRoleModal
    }

    //Opening Add role modal pop up 
    const onClickAddRole = (name) => {
        dialogFuncMap[`${name}`](true);
    }

    //Opening Edit role modal pop up and passing the values of permission for the particular role to the popup
    const onClickEditRole = (name,id) => {
        setLoading(true);
        RolesService.editRole(id).then(data => {
            setLoading(false);
            setEditRole(data.data.accessPermission)
            setEditText(data.data.role)
            setEditRoleId(id)
            dialogFuncMap[`${name}`](true);
        })
    }


    //mapping the add role and edit role model pop up for hiding 
    const onHide = (name, message, success) => {
        dialogFuncMap[`${name}`](false);
        message && toastRef.current.showToast(message)
        if (success) {
            setSuccess(success)
            getRoles(currentBu.id)
        }
    }

    //getting roles for the respective bu when selecting from the select button
    const getRoles = (buId) => {
        setLoading(true);
        RolesService.getRoles(buId).then(data => {
            setLoading(false);
            let rolesData = data.data;
            rolesData.sort(function(a,b) {
				var a1 = a.role.toLowerCase();
				var b1 = b.role.toLowerCase();
				return a1<b1 ?-1:a1> b1? 1 :0;
			}); 
            setRoles(rolesData)
        })

    }
    //getting active bu for rendering in the select element
    const getActiveBu = () => {
        RolesService.getActiveBu().then(data => {

            setBuUnit(data.data)
            var bu = cookies.get('buId')
            if (bu) {
                setCurrentBU(_.find(data.data, { id: bu }))
                getRoles(bu)
            }
        })
    }

    //Handling change bu from the top select element
    const buChange = (e) => {
        getRoles(e.value.id)
        setCurrentBU(e.value)
    }


    // Handling role active Inactive check in role page
    const checkboxValueEdit = (e,roleId) => {
        setLoading(true);
        const data = {
            buId: currentBu.id,
            id: roleId,
            isActive: e.checked
        }
        RolesService.updateRoleToggle(data).then(data => {
            setLoading(false);
            !data.patched.success && toastRef.current.showToast({
                type: 'error',
                summary: 'Update failed',
                message: data.patched.message
            })
            data.patched.success && toastRef.current.showToast({
                type: 'success',
                summary: 'Updated sucessfully',
                message: data.patched.message
            })
            getRoles(currentBu.id)
        })

    }
    /* Methods - End */

    /* Render View Return - Start */
    return (
        <div>
            <ToastElement ref={toastRef} />
            <Loader loading={loading}/>
            {/* Role configuration page */}
            <div className="p-grid">
                <div className="p-d-flex p-col-12 button-div">
                    <div className=" mr-20">
                        <SingleSelectElement
                            placeholder="Select BU"
                            value={currentBu}
                            options={buUnit}
                            optionLabel="name"
                            onChangeValue={(e) => buChange(e)}
                            defaultValue="code"
                            filterBy='name'
                        />
                    </div>
                    <ButtonElement
                        label="Add Role"
                        icon="pi pi-plus"
                        iconPos="left"
                        className="addRoles-button"
                        onClickButton={() => onClickAddRole('displayAddRoleModal')}
                    />
                </div>
                <div className="roles-div">
                    {
                        roles.length > 0 && roles.map((role) => (
                            <div className="flex-items relative">
                                <CheckboxElement
                                    key={role.id}
                                    inputId={role.id}
                                    value={role.role}
                                    checked={role.isActive}
                                    onChangeCheck={(e, roleId) => checkboxValueEdit(e, roleId)}
                                />
                                <span onClick={() => 
                                    onClickEditRole('displayEditRoleModal', role.id)}><i className="pi pi-pencil roles-edit"></i></span>
                            </div>
                        ))
                    }
                </div>
            </div>
            {/* displaying modal pop up for adding role */}
            {
                displayAddRoleModal &&
                <RoleModalPop
                    visible={displayAddRoleModal}
                    currentBu={currentBu.id}
                    currentBuName={currentBu.name}
                    header="Add Role"
                    buReload={(id) => getRoles(id)}
                    onHide={(message, success) => onHide('displayAddRoleModal', message, success)} 
                />
            }
            {/* displaying modal pop up for editing role */}
            {
                displayEditRoleModal &&
                <RoleModalPop
                    visible={displayEditRoleModal}
                    checkedValues={editRole}
                    editText={editText}
                    currentBu={currentBu}
                    currentBuName={currentBu.name}
                    editRoleId={editRoleId}
                    header="Edit Role"
                    buReload={(id) => getRoles(id)}
                    onHide={(message, success) => onHide('displayEditRoleModal', message, success)} 
                />
            }
        </div>
    )
    /* Render View Return - End */
}
/* Function - End */

/* Export default functionName; */

export default RolesConfig;