/* Import Section - Start */

/* React Imports - Start */

import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';

/* React Imports - End */

/* Plugin Imports - Start */

import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import Cookies from 'universal-cookie';
import _ from 'lodash';
import { Dialog } from 'primereact/dialog';

/* Plugin Imports - End */

/* Project Components Imports - Start */

import TextboxElement from '../uiComponents/TextboxElement';
import RadioElement from "../uiComponents/RadioElement";
import LeaveConfigService from '../../services/controlPanel/LeaveConfigService';
import Loader from '../uiComponents/Loader';
import ToastElement from "../uiComponents/ToastElement";
import DataTableElement from "../uiComponents/DataTableElement";

/* Project Components Imports - Start */

/* Import Section - End */

/* Function - Start */

const LeaveConfigNew = (props) => {

    /* State Declaration - Start */

    const cookies = new Cookies();
    const [loading, setLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    const [leaveTypeName, setLeaveTypeName] = useState(props.editLeaveTypeData ? props.editLeaveTypeData.leaveTypeName : "");
    const [balanceType, setBalanceType] = useState(props.editLeaveTypeData ? props.editLeaveTypeData.balanceType : "");
    const [isPaid, setIsPaid] = useState(props.editLeaveTypeData ? props.editLeaveTypeData.isPaid ? 'Yes' : 'No' : false);
    const [isAdvanceRequest, setIsAdvanceRequest] = useState(props.editLeaveTypeData ? props.editLeaveTypeData.isAdvanceRequest ? 'Yes' : 'No' : false);
    const [requestExpiresDays, setRequestExpiresDays] = useState(props.editLeaveTypeData ? props.editLeaveTypeData.requestExpiresDays : 0);
    const [startingBalance, setStartingBalance] = useState(props.editLeaveTypeData ? props.editLeaveTypeData.startingBalance : "");
    const [refreshFrequency, setRefreshFrequency] = useState(props.editLeaveTypeData ? props.editLeaveTypeData.refreshFrequency : "");
    const [isExperienceBenefit, setIsExperienceBenefit] = useState(props.editLeaveTypeData ? props.editLeaveTypeData.isExperienceBenefit ? 'Yes' : 'No' : false);
    const [noOfDays, setNoOfDays] = useState(props.editLeaveTypeData ? props.editLeaveTypeData.noOfDays : 0);
    const [carryForwardLimit, setCarryForwardLimit] = useState(props.editLeaveTypeData ? props.editLeaveTypeData.carryForwardLimit : 0);
    const [isProbationLimited, setIsProbationLimited] = useState(props.editLeaveTypeData ? props.editLeaveTypeData.isProbationLimited : "");
    const [leaveConfigValues, setLeaveConfigValues] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [experienceRange, setExperienceRange] = useState(props.editLeaveTypeData ? props.editLeaveTypeData.experienceRange.length > 0 ? props.editLeaveTypeData.experienceRange : [{ startMonth: null, endMonth: null, days: null }] : [{ startMonth: null, endMonth: null, days: null }]);
    const [hoursPerDayLimit, setHoursPerDayLimit] = useState(props.editLeaveTypeData ? props.editLeaveTypeData.hoursPerDayLimit : 0);
    const [isPrimary, setIsPrimary] = useState(props.editLeaveTypeData ? props.editLeaveTypeData.isPrimary?"Yes":"No":false)

    /* useRef - Start */

    const toastRef = useRef();
    var currentBu = cookies.get('buId');

    /* useRef - End */

    /* useEffect - Start */

    useEffect(() => {
        getLeaveConfigValues()
    }, [])

    /* useEffect - End */

    /* State Declaration - End */

    /* Static JSON - Start */

    const leaveConfigData = [
        { title: 'Leave Information' },
        { title: 'Refresh Information' },
        { title: 'Additional Information' }
    ]

    const paidLeaves = [
        { key: "1", name: "Yes" },
        { key: "2", name: "No" }
    ]

    const advanceRequestOptions = [
        { key: "3", name: "Yes" },
        { key: "4", name: "No" }
    ]

    const noOfDaysExperienceOptions = [
        { key: "5", name: "Yes" },
        { key: "6", name: "No" }
    ]

    const monthlyAccrualOptions = [
        { key: "7", name: "Yes" },
        { key: "8", name: "No" }
    ]

    const probationLimitedOptions = [
        { key: "9", name: "None" },
        { key: "10", name: "Monthly Cap" },
        { key: "11", name: "Monthly Accrual" }
    ]

    const balanceTypes = [
        { key: "12", name: "Limited" },
        { key: "13", name: "Unlimited" }
    ]

    const refreshFrequencies = [
        { key: "14", name: "Monthly" },
        { key: "15", name: "Yearly" },
        { key: "16", name: "Half Yearly"}, //New WFH
        { key: "17", name: "Biennial"} //For FTO
    ]

    const startingBalances = [
        { key: "18", name: "0" },
        { key: "19", name: "Proportional to start date" }
    ]

    const isPrimaryOptions = [
        { key: "20", name: "Yes" },
        { key: "21", name: "No" }
    ]

    const defaultValues = {
        leaveTypeName: leaveTypeName,
        balanceType: balanceType,
        isPaid: isPaid,
        isAdvanceRequest: isAdvanceRequest,
        requestExpiresDays: requestExpiresDays,
        startingBalance: startingBalance,
        refreshFrequency: refreshFrequency,
        noOfDays: noOfDays,
        carryForwardLimit: carryForwardLimit,
        isExperienceBenefit: isExperienceBenefit,
        experienceRange: experienceRange,
        isProbationLimited: isProbationLimited,
        hoursPerDayLimit: hoursPerDayLimit,
        isPrimary: isPrimary
    }

    const { control, formState: { errors }, handleSubmit, watch, setValue, getValues } = useForm({ defaultValues });

    const no_of_days_experience = watch("isExperienceBenefit");

    useEffect(() => {
        if (leaveConfigValues) {
            setExpandedRows(leaveConfigValues)
        }
    }, [leaveConfigValues])

    useEffect(() => {
        if (no_of_days_experience === 'Yes') {
            setShowTable(true)
        } else {
            setShowTable(false)
        }
    }, [no_of_days_experience])

    /* Static JSON - End */

    /* Methods - Start */

    const getLeaveConfigValues = () => {
        setLeaveConfigValues(leaveConfigData);
    }

    // To Add empty row to Experience section when clicking '+'
    const handleAddClick = () => {
        const item = { startMonth: "", endMonth: "", days: "" };
        setExperienceRange([...experienceRange, item]);
    };

    // To Remove specific row data from Experience section when clicking '-'
    const handleRemoveClick = (i) => {
        const list = [...experienceRange];
        list.splice(i, 1);
        setExperienceRange(list);
        const val = getValues('experienceRange')
        val.splice(i, 1)
        setValue('experienceRange', val, { shouldValidate: true })
    };

    const savebutton = (FormData) => {
        setLoading(true);
        var configData = {
            leaveTypeName: FormData.leaveTypeName,
            balanceType: FormData.balanceType,
            isPaid: FormData.isPaid === 'Yes' ? true : false,
            isAdvanceRequest: FormData.isAdvanceRequest === 'Yes' ? true : false,
            requestExpiresDays: FormData.requestExpiresDays === "" ? null : FormData.requestExpiresDays,
            startingBalance: FormData.startingBalance,
            refreshFrequency: FormData.refreshFrequency,
            noOfDays: FormData.noOfDays,
            carryForwardLimit: FormData.carryForwardLimit,
            isExperienceBenefit: FormData.isExperienceBenefit === 'Yes' ? true : false,
            experienceRange: FormData.experienceRange,
            isProbationLimited: FormData.isProbationLimited,
            buId: props.currentBuName.id,
            hoursPerDayLimit: FormData.hoursPerDayLimit,
            isPrimary: FormData.isPrimary === 'Yes' ? true : false,
        }

        LeaveConfigService.addLeaveType(configData).then(leaveData => {
            setLoading(false);
            let message = {
                type: leaveData.posted.success ? 'success' : 'error',
                summary: leaveData.posted.success ? 'Success' : 'Something went wrong',
                message: leaveData.posted.message
            }
            let leaveSuccess = leaveData.posted.success ? true : false
            if (leaveData.posted.success) {
                props.onHide(message, leaveSuccess)
            } else {
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Warning',
                    message: leaveData.posted.message
                });
            }
        });
    }

    const updateButton = (FormData) => {
        setLoading(true);
        var configData = {
            leaveTypeName: FormData.leaveTypeName,
            balanceType: FormData.balanceType,
            isPaid: FormData.isPaid === 'Yes' ? true : false,
            isAdvanceRequest: FormData.isAdvanceRequest === 'Yes' ? true : false,
            requestExpiresDays: FormData.requestExpiresDays === "" ? null : FormData.requestExpiresDays,
            startingBalance: FormData.startingBalance,
            refreshFrequency: FormData.refreshFrequency,
            noOfDays: FormData.noOfDays,
            carryForwardLimit: FormData.carryForwardLimit,
            isExperienceBenefit: FormData.isExperienceBenefit === 'Yes' ? true : false,
            experienceRange: FormData.experienceRange,
            isProbationLimited: FormData.isProbationLimited,
            buId: props.currentBuName.id,
            isActive: true,
            hoursPerDayLimit: FormData.hoursPerDayLimit,
            isPrimary: FormData.isPrimary === 'Yes' ? true : false,
        }

        LeaveConfigService.updateLeaveType(props.editLeaveTypeData.id, configData).then(leaveData => {
            setLoading(false);
            let message = {
                type: leaveData.patched.success ? 'success' : 'error',
                summary: leaveData.patched.success ? 'Success' : 'Something went wrong',
                message: leaveData.patched.message
            }
            let leaveSuccess = leaveData.patched.success ? true : false
            if (leaveData.patched.success) {
                props.onHide(message, leaveSuccess)
            } else {
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Warning',
                    message: leaveData.patched.message
                })
            }
        })
    }

    /* Methods - End */

    /* Render Templates - Start */

    const renderFooter = () => {
        return (
            <div style={{ height: "40px" }}></div>
        );
    }

    const rowExpansionTemplate = (data) => {
        if (data.title === 'Leave Information') {
            return (
                <div className='project-tabview'>
                    <div className="tabview-demo">
                        <div className='card project-tab-content tabs'>
                            <div className='project-creation'>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-4 p-md-6 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0">Leave Name <span className="text-red">*</span></p>
                                        <div className="p-field">
                                            <Controller name="leaveTypeName" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                                <TextboxElement
                                                    id={field.name}
                                                    value={field.value}
                                                    placeholder="Enter"
                                                    onChangeText={field.onChange}
                                                    className={classNames({ 'p-invalid': fieldState.invalid })}
                                                />
                                            )} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-lg-4 p-md-6 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0">Balance Type <span className="text-red">*</span></p>
                                        <div className="p-field">
                                            <Controller name="balanceType" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                                <div>
                                                    <RadioElement
                                                        id={field.name}
                                                        options={balanceTypes}
                                                        value={field.value}
                                                        onChangeRadio={field.onChange}
                                                        className={classNames({ 'p-invalid': fieldState.invalid }, 'group-radio')}
                                                    />
                                                </div>
                                            )} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-lg-4 p-md-6 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0">Paid Leave? <span className="text-red">*</span></p>
                                        <div className="p-field">
                                            <Controller name="isPaid" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                                <RadioElement
                                                    id={field.name}
                                                    options={paidLeaves}
                                                    value={field.value}
                                                    onChangeRadio={field.onChange}
                                                    className={classNames({ 'p-invalid': fieldState.invalid }, 'group-radio')}
                                                />
                                            )} />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-4 p-md-6 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0">Advance request?
                                            {watch(`balanceType`) === "Limited" && (
                                                <span className="text-red">*</span>
                                            )}
                                        </p>
                                        <div className="p-field">
                                            <Controller name="isAdvanceRequest" control={control} rules={{ required: watch("balanceType") === "Limited" ? true : false }} render={({ field, fieldState }) => (
                                                <div>
                                                    <RadioElement
                                                        id={field.name}
                                                        options={advanceRequestOptions}
                                                        value={field.value}
                                                        onChangeRadio={field.onChange}
                                                        className={classNames({ 'p-invalid': fieldState.invalid }, 'group-radio')}
                                                        disabled={watch("balanceType") === "Limited" ? false : true}
                                                    />
                                                </div>
                                            )} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-lg-4 p-md-6 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0">Request expires after (days)
                                            {watch(`isAdvanceRequest`) === "Yes" && (
                                                <span className="text-red">*</span>
                                            )}
                                        </p>
                                        <div className="p-field">
                                            <Controller name="requestExpiresDays" control={control} rules={{ required: watch("isAdvanceRequest") === "Yes" ? true : false }} render={({ field, fieldState }) => (
                                                <TextboxElement
                                                    id={field.name}
                                                    value={field.value}
                                                    placeholder="Enter"
                                                    onChangeText={field.onChange}
                                                    className={classNames({ 'p-invalid': fieldState.invalid })}
                                                    disabled={watch("isAdvanceRequest") === "Yes" ? false : true}
                                                />
                                            )} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-lg-4 p-md-6 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0">Starting balance
                                            {watch(`balanceType`) === "Limited" && (
                                                <span className="text-red">*</span>
                                            )}
                                        </p>
                                        <div className="p-field">
                                            <Controller name="startingBalance" control={control} rules={{ required: watch("balanceType") === "Limited" ? true : false }} render={({ field, fieldState }) => (
                                                <RadioElement
                                                    id={field.name}
                                                    options={startingBalances}
                                                    value={field.value}
                                                    onChangeRadio={field.onChange}
                                                    className={classNames({ 'p-invalid': fieldState.invalid }, 'group-radio')}
                                                    disabled={watch("balanceType") === "Limited" ? false : true}
                                                />
                                            )} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-lg-4 p-md-6 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0">Is Primary?</p>
                                        <div className="p-field">
                                            <Controller name="isPrimary" control={control} render={({ field, fieldState }) => (
                                                <>
                                                <RadioElement
                                                    id={field.name}
                                                    options={isPrimaryOptions}
                                                    value={field.value}
                                                    onChangeRadio={field.onChange}
                                                    className={classNames({ 'p-invalid': fieldState.invalid }, 'group-radio')}
                                                    disabled={isPrimary === "Yes" ? true : false}
                                                />
                                                </>
                                            )} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (data.title === 'Refresh Information') {
            return (
                <div className='project-tabview'>
                    <div className="tabview-demo">
                        <div className='card project-tab-content tabs'>
                            <div className='project-creation'>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-4 p-md-6 p-sm-6">
                                        <div className="p-field">
                                            <p className="font-normal fs-13 text-lightgray mt-0">Refresh Frequency
                                                {watch(`balanceType`) === "Limited" && watch('isAdvanceRequest') === 'No' && (
                                                    <span className="text-red">*</span>
                                                )}
                                            </p>
                                            <Controller name="refreshFrequency" control={control} rules={{ required: watch("balanceType") === "Limited" && watch('isAdvanceRequest') === 'No' ? true : false }} render={({ field, fieldState }) => (
                                                <div>
                                                    <RadioElement
                                                        id={field.name}
                                                        options={refreshFrequencies}
                                                        value={field.value}
                                                        onChangeRadio={field.onChange}
                                                        className={classNames({ 'p-invalid': fieldState.invalid }, 'group-radio')}
                                                        disabled={watch("balanceType") === "Limited" && watch('isAdvanceRequest') === 'No' ? false : true}
                                                    />
                                                </div>
                                            )} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-lg-4 p-md-6 p-sm-6">
                                        <div className="p-field">
                                            <p className="font-normal fs-13 text-lightgray mt-0">Number of days
                                                {watch(`balanceType`) === "Limited" && watch('isAdvanceRequest') === 'No' && (
                                                    <span className="text-red">*</span>
                                                )}
                                            </p>
                                            <Controller name="noOfDays" control={control} rules={{ required: watch("balanceType") === "Limited" && watch('isAdvanceRequest') === 'No' ? true : false }} render={({ field, fieldState }) => (
                                                <div>
                                                    <TextboxElement
                                                        id={field.name}
                                                        value={field.value}
                                                        placeholder="Enter"
                                                        onChangeText={field.onChange}
                                                        className={classNames({ 'p-invalid': fieldState.invalid })}
                                                        disabled={watch("balanceType") === "Limited" && watch('isAdvanceRequest') === 'No' ? false : true}
                                                    />
                                                </div>
                                            )} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-lg-4 p-md-6 p-sm-6">
                                        <div className="p-field">
                                            <p className="font-normal fs-13 text-lightgray mt-0">Carry forward limit
                                                {watch(`balanceType`) === "Limited" && watch('isAdvanceRequest') === 'No' && (
                                                    <span className="text-red">*</span>
                                                )}
                                            </p>
                                            <Controller name="carryForwardLimit" control={control} rules={{ required: watch("balanceType") === "Limited" && watch('isAdvanceRequest') === 'No' ? true : false }} render={({ field, fieldState }) => (
                                                <div>
                                                    <TextboxElement
                                                        id={field.name}
                                                        value={field.value}
                                                        placeholder="Enter"
                                                        onChangeText={field.onChange}
                                                        className={classNames({ 'p-invalid': fieldState.invalid })}
                                                        disabled={watch("balanceType") === "Limited" && watch('isAdvanceRequest') === 'No' ? false : true}
                                                    />
                                                </div>
                                            )} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (data.title === 'Additional Information') {
            return (
                <div className='project-tabview'>
                    <div className="tabview-demo">
                        <div className='card project-tab-content tabs'>
                            <div className='project-creation'>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-4 p-md-6 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0">Probation limited?
                                            {watch(`balanceType`) === "Limited" && watch('isAdvanceRequest') === 'No' && (
                                                <span className="text-red">*</span>
                                            )}
                                        </p>
                                        <div className="p-field">
                                            <Controller name="isProbationLimited" control={control} rules={{ required: watch("balanceType") === "Limited" && watch('isAdvanceRequest') === 'No' ? true : false }} render={({ field, fieldState }) => (
                                                <RadioElement
                                                    id={field.name}
                                                    options={probationLimitedOptions}
                                                    value={field.value}
                                                    onChangeRadio={field.onChange}
                                                    className={classNames({ 'p-invalid': fieldState.invalid }, 'group-radio')}
                                                    disabled={watch("balanceType") === "Limited" && watch('isAdvanceRequest') === 'No' ? false : true}
                                                />
                                            )} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-lg-4 p-md-6 p-sm-6">
                                        <p className="font-normal fs-13 text-lightgray mt-0">Monthly experience benefit?
                                            {watch(`balanceType`) === "Limited" && watch('isAdvanceRequest') === 'No' && (
                                                <span className="text-red">*</span>
                                            )}
                                        </p>
                                        <div className="p-field">
                                            <Controller name="isExperienceBenefit" control={control} rules={{ required: watch("balanceType") === "Limited" && watch('isAdvanceRequest') === 'No' ? true : false }} render={({ field, fieldState }) => (
                                                <RadioElement
                                                    id={field.name}
                                                    options={noOfDaysExperienceOptions}
                                                    value={field.value}
                                                    onChangeRadio={field.onChange}
                                                    className={classNames({ 'p-invalid': fieldState.invalid }, 'group-radio')}
                                                    disabled={watch("balanceType") === "Limited" && watch('isAdvanceRequest') === 'No' ? false : true}
                                                />
                                            )} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-lg-4 p-md-6 p-sm-6">
                                        {
                                            showTable &&

                                            <div className="p-field">
                                                <p className="font-normal fs-13 text-lightgray mt-0">Experience benefit <span className="text-red">*</span></p>

                                                {experienceRange.map((x, i) => (
                                                    <div key={i} className='experience-values'>
                                                        <div className='p-col-3 group-div experience-group'>
                                                            {!i ? <p className="leaveConfig-container-p">Start Month </p> : null}

                                                            <Controller name={`experienceRange.${i}.startMonth`} defaultValue={x.startMonth} control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                                                <TextboxElement name={field.name} id={field.id} value={field.value} placeholder="Start" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                                            )} />
                                                        </div>
                                                        <div className='p-col-3 group-div experience-group'>
                                                            {!i ? <p className="leaveConfig-container-p">End Month </p> : null}

                                                            <Controller name={`experienceRange.${i}.endMonth`} defaultValue={x.endMonth} control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                                                <TextboxElement name={field.name} id={field.id} value={field.value} placeholder="End" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                                            )} />
                                                        </div>
                                                        <div className='p-col-4 group-div experience-group'>
                                                            {!i ? <p className="leaveConfig-container-p">No. of days / month</p> : null}

                                                            <Controller name={`experienceRange.${i}.days`} defaultValue={x.days} control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                                                <TextboxElement name={field.name} id={field.id} value={field.value} placeholder="Days" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                                            )} />
                                                        </div>
                                                        <div className='p-col-2 group-div experience-group'>
                                                            <i className="pi pi-plus bu-plus-icon" onClick={() => handleAddClick()}></i>
                                                            {i ?
                                                                <i className="pi pi-minus bu-minus-icon" onClick={() => handleRemoveClick(i)}></i> : null
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-4 p-md-6 p-sm-6">
                                    {/* <p className="font-normal fs-13 text-lightgray mt-0">Hours per day limit </p>  */}
                                        {props.editLeaveTypeData?.leaveTypeName==='WFH'?<p className="font-normal fs-13 text-lightgray mt-0">Monthly Limit</p>:<p className="font-normal fs-13 text-lightgray mt-0">Days limit </p>}
                                        <Controller name="hoursPerDayLimit" control={control} rules={{ required: watch("balanceType") === "Limited" ? true : false }} render={({ field, fieldState }) => (
                                            <div>
                                                <TextboxElement
                                                    id={field.name}
                                                    value={field.value}
                                                    placeholder="Enter"
                                                    onChangeText={field.onChange}
                                                    className={classNames({ 'p-invalid': fieldState.invalid })}
                                                    disabled={watch("balanceType") === "Limited" ? false : true}
                                                />
                                            </div>
                                        )} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const leaveConfigValuesTemplate = (data) => {
        return <div className="pl-10">
            {data.title}
        </div>
    }

    const leaveConfigColumns = [
        { field: "title", header: "Leave Config", body: leaveConfigValuesTemplate },
        { field: "expander", header: "" }
    ];

    /* Render Templates - End */

    /* Render View Return - Start */

    return (
        <div>
            <ToastElement ref={toastRef} />
            <Loader loading={loading} />
            <div className="dialog-demo">
                <div className="card addRole">
                    <Dialog header={props.header} id='leaveConfigDialogView' className="leaveConfig-dialog" visible={props.visible} onHide={() => props.onHide('', true)} stylebreakpoints={{ '960px': '75vw' }} footer={renderFooter()} resizable={false}>
                        <form onSubmit={props.header === 'Add Leave Type' ? handleSubmit(savebutton) : handleSubmit(updateButton)}>
                            <div className='project-create'>
                                <DataTableElement
                                    expand={true}
                                    columns={leaveConfigColumns}
                                    expandedRows={expandedRows}
                                    onRowToggle={(e) => setExpandedRows(e.data)}
                                    rowExpansionTemplate={rowExpansionTemplate}
                                    value={leaveConfigValues}
                                    search={false}
                                />
                            </div>
                            <div className='project-tab-footer-edit'>
                                {
                                    props.header === 'Add Leave Type' &&
                                    <Button type="submit" iconPos="right" label="Save" className="create-button" />
                                }
                                {
                                    props.header === 'Edit Leave Type' &&
                                    <Button type="submit" iconPos="right" label="Update" className="create-button" />
                                }
                            </div>
                        </form>

                    </Dialog>
                </div>
            </div>
        </div>
    );

    /* Render View Return - Start */

}

/* Function - End */

/* Export default functionName; */

export default LeaveConfigNew;