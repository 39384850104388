/*
* Util service for Date functions
*
*/

function getDifferenceInDays(date1, date2) {
	
	// var a = Moment(date1);
	// var b = Moment(date2);

	// return b.diff(a, 'days');

	/*---------------------*/

		// var t2 = date2.getTime();
        // var t1 = date1.getTime();
        // return Math.floor((t2-t1)/(24*3600*1000));

	/*---------------------*/
	const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
	const firstDate = new Date(date2);
	const secondDate = new Date(date1);
	
	const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
	return diffDays+1;
	
}

function getDifferenceInHours(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60 * 60);
}

function getDifferenceInMinutes(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60);
}

function getDifferenceInSeconds(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / 1000;
}

function getWeeksLastDateText() {
	const today = new Date();
	const weekDates = {};
	const options = { month: 'short', day: 'numeric' };

	for (let i = 0; i < 10; i++) {
		const startDate = new Date(today);
		startDate.setDate(today.getDate() + (i * 7) - today.getDay() + 1);
		const endDate = new Date(startDate);
		endDate.setDate(startDate.getDate() + 6);
		const startFormatted = startDate.toLocaleDateString('en-US', options).replace(',', '');
		const endFormatted = endDate.toLocaleDateString('en-US', options).replace(',', '');
		weekDates[i] = `${endFormatted}`;
	}
	return weekDates;
}

function getWeeksFirstDateText(){
	const today = new Date();
	const weekDates = {};
	const options = {month: 'short', day:'numeric'};
	for (let i=0; i<10; i++){
		const startDate = new Date(today);
		startDate.setDate(today.getDate() + (i * 7) - today.getDay() + 1);
		const endDate = new Date(startDate);
		endDate.setDate(startDate.getDate() + 6);
		const startFormatted = startDate.toLocaleDateString('en-US', options).replace(',', '');
		const endFormatted = endDate.toLocaleDateString('en-US', options).replace(',', '');
		weekDates[i] = `${startFormatted.replace(' ', '-')}`;
	}
	return weekDates;
}

function minsToTimeObj(duration) {
	var milliseconds = duration * 60000;
	var seconds = parseInt((milliseconds / 1000) % 60);
	var minutes = parseInt((milliseconds / (1000 * 60)) % 60);
	var hours = parseInt((milliseconds / (1000 * 60 * 60)) % 24);
	var days = parseInt((milliseconds / (24 * 60 * 60 * 1000)));

	if(days < 10){
		days = '0' + days;
	}
	if(hours < 10){
		hours = '0' + hours;
	}
	if(minutes < 10){
		minutes = '0' + minutes;
	}
	if(seconds < 10){
		seconds = '0' + seconds;
	}

	return {
		'days': days ? days: '00',
		'hours': hours ? hours: '00',
		'minutes': minutes ? minutes: '00',
		'seconds': seconds ? seconds: '00'
	}
}

function msToTimeObj(duration) {

	var seconds = parseInt((duration / 1000) % 60),
		minutes = parseInt((duration / (1000 * 60)) % 60),
		hours = parseInt((duration / (1000 * 60 * 60)) % 24),
		days = parseInt((duration / (24 * 60 * 60 * 1000)));

	if(days < 10){
		days = '0' + days;
	}
	if(hours < 10){
		hours = '0' + hours;
	}
	if(minutes < 10){
		minutes = '0' + minutes;
	}
	if(seconds < 10){
		seconds = '0' + seconds;
	}

	return {
		'days': days ? days: '00',
		'hours': hours ? hours: '00',
		'minutes': minutes ? minutes: '00',
		'seconds': seconds ? seconds: '00'
	}
}

export default { getDifferenceInDays, getDifferenceInHours, getDifferenceInMinutes, getDifferenceInSeconds, getWeeksLastDateText, getWeeksFirstDateText, minsToTimeObj, msToTimeObj };