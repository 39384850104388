import axios from 'axios';

let baseUrl = process.env.REACT_APP_API_URL;

async function getRoles(buId) {
	let response = await axios.get(`${baseUrl}/api/role/roleByBuId/${buId}`);
	return response.data;
}

async function getActiveBu() {
	let response = await axios.get(`${baseUrl}/api/business-unit?status=active`);
	return response.data;
}

async function getPermissions() {
	let response = await axios.get(`${baseUrl}/api/permission?status=active`);
	return response.data;
}

async function addRole(data) {
	let response = await axios.post(`${baseUrl}/api/role/`, data);
	return response.data;
}

async function editRole(id) {
	let response = await axios.get(`${baseUrl}/api/role/${id}`);
	return response.data;
}

async function updateRole(id, data) {
	let response = await axios.patch(`${baseUrl}/api/role/${id}`, data);
	return response.data;
}

async function updateRoleToggle(data) {
	let response = await axios.patch(`${baseUrl}/api/role/roleStatus/${data.id}`, data);
	return response.data;
}

export default { getRoles, getActiveBu, getPermissions, addRole, editRole, updateRole, updateRoleToggle };