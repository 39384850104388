/* React Imports - Start */

import React, { useState, useEffect, useRef } from "react";

/* React Imports - End */

/* Plugin Imports - Start */

import Cookies from 'universal-cookie';
import Moment from 'moment';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';

/* Plugin Imports - End */

/* Project Components Imports - Start */

import Loader from "../uiComponents/Loader";
import LeaveService from '../../services/leave/LeaveService';
import DataTableElement from "../uiComponents/DataTableElement";
import LeaveApprovalService from "../../services/approval/LeaveApprovalService";
import ToastElement from "../uiComponents/ToastElement";
import RequestModalElement from '../leave/RequestModal';
import AdminFilterElement from '../uiComponents/AdminFilters';
import EmployeesService from "../../services/employees/EmployeesService";

/* Project Components Imports - End */

/* Import Section - End */

/* Function - Start */

const BehalfApproval = (props) => {

    /* State Declaration - Start */

    /* useState - Start */

    const [leaveList, setLeaveList] = useState([]);
    const [editMyLeaveData, setEditMyLeaveData] = useState();
    const [displayEditLeaveRequestModal, setDisplayEditLeaveRequestModal] = useState(false);
    const [editMyLeaveType, setEditMyLeaveType] = useState();
    const [reportingManager, setReportingManager] = useState('');
    const [reportingManagerData, setReportingManagerData] = useState('');
    const [reporterUserId, setReporterUserId] = useState('');
    const [behalfOfCancelData, setBehalfOfCancelData] = useState();
    const [displayBehalfOfCancel, setDisplayBehalfOfCancel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [balanceLeaves, setBalanceLeaves] = useState('');
    const [reporteesList, setReporteesList] = useState([]);

    /* useState - End */

    /* useRef - Start */

    const toastRef = useRef();
    const cookies = new Cookies();

    /* useRef - End */

    /* useEffect - Start */

    useEffect(() => {
        getBehalfOf();
        getReporteesDetails();
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (reporterUserId) {
            getReportingManager();
        }
        //eslint-disable-next-line
    }, [reporterUserId])

    /* useEffect - End */

    /* State Declaration - End */

    /* Static JSON - Start */

    const statusList = [
        { name: 'APPROVED', value: 'APPROVED' },
        { name: 'REJECTED', value: 'REJECTED' },
        { name: 'CANCELLED', value: 'CANCELLED' },
        { name: 'REQUESTED', value: 'REQUESTED' },
    ];

    /* Static JSON - End */

    /* Methods - Start */

    const behalfFilter = (filter) => {
        var behalfOfUserId = cookies.get('userId');
        LeaveApprovalService.getBehalfOf(behalfOfUserId, filter).then(data => {
            setLeaveList(data.data);
        })
    }

    const getList = () => {
        getBehalfOf();
    }

    const getBehalfOf = () => {
        setLoading(true);
        const currentUserId = cookies.get('userId');
        LeaveApprovalService.getBehalfOfAllList(currentUserId).then(data => {
            setLoading(false);
            let orderedData = _.orderBy(data.data, ['updatedAt'], ['desc']);
            if (props.dashboard) {
                let dashboardOrderedData = [];
                orderedData.map(value => {
                    if (value.leaveStatus === "REQUESTED") {
                        dashboardOrderedData.push(value);
                    }
                })
                setLeaveList(dashboardOrderedData);
                props.behalfOffVerify(dashboardOrderedData.length > 0 ? true : false);
            } else {
                setLeaveList(orderedData);
            }
        })
    }

    const getReportingManager = () => {
        LeaveApprovalService.getReporterDetails(reporterUserId).then(data => {
            setReportingManager(data.data.firstName + ' ' + data.data.lastName)
        })
    }

    const dialogFuncMap = {
        'displayEditLeaveRequestModal': setDisplayEditLeaveRequestModal,
        'displayBehalfOfCancel': setDisplayBehalfOfCancel
    }

    const onClickBehalfEdit = (leaveId, leaveEditType, reportingUserId, userId) => {
        setReportingManagerData(reportingUserId)
        setEditMyLeaveType(leaveEditType);
        setReporterUserId(userId);
        LeaveApprovalService.getLeaveInfo(leaveId).then(data => {
            setEditMyLeaveData(data.data);
            dialogFuncMap['displayEditLeaveRequestModal'](true);
        })
    }

    const onClickBehalfCancel = (data) => {
        setBehalfOfCancelData(data);
        setDisplayBehalfOfCancel(true);
    }

    const behalfOfCancelHandler = () => {
        LeaveApprovalService.behalfOfCancel(behalfOfCancelData).then(data => {
            let message = {
                type: data.patched.success ? 'successss' : 'error',
                summary: data.patched.success ? 'Success' : 'Something went wrong',
                message: data.patched.message
            }
            let success = data.patched.success ? true : false
            onHide('displayBehalfOfCancel', message, success);
        })
    }

    const onHide = (name, message, success) => {
        if (name) {
            dialogFuncMap[`${name}`](false);
        }
        message && toastRef.current.showToast(message)
        if (success) {
            getBehalfOf();
        }
    }

    const getReporteesDetails = () => {
        const reportingUserId = cookies.get('userId');
        let roles = cookies.get("roles");
        let buId = cookies.get('buId');
        if (roles.includes('Admin')) {
            EmployeesService.getEmployees(buId).then(data => {
                let employeeList = [];
                data.data.rows.forEach(emp => {
                    // Exculded loggedIn User an DeActivated
                    if (emp.accountStatus == 'ACTIVATED' && emp.id != cookies.get('userId')) {
                        employeeList.push({
                            name: emp.firstName + ' ' + emp.lastName,
                            value: emp.id
                        });
                    }
                });
                var filteredData = _.filter(employeeList, (item) => item.name.toLowerCase() !== 'admin user');
                setReporteesList(filteredData);
            })
        } else {

            //New WFH
            LeaveService.getReporteesDetails(reportingUserId,false).then(data => {
                let userList = [];
                data.data.forEach(user => {
                    userList.push({
                        name: user.firstName + ' ' + user.lastName,
                        value: user.id
                    })
                });
                setReporteesList(userList);
            });
        }
    }

    /* Methods - End */

    /* Render Templates - Start */


    const nameTemplate = (rowData) => {
        if (rowData.userDetails?.firstName) {
            return (
                <div>
                    <p className="cell-name">{rowData.userDetails.firstName + " " + rowData.userDetails.lastName}</p>
                    <p className="cell-text">{Moment(rowData.createdAt).format('DD-MM-YYYY')}</p>
                </div>
            )
        }
    }

    const dayTemplate = (rowData) => {
        if (rowData.total <= 1) {
            return (
                <div>
                    <p className="cell-name">{rowData.total + ' Day'}</p>
                    <p className="cell-text">{rowData.from_date + ' To ' + rowData.to_date}</p>
                </div>
            )
        }
        else {
            return (
                <div>
                    <p className="cell-name">{rowData.total + ' days'}</p>
                    <p className="cell-text">{rowData.from_date + ' To ' + rowData.to_date}</p>
                </div>
            )
        }
    }

    const descriptionTemplate = (rowData) => {
        let capitalizeData = rowData.description?.substr(0, 1).toUpperCase() + rowData.description?.substr(1).toLowerCase();
        return (
            <div>
                <p className="cell-name">{rowData.ConfigLeaveType.leaveTypeName}</p>
                <Tooltip target=".taButton" />
                <p data-pr-tooltip={capitalizeData} data-pr-position="right" className="taButton cell-desc" > {capitalizeData.length > 25 ? capitalizeData.substring(0, 25) + '.....' : capitalizeData} </p>
            </div>
        )
    }

    const actionTemplate = (rowData) => {
        let data = {
            id: rowData.id,
            leaveStatus: rowData.leaveStatus
        }
        return (
            <div>
                {
                    rowData.leaveStatus === 'REQUESTED' ?
                        <span className="align-center icon">
                            <i className="pi pi-pencil" onClick={() => onClickBehalfEdit(rowData.id, 'behalfof', rowData.reportingUserId, rowData.userId)}></i>
                            <i className="pi pi-ban" onClick={() => onClickBehalfCancel(data)}></i>
                        </span> : <span></span>
                }
            </div>
        );
    };

    const behalfstatusTemplate = (rowData) => {
        let capitalizeStatus = rowData.leaveStatus.substr(0, 1).toUpperCase() + rowData.leaveStatus.substr(1).toLowerCase();
        return (
            <div>
                {
                    <div>
                        {rowData.leaveStatus === "APPROVED" ? <div className="status status-approved"> {rowData.leaveStatus.toLowerCase()}</div> : rowData.leaveStatus === "REQUESTED" ? <div className="status status-requested">{rowData.leaveStatus.toLowerCase()}</div> : rowData.leaveStatus === "REJECTED" ? <div className="status status-rejected">{rowData.leaveStatus.toLowerCase()}</div> : rowData.leaveStatus === "TERMINATED" ? <div className="status status-terminated">{rowData.leaveStatus.toLowerCase()}</div> : <div className="status status-cancelled">{rowData.leaveStatus.toLowerCase()}</div>}

                    </div>
                }
            </div>
        )
    }

    const columns = [
        { field: "userDetails.firstName", header: "Applied To", body: nameTemplate, sortable: true },
        { field: "from_date, to_date, total", header: "Days / Period", body: dayTemplate, sortable: true },
        { field: "LeaveType.name, description", header: "Type / Description", body: descriptionTemplate, sortable: true },
        // { field: "createdAt", header: "Applied On", body: appliedOnTemplate, sortable: true },
        { field: "leaveStatus", header: "Status", body: behalfstatusTemplate, sortable: true },
        { field: " ", header: "Actions", body: actionTemplate, sortable: false },
    ];

    const getSelectedId = () => {
        return ''
    }

    const renderBehalfOfCancel = () => {
        return (
            <div className="cancel-footer">
                <div className='align-center justify-end pb-0'>
                    <Button type="submit" label="Yes" className="create-button request-button" onClick={() => behalfOfCancelHandler()} />
                </div>
            </div>
        );
    }

    /* Render Templates - End */

    /* Render View Return - Start */

    return (
        <div>
            <ToastElement ref={toastRef} />
            <Loader loading={loading} />
            <div className="leave-approval mt-20">
                <DataTableElement
                    value={leaveList}
                    columns={columns}
                    selectedId={getSelectedId}
                    paginationView={false}
                    header={true}
                    search={false}
                    filterButton={props.dashboard ? null :
                        <div>
                            {
                                <div className="userFilter">
                                    <AdminFilterElement
                                        statusList={statusList}
                                        label='leaveStatus'
                                        filter={behalfFilter}
                                        getList={getList}
                                        userList={reporteesList}
                                    />
                                </div>
                            }
                        </div>
                    }
                />
            </div>
            {
                displayEditLeaveRequestModal &&
                <RequestModalElement visible={true} header="Edit Request" type='Update' leaveEditData={editMyLeaveData} leaveEditType={editMyLeaveType} onHide={() => onHide('displayEditLeaveRequestModal')} reportingManagerData={reportingManagerData} reportingManager={reportingManager} />
            }
            {
                displayBehalfOfCancel &&
                <Dialog visible={displayBehalfOfCancel} header="Confirmation" onHide={(message, success) => onHide('displayBehalfOfCancel', message, success)} footer={renderBehalfOfCancel('displayBehalfOfCancel')} breakpoints={{ '960px': '75vw' }} draggable={false}>
                    <div className="cancel-request">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <p className="font-normal fs-13 text-lightgray m-0">Are you sure to cancel this behalf of leave ?</p>
                            </div>
                        </div>
                    </div>
                </Dialog>
            }
        </div>
    )

    /* Render View Return - End */

}

/* Function - End */

/* Export default functionName; */

export default BehalfApproval;