/* Import Section - Start */

/* React Imports - Start */

import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

/* React Imports - End */

/* Plugin Imports - Start */

import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import _ from "lodash";
import { Calendar } from 'primereact/calendar';
import Moment from 'moment';
import Cookies from 'universal-cookie';

/* Plugin Imports - End */

/* Project Components Imports - Start */

import TextboxElement from "../uiComponents/TextboxElement";
import SingleSelectElement from "../uiComponents/SingleSelectElement";
import MultiSelectElement from "../uiComponents/MultiSelectElement";
import ToastElement from "../uiComponents/ToastElement";
import LeaveService from "../../services/leave/LeaveService";
import RadioElement from "../uiComponents/RadioElement";
import EmployeesService from "../../services/employees/EmployeesService";
import Loader from "../uiComponents/Loader";
import CalendarElement from "../uiComponents/CalendarElement";

/* Project Components Imports - End */

/* Import Section - End */

/* Function - Start */

/* Function - Start */

function EmployeeCreationPage(props) {
    const cookies = new Cookies();

    const [firstName, setFirstName] = useState(props.editData ? props.editData.firstName : "");
    const [lastName, setLastName] = useState(props.editData ? props.editData.lastName : "");
    const [email, setEmail] = useState(props.editData ? props.editData.email : "");
    const [mobileNumber, setMobileNumber] = useState(props.editData ? props.editData.mobileNumber : "");
     const [previousWorkExp, setPreviousExp] = useState(props.editData ? props.editData.previousWorkExp == 0 ? "0" : props.editData.previousWorkExp : "");
    const [dateOfJoin, setDateOfJoin] = useState(props.editData ? new Date(props.dateFormat) : "");
    const [genderDependent, setGenderDependent] = useState(props.editData ? props.editData.gender : "");
    const [buId, setBuId] = useState(props.editData ? props.editData.BusinessUnit : props.currentBu);
    const [global, setGlobal] = useState(props.editData ? props.editData.isGlobalUser ? "Yes" : "No" : false);
    const [code, setCode] = useState(props.editData ? props.editData.code : "");
    const [reportingUserId, setReportingUserId] = useState(props.editData ? props.editData.reportingUserId : "");
    const [statusId, setStatusId] = useState(props.editData ? props.editData.statusId : "");
    const [designationId, setDesignationId] = useState(props.editData ? props.editData.designationId : "");
    const [gradeId, setGradeId] = useState(props.editData ? props.editData.gradeId : "");
    const [departmentId, setDepartmentId] = useState(props.editData ? props.editData.departmentId : "");
    const [roles, setRoles] = useState(props.roleList ? props.roleList : []);
    const [userList, setUserList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [designationList, setDesignationList] = useState([]);
    const [gradeList, setGradeList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [buUnit, setBuUnit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(props.disabled ? props.disabled : false);

    /* useRef - Start */

    const toastRef = useRef();

    /* useRef - End */
    var curBuId = cookies.get('buId');

    //Function call on load
    useEffect(() => {
        getUserData(curBuId);
        getUserStatusData(curBuId);
        getUserDesignationData(curBuId);
        getUserGradeData(curBuId);
        getDepartmentByBuId(curBuId);
        getRoleByBuId(curBuId);
        getActiveBu();
    }, []);

   
    const gender = [{ key: "1", name: "Male" }, { key: "2", name: "Female" }]
    const globalEmployee = [{ key: "3", name: "Yes" }, { key: "4", name: "No" }]

    const defaultValues = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        mobileNumber: mobileNumber,
        dateOfJoin: dateOfJoin,
        genderDependent: genderDependent,
        buId: buId,
        global: global,
        code: code,
        reportingUserId: reportingUserId,
        statusId: statusId,
        designationId: designationId,
        gradeId: gradeId,
        departmentId: departmentId,
        roles: roles,
        previousWorkExp:previousWorkExp
    }

    /* navigate Declaration */

    let navigate = useNavigate();

    const { control, formState: { errors }, handleSubmit, watch, setValue } = useForm({ defaultValues });

    const bu_id = watch("buId");
    const contact_no = watch("mobileNumber");

    useEffect(() => {
        
		if(contact_no && contact_no.length > 10) {
			setValue('mobileNumber', `${contact_no.substr(0, 10)}`);
		}
    }, [contact_no])

    useEffect(() => {
        getUserData(bu_id?.id);
        getUserStatusData(bu_id?.id);
        getUserDesignationData(bu_id?.id);
        getUserGradeData(bu_id?.id);
        getDepartmentByBuId(bu_id?.id);
        getRoleByBuId(bu_id?.id);
        if (props.editData && props.editData.buId != bu_id?.id) {
            setValue("reportingUserId", "");
            setValue("statusId", "");
            setValue("designationId", "");
            setValue("gradeId", "");
            setValue("departmentId", "");
            setValue("roles", "");
        }
    }, [bu_id])


    /* Service Method Call - Start */

    // Reporting to field
    const getUserData = (curBuId) => {
        let data = {
            buId: curBuId,
            userId: cookies.get('userId')
        }
        EmployeesService.getEmployees(data.buId).then(data => {
            let userList = [];
            data?.data?.rows.forEach(user => {
                userList.push({
                    name: user.firstName + ' ' + user.lastName,
                    value: user.id
                })
            });
            setUserList(userList);
        });
    }

    // User Status field
    const getUserStatusData = (buId) => {
        EmployeesService.getUserStatusDataByBuId(buId).then(data => {
            let statusList = [];
            data?.data?.forEach(user => {
                statusList.push({
                    name: user.status,
                    value: user.id
                })
            });
            setStatusList(statusList);
        });
    }

    // User Designation field
    const getUserDesignationData = (buId) => {
        EmployeesService.getUserDesignationData(buId).then(data => {
            let designationList = [];
            data?.data?.forEach(user => {
                designationList.push({
                    name: user.designation,
                    value: user.id
                })
            });
            setDesignationList(designationList);
        });
    }

    // User Grade field
    const getUserGradeData = (buId) => {
        EmployeesService.listGradeByBuId(buId).then(data => {
            let gradeList = [];
            data?.data?.forEach(user => {
                gradeList.push({
                    name: user.grade,
                    value: user.id
                })
            });
            setGradeList(gradeList);
        });
    }

    // User Department field
    const getDepartmentByBuId = (buId) => {
        EmployeesService.getDepartmentByBuId(buId).then(data => {
            let departmentList = [];
            data?.data?.forEach(user => {
                departmentList.push({
                    name: user.department,
                    value: user.id
                })
            });
            setDepartmentList(departmentList);
        });
    }

    // User Role field
    const getRoleByBuId = (buId) => {
        EmployeesService.getRoleByBuId(buId).then(data => {
            let roleList = [];
            data?.data?.forEach(user => {
                if(user.isActive) {
                    roleList.push({
                        name: user.role,
                        value: user.id
                    })
                }
            });
            setRoleList(roleList);
        });
    }

    //getting active bu for rendering in the select element
    const getActiveBu = () => {
        EmployeesService.getActiveBu().then(data => {
            setBuUnit(data.data);
        })
    }

    /* Render Templates - Start */

    // Function for API hit - User Save
    const savebutton = (FormData) => {
        setLoading(true);
        var roleName = [];
        roleList.forEach(role => {
            return FormData.roles.forEach(data => {
                if (data == role.value) {
                    roleName.push(role.name);
                    return roleName;
                }
            })
        })
        var save = {
            firstName: FormData.firstName,
            lastName: FormData.lastName,
            email: FormData.email,
            mobileNumber: FormData.mobileNumber,
            dateOfJoin: Moment(FormData.dateOfJoin).format('YYYY-MM-DD'),
            gender: FormData.genderDependent,
            buId: FormData.buId.id,
            isGlobalUser: FormData.global == 'Yes' ? true : false,
            code: FormData.code,
            reportingUserId: FormData.reportingUserId,
            statusId: FormData.statusId,
            designationId: FormData.designationId,
            gradeId: FormData.gradeId,
            departmentId: FormData.departmentId,
            roles: roleName,
            previousWorkExp:FormData.previousWorkExp
        }
        EmployeesService.createUser(save).then(data => {
            setLoading(false);
            let createUser = true;
            let updateUser = false;
            props.onSuccess(data, createUser, updateUser);
        })
    }

    // Function for API hit - User Update
    const editButton = (FormData) => {
        setLoading(true);
        var roleName = [];
        roleList.forEach(role => {
            return FormData.roles.forEach(data => {
                if (data == role.value) {
                    roleName.push(role.name);
                    return roleName;
                }
            })
        })
        let edit = {
            id: props.editData.id,
            firstName: FormData.firstName,
            lastName: FormData.lastName,
            email: FormData.email,
            mobileNumber: FormData.mobileNumber,
            dateOfJoin: Moment(FormData.dateOfJoin).format('YYYY-MM-DD'),
            gender: FormData.genderDependent,
            buId: FormData.buId.id,
            isGlobalUser: FormData.global == 'Yes' ? true : false,
            code: FormData.code,
            reportingUserId: FormData.reportingUserId,
            statusId: FormData.statusId,
            designationId: FormData.designationId,
            gradeId: FormData.gradeId,
            departmentId: FormData.departmentId,
            roles: roleName,
            previousWorkExp:FormData.previousWorkExp
        }
        EmployeesService.updateUser(props.editData.id, edit).then(data => {
            setLoading(false);
            let createUser = false;
            let updateUser = true;
            props.onSuccess(data, createUser, updateUser);
        })
    }

    const RoleTextBox = ({ roles }) => {
        const rolesText = roles.join(', ');
        return (
            <input className="p-inputtext p-component p-filled" style={{width: "100%"}} value={rolesText} disabled={disabled} />
        );
      };

    /* Render Templates - End */

    /* Render View Return - Start */

    return (
        <div>
            <ToastElement ref={toastRef} />
            <Loader loading={loading} />
            <form onSubmit={props.type === 'create' ? handleSubmit(savebutton) : handleSubmit(editButton)}>
                <div className="p-grid buCreation">
                    <div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
                        <p className="font-normal fs-13 text-lightgray mt-0">First Name <span className="text-red">*</span></p>
                        <div className="p-field">
                            <Controller name="firstName" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                <TextboxElement id={field.name} value={field.value} placeholder="First Name" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} disabled={disabled} />
                            )} />
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
                        <p className="font-normal fs-13 text-lightgray mt-0">Last Name <span className="text-red">*</span></p>
                        <div className="p-field">
                            <Controller name="lastName" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                <TextboxElement id={field.name} value={field.value} placeholder="Last Name" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })}  disabled={disabled}/>
                            )} />
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
                        <p className="font-normal fs-13 text-lightgray mt-0">Email Id <span className="text-red">*</span></p>
                        <div className="p-field">
                            <Controller name="email" control={control} rules={{ required: 'Email is required.', pattern: { value: "[A-Za-z0-9\._''%-]+@[A-Za-z0-9\.''-]+\.[A-Za-z]{2,4}", message: 'Invalid email address. E.g. example@email.com' } }} render={({ field, fieldState }) => (
                                <TextboxElement id={field.name} value={field.value} placeholder="name@imitorgraphica.com" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} disabled={disabled} />
                            )} />
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
                        <p className="font-normal fs-13 text-lightgray mt-0">Contact Number <span className="text-red">*</span></p>
                        <div className="p-field">
                            <Controller name="mobileNumber" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                <TextboxElement type='number' id={field.name} value={field.value} placeholder="Enter 10 digit" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} disabled={disabled} />
                            )} />
                        </div>
                    </div>
                </div>
                <div className="p-grid buCreation">
                    <div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
                        <p className="font-normal fs-13 text-lightgray mt-0">Date of Joining <span className="text-red">*</span></p>
                        <div className="p-field employeeDate">
                            <Controller name="dateOfJoin" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                <CalendarElement
                                    name={field.name}
                                    placeholder='Date of Joining'
                                    value={field.value}
                                    view='date'
                                    dateFormat='dd/mm/yy'
                                    onChange={field.onChange}
                                    showIcon='true'
                                    className={classNames({ 'p-invalid': fieldState.invalid })} disabled={disabled}
                                />
                            )} />
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
                        <p className="font-normal fs-13 text-lightgray mt-0">Gender <span className="text-red">*</span></p>
                        <div className="p-field employee-creation font-normal fs-13 text-lightgray">
                            <Controller name="genderDependent" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                <RadioElement id={field.name} options={gender} value={field.value} onChangeRadio={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid }, 'group-radio')} disabled={disabled} />
                            )} />
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
                        <p className="font-normal fs-13 text-lightgray mt-0">Is Global Employee <span className="text-red">*</span></p>
                        <div className="p-field employee-creation font-normal fs-13 text-lightgray">
                            <Controller name="global" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                <RadioElement id={field.name} options={globalEmployee} value={field.value} onChangeRadio={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid }, 'group-radio')} disabled={disabled} />
                            )} />
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
                        <p className="font-normal fs-13 text-lightgray mt-0">Employee Id <span className="text-red">*</span></p>
                        <div className="p-field">
                            <Controller name="code" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                <TextboxElement id={field.name} value={field.value} placeholder="XXX" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} disabled={disabled} />
                            )} />
                        </div>
                    </div>
                </div>
                <div className="p-grid buCreation">
                    <div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
                        <p className="font-normal fs-13 text-lightgray mt-0">Business Unit <span className="text-red">*</span></p>
                        <div className="p-field employee-Dropdown">
                            <Controller name="buId" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                <SingleSelectElement id={field.name} placeholder="Select" optionLabel="name" options={buUnit} value={field.value} onChangeValue={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })}
                                filterBy='name' disabled={disabled} />
                            )} />
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
                        <p className="font-normal fs-13 text-lightgray mt-0">Reporting To <span className="text-red">*</span></p>
                        <div className="p-field employee-Dropdown">
                            <Controller name="reportingUserId" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                <SingleSelectElement id={field.name} placeholder="Select" optionLabel="name" options={userList} value={field.value} onChangeValue={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })}
                                filterBy='name' disabled={disabled} />
                            )} />
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
                        <p className="font-normal fs-13 text-lightgray mt-0">User Status <span className="text-red">*</span></p>
                        <div className="p-field employee-Dropdown">
                            <Controller name="statusId" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                <SingleSelectElement id={field.name} placeholder="Select" optionLabel="name" options={statusList} value={field.value} onChangeValue={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })}
                                filterBy='name' disabled={disabled} />
                            )} />
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
                        <p className="font-normal fs-13 text-lightgray mt-0">Designation <span className="text-red">*</span></p>
                        <div className="p-field employee-Dropdown">
                            <Controller name="designationId" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                <SingleSelectElement id={field.name} placeholder="Select" optionLabel="name" options={designationList} value={field.value} onChangeValue={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })}
                                filterBy='name' disabled={disabled} />
                            )} />
                        </div>
                    </div>
                </div>
                <div className="p-grid buCreation">
                    <div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
                        <p className="font-normal fs-13 text-lightgray mt-0">Grade <span className="text-red">*</span></p>
                        <div className="p-field employee-Dropdown">
                            <Controller name="gradeId" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                <SingleSelectElement id={field.name} placeholder="Select" optionLabel="name" options={gradeList} value={field.value} onChangeValue={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })}
                                filterBy='name' disabled={disabled} />
                            )} />
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
                        <p className="font-normal fs-13 text-lightgray mt-0">Department <span className="text-red">*</span></p>
                        <div className="p-field employee-Dropdown">
                            <Controller name="departmentId" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                <SingleSelectElement id={field.name} placeholder="Select" optionLabel="name" options={departmentList} value={field.value} onChangeValue={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })}
                                filterBy='name' disabled={disabled} />
                            )} />
                        </div>
                    </div>
                    {props.type !== undefined && (
                        <div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
                            <p className="font-normal fs-13 text-lightgray mt-0">Role <span className="text-red">*</span></p>
                            <div className="p-field employee-Dropdown">
                                <Controller name="roles" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                    <MultiSelectElement id={field.name} placeholder="Select" optionLabel="name" options={roleList} value={field.value} onChangeValue={field.onChange} maxSelectLabel={1} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                            </div>
                        </div>
                    )}
                    <div className="p-col-12 p-lg-3 p-md-6 p-sm-6">
                        <p className="font-normal fs-13 text-lightgray mt-0">Previous Work Experience (Months)<span className="text-red"> *</span></p>
                        <div className="p-field">
                            <Controller name="previousWorkExp" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                <TextboxElement type='number' id={field.name} value={field.value} placeholder="Enter Work Experience" onChangeText={field.onChange} className={classNames({ 'p-invalid': fieldState.invalid })} disabled={disabled} />
                            )} />
                        </div>
                    </div>
                    {
                        props.type === undefined && (
                         <div className="p-col-12">
                            <p className="font-normal fs-13 text-lightgray mt-0">Role <span className="text-red">*</span></p>
                            <div className="p-field">
                                <RoleTextBox roles={props.editData.roles} /> 
                            </div>
                         </div>
                        )
                    }
                </div>
                {
                    props.type === 'create' &&
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Button type="submit" icon='pi pi-arrow-right' iconPos="right" label="CREATE" className="create-button" />
                        </div>
                    </div>
                }
                {
                    props.type === 'edit' &&
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Button type="submit" icon='pi pi-arrow-right' iconPos="right" label="UPDATE" className="create-button" />
                        </div>
                    </div>
                }
            </form>
        </div>
    )

    /* Render View Return - End */

}

/* Function - End */

/* Export default functionName; */

export default EmployeeCreationPage;
