import axios from 'axios';

let baseUrl = process.env.REACT_APP_API_URL;
let baseUrl2 = process.env.REACT_APP_API_URL2;

async function getDesignationData(buId) {
	let response = await axios.get(`${baseUrl}/api/designation/userDesignationByBuId/${buId}`);
	return response.data;
}

async function getDepartmentData(buId) {
	let response = await axios.get(`${baseUrl}/api/department/getDepartmentByBuId/${buId}`);
	return response.data;
}

async function getGradeData(buId) {
	let response = await axios.get(`${baseUrl}/api/grade//userGradeByBuId/${buId}`);
	return response.data;
}

async function addDesignationData(data) {
	let response = await axios.post(`${baseUrl}/api/designation/`, data);
	return response.data;
}

async function updateDesignationData(id, data) {
	let response = await axios.patch(`${baseUrl}/api/designation/designationStatus/${id}`, data);
	return response.data;
}

async function addDepartmentData(data) {
	let response = await axios.post(`${baseUrl}/api/department/`, data);
	return response.data;
}

async function addGradeData(data) {
	let response = await axios.post(`${baseUrl}/api/grade/`, data);
	return response.data;
}

async function addEmployeeData(data) {
	let response = await axios.post(`${baseUrl}/api/user-status/`, data);
	return response.data;
}

async function getEmployeeStatusData(buId) {
	let response = await axios.get(`${baseUrl}/api/user-status/getUserStatusByBuid/${buId}`);
	return response.data;
}

async function updateDepartmentData(buId, data) {
	let response = await axios.patch(`${baseUrl}/api/department/departmentStatus/${buId}`, data);
	return response.data;
}

async function updateGradeData(buId, data) {
	let response = await axios.patch(`${baseUrl}/api/grade/gradeStatus/${buId}`, data);
	return response.data;
}

async function updateEmployeeStatusData(id, data) {
	let response = await axios.patch(`${baseUrl}/api/user-status/userStatusActive/${id}`, data);
	return response.data;
}

async function updateEmployeeLockData(id, data) {
	let response = await axios.patch(`${baseUrl}/api/user-status/${id}`, data);
	return response.data;
}

async function getAllDesignationData() {
	let response = await axios.get(`${baseUrl}/api/designation/`);
	return response.data;
}

async function getMetricsData(buId) {
	let response = await axios.get(`${baseUrl}/api/kraConfig/getByBuId/${buId}`);
	return response.data;
}

async function updateMetricsStatus(data) {
	let response = await axios.patch(`${baseUrl}/api/kraConfig/status/${data.buId}/${data.id}`, data);
	return response.data;
}

async function editMetrics(id) {
	let response = await axios.get(`${baseUrl}/api/kraConfig/${id}`);
	return response.data;
}

async function addMetrics(data) {
	let response = await axios.post(`${baseUrl}/api/kraConfig`, data);
	return response.data;
}

async function updateMetrics(data) {
	let response = await axios.patch(`${baseUrl}/api/kraConfig/${data.id}`, data);
	return response.data;
}

async function getActiveBu() {
	let response = await axios.get(`${baseUrl}/api/business-unit?status=active`);
	return response.data;
}

async function addServiceDeskData(data) {
	let response = await axios.post(`${baseUrl}/api/jobCategory/`, data);
	return response.data;
}

async function updateServiceDeskData(Id, data) {
	let response = await axios.patch(`${baseUrl}/api/jobCategory/categoryStatus/${Id}`, data);
	return response.data;
}

async function addJobPriority(data) {
	let response = await axios.post(`${baseUrl2}/api/settings/priorityType`, data);
	return response.data;
}

async function getJobPriorityData(buId) {
	let response = await axios.get(`${baseUrl2}/api/settings/priorityType/${buId}`);
	return response.data;
}

async function getJobCategoryData(buId, status) {
	let response = await axios.get(`${baseUrl}/api/jobCategory/getJobCategoryById/${buId}?status=${status}`);
	return response.data;
}

async function editJobPriorityData(id) {
	let response = await axios.get(`${baseUrl2}/api/settings/editPriorityType/${id}`);
	return response.data;
}

async function editServiceDeskData(id) {
	let response = await axios.get(`${baseUrl}/api/jobCategory/${id}`);
	return response.data;
}

async function updateJobPriority(data, id) {
	let response = await axios.put(`${baseUrl2}/api/settings/updatepriorityType/${id}/`, data);
	return response.data;
}

async function updateServicedeskCategoryData(data, id) {
	let response = await axios.patch(`${baseUrl}/api/jobCategory/${id}/`, data);
	return response.data;
}

async function getJobCategoryRequestData(id) {
	let response = await axios.get(`${baseUrl}/api/jobCategory/${id}`);
	return response.data;
}

export default { updateEmployeeLockData, updateEmployeeStatusData, updateGradeData, addEmployeeData, getDesignationData, getDepartmentData, getGradeData, addDesignationData, updateDesignationData, addDepartmentData, addGradeData, getEmployeeStatusData, updateDepartmentData, getAllDesignationData, getMetricsData, updateMetricsStatus, editMetrics, addMetrics, updateMetrics, getActiveBu, addServiceDeskData, updateServiceDeskData, addJobPriority, getJobPriorityData, getJobCategoryData, editJobPriorityData, editServiceDeskData, updateJobPriority, updateServicedeskCategoryData, getJobCategoryRequestData };