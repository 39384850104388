/* Import Section - Start */
/* React Imports - Start */

import React,{ useState,useEffect, useRef} from "react";

/* React Imports - End */

/* Project Components Imports - Start */

import { Dialog } from 'primereact/dialog';
import { Button} from 'primereact/button';
import TextboxElement from "../uiComponents/TextboxElement";
import RolesService from '../../services/controlPanel/RolesService';
import CheckboxElement from '../uiComponents/CheckboxElement'
import ToastElement from "../uiComponents/ToastElement";
import Loader from '../uiComponents/Loader';

/* Project Components Imports - End */

/* Plugin Imports - Start */

import _ from 'lodash';

/* Plugin Imports - End */

/* Import Section - End */

/* Function - Start */

const RoleModalPop = (props) => {

    /* State Declaration - Start */

    /* useState - Start */

    const [permissions, setPermissions] = useState([])
    const [role, setRole] = useState('')
    const [permissionList, setPermissionList] = useState([])
    const [checkedValues,setCheckedValues] = useState(props.checkedValues)
    const [editRoleText,setEditRoleText] = useState(props.editText);
    const [loading,setLoading] = useState(false);

    /* useRef - Start */

    const toastRef = useRef();

    /* useRef - End */

    /* useEffect - Start */

    useEffect(() => {
        getPermissions(); //getting the list of permission while the pop up coponent is rendering
    }, [])

    /* useEffect - End */

    /* Methods - Start */

    //function to fetch the permissions
    const getPermissions = () => {
        RolesService.getPermissions().then(data => {
           
            var mappedData = data.data.map(obj => ({...obj,checked:false}))
            setPermissions(mappedData)

        })
    }

    //Creating a new role
    const createRole = () => {
        var data = {
            accessPermission: permissionList,
            buId: props.currentBu,
            role: role
        }
        if (data.accessPermission.length > 0 && data.role !== '') {
            RolesService.addRole(data).then(data => {
                let message = {
                    type: data.posted.success ? 'success' : 'error',
                    summary: data.posted.success ? 'Success' : 'Something went wrong',
                    message: data.posted.message
                }
                let success = data.posted.success ? true : false
                // props.buReload(props.currentBu)
                if (data.posted.success) {
                    props.onHide(message,success)
                } else {
                    toastRef.current.showToast({
                        type: 'error',
                        summary: 'Warning',
                        message: data.posted.message
                    })
                }
            })
        }
        else {
            if (data.accessPermission.length <= 0) {
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Select role',
                    message: 'Please select atleast one item'

                })
            }else if(data.role === ''){
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Enter role',
                    message: 'Please enter the role'

                })
            }
        }
    }

    //editing the role which already exists
    const editRole = () => {
        var data = {
            accessPermission: checkedValues,
            buId: props.currentBu.id,
            role: editRoleText
        }
        if (data.accessPermission.length > 0 && data.role !== '') {
            setLoading(true);
            RolesService.updateRole(props.editRoleId, data).then(data => {
                setLoading(false);
                let message = {
                    type: data.patched.success ? 'success' : 'error',
                    summary: data.patched.success ? 'Success' : 'Something went wrong',
                    message: data.patched.message
                }
                let success = data.patched.success ? true : false
                // props.buReload(props.currentBu)
                if (data.patched.success) {
                    props.onHide(message,success)
                } else {
                    toastRef.current.showToast({
                        type: 'error',
                        summary: 'Warning',
                        message: data.patched.message
                    })
                }
            })
        }else{
            if(data.accessPermission.length <= 0){
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Select role',
                    message: 'Please select atleast one item'
                })

            }else if(data.role === ''){
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Select role',
                    message: 'Please select atleast one item'
                })
            }
        }

    }

    //getting the unique elements form checkbox selection and storing in a state for add role pop up
    const checkboxValue = (e,code) => {
        var mappedArr = permissions.map(obj => obj.permissionCode === code? {...obj,checked: e.checked}: obj)
        setPermissions(mappedArr)
        if(e.checked) {
            setPermissionList(array => _.uniq([...array,code]))
        }else {
            setPermissionList(permissionList.filter(x => x != code))
        }
    }

    //getting the unique elements form checkbox selection and storing in a state for edit role pop up
    const checkboxValueEdit = (e,buCode) => {
        if(e.checked) {
            setCheckedValues(array => _.uniq([...array,buCode]))
        }else {
            setCheckedValues(checkedValues.filter(x => x != buCode))
        }
    }
    /* Methods - Ends */

    /* Render Templates - Start */

    //function for rendering footer to add role modal pop up
    const renderAddFooter = () => {
        return (
            <div className="d-flex justify-end add-role-button">
                <Button type="submit" label="Add Role" className="create-button request-button" onClick={() => createRole()} />
            </div>
        );
    }

    //function for rendering footer to edit role modal pop up
    const renderEditFooter = () => {
        return (
            <div className="d-flex justify-end add-role-button">
                <Button type="submit" label="Edit Role" className="create-button request-button" onClick={() => editRole()} />
            </div>
        );
    }

    /* Render Templates - End */

    /* Render View Return - Start */

    return(
        <div>
            <ToastElement ref={toastRef} />
            <Loader loading={loading}/>
            <div className="dialog-demo">
                <div className="card addRole">
                    {/* Add role modal pop up */}
                    {props.header === "Add Role" &&
                        <Dialog header={props.header} className="addRole-dialog" visible={props.visible} onHide={() => props.onHide()} stylebreakpoints={{ '960px': '75vw' }} footer={renderAddFooter()} draggable={false}>
                            <div >
                                <div className="p-grid">
                                    <div className="p-col-12 addRole-text">
                                        <TextboxElement placeholder="Role" value={role} onChangeText={(e) => setRole(e.target.value)}></TextboxElement>
                                        <p className="dialog-p">{`Selected BU: ${props.currentBuName}`}</p>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    {permissions.length > 0 && permissions.map((permission) => (
                                    <div className="p-col-12 p-md-6 fs-13">
                                        <CheckboxElement
                                            key={permission.permissionCode}
                                            inputId={permission.permissionCode}
                                            value={permission.permissionName}
                                            checked={permission.checked}
                                            onChangeCheck={(e, code) => checkboxValue(e, code)} 
                                        />
                                    </div>
                                    ))}
                                </div>
                            </div>
                        </Dialog>
                    }
                    {/* edit role modal pop up */}
                    {props.header === "Edit Role" &&
                        <Dialog header={props.header} className="addRole-dialog" visible={props.visible} onHide={() => props.onHide()} stylebreakpoints={{ '960px': '75vw' }} footer={renderEditFooter()}>
                            <div >
                                <div className="p-grid">
                                    <div className="p-col-12 addRole-text">
                                        <TextboxElement value={editRoleText} placeholder="Role" onChangeText={(e) => setEditRoleText(e.target.value)}></TextboxElement>
                                        <p className="dialog-p">{`Selected BU: ${props.currentBuName}`}</p>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    {permissions.length > 0 && permissions.map((permission) => (
                                        checkedValues.includes(permission.permissionCode) ?
                                        <div className="p-col-12 p-md-6 fs-13">
                                            <CheckboxElement
                                                key={permission.permissionCode}
                                                inputId={permission.permissionCode}
                                                checked={true}
                                                value={permission.permissionName}
                                                onChangeCheck={(e, buCode) => checkboxValueEdit(e, buCode)} />
                                        </div> : 
                                        <div className="p-col-12 p-md-6 fs-13">
                                            <CheckboxElement
                                                key={permission.permissionCode}
                                                inputId={permission.permissionCode}
                                                checked={false}
                                                value={permission.permissionName}
                                                onChangeCheck={(e, buCode) => checkboxValueEdit(e, buCode)} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Dialog>
                    }
                </div>
            </div>
        </div>
    )
    /* Render View Return - End */
}
/* Function - End */

/* Export default functionName; */
export default RoleModalPop