/* Import Section - Start */

/* React Imports - Start */

import React, { useEffect, useState } from 'react';

/* React Imports - End */

/* Plugin Imports - Start */

import { MultiSelect } from 'primereact/multiselect';

/* Plugin Imports - End */

/* Import Section - End */

/* Function - Start */

const MultiSelectElement = (props) => {

    /* State Declaration - Start */

    /* useState - Start */
    const [selectedValue, setSelectedValue] = useState();

    /* useState - End */

    /* useEffect - Start */

    useEffect(() => {
        if (props.value) {
            setSelectedValue(props.value);
        } else {
            setSelectedValue(null);
        }
    }, [props.value])

    /* useEffect - End */

    /* State Declaration - End */

    /* Methods - Start */

    const onValueChange = (e) => {
        setSelectedValue(e.value)
        if (props?.onChangeValue) {
            props.onChangeValue(e);
        }
    }

    /* Methods - End */

    /* Render View Return - Start */

    return (
        <div>
            <h5 className='dropdown-heading'>{props.dropdownName}</h5>
            <MultiSelect
                name={props.name}
                id={props.id}
                value={selectedValue}
                options={props.options}
                onChange={(e) => onValueChange(e)}
                optionLabel={props.optionLabel}
                filter
                showClear={props.showClear}
                filterBy="name"
                placeholder={props.placeholder}
                className={props.className}
                disabled={props.disabled}
                maxSelectedLabels={props.maxSelectLabel}
            />
        </div>
    )

    /* Render View Return - End */

}

/* Function - End */

/* Export default functionName; */

export default MultiSelectElement;
