/* Import Section - Start */

/* React Imports - Start */

import React, { useEffect, useState, useRef } from 'react';

/* React Imports - End */

/* Plugin Imports - Start */

import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useNavigate, useLocation } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';

/* Plugin Imports - End */

/* Project Components Imports - Start */

import LoginService from '../../services/login/LoginService';
import JMANfooter from '../../assets/images/JMANfooter1.png'
import JINlogo from '../../assets/images/Login-logo.png';
import email from '../../assets/images/Email.png';
import password from '../../assets/images/Password.png';
import ToastElement from "../uiComponents/ToastElement";
import JMANLogoBlue from '../../assets/images/JMANLogoBlue.png';
import Cookies from 'universal-cookie';
import Loader from "../uiComponents/Loader";

/* Project Components Imports - End */

/* Import Section - End */

/* Function - Start */

const JinLogin = (props) => {

    /* State Declaration - Start */

    /* useState - Start */

    const [signIn, setSignIn] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [position, setPosition] = useState('center');
    const [forgotEmailValue, setForgotEmailValue] = useState('');
    const [loading, setLoading] = useState(false);

    /* useState - End */

    /* useRef - Start */

    const toastRef = useRef();

    /* useRef - End */

    const cookies = new Cookies();
    let navigate = useNavigate();
    let location = useLocation();

    const defaultValues = {
        email: '',
        password: ''
    }

    /* useForm - Start */

    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });

    /* useForm - End */

    /* useEffect - Start */

    useEffect(() => {
        setSignIn(true);
    }, [])

    /* useEffect - End */

    /* State Declaration - End */

    /* Methods - Start */

    // Login form submit - To get authentication
    const onSubmit = (formData) => {
        LoginService.loginUser(formData).then(data => {
            if (data.posted.success) {
                toastRef.current?.showToast({
                    type: 'success',
                    summary: 'Successfully Logged In',
                    message: data.posted.message
                })

                cookies.set('token', data.posted.token);
                cookies.set('userId', data.posted.userId);
                cookies.set('buId', data.posted.buId);
                cookies.set('userName', data.posted.name);
                cookies.set("roles", JSON.stringify(data.posted.roles));
                cookies.set("isAuth", true);
                cookies.set("buName", data.posted.buName, { path: '/' });
                cookies.set("isGlobalUser", data.posted.isGlobalUser);

                setTimeout(() => {
                    var roles = cookies.get('roles');
                    // if (roles.includes('Super Admin')) {
                    //     navigate(`/admin/accessControl`);
                    // } else {
                    //     navigate(`/dashboard`);
                    // }
                    navigate(`/trackingSystem`);
                }, 1000);
            } else {
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Please check your credentials',
                    message: data.posted.message
                })
            }
        })
    };

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    // To Reset form
    const onSubmitReset = () => {
        setResetPassword(true);
        setSignIn(false);
    };

    const onSubmitProceed = (formData) => {
        setSignIn(true);
        setResetPassword(false);
        LoginService.ForgotPassword(formData).then(data => {
            if (data.success) {
                toastRef.current.showToast({
                    type: 'success',
                    message: data.message
                })
            } else {
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Please check your credentials',
                    message: data.message
                })
            }
        })
    }

    const requestHandler = () => {
        let formData = {
            email: forgotEmailValue
        }
        setLoading(true);
        LoginService.forgotPassword(formData).then(value => {
            setLoading(false);
            if (value.posted.success) {
                toastRef.current.showToast({
                    type: 'success',
                    summary: 'Forgot Password',
                    message: value.posted.message
                });
                setDisplayBasic(false);
            } else {
                toastRef.current.showToast({
                    type: 'error',
                    summary: 'Forgot Password',
                    message: value.posted.message
                })
            }
        })
        setForgotEmailValue('');
    }

    /* Methods - End */

    /* Render Templates - Start */

    //  renderCancelFooter
    const renderCanelFooter = (name) => {
        return (
            <div>
                <div className='p-grid request-footer'>
                    <div className='p-col-12 align-center justify-end pb-0'>
                        <Button type="submit" label="PROCEED" className="resignin-button" onClick={() => requestHandler()} />
                    </div>
                </div>
            </div>
        );
    }

    const onClickLogin = () => {
        var name = '';
        var value = ''
        var allcookies = decodeURIComponent(document.cookie);

        var cookiearray = allcookies.split('; ');
        var cookie_name = {};

        for (var i = 0; i < cookiearray.length; i++) {
            name = cookiearray[i].split('=')[0];
            value = cookiearray[i].split('=')[1];
            cookie_name[name] = value;
        }

        if (cookie_name.accessToken) {
            let data = {
                email: cookie_name.email
            }
            LoginService.loginUser(data).then(data => {
                if (data.posted.success && data.posted.accountStatus === 'ACTIVATED') {
                    cookies.set('userId', data.posted.userId, { path: '/' });
                    cookies.set('buId', data.posted.buId, { path: '/' });
                    cookies.set('userName', data.posted.name, { path: '/' });
                    cookies.set("roles", JSON.stringify(data.posted.roles), { path: '/' });
                    cookies.set("isAuth", true);
                    cookies.set("buName", data.posted.buName, { path: '/' });

                    setTimeout(() => {
                        navigate(`/dashboard`);
                    }, 1000);
                } else {
                    toastRef.current.showToast({
                        type: 'error',
                        summary: 'Error',
                        message: data.posted.message
                    })
                    navigate(`/loginError`);
                }
            })
        }
        else {
            LoginService.loginO365User().then(data => {
                if (data.data.success === true) {
                    window.location.replace(`${data.data.url}`);
                }
            })
        }
    }

    /* Render Templates - End */

    /* Render View Return - Start */

    return (
        <div>
            <ToastElement ref={toastRef} />
            <Loader loading={loading} />
            <div className="p-grid login-grid h-100 m-0">
                <div className="p-col-12 p-md-6 p-lg-6 login-bg p-0">
                    {/* <img src={JINlogo} alt="logo" className='jin-logo' /> */}
                </div>
                {/* Login page */}
                {
                    signIn &&
                    <div className="p-col-12 p-md-6 p-lg-6 flex-center">
                        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                        <h1>Welcome to JIN IG</h1>
                            {/* <p className='font-normal fs-11 login-footer-text text-lightgray'>People's Self Service Portal</p> */}
                            <p className='font-normal fs-11 login-footer-text text-lightgray mt-24'>For support and clarifications, reach out to <b>jinsupport@jmangroup.com</b> in email</p>
                             <div className="p-field">
                                <span className="p-input-icon-left">
                                    <img src={email} className='email-icon' />
                                    <Controller name="email" control={control} rules={{ required: 'Email is required.', pattern: { value: "[A-Za-z0-9\._''%-]+@[A-Za-z0-9\.''-]+\.[A-Za-z]{2,4}", message: 'Invalid email address. E.g. example@email.com' } }} render={({ field, fieldState }) => (
                                        <InputText type='email' id={field.name} {...field} placeholder="Email" className={classNames({ 'p-invalid': fieldState.invalid }, 'input-text')} />
                                    )} />
                                </span>
                            </div>
 
                            <div className="p-field p-mb-0">
                                <span className="p-input-icon-left">
                                    <img src={password} className='password-icon' />
                                    <Controller name="password" control={control} rules={{ required: 'Password is required.' }} render={({ field, fieldState }) => (
                                        <InputText type='password' id={field.name} {...field} placeholder="Password" className={classNames({ 'p-invalid': fieldState.invalid }, 'input-text')} />
                                    )} />
                                </span>
                            </div>
                            <div className='p-0'>
                                <div className='relative forgot-button-box p-mb-3' >
                                    <p className="forgot-button" onClick={() => onClick('displayBasic')} >Forgot Password?</p>
                                </div>
                                <div className='d-flex sign-in-align'>
                                    <Button type="submit" icon='pi pi-arrow-right' iconPos="right" label="SIGN IN" className="signin-button" />
                                    {/* <Button icon='pi pi-arrow-right' iconPos="right" label="SIGN IN WITH O365" type='button' onClick={() => onClickLogin()} className="p-mt-2 signin-button-o365 signin-button" /> */}
                                </div>
                                {/* <div>
                                    <p className='font-normal fs-11 login-footer-text text-lightgray mt-24'>For Support and Clarifications, reach out to <b>jinsupport@jmangroup.com</b> in email</p>
                                </div> */}
                                {/* <div>
                                <p className='font-normal fs-11 login-footer-text text-lightgray'> or <u><b>jinsupport@jmangroup.com</b></u> in email</p>
                                </div> */}
                                <div className='p-grid copyright-footer m-0'>
                                    <div className='p-col-4 pl-30 footer-border-1'>
                                        <p className="font-normal fs-10 m-0 copyright-text">2024</p>
                                        <p className="font-normal fs-10 m-0 mt-5 login-footer-text">&copy; Copyright</p>
                                    </div>
                                    <div className='p-col-4 pl-14 footer-border-2'>
                                        <p className="font-normal fs-10 m-0 copyright-text">2.0</p>
                                        <p className="font-normal fs-10 m-0 mt-5 login-footer-text no-wrap">IG JIN APPS Version</p>
                                    </div>
                                    <div className='p-col-4 pl-17'>
                                        <p className="font-normal fs-10 m-0 login-footer-text">Powered by</p>
                                        <img src={JMANLogoBlue} alt="footer" className='footer-image d-des-block' />
                                    </div>
                                </div>
                                <Dialog visible={displayBasic} header="FORGOT PASSWORD" footer={renderCanelFooter('displayBasic')} breakpoints={{ '960px': '75vw' }} draggable={false} onHide={() => onHide('displayBasic')} >
                                    <div className="cancel-request">
                                        <div>
                                            <p className="reset-email-text">Enter your registered email to reset your password</p>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-12">
                                                <InputText type='email' id="forgotEmail" placeholder="Email"
                                                    value={forgotEmailValue}
                                                    onChange={(e) => setForgotEmailValue(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Dialog>
                            </div>
                        </form>
                    </div>
                }
                {/* Reset password page */}
                {/* {
                    resetPassword &&
                    <div className="p-col-12 p-md-6 p-lg-6 flex-center">
                        <form onSubmit={handleSubmit(onSubmitProceed)} className="p-fluid w-356">
                            <h1>Reset Password</h1>
                            <div className="p-field">
                                <span className="p-float-label p-input-icon-left">
                                    <img src={email} className='email-icon' />
                                    <Controller name="email" control={control} rules={{ required: 'Email is required.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address. E.g. example@email.com' } }} render={({ field, fieldState }) => (
                                        <InputText type='email' id={field.name} {...field} placeholder="Email" className={classNames({ 'p-invalid': fieldState.invalid }, 'input-text')} />
                                    )} />
                                </span>
                                {getFormErrorMessage('email')}
                            </div>
                            <div className='p-0'>
                                <Button type="submit" icon='pi pi-arrow-right' iconPos="right" label="PROCEED" className="p-mt-2 p-mr-3 signin-button"/>
                            </div>
                        </form>
                    </div>
                } */}
            </div>
            {/* Footer */}
            {/* <div className='p-grid copyright-footer m-0'>
                <div className='p-col-4'>
                    <p className="font-normal fs-10 m-0 login-footer-text">Powered by</p>
                    <img src={JMANfooter} alt="footer" className='footer-image d-des-block' />
                    <img src={JMANLogoBlue} alt="footer" className='footer-image d-mob-block' />
                </div>
                <div className='p-col-4 pl-30 footer-border-1'>
                    <p className="font-normal fs-10 m-0 copyright-text">3.0</p>
                    <p className="font-normal fs-10 m-0 mt-5 login-footer-text no-wrap">JIN APPS Version</p>
                </div>
                <div className='p-col-4 pl-30 footer-border-2'>
                    <p className="font-normal fs-10 m-0 copyright-text">2022</p>
                    <p className="font-normal fs-10 m-0 mt-5 login-footer-text">&copy; Copyright</p>
                </div>
            </div> */}
        </div>
    );

    /* Render View Return - End */

}

/* Function - End */

/* Export default functionName; */

export default JinLogin;