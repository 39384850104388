/**
 * Authentication Service
 */
 import axios from 'axios';

 let baseUrl = process.env.REACT_APP_API_URL;
 
 /* Leave Page Service - Start */
 
    async function getActiveBu() {
        let response = await axios.get(`${baseUrl}/api/business-unit?status=active`);
        return response.data;
    }

 async function getRatingConfig(currentBuId) {
     let response = await axios.get(`${baseUrl}/api/performance/${currentBuId}`);
     return response.data;
 }

 async function getScheduleConfig(currentBuId) {
    let response = await axios.get(`${baseUrl}/api/performance/${currentBuId}`);
    return response.data;
}

 async function addNewRating(data) {
    let response = await axios.post(`${baseUrl}/api/performance`, data);
    return response.data;
}

async function addNewSchedule(data) {
    let response = await axios.post(`${baseUrl}/api/performance`, data);
    return response.data;
}
 
 /* Export default functionNames; */
 
 export default { getRatingConfig,getScheduleConfig,addNewRating,addNewSchedule, getActiveBu};