/* Import Section - Start */

/* React Imports - Start */

import React, { useState, useEffect } from 'react';

/* React Imports - End */

/* Project Components Imports - Start */

import Header from './Header';
import SideBar from './Sidebar';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import EmployeesService from '../../services/employees/EmployeesService';
// import ATSService from '../../services/ats/AtsService';

import { jwtInterceptor } from '../../auth-interceptor/jwtInterceptor'
/* Project Components Imports - Start */

/* Function - Start */

const Layout = (props) => {

    jwtInterceptor()
    /* State Declaration - Start */

    /* useState - Start */

    const [menuClick, setMenuClick] = useState(false);
    const [menuLabel, setMenuLabel] = useState('');
    const [menuTitle, setMenuTitle] = useState('');

    /* useState - End */

    const navigate = useNavigate();
    let location = useLocation();
    const cookies = new Cookies();

    useEffect(() => {
        if (location.pathname === '/timesheet') {
            setMenuLabel('Timesheet');
        } else if (location.pathname === '/roles') {
            setMenuLabel('Roles');
            setMenuTitle('Control Panel');
        } else if (location.pathname === '/employees') {
            setMenuLabel('Employees');
            setMenuTitle('Settings');
        } else if (location.pathname === '/businessUnit') {
            setMenuLabel('Business Unit');
            setMenuTitle('Settings');
        } else if (location.pathname === '/leave') {
            setMenuLabel('Leave');
        }
         else if (location.pathname === '/leaveConfiguration') {
            setMenuLabel('Leave Config');
            setMenuTitle('Control Panel');
        } else if (location.pathname === '/referenceData') {
            setMenuLabel('Reference Data');
            setMenuTitle('Control Panel');
        } else if (location.pathname === '/leaveApproval') {
            setMenuLabel('Leave Approval');
            setMenuTitle('Approvals');
        } else if (location.pathname === '/travelApproval') {
            setMenuLabel('Travel Approval');
            setMenuTitle('Approvals');
        } else if (location.pathname === '/timesheetApproval') {
            setMenuLabel('Timesheet Approval');
            setMenuTitle('Approvals');
        } else if (location.pathname === '/allocationExtensionApproval') {
            setMenuLabel('Allocation Extension Approval');
            setMenuTitle('Approvals');
        } else if (location.pathname === '/timesheetConfiguration') {
            setMenuLabel('Timesheet Config');
            setMenuTitle('Control Panel');
        } else if (location.pathname === '/compOff') {
            setMenuLabel('Comp Off Approval');
            setMenuTitle('Approvals');
        } else if (location.pathname === '/permission') {
            setMenuLabel('Permission Approval');
            setMenuTitle('Approvals');
        } else if (location.pathname === '/behalfOf') {
            setMenuLabel('Behalf Of Request');
            setMenuTitle('Leave');
        } else if (location.pathname === '/clients') {
            setMenuLabel('Clients');
            // setMenuTitle('Settings');
        } else if (location.pathname === '/projects') {
            // if (props.value)
            // {
            //     setMenuLabel('All Projects');
            // }
            // else{
            //     setMenuLabel('My Projects')
            // }
            setMenuLabel('Projects');
        } else if (location.pathname === '/projectConfig') {
            setMenuLabel('Project Config');
            setMenuTitle('Control Panel');
        } else if (location.pathname === '/trackingSystem') {
            setMenuLabel('Tracking System');
        } else if (location.pathname === 'trackingSystemDashboards') {
            setMenuTitle('Dashboards')
        } else if(location.pathname === '/jobs/:jobId/bu/:buId') {
            setMenuLabel("Job Information");
        }else if (location.pathname === '/trackingSystemConfiguration') {
            setMenuLabel('Tracking System Config');
            setMenuTitle('Control Panel');
        } else if (location.pathname === '/kraConfiguration') {
            setMenuLabel('KRA Configuration');
        } else if (location.pathname === '/survey') {
            setMenuLabel('Survey');
        } else if (location.pathname === '/surveyCreation') {
            setMenuLabel('Admin Survey');
            setMenuTitle('Settings');
        } else if (location.pathname === '/holidayConfig') {
            setMenuLabel('Holiday Config');
            setMenuTitle('Control Panel');
        } else if (location.pathname === '/admin/companyInfo') {
            setMenuLabel('Company Info');
        } else if (location.pathname === '/admin/accessControl') {
            setMenuLabel('Access Control');
        } else if (location.pathname === '/') {
            setMenuLabel('Login');
        } else if (location.pathname === '/performanceSettings') {
            setMenuLabel('Performance Configuration');
        } else if (location.pathname === '/wfhRequest') {
            setMenuLabel('Work From Home');
            setMenuTitle('Leave');
        } else if (location.pathname === '/wfhApproval') {
            setMenuLabel('WFH Approval');
            setMenuTitle('Approvals');
        } else if (location.pathname === '/kra') {
            setMenuLabel('Form');
            setMenuTitle('Settings');
        } else if (location.pathname === '/userKra') {
            setMenuLabel('Forms');
        } else if (location.pathname === '/kraReview') {
            setMenuLabel('KRA Review');
            setMenuTitle('Approvals');
        } else if (location.pathname === '/performanceSettings') {
            setMenuLabel('Performance Configuration');
            setMenuTitle('Control Panel');
        // } else if (location.pathname === '/dashboard') {
        //     setMenuLabel('Dashboard');
        }
        else if (location.pathname === '/resourcing') {
            setMenuLabel('Resourcing');
        } else if (location.pathname === '/wsr') {
            setMenuLabel('Weekly Status Report');
        } else if (location.pathname === '/travel') {
            setMenuLabel('Travel');
        } else if (location.pathname === '/projects/create') {
            setMenuLabel('Projects');
        } else if (location.pathname === '/employees/create') {
            setMenuLabel('Employees');
            setMenuTitle('Settings');
        } else if (location.pathname === '/timesheet/create') {
            setMenuLabel('Timesheet');
        } else if (location.pathname === '/projects/edit') {
            setMenuLabel('Projects');
        } else if (location.pathname === '/employees/edit') {
            setMenuLabel('Employees');
            setMenuTitle('Settings');
        } else if (location.pathname === '/timesheet/edit') {
            setMenuLabel('Timesheet');
        } else if (location.pathname === '/training') {
            setMenuLabel('Competency Tracker');
            setMenuTitle('Competency Tracker');
        } else if (location.pathname.startsWith('/competency/view')) {
            setMenuLabel('Competency');
            setMenuTitle('Competency');
        } else if (location.pathname === '/feedback/create') {
            setMenuLabel('Create Feedback');
        } else if (location.pathname === '/feedback/:feedbackId/:idEdit') {
            setMenuLabel('Edit Feedback');
        } else if (location.pathname === '/reports/create') {
            setMenuLabel('Reports');
        } else if (location.pathname === '/reports/edit') {
            setMenuLabel('Reports');
        } else if (location.pathname === '/biometric') {
            setMenuLabel('Biometric');
        }
        else if (location.pathname === '/Reports') {
            setMenuLabel('Reports');
            setMenuTitle('Tracking System');
        }
        
        // else if (location.pathname.startsWith('/wsr/edit')) {
        //     setMenuLabel('Weekly Status Report');
        // }
    }, [location.pathname,props.value])

    /* State Declaration - End */

    /* Methods - Start */

    const hamburgerClick = (menuOpen) => {
        if (menuOpen) {
            setMenuClick(true);
        } else {
            setMenuClick(false);
        }
    }

    const menuSelect = (title, menuTitle) => {
        setMenuLabel(title);
        setMenuTitle(menuTitle);
        if (props.IsSetTitle === true) {
            if (title === 'Settings' || title === 'Control Panel' || title === 'Approvals') {
                props.setTitle(false, title);
            } else {
                props.setTitle(true, title);
            }
        }

        if (title === "Business Unit") {
            navigate("/businessUnit")
        } else if (title === "Roles") {
            navigate("/roles")
        } else if (title === "Employees") {
            navigate("/employees")
        } else if (title === "Timesheet") {
            navigate("/timesheet")
        } else if (title === "Leave") {
            navigate("/leave")
        } else if (title === "Leave Config") {
            navigate("/leaveConfiguration")
        }
        else if (title === "Reference Data") {
            navigate("/referenceData")
        } else if (title === "Leave Approval") {
            navigate("/leaveApproval")
        } else if (title === "Travel Approval") {
            navigate("/travelApproval")
        } else if (title === "Timesheet Approval") {
            navigate("/timesheetApproval")
        } else if (title === "Allocation Extension Approval") {
            navigate("/allocationExtensionApproval")
        } else if (title === "Timesheet Config") {
            navigate("/timesheetConfiguration")
        } else if (title === "Comp Off Approval") {
            navigate("/compOff")
        } else if (title === "Permission Approval") {
            navigate("/permission")
        } else if (title === "Behalf Of Request") {
            navigate("/behalfOf")
        } else if (title === "Clients") {
            navigate("/clients")
        } else if (title === "Projects") {
            navigate("/projects")
            props.setValue(props.value);
        } else if (title === "Reports") {
            navigate("/Reports")
            props.setValue(props.value);
        } else if (title === "Project Config") {
            navigate("/projectConfig")
        } else if (title === "Tracking System") {
            navigate("/trackingSystem")
        }  else if (title === 'Job Information') {
            navigate("/jobs/:jobId/bu/:buId");
        } else if (title === 'Dashboards') {
            navigate("/trackingSystemDashboards")
        } else if (title === "Tracking System Config") {
            navigate("/trackingSystemConfiguration")
        } else if (title === "KRA Configuration") {
            navigate("/kraConfiguration")
        } else if (title === "Survey") {
            navigate("/survey")
        } else if (title === "Admin Survey") {
            navigate("/surveyCreation")
        } else if (title === "Holiday Config") {
            navigate("/holidayConfig")
        } else if (title === "Company Info") {
            navigate("/admin/companyInfo")
        } else if (title === "Access Control") {
            navigate("/admin/accessControl")
        } else if (title === "Login") {
            navigate("/")
        } else if (title === "Performance Config") {
            navigate("/performanceSettings")
        } else if (title === "Work From Home") {
            navigate("/wfhRequest")
        } else if (title === "WFH Approval") {
            navigate("/wfhApproval")
        } else if (title === "Form") {
            navigate("/kra")
        } else if (title === "Forms") {
            navigate("/userKra")
        } else if (title === "KRA Review") {
            navigate("/kraReview")
        // } else if (title === "Dashboard") {
        //     navigate("/dashboard")
        } else if (title === "Travel") {
            navigate("/travel")
        } else if (title === "Expenses") {
            navigate("/expense")
        } else if (title === "Expenses Approval") {
            navigate("/expensesApproval")
        } else if (title === "Resourcing") {
            navigate("/resourcing")
        } else if (title === "WSR") {
            navigate("/wsr")
        } else if (title === "Pay Expenses") {
            navigate("/expensesPaidApproval")
        } else if (title === "Competency") {
            navigate("/training")
        } else if (title === "Biometric") {
            navigate("/biometric")
        } else if (title === "Profile") {
            navigate("/profile");
        }
        else if (title === "Reports") {
            navigate("/Reports");
        } 
        else if (title ==="Feedback") {
            navigate("/feedback");
            props.setValue(props.value);
        }
        // else if (title === "ATS") {
        //     // navigate("/ats")
        //     var userId = cookies.get("userId");
        //     EmployeesService.editUser(userId).then(data => {
        //         if (data.data) {
        //             let ATS = process.env.REACT_APP_ATS_URL;
        //             var user = data.data;
        //             user.appKey = 'ATS_INTEGRATION';
        //             ATSService.atsAuthenticate(user).then(data => {
        //                 if (data.posted.status && data.posted.statusCode === 200) {
        //                     window.open(ATS + data.posted.token, '_blank');
        //                 }
        //             })
        //         }
        //     })
        // }
    }

    /* Methods - End */

    /* Render View Return - Start */

    return (
        <div className='bg-white'>
            <SideBar
                isOpen={menuClick}
                onSelectHamburger={() => hamburgerClick()}
                onMenuSelect={(title, menuTitle) => menuSelect(title, menuTitle)}
            />
            <div className='content'>
                <Header
                    isOpen={menuClick}
                    onSelectHamburger={hamburgerClick}
                    menuLabel={menuLabel}
                    menuTitle={menuTitle}
                />
                <div>
                    <Outlet></Outlet>
                </div>
            </div>
        </div>
    )

    /* Render View Return - End */

}

/* Function - End */

/* Export default functionName; */

export default Layout;