
function allTypeFiles() {
    return [
        'image/png',
        'image/jpeg',
        'image/bmp',
        'image/svg',
        'image/tiff',
        'image/gif',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        'application/vnd.ms-word.template.macroEnabled.12',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.presentationml.template',
        'application/xml',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        'application/vnd.ms-excel.template.macroEnabled.12',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
        'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
        'application/vnd.ms-excel.addin.macroEnabled.12',
        'application/pdf',
        'application/x-indesign',
        'application/postscript',
        'application/vnd.dvb.ait',
        'application/acad',
        'image/vnd.dwg',
        'image/x-dwg',
        'application/dxf',
        'application/emf',
        'application/x-emf',
        'image/x-emf',
        'image/x-mgx-emf',
        'image/x-xbitmap',
        'application/x-pointplus',
        'text/css',
        'application/x-shockwave-flash',
        'image/x-pict',
        'image/x-targa',
        'image/x-tga',
        'windows/metafile',
        'image/x-dpx',
        'video/quicktime',
        'image/x-jp2',
        'image/x-jpf',
        'image/photoshop',
        'image/x-photoshop',
        'image/psd',
        'application/photoshop',
        'application/psd',
        'zz-application/zz-winassoc-psd',
        'image/x‑portable‑bitmap',
        'image/x-jps',
        'application/x-troff-msvideo',
        'video/avi',
        'video/msvideo',
        'video/x-msvideo',
        'application/octet-stream',
        'application/iff',
        'application/x-iff',
        'image/iff',
        'image/x-iff',
        'image/sgi',
        'video/mp4',
        'video/mpeg'
    ];
}

export default { allTypeFiles }