import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'universal-cookie';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import drilldown from 'highcharts/modules/drilldown'

import { TabView, TabPanel } from 'primereact/tabview';
import CheckboxElement from '../uiComponents/CheckboxElement';
import MultiSelectElement from '../uiComponents/MultiSelectElement';
import ToastElement from '../uiComponents/ToastElement';
import ButtonElement from '../uiComponents/ButtonElement';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';

import Moment from 'moment';

import TrackingSystemService from '../../services/trackingSystem/TrackingSystemService';

const Dashboards = () => {

	drilldown(Highcharts);

	const toastRef = useRef();
	const cookies = new Cookies();
	const userId = cookies.get('userId');
	const roles = cookies.get('roles');
	const isGlobalUser = cookies.get('isGlobalUser');
	const buId = cookies.get('buId');

	const [activeIndex, setActiveIndex] = useState();
	const [adminPermission, setAdminPermission] = useState(false);
	const [selectedBu, setSelectedBu] = useState([]);
	const [activeBu, setActiveBu] = useState([]);
	const [clientData, setClientData] = useState([]);
	const [qcProjects, setQcProjects] = useState([]);
	const [jobNum, setJobNum] = useState([]);
	const [errorTypes, setErrorTypes] = useState([]);
	const [actDeactUsers, setActDeactUsers] = useState([]);

	const [filter, setFilter] = useState({
		isGlobalUser: isGlobalUser,
		clients: [],
		projects: [],
		jobNum: [],
		user: [],
		errorTypes: [],
		buId: [buId],
		isGlobalJob: true
	});

	const [userChartOptions, setUserChartOptions] = useState({
		chart: {
			type: 'column'
		},
		colors: ['#CEDBD7', '#96C0C1', '#4E9FA7', '#464A4E', '#EC894E', '#D4CFC5', '#FF6969', '#E7ACF1', '#C00000', '#581845  ', '#FEBF00', '#0055ab', '#006ddc', '#007af5', '#3294f7', '#66aff9', '#99c9fb'],
		title: {
			text: 'User Quality Management',
			style: {
				"fontSize": "14px"
			}
		},
		subtitle: {
			text: '',
			style: {
				"fontSize": "12px"
			}
		},
		xAxis: {
			type: 'category'
		},
		yAxis: {
			title: {
				text: 'Total number of errors'
			}
		},
		legend: {
			enabled: false
		},
		credits: {
			enabled: false
	    },
		plotOptions: {
			series: {
				borderWidth: 0,
				dataLabels: {
					enabled: true,
					format: '{point.y:f}'
				}
			}
		},
		tooltip: {
			headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
			pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:f}</b> number of error(s)<br/>'
		},
		series: [
			{
				name: 'Error Types',
				colorByPoint: true,
				data: []
			}
		],
		drilldown: {
			breadcrumbs: {
				position: {
					align: 'right',
				}
			},
			series: []
		}
	})

	const [adminChartOptions, setAdminChartOptions] = useState({
		chart: {
			type: 'column'
		},
		colors: ['#CEDBD7', '#96C0C1', '#4E9FA7', '#464A4E', '#EC894E', '#D4CFC5', '#FF6969', '#E7ACF1', '#C00000', '#581845  ', '#FEBF00', '#0055ab', '#006ddc', '#007af5', '#3294f7', '#66aff9', '#99c9fb'],
		title: {
			text: 'All User Quality Management',
			style: {
				"fontSize": "14px"
			}
		},
		xAxis: {
			categories: []
		},
		yAxis: {
			min: 0,
			title: {
				text: 'Total number of errors'
			},
			stackLabels: {
				enabled: true,
				style: {
					fontWeight: 'bold',
					color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
				}
			}
		},
		credits: {
			enabled: false
	    },
		tooltip: {
			headerFormat: '<b>{point.x}</b><br/>',
			pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
			shared: true
		},
		plotOptions: {
			column: {
				stacking: 0.2,
				borderWidth: 0
			}
		},
		series: []
	})

	useEffect(() => {
		getActiveBu();
		getIsAdmin(userId);
		getQcUserChart(filter);
		getClientData(filter.buId, filter.isGlobalJob);
		getGlobalErrorTypes(filter.buId, filter.isGlobalJob);
		getActDeactUsers(filter.buId);
	}, [])

	const getActiveBu = () => {
		TrackingSystemService.getActiveBu().then(data => {
			let buList = data.filter(element => element.id === buId);
			setSelectedBu(buList);
			setActiveBu(data);
		})
	}

	const getIsAdmin = (userId) => {
        TrackingSystemService.adminPermission(userId).then(data => {
            setAdminPermission(data.success);
        })
    }

	const getClientData = (buIds, isGlobal) => {
		TrackingSystemService.getClientData(JSON.stringify(buIds), isGlobal).then(data => {
			setClientData(data.clients);
		});
	}

	const getGlobalErrorTypes = (buIds, isGlobal) => {
		TrackingSystemService.getGlobalErrorTypes(buIds, isGlobal).then(data => {
			setErrorTypes(data);
		})
	}

	const getActDeactUsers = (buIds) => {
		TrackingSystemService.getActDeactUsers(JSON.stringify(buIds)).then(data => {
			setActDeactUsers(data);
		})
	}

	const getQcUserChart = (filterData) => {

		if (filterData.buId.length > 0) {
			TrackingSystemService.getQcUserChart(JSON.stringify(filterData), userId).then(data => {
				setUserChartOptions(prevOptions => ({
					...prevOptions,
					series: [{
						...prevOptions.series[0],
						data: data.errorSeries
					}],
					drilldown: {
						...prevOptions.drilldown,
						series: data.drillSeries
					}
				}));
			})
		}
		else {
			toastRef.current.showToast({
				type: 'error',
				message: 'Please Select Business Unit'
			})
		}
	}

	const getQcAdminChart = (filterData) => {
		if (filterData.buId.length > 0) {
			TrackingSystemService.getQcAdminChart(filterData).then(data => {
				let seriesTotal = [];
				let adminChartData = data.categories;
				let adminDashboardData = data.series;
				let errorTypes = data.errorType;

				let categories = adminChartData.map(element => element.name);

				for (var i = 0; i < adminDashboardData.length; i++) {
					let series = [];
					adminDashboardData[i].forEach(element => {
						series.push(element.noOfError);
					});

					seriesTotal.push({ name: errorTypes[i], data: series });
				}

				setAdminChartOptions(prevOptions => ({
					...prevOptions,
					xAxis: {
						...prevOptions.xAxis,
						categories: categories
					},
					series: seriesTotal
				}));
			})
		}
		else {
			toastRef.current.showToast({
				type: 'error',
				message: 'Please Select Business Unit'
			})
		}
	}

	const onChangeBu = (e) => {
		setSelectedBu(e.value);

		let buIds = e.value.map(element => element.id);

		setFilter(prevData => ({
			...prevData,
			buId: buIds
		}));

		if (buIds.length > 0) {
			getClientData(buIds, filter.isGlobalJob);
			getActDeactUsers(buIds);
		}
	}

	const onChangeGlobalPost = (e) => {

		setFilter(prevData => ({
			...prevData,
			isGlobalJob: e.checked
		}));

		getClientData(filter.buId, e.checked);
		getGlobalErrorTypes(filter.buId, e.checked)
	}

	const onChangeFilter = (e) => {
		let id = e.target.id;
		let value = e.target.value;

		// let clientIds = e.value.map(element => element.id);

		// setFilter(prevData => ({
		// 	...prevData,
		// 	clients: clientIds
		// }));

		if(id == 'to' || id == 'from') {
			value = Moment(value).format('YYYY-MM-DD');
		}
		setFilter({...filter, [id]: value});

		// if (clientIds.length > 0) {
		// 	TrackingSystemService.getQcProjects(clientIds, filter.isGlobalJob).then(data => {
		// 		setQcProjects(data);
		// 	})
		// }
		// else {
		// 	setQcProjects([]);
		// }
		if (id == 'clients') {
			if (value.length > 0) {
				TrackingSystemService.getQcProjects(value, filter.isGlobalJob).then(data => {
					setQcProjects(data);
				})
			}
			else {
				setQcProjects([]);
			}
		}
		else if (id == 'projects') {
			if (value.length > 0) {
				TrackingSystemService.getQcJobs(filter.clients, value, filter.isGlobalJob).then(data => {
					setJobNum(data);
				})
			}
			else {
				setJobNum([]);
			}
		}
	}

	const onChangeProject = (e) => {
		let projectIds = e.value.map(element => element.id);

		setFilter(prevData => ({
			...prevData,
			projects: projectIds
		}));

		if (projectIds.length > 0) {
			TrackingSystemService.getQcJobs(filter.clients, projectIds, filter.isGlobalJob).then(data => {
				setJobNum(data);
			})
		}
		else {
			setJobNum([]);
		}
	}

	const onChangeJobNum = (e) => {
		let jobIds = e.value.map(element => element.id);

		setFilter(prevData => ({
			...prevData,
			jobNum: jobIds
		}));
	}

	const onChangeFromDate = (e) => {
		const fromDate = Moment(e.value).format('YYYY-MM-DD');

		setFilter(prevData => ({
			...prevData,
			from: fromDate
		}));
	}

	const onChangeToDate = (e) => {
		const toDate = Moment(e.value).format('YYYY-MM-DD');

		setFilter(prevData => ({
			...prevData,
			to: toDate
		}));
	}

	const onChangeUser = (e) => {
		let userIds = e.value.map(element => element.id);
		setFilter(prevData => ({
			...prevData,
			user: userIds
		}));
	}

	const onChangeErrorType = (e) => {
		let errorTypeIds = e.value.map(element => element.id);
		setFilter(prevData => ({
			...prevData,
			errorTypes: errorTypeIds
		}));
	}

	const onClickUserFilter = () => {
		getQcUserChart(filter);
	}

	const onClickUserReset = () => {
		setFilter({
			// clients: [],
			// projects: [],
			// jobNum: [],
			// user: [],
			// errorTypes: [],
			buId: [buId],
			isGlobalJob: true
		});

		let buList = activeBu.filter(element => element.id === buId);
		setSelectedBu(buList);

		// setClientData([]);
		setQcProjects([]);
		setJobNum([]);
		// setActDeactUsers([]);
		// setErrorTypes([]);

		getQcUserChart({
			buId: [buId],
			isGlobalJob: true
		});
	}

	const onClickAdminFilter = () => {
		getQcAdminChart(filter);
	}

	const onClickAdminReset = () => {
		setFilter({
			// clients: [],
			// projects: [],
			// jobNum: [],
			// user: [],
			// errorTypes: [],
			buId: [buId],
			isGlobalJob: true
		});

		let buList = activeBu.filter(element => element.id === buId);
		setSelectedBu(buList);

		// setClientData([]);
		setQcProjects([]);
		setJobNum([]);
		// setActDeactUsers([]);
		// setErrorTypes([]);

		getQcAdminChart({
			buId: [buId],
			isGlobalJob: true
		});
	}

	return (
		<div className='qc-charts mt-20' id="serviceDesk">
			<ToastElement ref={toastRef} />
			<div className='tracking-system-tabview'>
			<TabView activeIndex={activeIndex} onTabChange={(e) => { setActiveIndex(e.index) }}>
				<TabPanel header='User' className='tracking-system-tab'>
					<div className="cancel-request">
						{
							isGlobalUser &&
							<div className="p-grid d-flex">
								<div className="p-col-12 p-md-3 p-sm-3 tracking-system-multiselect">
									<p className="font-normal fs-13 text-lightgray mt-0">Business Unit <span className='text-red'>*</span></p>
									<MultiSelectElement
										className="p-inputtext-multiselect"
										placeholder="Select"
										optionLabel="name"
										options={activeBu}
										value={selectedBu}
										maxSelectLabel={1}
										onChangeValue={(e) => onChangeBu(e)}
									/>
								</div>
								<div className="p-col-12 p-md-3 p-sm-3 leave-request d-flex">
									<CheckboxElement
										className='font-normal fs-13 text-lightgray'
										value='Is Global Post'
										checked={filter.isGlobalJob}
										onChangeCheck={(e) => onChangeGlobalPost(e)}
									/>
								</div>
							</div>
						}
						<div className="p-grid d-flex">
							<div className="p-col-12 p-md-3 p-sm-3 tracking-system-multiselect">
								<p className="font-normal fs-13 text-lightgray mt-0">Client</p>
								<MultiSelect
									className="p-inputtext-multiselect"
									id="clients"
									value={filter.clients}
									placeholder="Select"
									optionLabel="clientName"
									optionValue='id'
									options={clientData}
									maxSelectedLabels={1}
									onChange={(e) => onChangeFilter(e)}
								/>
							</div>
							<div className="p-col-12 p-md-3 p-sm-3 tracking-system-multiselect">
								<p className="font-normal fs-13 text-lightgray mt-0">Project</p>
								<MultiSelect
									className="p-inputtext-multiselect"
									id='projects'
									value={filter.projects}
									optionValue='id'
									placeholder="Select"
									optionLabel="name"
									options={qcProjects}
									maxSelectedLabels={1}
									onChange={(e) => onChangeFilter(e)}
								/>
							</div>
							<div className="p-col-12 p-md-3 p-sm-3 tracking-system-multiselect">
								<p className="font-normal fs-13 text-lightgray mt-0">Job Number</p>
								<MultiSelect
									className="p-inputtext-multiselect"
									id='jobNum'
									value={filter.jobNum}
									optionValue='id'
									placeholder="Select"
									optionLabel="jobNumber"
									options={jobNum}
									maxSelectedLabels={1}
									onChange={(e) => onChangeFilter(e)}
								/>
							</div>
							<div className="p-col-2 p-md-2 pl-0 pr-6 filter-times expence-box userFilter">
								<ButtonElement
									className="fs-13 font-normal"
									icon="pi pi-filter-fill"
									iconPos="right"
									onClickButton={() => onClickUserFilter()}
									tooltip="Filter"
								/>
							</div>
							<div className="p-col-2 p-md-2 pl-0 pr-6 filter-times expence-box userFilter">
								<ButtonElement
									className="fs-13 font-normal"
									icon="pi pi-filter-slash"
									iconPos="right"
									onClickButton={() => onClickUserReset()}
									tooltip="Reset"
								/>
							</div>
						</div>
						<div className='d-flex justify-center mt-10'>
							<HighchartsReact className="tracking-system-dashboard" highcharts={Highcharts} options={userChartOptions} />
						</div>
					</div>
				</TabPanel>
				{
					(roles.includes('Super Admin') || adminPermission == true) &&
					<TabPanel header='Admin' className='tracking-system-tab'>
						<div className="cancel-request">
							<div className="p-grid d-flex">
								<div className="p-col-12 p-md-3 p-sm-3 tracking-system-multiselect">
									<p className="font-normal fs-13 text-lightgray mt-0">Business Unit <span className='text-red'>*</span></p>
									<MultiSelectElement
										className="p-inputtext-multiselect"
										placeholder="Select"
										optionLabel="name"
										options={activeBu}
										value={selectedBu}
										maxSelectLabel={1}
										onChangeValue={(e) => onChangeBu(e)}
									/>
								</div>
								<div className="p-col-12 p-md-3 p-sm-3 leave-request d-flex">
									<CheckboxElement
										className='font-normal fs-13 text-lightgray'
										value='Is Global Post'
										checked={filter.isGlobalJob}
										onChangeCheck={(e) => onChangeGlobalPost(e)}
									/>
								</div>
							</div>
							<div className="p-grid d-flex">
								<div className="p-col-12 p-md-2 p-sm-2">
									<p className="font-normal fs-13 text-lightgray mt-0">From</p>
									<h5 className='dropdown-heading'></h5>
									<Calendar
										className='leave-request'
										style={{height: '34px'}}
										id="from"
										// name='fromDate'
										placeholder='Select'
										view='date'
										dateFormat='yy-mm-dd'
										value={filter.from}
										onChange={(e) => onChangeFilter(e)}
										showIcon
									/>
								</div>
								<div className="p-col-12 p-md-2 p-sm-2">
									<p className="font-normal fs-13 text-lightgray mt-0">To</p>
									<h5 className='dropdown-heading'></h5>
									<Calendar
										className='leave-request p-calendar-w-btn'
										style={{height: '34px'}}
										id='to'
										// name='toDate'
										placeholder='Select'
										view='date'
										dateFormat='yy-mm-dd'
										value={filter.to}
										onChange={(e) => onChangeFilter(e)}
										showIcon
									/>
								</div>
								<div className="p-col-12 p-md-3 p-sm-3 tracking-system-multiselect">
									<p className="font-normal fs-13 text-lightgray mt-0">User</p>
									<MultiSelect
										className="p-inputtext-multiselect"
										id='user'
										optionValue='id'
										value={filter.user}
										placeholder="Select"
										optionLabel={(option) => `${option.firstName} ${option.lastName}`}
										options={actDeactUsers}
										maxSelectedLabels={1}
										onChange={(e) => onChangeFilter(e)}
									/>
								</div>
								<div className="p-col-12 p-md-3 p-sm-3 tracking-system-multiselect">
									<p className="font-normal fs-13 text-lightgray mt-0">Error Type</p>
									<MultiSelect
										className="p-inputtext-multiselect"
										id='errorTypes'
										optionValue='id'
										value={filter.errorTypes}
										placeholder="Select"
										optionLabel="type"
										options={errorTypes}
										maxSelectedLabels={1}
										onChange={(e) => onChangeFilter(e)}
									/>
								</div>
								<div className="p-col-2 p-md-2 pl-0 pr-6 filter-times expence-box userFilter">
									<ButtonElement
										className="fs-13 font-normal"
										icon="pi pi-filter-fill"
										iconPos="right"
										onClickButton={() => onClickAdminFilter()}
										tooltip="Filter"
									/>
								</div>
								<div className="p-col-2 p-md-2 pl-0 pr-6 filter-times expence-box userFilter">
									<ButtonElement
										className="fs-13 font-normal"
										icon="pi pi-filter-slash"
										iconPos="right"
										onClickButton={() => onClickAdminReset()}
										tooltip="Reset"
									/>
								</div>
							</div>
							<div className='d-flex justify-center mt-10'>
								<HighchartsReact className='tracking-system-dashboard' highcharts={Highcharts} options={adminChartOptions} />
							</div>
						</div>
					</TabPanel>
				}
			</TabView>
			</div>
		</div>
	)
}

export default Dashboards;