/**
 * Authentication Service
 */
import axios from 'axios';

let baseUrl = process.env.REACT_APP_API_URL;

/* Leave Page Service - Start */

async function allHolidays(currentBuId) {
	let response = await axios.get(`${baseUrl}/api/holiday/holidayByBuId/${currentBuId}`);
	return response.data;
}

async function getAllLeaveCount(currentId) {
	let response = await axios.get(`${baseUrl}/api/v1/leaveCount/${currentId.currentUserId}/${currentId.currentBuId}`);
	return response.data;
}

async function getPermissionBalance(currentUserId) {
	let response = await axios.get(`${baseUrl}/api/permission-balance/${currentUserId}`);
	return response.data;
}

async function getAllLeaveRequestByUser(currentUserId) {
	let response = await axios.get(`${baseUrl}/api/leave-request/getLeaveRequest/users?userId=${currentUserId}`);
	return response.data;
}

async function getAllCompRequestByUser(currentUserId) {
	let response = await axios.get(`${baseUrl}/api/employee-compoff-request/getCompOffRequest/${currentUserId}`);
	return response.data;
}

async function getAllPermissionRequestByUser(currentUserId) {
	let response = await axios.get(`${baseUrl}/api/employee-permission-request/getPermissionRequest/${currentUserId}`);
	return response.data;
}

async function getAssociatesLeaveList(reportingUserId) {
	let response = await axios.get(`${baseUrl}/api/leave-request/getReportersLeaveRequest/${reportingUserId}`);
	return response.data;
}

async function getAssociatesCompOffList(reportingUserId) {
	let response = await axios.get(`${baseUrl}/api/employee-compoff-request/getReportersCompRequest/${reportingUserId}`);
	return response.data;
}

async function getAssociatesPermissionList(reportingUserId) {
	let response = await axios.get(`${baseUrl}/api/employee-permission-request/getReportersPermissionRequest/${reportingUserId}`);
	return response.data;
}

async function myLeaveCancel(data) {
	let response = await axios.patch(`${baseUrl}/api/leave-request/leaveCancel/${data.id}`, data);
	return response.data;
}

async function assLeaveApproveRejectCancel(data) {
	let response = await axios.post(`${baseUrl}/api/leave-balance/bulk`, data);
	return response.data;
}

async function myCompOffCancel(data) {
	let response = await axios.patch(`${baseUrl}/api/employee-compoff-request/userCompOffCancel/${data.id}`, data);
	return response.data;
}

async function assCompOffApproveReject(data) {
	let response = await axios.patch(`${baseUrl}/api/employee-compoff-request/compOff/bulk`, data);
	return response.data;
}

async function myPermissionCancel(data) {
	let response = await axios.patch(`${baseUrl}/api/employee-permission-request/userPermissionCancel/${data.id}`, data);
	return response.data;
}

async function assPermissionApproveRejectCancel(data) {
	let response = await axios.patch(`${baseUrl}/api/employee-permission-request/permission/bulk`, data);
	return response.data;
}

async function editMyLeaveDetails(leaveId) {
	let response = await axios.get(`${baseUrl}/api/leave-request/${leaveId}`);
	return response.data;
}

async function editMyCompOffDetails(compId, userId) {
	let response = await axios.get(`${baseUrl}/api/employee-compoff-request/editCompOffRequest/${userId}/${compId}`);
	return response.data;
}

async function editMyPermissionDetails(permissionId, userId) {
	let response = await axios.get(`${baseUrl}/api/employee-permission-request/editPermissionRequest/${userId}/${permissionId}`);
	return response.data;
}

/* Leave Page Service - End */

/* New Request Modal Service - Start */

async function getLeaveTypes(currentUserId) {
	let response = await axios.get(`${baseUrl}/api/leave-balance/getLeaveType/${currentUserId}`);
	return response.data;
}

async function getTotalLeave(leaveData) {
	let response = await axios.post(`${baseUrl}/api/leave-request/postLeaveCalculation`, leaveData);
	return response.data;
}

async function leaveRequest(data) {
	let response = await axios.post(`${baseUrl}/api/leave-request/`, data);
	return response.data;
}

async function compOffRequest(compoffdata) {
	let response = await axios.post(`${baseUrl}/api/employee-compoff-request/`, compoffdata);
	return response.data;
}

async function permissionRequest(permissiondata) {
	let response = await axios.post(`${baseUrl}/api/employee-permission-request/`, permissiondata);
	return response.data;
}

async function updateLeave(id, editData) {
	let response = await axios.patch(`${baseUrl}/api/leave-request/${id}`, editData);
	return response.data;
}

async function updateCompOff(id, editData) {
	let response = await axios.patch(`${baseUrl}/api/employee-compoff-request/${id}`, editData);
	return response.data;
}

async function updatePermission(id, editData) {
	let response = await axios.patch(`${baseUrl}/api/employee-permission-request/${id}`, editData);
	return response.data;
}

async function leaveConfiguration(buId) {
	let response = await axios.get(`${baseUrl}/api/leave-config/${buId}`);
	return response.data;
}

async function getLeaveType(buId) {
	let response = await axios.get(`${baseUrl}/api/leave-type/leaveTypeByBuId/${buId}`);
	return response.data;
}

async function getUsersListByBuId(buId) {
	let response = await axios.get(`${baseUrl}/api/user/getUserDetails/${buId}`);
	return response.data;
}

async function getReporterDetails(userId) {
	let response = await axios.get(`${baseUrl}/api/user/getReporterDetails/${userId}`);
	return response.data;
}

async function getAllLeaveRequests(buId) {
	let response = await axios.get(`${baseUrl}/api/leave-request/getAllLeaveRequests/${buId}`);
	return response.data;
}

async function getReporteesDetails(reportingUserId, isWfh) {
	let response = await axios.get(`${baseUrl}/api/user/reporteesDetails/reportees/${reportingUserId}/${isWfh}`);
	return response.data;
}
// async function getReporteesDetails(reportingUserId) {
// 	let response = await axios.get(`${baseUrl}/api/user/reporteesDetails/reportees/${reportingUserId}`);
// 	return response.data;
// }
async function getDeliveryUserDetails(buId) {
	let response = await axios.get(`${baseUrl}/api/user/deliveryUserDetails/${buId}`);
	return response.data;
}

async function getLeaveBalanceByTypeAndUser(leaveTypeId, userId) {
	let response = await axios.get(`${baseUrl}/api/leave-balance/getleaveBalance/${leaveTypeId}/${userId}`);
	return response.data;
}


async function getLeaveTypeByLeaveTypeId(leaveTypeId) {
	let response = await axios.get(`${baseUrl}/api/leave-type/${leaveTypeId}`);
	return response.data;
}

async function uploadFile(files, category){
    let name= "";
    let type = "";
    for (const file of files) {
        name = file.name;
        type = file.type;
    }
    const formData = new FormData();
    // Convert file to blob
    var blob = new Blob(files, { type: files[0].type, name: files[0].type})
    // Appending all data using form-data
    formData.append("file", blob);
    formData.append("fileName", name);
    formData.append("fileType",type);
    formData.append("category", category);

    let response = await axios.post(`${baseUrl}/api/expenses/uploadFile/new`,formData,{ config:{ "Content-Type": "multipart/form-data"}});
    return response.data;
}

/* New Request Modal Service - End */

/* Export default functionNames; */

export default { getLeaveTypeByLeaveTypeId, allHolidays, getAllLeaveCount, getPermissionBalance, getAllLeaveRequestByUser, getAllCompRequestByUser, getAllPermissionRequestByUser, getAssociatesLeaveList, getAssociatesCompOffList, getAssociatesPermissionList, myLeaveCancel, assLeaveApproveRejectCancel, myCompOffCancel, assCompOffApproveReject, myPermissionCancel, assPermissionApproveRejectCancel, editMyLeaveDetails, getLeaveTypes, getTotalLeave, leaveRequest, updateLeave, editMyCompOffDetails, updateCompOff, editMyPermissionDetails, updatePermission, compOffRequest, permissionRequest, leaveConfiguration, getLeaveType, getUsersListByBuId, getReporterDetails, getAllLeaveRequests, getReporteesDetails, getDeliveryUserDetails, getLeaveBalanceByTypeAndUser, uploadFile };