/**
 * Authentication Service
 */
import axios from 'axios';

let baseUrl = process.env.REACT_APP_API_URL;

async function editMyLeaveDetails(leaveId) {
    let response = await axios.get(`${baseUrl}/api/leave-request/${leaveId}`);
    return response.data;
}
async function getAssociatesLeaveList(reportingUserId) {
    let response = await axios.get(`${baseUrl}/api/leave-request/getReportersLeaveRequest/${reportingUserId}`);
    return response.data;
}

async function assLeaveApproveRejectCancel(data) {
    let response = await axios.post(`${baseUrl}/api/leave-balance/bulk`, data);
    return response.data;
}

async function getAssociatesCompOffAllList(reportingUserId) {
    let response = await axios.get(`${baseUrl}/api/employee-compoff-request/getReportersCompRequest/${reportingUserId}`);
    return response.data;
}

async function getAssociatesCompOffList(reportingUserId, filter) {
    let response = await axios.get(`${baseUrl}/api/employee-compoff-request/getReportersCompRequest/${reportingUserId}?from=${filter.from}&to=${filter.to}&status=${filter.status}&user=${filter.user}`);
    return response.data;
}

async function assCompOffApproveReject(data) {
    let response = await axios.patch(`${baseUrl}/api/employee-compoff-request/compOff/bulk`, data);
    return response.data;
}

export default { getAssociatesCompOffAllList, assCompOffApproveReject, assLeaveApproveRejectCancel, getAssociatesLeaveList, editMyLeaveDetails, getAssociatesCompOffList };